
import Tab from '@mui/material/Tab';
import TabList from "@mui/lab/TabList";
import { Box, Stack } from "@mui/material";
import { TerminationReport } from "api/Api";

import { useEffect, useState } from "react";
import TabContext from '@mui/lab/TabContext';
import { MonthlyTerminationReport } from './Monthly/MonthlyTurnoverReport';
import { AnnualTerminationReport } from './Annual/AnnualTurnoverReport';


export type TerminationFilters = {
    startDate: Date,
    endDate: Date,
    was_terminated: boolean,
}

export function TurnoverReportData({terminationData, filters} : {terminationData?: TerminationReport, filters: TerminationFilters}) {
    const [currentTab, setCurrentTab] = useState<string>('annual');
    const [years, setYears] = useState<number[]>([]);
    useEffect(() => {
        if(!terminationData){
            return;
        }
        const years: number[] = [];
        let i = filters.startDate.getFullYear();
        while(i <= filters.endDate.getFullYear()){
            years.push(i);
            i++;
        }
        setYears(years);
    }, [terminationData, filters]);
    if(!terminationData){
        return <div className="loading">Loading...</div>
    }
    return (<>
        <Box style={{width: "100%"}}>
            <TabContext value={`${currentTab}`}>
                <TabList onChange={(event, newTab)=>{
                    setCurrentTab(newTab);
                }}>
                    {years.map(year => {
                        return (<Tab
                            key={year}
                            label={`${year}`}
                            value={`${year}`}
                            />);
                        })
                    }
                    <Tab 
                        key={'annual'}
                        label={'Annual'}
                        value={'annual'}
                    />
                </TabList>
            </TabContext>
            {years.map(year => {
                return (<Stack direction="row" style={{width: "100%"}} 
                    key={year}
                    display={currentTab === `${year}` ? "block" : "none"}
                    sx={{ width: "100%" }}
                    >
                        <Box>
                            <MonthlyTerminationReport year={year} terminationData={terminationData} filters={filters} />
                        </Box>
                    </Stack>)
                })
            }
            <Stack direction="row" style={{width: "100%"}} 
                display={currentTab === 'annual' ? "block" : "none"}
                sx={{ width: "100%" }}
                >
                    <Box>
                        <AnnualTerminationReport terminationData={terminationData} filters={filters} />
                    </Box>
                </Stack>
        </Box>
    </>);
}

