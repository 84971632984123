import { Autocomplete, Box, Button, FormGroup, Link, Stack, Table, TableBody, TableCell, TableHead, TextField } from "@mui/material";
import { EmailSubscriptionMap, EmployeeEmail } from "api/Api";
import { Input, TableRow } from "components";
import { useIncompleteSubscribers } from "hooks/email/useIncompleteSubscribers";
import { usePotentialSubscribers } from "hooks/email/usePotentialSubscribers";
import { useEffect, useRef, useState } from "react";
import { zeroPad } from "utils";

export function SubscriptionPicker({ listId, facilityId,subscribers, onChange, }: {listId: number, facilityId: number, subscribers: EmailSubscriptionMap[], onChange: Function}) {
    const tempBadgeRef = useRef(null);
    const [potentialSubscribers, potentialSubscribersLoading] = usePotentialSubscribers();
    const [incompleteSubscribers, incompleteSubscribersLoading] = useIncompleteSubscribers();
    const [tempSubscriber, setTempSubscriber] = useState<string | null>(null);
    const [tempBadgeNumber, setTempBadgeNumber] = useState<string | null>(null);
    const [badgeNumberIsValid, setBadgeNumberIsValid] = useState<boolean>(true);

    useEffect(()=>{
        const badgeNumber = zeroPad(parseInt(`${tempBadgeNumber}`), 0);
        // if the badge number is not in either the potential or incomplete subscribers, then it is invalid
        if(!potentialSubscribers.find((subscriber) => `${subscriber.badgeNumber}` === `${badgeNumber}`) && !incompleteSubscribers.find((subscriber) => `${subscriber.badgeNumber}` === `${badgeNumber}`)){
            setBadgeNumberIsValid(false)
        } else {
            setBadgeNumberIsValid(true)
        }

    }, [tempBadgeNumber])

    useEffect(() => {
        console.log(tempSubscriber)
    }, [tempSubscriber])

    function addSubscriber(){
        if(tempSubscriber && tempSubscriber !== '' && tempBadgeNumber){
            onChange([...subscribers, {facilityId, email: tempSubscriber, badgeNumber: tempBadgeNumber}]);
            setTempSubscriber('');
            setTempBadgeNumber(null);
        }
    }

    function handleDelete(deletedSubscriber: EmailSubscriptionMap){
        onChange(subscribers.filter((subscriber:EmailSubscriptionMap) => subscriber.email !== deletedSubscriber.email));
    }

    if(potentialSubscribersLoading){ 
        return <div>Loading subscriber list...</div>;
    }
    
    return (
        <Stack direction="column" spacing={4}>
            <FormGroup>
            <Stack width="100%" direction="row" spacing={2}>
            <Box width="60%">
                <Autocomplete
                    fullWidth
                    freeSolo
                    id="subscriber-lookup"
                    
                    options={potentialSubscribers}
                    getOptionLabel={(subscriber: string | EmployeeEmail) => {
                        if (typeof subscriber === 'string') {
                            return subscriber;
                        }
                        return `"${subscriber.firstName} ${subscriber.lastName}" <${subscriber.email}>`;
                    }}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Search Known Email Addresses"
                        value={tempSubscriber}
                    />
                    )}
                    onChange={(e, value) => {
                        if(!value){return;}
                        if(typeof value === 'string'){
                            setTempSubscriber(value);
                        } else {
                            console.log(value);
                            setTempSubscriber(value.email);
                            setTempBadgeNumber(zeroPad(parseInt(`${value.badgeNumber}`), 6));
                        }
                        setTimeout(()=>{
                            if(tempBadgeRef?.current){
                                (tempBadgeRef.current as HTMLElement).focus();
                            }
                        }, 100)
                    }}
                    onBlur={(e) => {
                        const target = e.target as HTMLInputElement;
                        if(typeof target.value === 'string'){
                            setTempSubscriber(target.value);
                        }
                    }}
                />
            </Box>
            <Box width="30%">
                <Input inputRef={tempBadgeRef} value={tempBadgeNumber} onChange={(e)=> {
                    setTempBadgeNumber(e.target.value);
                }} errors={tempBadgeNumber && tempBadgeNumber.length > 0 && !badgeNumberIsValid?['Badge number is unknown']:[]} type="text" label="Badge Number" />
            </Box>
            <Box width="10%">
                <Button 
                disabled={!badgeNumberIsValid}
                onClick={()=>{addSubscriber()}}
                >Add</Button>
            </Box>
            </Stack>
            </FormGroup>
            <Box>
                <Stack direction="row" spacing={1}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Email</TableCell>
                                <TableCell>Badge Number</TableCell>
                                <TableCell>Delete</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                    {subscribers.map((subscriber:EmailSubscriptionMap, index: number) => (
                        <TableRow key={`subscribers-${index}`}>
                            <TableCell>{subscriber.email}</TableCell>
                            <TableCell>{zeroPad(parseInt(subscriber.badgeNumber), 6)}</TableCell>
                            <TableCell>
                                <Link
                                    href="#Delete"
                                    onClick={(e)=>{
                                        e.preventDefault();
                                        handleDelete(subscriber);
                                    }}
                                >Delete</Link>
                            </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                    </Table>
                </Stack>
            </Box>
        </Stack>
    
    );
}
