import { Filter, FilterRelativeDateValueUnit } from "api/Api";
import { Checkbox, DatePicker, Grid, Input, Select, Stack, TimePicker, Typography } from "components";
import { useElementBreakpoints } from "hooks";
import moment, { Moment, unitOfTime } from "moment";
import { ReportContext } from "pages/ReportPage/ReportContextProvider";
import { useContext, useEffect, useState } from "react";

type InputType =
  | "multiTextInput"
  | "singleTextInput"
  | "betweenTextInputs"
  | "singleDateInput"
  | "betweenDateInputs"
  | "singleTimeInput"
  | "betweenTimeInputs"
  | "relativeDateInputs"
  | "nullCheckbox";

export const FilterValueInput = (props: { filter: Filter; setFilter: (filter: Filter) => void }) => {
  const { breakpointsRef, elementWidth } = useElementBreakpoints();
  const BREAKPOINT_1 = 280;

  const [inputType, setInputType] = useState<InputType | undefined>();
  const [inputValue, setInputValue] = useState<string>("");
  const reportContext = useContext(ReportContext);
  const fieldOutputDataType = reportContext.getFieldOutput(props.filter.field)?.dataType;
  const unitOptions = reportContext.filterRelativeDateValueUnitOptions;
  const operator = props.filter.operator;
  const lookupValues =
    reportContext.lookups
      ?.find(
        (x) =>
          x.dbName?.toLowerCase() === props.filter.field?.dbName?.toLowerCase() &&
          x.table?.toLowerCase() === props.filter.field?.tableName?.toLowerCase() &&
          x.column?.toLowerCase() === props.filter.field?.fieldName?.toLowerCase()
      )
      ?.values?.filter((x) => x !== "") || [];
  const [relativeDatePast, setRelativeDatePast] = useState<boolean>(false);

  const value1 = props.filter.values?.[0] ?? "";
  const value2 = props.filter.values?.[1] ?? "";
  const setValue1 = (value1: string) => {
    let values = [...props.filter.values];
    if (values?.[0] !== undefined || values?.[0] !== null) values[0] = value1;
    else values = [value1];
    props.setFilter({ ...props.filter, values: values });
  };
  const setValue2 = (value2: string) => props.setFilter({ ...props.filter, values: [value1, value2] });

  // when field or operator changes, determine the input type to show
  useEffect(() => {
    const operator = props.filter.operator;
    let newInputType: InputType | undefined = undefined;

    switch (fieldOutputDataType) {
      case "String":
        if (
          operator === "is" ||
          operator === "is_not" ||
          operator === "starts_with" ||
          operator === "ends_with" ||
          operator === "contains" ||
          operator === "does_not_contain"
        )
          newInputType = "multiTextInput";
        if (operator === "is_null" || operator === "is_not_null") newInputType = "nullCheckbox";
        break;
      case "Number":
        if (operator === "is" || operator === "is_not") newInputType = "multiTextInput";
        if (
          operator === "is_greater_than" ||
          operator === "is_greater_than_or_equals" ||
          operator === "is_less_than" ||
          operator === "is_less_than_or_equals"
        )
          newInputType = "singleTextInput";
        if (operator === "is_between") newInputType = "betweenTextInputs";
        break;
      case "Date":
      case "Datetime":
        if (operator === "is" || operator === "is_not") newInputType = "singleDateInput";
        if (
          operator === "is_after" ||
          operator === "is_on_or_after" ||
          operator === "is_before" ||
          operator === "is_on_or_before"
        )
          newInputType = "singleDateInput";
        if (operator === "is_between") newInputType = "betweenDateInputs";
        if (
          operator === "is_after_relative" ||
          operator === "is_on_or_after_relative" ||
          operator === "is_before_relative" ||
          operator === "is_on_or_before_relative"
        ) {
          newInputType = "relativeDateInputs";
        }
        break;
      case "Time":
        if (
          operator === "is" ||
          operator === "is_not" ||
          operator === "is_after" ||
          operator === "is_on_or_after" ||
          operator === "is_before" ||
          operator === "is_on_or_before"
        )
          newInputType = "singleTimeInput";
        if (operator === "is_between") newInputType = "betweenTimeInputs";
        break;
    }

    setInputType(newInputType);
  }, [props.filter.field, props.filter.operator]);

  const multiTextInput = (
    <Select
      multiple
      freeSolo
      placeholder={"Multiple Values: Press Enter after each value"}
      inputValue={inputValue}
      value={props.filter.values}
      getOptionLabel={(option) => option || ""}
      onChange={(e, value) => {
        props.setFilter({ ...props.filter, values: value });
        setInputValue("");
      }}
      onInputChange={(e) => setInputValue(e.target.value)}
      options={lookupValues}
      size="small"
      onBlur={() => {
        if (inputValue) props.setFilter({ ...props.filter, values: [...props.filter.values, inputValue] });
        setInputValue("");
      }}
      inputVariant="standard"
      chipSize="small"
      disableClearable
    />
  );

  const singleTextInput = (
    <Input
      placeholder={"Value"}
      value={value1}
      onChange={(e) => setValue1(e.target.value)}
      size="small"
      variant="standard"
    />
  );

  const betweenTextInputs = (
    <Grid container spacing="xs">
      <Grid item xs={elementWidth >= BREAKPOINT_1 ? 6 : 12}>
        <Input
          placeholder={"From"}
          value={value1}
          onChange={(e) => setValue1(e.target.value)}
          size="small"
          variant="standard"
        />
      </Grid>
      <Grid item xs={elementWidth >= BREAKPOINT_1 ? 6 : 12}>
        <Input
          placeholder={"To"}
          value={value2}
          onChange={(e) => setValue2(e.target.value)}
          size="small"
          variant="standard"
        />
      </Grid>
    </Grid>
  );

  const singleDateInput = (
    <DatePicker
      placeholder={"Value"}
      value={value1}
      onChange={(value) => setValue1(value)}
      size="small"
      inputVariant="standard"
    />
  );

  const betweenDateInputs = (
    <Grid container spacing="xs">
      <Grid item xs={elementWidth >= BREAKPOINT_1 ? 6 : 12}>
        <DatePicker
          placeholder={"From"}
          value={value1}
          onChange={(value) => setValue1(value)}
          size="small"
          inputVariant="standard"
        />
      </Grid>

      <Grid item xs={elementWidth >= BREAKPOINT_1 ? 6 : 12}>
        <DatePicker
          placeholder={"To"}
          value={value2}
          onChange={(value) => setValue2(value)}
          size="small"
          inputVariant="standard"
        />
      </Grid>
    </Grid>
  );

  const relativeDateInputs = () => {
    let fromDate: Moment | undefined;
    let toDate: Moment | undefined;
    let numberOfUnits: number | undefined;
    let unit: FilterRelativeDateValueUnit | undefined;

    if (value1) numberOfUnits = Number(value1);
    if (value2) unit = value2 as FilterRelativeDateValueUnit;

    if (numberOfUnits !== undefined && unit) {
      let baseUnit: unitOfTime.Base = "days";
      let startOfUnit: unitOfTime.StartOf = "day";
      const isCalendarUnit = unit === FilterRelativeDateValueUnit.MonthsCalendar;

      switch (unit) {
        case FilterRelativeDateValueUnit.Days:
          baseUnit = "days";
          startOfUnit = "day";
          break;
        case FilterRelativeDateValueUnit.Weeks:
        case FilterRelativeDateValueUnit.WeeksCalendar:
          baseUnit = "weeks";
          startOfUnit = "week";
          break;
        case FilterRelativeDateValueUnit.Months:
        case FilterRelativeDateValueUnit.MonthsCalendar:
          baseUnit = "months";
          startOfUnit = "month";
          break;
        case FilterRelativeDateValueUnit.Years:
        case FilterRelativeDateValueUnit.YearsCalendar:
          baseUnit = "years";
          startOfUnit = "year";
          break;
      }

      switch (operator) {
        case "is_after_relative":
        case "is_on_or_after_relative":
          fromDate = moment().add(numberOfUnits, baseUnit);
          if (isCalendarUnit) fromDate = fromDate.startOf(startOfUnit);
          if (operator === "is_after_relative") fromDate = fromDate.add(1, "days");
          break;
        case "is_before_relative":
        case "is_on_or_before_relative":
          toDate = moment().add(numberOfUnits, baseUnit);
          if (isCalendarUnit) toDate = toDate.startOf(startOfUnit);
          if (operator === "is_before_relative") toDate = toDate.subtract(1, "days");
          break;
        //case "is_in_the_next":
        //   fromDate = moment();
        //   if (isCalendarUnit) fromDate = fromDate.startOf(startOfUnit).add(1, baseUnit);
        //   else if (!props.filter.includesToday) fromDate = fromDate.add(1, "days");

        //   toDate = moment().add(numberOfUnits, baseUnit);
        //   if (isCalendarUnit) toDate = toDate.endOf(startOfUnit);
        //   else if (!props.filter.includesToday) toDate = toDate.add(1, "days");
        //   break;
        // case "is_in_the_last":
        //   fromDate = moment().subtract(numberOfUnits, baseUnit);
        //   if (isCalendarUnit) fromDate = fromDate.startOf(startOfUnit);
        //   else if (!props.filter.includesToday) fromDate = fromDate.add(1, "days");

        //   toDate = moment();
        //   if (isCalendarUnit) toDate = toDate.endOf(startOfUnit).subtract(1, baseUnit);
        //   else if (!props.filter.includesToday) toDate = toDate.subtract(1, "days");
        //   break;
        // case "is_in_this":
        //   fromDate = moment().startOf(startOfUnit);
        //   toDate = moment().endOf(startOfUnit).add(numberOfUnits, baseUnit);
        //   break;
      }
    }

    return (
      <Stack>
        <Grid container spacing="xs">
          <Grid item xs={elementWidth >= BREAKPOINT_1 ? 3 : 12}>
            <Input
              type="number"
              value={numberOfUnits && Math.abs(numberOfUnits)}
              onChange={(e) => {
                let newValue: string = "";
                if (e.target.value) {
                  if (relativeDatePast) newValue = (-Math.abs(Number(e.target.value))).toString();
                  else newValue = Math.abs(Number(e.target.value)).toString();
                }
                setValue1(newValue);
              }}
              size="small"
              autoComplete="off"
              placeholder="0-10000"
              variant="standard"
              sx={{ fontSize: "5px !important" }}
            />
          </Grid>

          <Grid item xs={elementWidth >= BREAKPOINT_1 ? 4 : 12}>
            <Select
              options={unitOptions}
              value={unitOptions?.find((x) => x.unit === value2)}
              onChange={(e, value) => setValue2(value?.unit)}
              getOptionLabel={(option) => option.displayName}
              size="small"
              autoComplete="off"
              disableClearable
              inputVariant="standard"
            />
          </Grid>

          <Grid item xs={elementWidth >= BREAKPOINT_1 ? 5 : 12}>
            <Select
              options={["ago", "from now"]}
              value={relativeDatePast ? "ago" : "from now"}
              onChange={(e, value) => {
                const isPast = value === "ago";
                setRelativeDatePast(isPast);

                if (numberOfUnits) {
                  const newNumberOfUnits = Math.abs(numberOfUnits) * (isPast ? -1 : 1);
                  setValue1(newNumberOfUnits.toString());
                }
              }}
              size="small"
              autoComplete="off"
              disableClearable
              inputVariant="standard"
            />
          </Grid>
        </Grid>
        {numberOfUnits !== undefined && unit && (
          <Typography variant="caption">
            {fromDate && <>from {fromDate.format("MM/DD/YYYY")} </>}
            {toDate && <>to {toDate.format("MM/DD/YYYY")}</>}
          </Typography>
        )}
      </Stack>
    );
  };

  const singleTimeInput = (
    <TimePicker value={value1} onChange={(value) => setValue1(value)} size="small" inputVariant="standard" />
  );

  const betweenTimeInputs = (
    <Grid container spacing="xs">
      <Grid item xs={elementWidth >= BREAKPOINT_1 ? 6 : 12}>
        <TimePicker
          placeholder={"From"}
          value={value1}
          onChange={(value) => setValue1(value)}
          size="small"
          inputVariant="standard"
        />
      </Grid>
      <Grid item xs={elementWidth >= BREAKPOINT_1 ? 6 : 12}>
        <TimePicker
          placeholder={"To"}
          value={value2}
          onChange={(value) => setValue2(value)}
          size="small"
          inputVariant="standard"
        />
      </Grid>
    </Grid>
  );

  const nullCheckbox = (
    <Checkbox
      label={<Typography variant="caption">NULL includes empty and whitespaces</Typography>}
      checked={props.filter.nullIncludesEmptyAndWhitespaceString}
      onChange={(e) => props.setFilter({ ...props.filter, nullIncludesEmptyAndWhitespaceString: e.target.checked })}
      size="small"
    />
  );

  let inputElements: JSX.Element | null = null;
  if (inputType === "multiTextInput") inputElements = multiTextInput;
  if (inputType === "singleTextInput") inputElements = singleTextInput;
  if (inputType === "betweenTextInputs") inputElements = betweenTextInputs;
  if (inputType === "singleDateInput") inputElements = singleDateInput;
  if (inputType === "betweenDateInputs") inputElements = betweenDateInputs;
  if (inputType === "singleTimeInput") inputElements = singleTimeInput;
  if (inputType === "betweenTimeInputs") inputElements = betweenTimeInputs;
  if (inputType === "relativeDateInputs") inputElements = relativeDateInputs();
  if (inputType === "nullCheckbox") inputElements = nullCheckbox;
  return <Stack ref={breakpointsRef}>{inputElements}</Stack>;
};
