import { TerminationReport } from "api/Api";
import { Box, Stack } from "components";
import { monthsOfTheYear } from "../helpers";
import { TerminationFilters } from "../../TurnoverReportData";
import { useState } from "react";

export function TerminationReportByTitle({year, terminationData, filters}: {year: number; terminationData: TerminationReport; filters: TerminationFilters;}) {
    const [hoverRow, setHoverRow] = useState<number | undefined>(undefined);
    
    return <>
    <Stack direction="row">
        <Titles year={year} terminationData={terminationData} hoverRow={hoverRow} setHoverRow={setHoverRow} />
        <Terminations year={year} terminationData={terminationData} was_terminated={filters.was_terminated} hoverRow={hoverRow} setHoverRow={setHoverRow} />
        <EmployeeCount year={year} terminationData={terminationData} hoverRow={hoverRow} setHoverRow={setHoverRow} />
        <Turnovers year={year} terminationData={terminationData} hoverRow={hoverRow} setHoverRow={setHoverRow} />
    </Stack>
    </>
}

function Titles({year, terminationData, hoverRow, setHoverRow}: {year: number; terminationData: TerminationReport; hoverRow: number | undefined; setHoverRow: Function;}) {
    const jobDepartmentData: {
        [jobTitle:string]: any;
    } = terminationData.monthlyTerminationsByJobTitle;
    
    return <>
        <Box className="grouped-by-field">
            <h3>By Title</h3>
            <table>
                    <thead>
                        <tr>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                    {Object.keys(jobDepartmentData).map((jobTitle, index) => {
                        return <tr 
                                className={`${index === hoverRow ? 'hovered':''}`}
                                onMouseOver={(e)=> {
                                    setHoverRow(index);
                                }} 
                                onMouseOut={(e)=> {
                                    setHoverRow(undefined);
                                }}
                                key={`mtrbt-titles-${index}`}>
                                <th style={{width: "100%", textAlign: "left", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}}>{jobTitle}</th>
                            </tr>;
                    })}
                    </tbody>
                    <tfoot>
                        <tr>
                            <th style={{width: "100%", textAlign: "left", overflow: "hidden", whiteSpace: "nowrap"}}>Totals</th>
                        </tr>
                    </tfoot>
                </table>
        </Box>
    </>
}

function Terminations({year, terminationData, was_terminated, hoverRow, setHoverRow}: {year: number; terminationData: TerminationReport; was_terminated: boolean; hoverRow: number | undefined; setHoverRow: Function;}) {
    const jobTitleData: {
        [jobTitle:string]: any;
    } = terminationData.monthlyTerminationsByJobTitle;
    
    let maxMonth = 1;

    const columnTotals: number[] = getTerminationColumnTotals(jobTitleData, year);

    let jobTitleDataSummary: {
        [
            title: string
        ]: {
            total: number;
            months:{[month: number]: number;};
        };
    } = Object.keys(jobTitleData).reduce((acc, title, idx) => {
        const jobTitleGroupData = jobTitleData[title];
        acc[title]= {
            total: 0,
            months: {}
        };
        maxMonth = 1;
        let total = 0;
        Object.keys(jobTitleGroupData).filter((yearMonth) => yearMonth.startsWith(`${year}`)).forEach((yearMonth) => {
            const month = parseInt(yearMonth.split('-')[1])-1;
            const count = jobTitleGroupData[yearMonth];
            acc[title].months[month] = typeof count === 'string' ? parseInt(count) : count;
            total += acc[title].months[month];
            maxMonth = Math.max(maxMonth, month);
        })

        // if maxMonth is less than 12, add empty cells to the end of the array
        if (maxMonth < 11) {
            for (let i = maxMonth+1; i < 12; i++) {
                acc[title].months[i] = 0;
                columnTotals[i] = (columnTotals[i] || 0) + 0;
            }
        }

        acc[title].total = total;

        return acc;
    }, {});

    const columnWidth = `${(100/13)}%`;
    
    return <>
        
            <Box className="data-presentation termination">
                <h3>Terminations</h3>
                <table>
                    <thead>
                        <tr>
                            {
                                Array.from({length: 12}, (_, i) => i).map((month, index) => <th style={{width: columnWidth}} key={index}>{monthsOfTheYear[month]}</th>)
                            }
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(jobTitleDataSummary).map((title, index) => {
                            const jobTitleGroupData = jobTitleDataSummary[title];
                            
                            return <tr
                                    className={`${index === hoverRow ? 'hovered':''}`}
                                    onMouseOver={(e)=> {
                                        setHoverRow(index);
                                    }} 
                                    onMouseOut={(e)=> {
                                        setHoverRow(undefined);
                                    }}
                                    key={`mtrbt-terminations-${index}`}>
                                    {Object.values(jobTitleGroupData.months).map((count, idx) => {
                                        return <td key={`$dep-${year}-${idx}`} style={{ color: (count === 0 ? '#777': '#000'), textAlign: "right", width: columnWidth}}>{count}</td>
                                    })}
                                    <td style={{textAlign: "right", color: (jobTitleGroupData.total === 0 ? '#777': '#000'), width: columnWidth}}>
                                        {jobTitleGroupData.total}
                                    </td>
                                </tr>;
                        })}
                    </tbody>
                    <tfoot>
                        <tr>
                            {columnTotals.map((total, index) => <td style={{width: columnWidth, textAlign: "right"}} key={index}>{total}</td>)}
                            <td style={{width: columnWidth, textAlign: "right"}}>{Object.values(jobTitleDataSummary).reduce((acc, jobTitle) => acc + jobTitle.total, 0)}</td>
                        </tr>
                    </tfoot>
                </table>
            </Box>
        
    </>
}


function EmployeeCount({year, terminationData, hoverRow, setHoverRow}: {year: number; terminationData: TerminationReport; hoverRow: number | undefined; setHoverRow: Function;}) {
    const jobTitleData: {
        [jobTitle:string]: any;
    } = terminationData.monthlyEmployeesAverageByJobTitle;
    
    let maxMonth = 1;

    const columnTotals: number[] = getEmployeeCountColumnTotals(jobTitleData, year);

    let jobTitleDataSummary: {
        [
            title: string
        ]: {
            total: number;
            months:{[month: number]: number;};
        };
    } = Object.keys(jobTitleData).reduce((acc, title, idx) => {
        const jobTitleGroupData = jobTitleData[title];
        acc[title] = {
            total: 0,
            months: {}
        };

        maxMonth = 1;
        let total = 0;
        Object.keys(jobTitleGroupData).filter((yearMonth) => yearMonth.startsWith(`${year}`)).forEach((yearMonth) => {
            const month = parseInt(yearMonth.split('-')[1])-1;
            const count = jobTitleGroupData[yearMonth];
            acc[title].months[month] = typeof count === 'string' ? parseFloat(count) : count;
            total += acc[title].months[month];
            maxMonth = Math.max(maxMonth, month);
        })
        // if maxMonth is less than 12, add empty cells to the end of the array
        if (maxMonth < 11) {
            for (let i = maxMonth+1; i < 12; i++) {
                acc[title].months[i] = 0;
                columnTotals[i] = (columnTotals[i] || 0) + 0;
            }
        }

        acc[title].total = total;

        return acc;
    }, {});

    const columnWidth = `${(100/13)}%`;

    return <Box className="data-presentation employee-count">
                <h3>Average Employee Count</h3>
                <table style={{width: "95%"}}>
                    <thead>
                        <tr>
                            {
                                Array.from({length: 12}, (_, i) => i).map((month, index) => <th style={{width: columnWidth}} key={index}>{monthsOfTheYear[month]}</th>)
                            }
                        </tr>
                    </thead>
                    <tbody>
                    {Object.keys(jobTitleDataSummary).map((title, index) => {
                        const jobTitleGroupData = jobTitleDataSummary[title];
                        return <tr 
                                    className={`${index === hoverRow ? 'hovered':''}`}
                                    onMouseOver={(e)=> {
                                        setHoverRow(index);
                                    }} 
                                    onMouseOut={(e)=> {
                                        setHoverRow(undefined);
                                    }}
                                    key={`mtrbt-employee-count-${index}`}>
                                {Object.values(jobTitleGroupData.months).map((count, idx) => {
                                    return <td key={`$dep-${year}-${idx}`} style={{ color: (count === 0 ? '#777': '#000'), textAlign: "right", width:columnWidth}}>{count}</td>
                                })}
                            </tr>;
                    })}
                    </tbody>
                    <tfoot>
                        <tr>
                            {columnTotals.map((total, index) => <td style={{width: columnWidth, textAlign: "right"}} key={index}>{total}</td>)}
                        </tr>
                    </tfoot>
                </table>
            </Box>;
}

function Turnovers({year, terminationData, hoverRow, setHoverRow}: {year: number; terminationData: TerminationReport; hoverRow: number | undefined; setHoverRow: Function; }) {
    const jobTitleData: {
        [jobTitle:string]: any;
    } = terminationData.monthlyTurnoverRateByJobTitle;
    
    let maxMonth = 1;

    const terminationTotals = getTerminationColumnTotals(terminationData.monthlyTerminationsByJobTitle, year);
    const employeeCountTotals = getEmployeeCountColumnTotals(terminationData.monthlyEmployeesAverageByJobTitle, year);
    const turnoverRates: number[] = getTurnoverRates(terminationTotals, employeeCountTotals);

    let jobTitleDataSummary: {
        [
            title: string
        ]: {
            total: number;
            months:{[month: number]: number;};
        };
    } = Object.keys(jobTitleData).reduce((acc, title, idx) => {
        const jobTitleGroupData = jobTitleData[title];
        acc[title] = {
            total: 0,
            months: {}
        };

        maxMonth = 1;
        let total = 0;
        Object.keys(jobTitleGroupData).filter((yearMonth) => yearMonth.startsWith(`${year}`)).forEach((yearMonth) => {
            const month = parseInt(yearMonth.split('-')[1])-1;
            const count = jobTitleGroupData[yearMonth];
            acc[title].months[month] = typeof count === 'string' ? parseInt(count) : count;
            total += acc[title].months[month];
            maxMonth = Math.max(maxMonth, month);
        })

        // if maxMonth is less than 12, add empty cells to the end of the array
        if (maxMonth < 11) {
            for (let i = maxMonth+1; i < 12; i++) {
                acc[title].months[i] = 0;
            }
            maxMonth = 12;
        }

        acc[title].total = total;

        return acc;
    }, {});

    const columnWidth = `${(100/13)}%`;

    return <>
            <Box className="data-presentation turnover-rate">
                <h3>Turnover Rate</h3>
                <table>
                    <thead>
                        <tr>
                            {
                                Array.from({length: 12}, (_, i) => i).map((month, index) => <th key={index} style={{width: columnWidth}}>{monthsOfTheYear[month]}</th>)
                            }
                        </tr>
                    </thead>
                    <tbody>
                    {Object.keys(jobTitleDataSummary).map((title, index) => {
                        const jobTitleGroupData = jobTitleDataSummary[title];
                        
                        return <tr 
                                className={`${index === hoverRow ? 'hovered':''}`}
                                onMouseOver={(e)=> {
                                    setHoverRow(index);
                                }} 
                                onMouseOut={(e)=> {
                                    setHoverRow(undefined);
                                }}
                                key={`mtrbt-ratios-${index}`}>
                                {Object.values(jobTitleGroupData.months).map((count, idx) => {
                                    return <td key={`${title}-${year}-${idx}`} style={{ color: (count === 0 ? '#777': '#000'), textAlign: "right", width:columnWidth}}>{count}%</td>
                                })}
                            </tr>;
                    })}
                    </tbody>
                    <tfoot>
                        <tr>
                            {turnoverRates.map((total, index) => <td key={`mtrbt-ratios-totals-${index}`} style={{width: columnWidth, textAlign: "right"}}>{total.toFixed(1)}%</td>)}
                        </tr>
                    </tfoot>
                </table>
            </Box>
 
    </>
}

function getTerminationColumnTotals(jobTitleData, year): number[] {
    const columnTotals: number[] = [];
    Object.keys(jobTitleData).forEach((title) => {
        const jobTitleGroupData = jobTitleData[title];
        Object.keys(jobTitleGroupData).filter((yearMonth) => yearMonth.startsWith(`${year}`)).forEach((yearMonth) => {
            const month = parseInt(yearMonth.split('-')[1])-1;
            const count = jobTitleGroupData[yearMonth];
            columnTotals[month] = (columnTotals[month] || 0) + (typeof count === 'string' ? parseInt(count) : count);
        })
    });

    return columnTotals;
}

function getEmployeeCountColumnTotals(jobTitleData, year): number[] {
    const columnTotals: number[] = [];
    Object.keys(jobTitleData).forEach((title) => {
        const jobTitleGroupData = jobTitleData[title];
        Object.keys(jobTitleGroupData).filter((yearMonth) => yearMonth.startsWith(`${year}`)).forEach((yearMonth) => {
            const month = parseInt(yearMonth.split('-')[1])-1;
            const count = jobTitleGroupData[yearMonth];
            columnTotals[month] = (columnTotals[month] || 0) + (typeof count === 'string' ? parseFloat(count) : count);
        })
    });

    return columnTotals;
}

function getTurnoverRates(terminationTotals: number[], employeeCountTotals: number[]): number[] {
    const termTots = terminationTotals.map((terminationTotal, index) => {
        return (terminationTotal / employeeCountTotals[index]) * 100;
    });
    // fill in the empty months with 0
    for (let i = termTots.length; i < 12; i++) {
        termTots.push(0);
    }
    return termTots;
}