import { Box } from "components";

import { Filters } from "./Filters/Filters";
import { GroupBy } from "./GroupBy/GroupBy";
import { NavMenuItemType } from "./NavigationBar";
import { TableExplorer } from "./TableExplorer/TableExplorer";

export const DetailsPanel = (props: {
  navMenuItemType: NavMenuItemType;
  setNavMenuItemType: (type: NavMenuItemType) => void;
}) => {
  return (
    <Box height="100%" overflow="auto" flexGrow={1}>
      <Box hidden={props.navMenuItemType !== "Explorer"} height="100%" overflow="auto">
        <TableExplorer open={true} setOpen={() => {}} />
      </Box>

      <Box hidden={props.navMenuItemType !== "Filters"} height="100%" overflow="auto">
        <Filters />
      </Box>

      <Box hidden={props.navMenuItemType !== "Group-bys"} height="100%" overflow="auto">
        <GroupBy />
      </Box>
    </Box>
  );
};
