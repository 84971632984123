import { useCallback, useRef, useState } from "react";
import { useTheme } from "./useTheme";
import { Breakpoint } from "@mui/material";

type GridBreakpoints = {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
};

export const useElementBreakpoints = () => {
  const defaultSize = window.innerWidth;
  const theme = useTheme();

  const [elementWidth, setElementWidth] = useState<number>(defaultSize);

  // the following code works logically: it prevents updating the elementWidth too quickly (for complex screens like reports)
  // however, there are cases where the second value elementWidth is the true value and that causes a problem (ex. ContributorMatchPageBody)
  let timeout: NodeJS.Timeout | undefined = undefined;
  const hasImmediateUpdate = useRef(true);
  const elementRef = useCallback((node: HTMLDivElement) => {
    const handleResize = () => {
      if (node.className.includes("testBreakpoints")) console.log("handleResize: ", node.offsetWidth);

      // update immediately when width changes
      if (hasImmediateUpdate.current) {
        if (node.offsetWidth > 0 && node.offsetWidth != elementWidth) setElementWidth(node.offsetWidth);
        hasImmediateUpdate.current = false;
      }

      // then: limit subsequent updates to one update per X ms:
      // this prevents complex screens from rerendering too quickly
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        if (node.offsetWidth > 0 && node.offsetWidth != elementWidth) setElementWidth(node.offsetWidth);
        hasImmediateUpdate.current = true;
      }, 0);
    };

    const resizeObserver = new ResizeObserver(handleResize);
    if (node) {
      const resizeObserver = new ResizeObserver(handleResize);
      resizeObserver.observe(node);
    } else {
      resizeObserver.disconnect();
    }
  }, []);

  const getCurrentBreakpoint = (): Breakpoint => {
    const offset = 50; // offset to account for paddings to match closer the device's width
    const width = elementWidth + offset;

    if (width >= theme.breakpoints.values.xs && width < theme.breakpoints.values.sm) return "xs";
    if (width >= theme.breakpoints.values.sm && width < theme.breakpoints.values.md) return "sm";
    if (width >= theme.breakpoints.values.md && width < theme.breakpoints.values.lg) return "md";
    if (width >= theme.breakpoints.values.lg && width < theme.breakpoints.values.xl) return "lg";
    if (width >= theme.breakpoints.values.xl) return "xl";

    return "xs";
  };

  const setBreakpoints = (breakpoints: GridBreakpoints) => {
    let xs = breakpoints.xs;
    let sm = breakpoints.sm ?? xs;
    let md = breakpoints.md ?? sm;
    let lg = breakpoints.lg ?? md;
    let xl = breakpoints.xl ?? lg;

    const currentBreakpoint = getCurrentBreakpoint();
    switch (currentBreakpoint) {
      case "xs":
        return xs;
      case "sm":
        return sm;
      case "md":
        return md;
      case "lg":
        return lg;
      case "xl":
        return xl;
    }
  };

  return {
    breakpointsRef: elementRef,
    setBreakpoints: setBreakpoints,
    elementWidth: elementWidth,
    currentBreakpoint: getCurrentBreakpoint(),

    // some standard breakpoints to use
    xSmallFieldBreakpoints: setBreakpoints({ xl: 1, lg: 2, md: 3, sm: 4, xs: 6 }), // rarely used
    smallFieldBreakpoints: setBreakpoints({ xl: 2, lg: 3, md: 4, sm: 6, xs: 12 }), // most common
    mediumFieldBreakpoints: setBreakpoints({ xl: 3, lg: 4, md: 6, sm: 12, xs: 12 }), // rarely used
    largeFieldBreakpoints: setBreakpoints({ xl: 4, lg: 6, md: 12, sm: 12, xs: 12 }), // typically for multi-select
    xLargeFieldBreakpoints: setBreakpoints({ xl: 6, lg: 12, md: 12, sm: 12, xs: 12 }), // rarely used
    fullFieldBreakpoints: setBreakpoints({ xl: 12, lg: 12, md: 12, sm: 12, xs: 12 }), // full field like notes
  };
};
