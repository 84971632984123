import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";
import { AppRoutes } from "routes";
import { theme } from "theme";
import "./App.css";
import "./auth"; // Configure Amplify
import { registerChartJS } from 'setups/registerChartJS';

registerChartJS();

export const BASE_URL: string = `${process.env.REACT_APP_API_ENDPOINT}/${process.env.REACT_APP_API_VERSION}`;

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </ThemeProvider>
  );
}
