
import { Box } from "@mui/material";
import Stack from "@mui/material/Stack";
import { TerminationReport } from "api/Api";
import { AnnualTurnoverReportByLocation } from "./TurnoverReportByLocation/AnnualTurnoverReportByLocation";
import { AnnualTerminationReportByDepartment } from "./TurnoverReportByDepartment/AnnualTurnoverReportByDepartment";
import { AnnualTerminationReportByTitle } from "./TurnoverReportByTitle/AnnualTurnoverReportByTitle";
import { TerminationFilters } from "../TurnoverReportData";

export function AnnualTerminationReport({terminationData, filters} : {terminationData: TerminationReport, filters: TerminationFilters}) {
    return (<>
        <Stack direction="column" spacing={2}>
            <Box>
                <h1>Annual</h1>
                <AnnualTurnoverReportByLocation terminationData={terminationData} filters={filters} />
                <AnnualTerminationReportByDepartment terminationData={terminationData} filters={filters} />
                <AnnualTerminationReportByTitle terminationData={terminationData} filters={filters} />
            </Box>
        </Stack>
    </>);
    }