import { Menu, MenuItem, Stack, Typography, CircularProgress, Box } from "components";

import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { useContext, useEffect, useState } from "react";
import apiService from "services/apiService";
import { PresetListDto } from "api/Api";
import { ReportContext } from "pages/ReportPage/ReportContextProvider";
import produce from "immer";
import { addTableToTree } from "pages/ReportPage/utils/table-tree-utils";

export const MyPresetsMenuItem = (props: { closeParentMenu: () => void }) => {
  const reportContext = useContext(ReportContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [presets, setPresets] = useState<PresetListDto[]>();
  const [presetsFetched, setPresetsFetched] = useState<boolean>(false);
  const joinMaps = reportContext.joinMaps;

  useEffect(() => {
    if (anchorEl && !presetsFetched) fetchPresets();
  }, [anchorEl, presetsFetched]);

  const fetchPresets = () => {
    apiService
      .getApi()
      .presets.presetsControllerFindAll({ isPublic: false })
      .then((response) => {
        setPresets(response.data);
        setPresetsFetched(true);
      });
  };

  const fetchPreset = (id: number) => {
    props.closeParentMenu();

    apiService
      .getApi()
      .presets.presetsControllerFindOne(id)
      .then((response) => {
        // before setting the queryRequest, we need to build the queryRequest's tableTree from the preset's tables
        // which is an array
        const preset = response.data;
        reportContext.setQueryRequest(
          produce(response.data.queryRequest, (draft) => {
            preset?.tables?.forEach((table) => {
              draft.tableTree = addTableToTree(draft.tableTree, table, joinMaps);
            });
          })
        );
      });
  };

  return (
    <>
      <MenuItem
        onClick={(e: React.MouseEvent<HTMLLIElement>) => {
          e.stopPropagation();
          setAnchorEl(e.currentTarget);
        }}
      >
        <Stack width="100%" direction="row" spacing="lg" justifyContent={"space-between"} alignItems={"center"}>
          <Typography>My Presets</Typography>

          <ArrowRightIcon />
        </Stack>
      </MenuItem>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        {presets?.map((preset) => (
          <MenuItem onClick={() => fetchPreset(preset.id)}>{preset.presetName}</MenuItem>
        ))}

        {!presetsFetched && (
          <Stack display="flex" width="200px" height="100px" alignItems="center" justifyContent={"center"}>
            <CircularProgress />
          </Stack>
        )}

        {presetsFetched && (!presets || presets.length === 0) && (
          <Box display="flex" width="200px" height="100px" alignItems="center" justifyContent={"center"}>
            <Typography>No Presets</Typography>
          </Box>
        )}
      </Menu>
    </>
  );
};
