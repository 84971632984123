import { Box, Button, Divider, Paper, Stack } from "@mui/material";
import { Employee, ReportSegment, TestDashboardReportResult, TestReportQuery, TestReportResult } from "api/Api";
import { ReaderPicker } from "../../ReaderPicker/ReaderPicker";
import { useEffect, useState } from "react";
import { ReportResults } from "../ReportResults";
import apiService from "services/apiService";
import { DatePicker } from "components";
import { getUIDateFormat, zeroPad } from "utils";
import { ReportPuckPreview } from "../ReportPuckPreview";

export function ReportSegmentPreview({
    reportSegment,
    index,
    name,
    description,
    sqlQuery,
    dashboardView = false, 
    reader, 
    setReader,
    readerDate,
    setReaderDate}: 
    {
        reportSegment:ReportSegment; 
        index: number;
        name:string;
        description?: string;
        sqlQuery: string;
        dashboardView?: boolean; 
        reader: Employee | undefined; 
        setReader: (emp:Employee) => void;
        readerDate: string;
        setReaderDate:(date:string) => void;
    }) {
    const [reportContext, setReportContext] = useState({
        // userId: reader ? parseInt(reader.badge_number) : undefined,
        badgeNumber: reader?.badge_number,
        facilityId: reader?.facility_id,
        reportDate: readerDate,
    } as TestReportQuery);
    const [loading, setLoading] = useState(false);
    const [reportResults, setReportResults] = useState<TestReportResult|undefined>(undefined);
    const [reportDashboardResults, setDashboardReportResults] = useState<TestDashboardReportResult|undefined>(undefined);

    useEffect(() => {
        setReportContext(
            {
                ...reportContext,
                reportDate: readerDate
            }
        );
    }, [readerDate]);

    useEffect(()=>{
        setReportContext({
            ...reportContext,
            facilityId: `${reader?.facility_id}`,
            badgeNumber: reader ? reader.badge_number : undefined,
        })
    }, [reader])

    function runReportQuery() {
        if(dashboardView){
            apiService
            .getApi()
            .admin.reportSegmentControllerTestDashboardReportQuery({
                ...reportContext,
                query: sqlQuery,
                name,
            })
            .then((response) => {
                setDashboardReportResults(response.data as TestDashboardReportResult);
                setLoading(false);
            }).catch((e) => {
                console.log(e);
            });
        } else {
            apiService
            .getApi()
            .admin.reportSegmentControllerTestReportQuery({
                ...reportContext,
                query: sqlQuery,
            })
            .then((response) => {
                setReportResults(response.data as TestReportResult);
                setLoading(false);
            }).catch((e) => {
                console.log(e);
            });
        }
    }
    
    return <Stack spacing={2}>
        <Stack direction="row" spacing={2}>
        <Box width="65%">
             <ReaderPicker
                reader={reader}
                setReader={(e: Employee) => {
                    setReader(e);
                    setReportContext({
                        ...reportContext,
                        facilityId: `${e?.facility_id}`,
                    })
                }}
            />
            </Box>
            <Box width="35%">
                <DatePicker 
                    label="Report Date"
                    value={reportContext?.reportDate ?? new Date()}
                    onChange={(e)=>{
                        // component returns 'yyyy-mm-dd' value
                    setReaderDate(e);
                    setReportContext({
                        ...reportContext,
                        reportDate: e,
                    });
                }} />
            </Box>
        </Stack>
        <Stack direction="row">
            <Box width="33%" textAlign="center">badge_number: {reportContext.userId ? zeroPad(reportContext.userId, 6) : 'none'}</Box>
            <Box width="33%" textAlign="center">facility_id: {reportContext?.facilityId ?? 'none'}</Box>
            <Box width="33%" textAlign="center">report_date: {reportContext?.reportDate ?? getUIDateFormat(new Date())}</Box>
        </Stack>
        <Box textAlign="right">
                <Button 
                    variant="contained"
                    disabled={typeof reportContext?.facilityId === 'undefined'}
                    onClick={()=>{
                    runReportQuery();
                    setLoading(true);
                }}>Run Report Query</Button>
            </Box>
        <Divider />
        <Paper>
            <Box padding={2}>
                <div>
                    <h2>{name}</h2>
                    <p>{description}</p>
                    {
                        !dashboardView && reportResults && reportResults?.results && reportResults?.results?.length > 0 && 
                        <ReportPuckPreview reportSegment={reportSegment} reportResults={reportResults.results[0]} />
                    }
                    {
                        dashboardView && reportDashboardResults && reportDashboardResults?.dataSet && reportDashboardResults?.dataSet?.length > 0 && 
                        <ReportResults reportContext={reportContext} dashboard={reportSegment.dashboards[index]} reportResults={reportDashboardResults}  />
                    }
                </div>
            </Box>
        </Paper>
    </Stack>;
}