import { useSearchParams, useParams as routerUseParams } from "react-router-dom";

export const useParams = <TParams, TOptionalParams>(
  routeName: string,
  paramClass?: new () => TParams,
  optionalParamClass?: new () => TOptionalParams
): { params: TParams; optionalParams: TOptionalParams } => {
  const params = routerUseParams(); // get the actual parameters values
  const [searchParams] = useSearchParams();

  // Params
  let paramsCopy: TParams = {} as TParams;
  if (paramClass) {
    let paramObj = new paramClass(); // declare a class object only to get their property name and types
    let properties = Object.getOwnPropertyNames(paramObj);

    for (let property of properties) {
      const propertyType = typeof paramObj[property];
      const getValue = params[`${routeName}${property}`]; // add prefix for each parameter, that was appended to avoid name unique issues: see buildTemplatePath
      let assignValue: any = getValue;

      if (propertyType === "number") assignValue = Number(getValue);
      if (propertyType === "boolean") {
        if (getValue !== undefined) {
          assignValue = getValue === "true" ? true : false;
        } else {
          assignValue = undefined;
        }
      }

      paramsCopy[property] = assignValue;
    }
  }

  // Optional Params
  let optionalParamsCopy: TOptionalParams = {} as TOptionalParams;
  if (optionalParamClass) {
    let optionalParamObj = new optionalParamClass(); // declare a class object only to get their property name and types
    let properties = Object.getOwnPropertyNames(optionalParamObj);

    //const optionalParams = Array.from(searchParams.entries());

    for (let property of properties) {
      const propertyType = typeof optionalParamObj[property];
      const getValue = searchParams.get(property);
      let assignValue: any = getValue;
      
      if (propertyType === "number") assignValue = Number(getValue);
      if (propertyType === "boolean") {
        if (getValue !== undefined) {
          assignValue = getValue === "true" ? true : false;
        } else {
          assignValue = undefined;
        }
      }

      optionalParamsCopy[property] = assignValue;
    }
  }

  return { params: paramsCopy, optionalParams: optionalParamsCopy };
};
