import { EmailAssetInformation } from "api/Api";
import { ListItemIcon, MenuItem, Select } from "@mui/material";
import { useIcons } from "hooks/email/segments/useIcons";
import { useEffect, useState } from "react";

export function IconPicker({ segmentIconName, setSelectedIcon } : { segmentIconName: string | undefined; setSelectedIcon: (icon: string) => void}) {
    const [icons, loading] = useIcons();
    const [segmentIcon, setSegmentIcon] = useState<EmailAssetInformation | undefined>(undefined);

    useEffect(() => {
        
            if(!icons || !segmentIconName) {
                console.log(' no icons or not segmentIconName');
                return;
            }
            const icon = icons.find((icon) => icon.icon.trim() === segmentIconName.trim());
            if(icon) {
                setSegmentIcon(icon);
            } else {
                console.log(`Could not find icon: ${segmentIconName} in the list of icons.`);
                console.log(icons);
            }
        
    }, [icons, segmentIconName]);
    
    return <>
        {loading && <div>Loading...</div>}
        {!loading && <Select 
            fullWidth={true}
            value={segmentIcon?.icon ?? ''}
            placeholder="Select an Icon"
            onChange={(e) => {
                if(typeof e.target.value === 'string') {
                    setSelectedIcon(e.target.value);
                }
            }}>
                <MenuItem value={undefined}>None</MenuItem>
                {icons.map((icon) => {
                    return <MenuItem key={icon.icon} value={icon.icon}>
                        <ListItemIcon>
                            <img src={icon.iconPath} alt={icon.icon} style={{height: `1.5rem`}} />
                        </ListItemIcon>
                        {icon.icon}
                    </MenuItem>
                })}
        </Select>}
    </>;
}