import { QueryRequest } from "api/Api";

export const defaultQueryRequest: QueryRequest = {
  tableTree: undefined,
  fields: [],
  filters: [],
  sortFields: [],
  page: 1,
  pageSize: 100,
};
