import { DbtDataField, Field } from "api/Api";
import { Box, Chip, Select, Stack } from "components";
import { ReportContext } from "pages/ReportPage/ReportContextProvider";
import { useContext, useEffect, useState } from "react";

export const FilterFieldInput = (props: {
  dbtDataFields: DbtDataField[];
  selectedField: Field;
  setSelectedField: (value: Field) => void;
}) => {
  const reportContext = useContext(ReportContext);
  const [selectableFields, setSelectableFields] = useState<Field[]>([]);

  // populate selectableFields with datafields + any of the selected fields that are aggregates
  useEffect(() => {
    let fields: Field[] = [];
    props.dbtDataFields.forEach((dataField) => {
      // add the original datafield
      fields.push({ dbName: dataField.dbName, tableName: dataField.tableName, fieldName: dataField.fieldName });

      // if there are any transform fields in the selected queryRequest, add them here
      const selectedTransformedFields = reportContext.queryRequest?.fields?.filter(
        (x) =>
          x.tableName.toLowerCase() === dataField.tableName.toLowerCase() &&
          x.fieldName.toLowerCase() === dataField.fieldName.toLowerCase() &&
          x.transform
      );

      selectedTransformedFields.forEach((transformedField) => {
        fields.push(transformedField);
      });
    });

    setSelectableFields(fields);
  }, [props.dbtDataFields, reportContext.queryRequest]);

  return (
    <Select
      name="placeholderToPreventPasswordAutofill"
      placeholder="Field"
      value={props.selectedField?.fieldName ? props.selectedField : undefined}
      options={selectableFields}
      getOptionLabel={(option) => reportContext.getFieldDisplay(option, "string")!}
      renderOption={(_props, option) => {
        const lookupCount = reportContext?.lookups?.find(
          (x) =>
            x.dbName.toLowerCase() === option?.dbName?.toLowerCase() &&
            x.table.toLowerCase() === option?.tableName?.toLowerCase() &&
            x.column.toLowerCase() === option?.fieldName?.toLowerCase()
        )?.values?.length;

        return (
          <Box component="li" {..._props} sx={{ py: "0 !important" }}>
            <Stack
              direction="row"
              spacing="sm"
              alignItems={"center"}
              justifyContent="space-between"
              width="100%"
              minHeight="35px"
            >
              <Stack direction="row" spacing="sm" alignItems={"center"}>
                {reportContext.getFieldDisplay(option, "node")}

                {lookupCount && <Chip label={lookupCount} size="small" />}
              </Stack>
            </Stack>
          </Box>
        );
      }}
      onChange={(e, value) => props.setSelectedField(value)}
      size="small"
      noOptionsText={selectableFields.length === 0 && "No selectable fields"}
      autoComplete="off"
      disableClearable
      inputVariant="standard"
    />
  );
};
