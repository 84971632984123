import { Box, BoxProps, SxProps, Theme } from "@mui/material";
import { ReactNode } from "react";
import { useTheme } from "../../hooks";
import { Stack } from "../mui";

type ListContainerProps = Omit<BoxProps, "children"> & {
  header?: ReactNode;
  rightHeader?: ReactNode;
  tableContainer?: ReactNode;
  footer?: ReactNode;
  fullHeight?: boolean;
  type?: "main" | "grid";
};

export const ListContainer = ({
  sx,
  type = "main",
  header,
  rightHeader,
  tableContainer,
  footer,
  fullHeight,
  ...rest
}: ListContainerProps) => {
  const theme = useTheme();

  let _sx: SxProps<Theme> | undefined = {
    ...sx,
    display: "flex",
    flexDirection: "column",
    overflow: fullHeight ? "auto" : "unset",
    height: fullHeight ? "100%" : "auto",
  };

  if (type === "grid") {
    const gridSx: SxProps<Theme> = {
      boxShadow: "none",
      border: `1px solid ${theme.palette.border.main}`,
      //borderRadius: "0px",
      //".MuiTableCell-root": { border: "1px solid black" },
    };

    _sx = { ..._sx, ...gridSx } as SxProps<Theme>;
  }

  return (
    <Box sx={_sx}>
      {(header || rightHeader) && (
        // <Box display="flex" flexDirection="column" px={3} py={1.5} flexBasis="60px" justifyContent={"center"}>

        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems="center"
          spacing="sm"
          px={3.5}
          py={1.5}
          minHeight={type === "main" ? "50px" : "50px"}
          flexShrink={0}
          borderBottom={`1px solid ${theme.palette.border.main}`}
        >
          <Stack direction="row" spacing="sm" fontSize="1.1rem" fontWeight="light">
            {header}
          </Stack>
          <Stack direction="row" spacing="sm">
            {rightHeader}
          </Stack>
        </Stack>
      )}
      {tableContainer && (
        <Box display="flex" flexGrow={1} overflow="auto">
          {tableContainer}
        </Box>
      )}
      {footer && (
        <Box
          sx={{
            //borderTop: `1px solid ${theme.palette.border.main}`,
            fontSize: "0.875rem", // match table font
          }}
          px={2}
          py={1.5}
          flexGrow={0}
        >
          {footer}
        </Box>
      )}
    </Box>
  );
};
