import { Box, Input, Skeleton, Stack } from "components";

import produce from "immer";
import { useContext, useState } from "react";
import { getSpacing } from "theme";
import { ReportContext } from "../../ReportContextProvider";
import { addTableToTree, isTableJoinable, removeTableFromTree, treeHasTable } from "../../utils/table-tree-utils";
import { Table } from "./Table/Table";
import { useDebounce } from "hooks";

export const SelectTab = () => {
  const reportContext = useContext(ReportContext);
  const tableTree = reportContext.queryRequest.tableTree;
  const isTablesLoading = reportContext.resourceLoading;
  const selectedFields = reportContext.queryRequest.fields ?? [];
  const joinMaps = reportContext.joinMaps;
  const [searchInput, setSearchInput] = useState<string>("");

  const searchString = useDebounce(searchInput, 500);

  let filteredDbtTables = reportContext.dbtTables || [];
  if (searchString) {
    filteredDbtTables = filteredDbtTables.filter(
      (x) =>
        x.name.toLowerCase().includes(searchString.toLowerCase()) ||
        x.displayName?.toLowerCase().includes(searchString.toLowerCase()) ||
        x.dataFields?.some(
          (y) =>
            y.fieldName.toLowerCase().includes(searchString.toLowerCase()) ||
            y.displayName?.toLowerCase().includes(searchString.toLowerCase())
        )
    );
  }

  return (
    <Stack py={getSpacing("md")} direction="column" spacing="md" height="100%">
      <Box px={getSpacing("sm")}>
        <Input
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          placeholder="Search"
          size="small"
          autoComplete="off"
        />
      </Box>

      <Stack
        sx={{
          overflow: "hidden",
          "&:hover": {
            overflow: "auto",
          },
        }}
      >
        {isTablesLoading
          ? Array.from({ length: 50 }).map((_, index) => (
              <Box key={index} px={2} py={0.5}>
                <Skeleton variant="rectangular" height={28} sx={{ flexGrow: 0, flexShrink: 0 }} />
              </Box>
            ))
          : filteredDbtTables.map((dbtTable) => {
              const key = `${dbtTable.dbName}.${dbtTable.name}`;

              return (
                <Table
                  key={key}
                  clickMode="select"
                  dbtTable={dbtTable}
                  selected={treeHasTable(tableTree, dbtTable)}
                  toggleSelect={() => {
                    reportContext.setQueryRequest(
                      produce(reportContext.queryRequest, (draft) => {
                        if (treeHasTable(draft.tableTree, dbtTable))
                          draft.tableTree = removeTableFromTree(draft.tableTree, dbtTable);
                        else draft.tableTree = addTableToTree(draft.tableTree, dbtTable, joinMaps);
                      })
                    );
                  }}
                  selectedFields={selectedFields.filter(
                    (x) =>
                      x.dbName.toLowerCase() === dbtTable.dbName.toLowerCase() &&
                      x.tableName.toLowerCase() === dbtTable.name?.toLowerCase()
                  )}
                  setSelectedFields={(selectedFields) => {
                    reportContext.setQueryRequest(
                      produce(reportContext.queryRequest, (draft) => {
                        if (!treeHasTable(draft.tableTree, dbtTable))
                          draft.tableTree = addTableToTree(tableTree, dbtTable, joinMaps);

                        if (selectedFields.length === 0)
                          draft.tableTree = removeTableFromTree(draft.tableTree, dbtTable);

                        draft.fields = reportContext.mergeFields(draft.fields!, dbtTable.name, selectedFields);
                      })
                    );
                  }}
                  searchString={searchString}
                  disabled={!isTableJoinable(tableTree, dbtTable, joinMaps)}
                />
              );
            })}
      </Stack>
    </Stack>
  );
};
