import { Employee, ReportSegment as iReportSegment } from "api/Api";
import { useSegmentQuery } from "hooks/email/segments/useSegmentQuery";
import { ReportPuckPreview } from "../../ReportSegments/ReportPuckPreview";


export function ReportSegment({segment, reader, index, reportDate}: {segment: iReportSegment, reader: Employee | undefined, index: number, reportDate: string}){
    // find the segment in the potential report segments
    const [queryResults, loadingQueryResults] = useSegmentQuery({
        query: segment?.reportPuck.sqlQuery,
        facilityId: `${reader?.facility_id ?? 0}`,
        reportDate,
        targetValue: segment.reportPuck.puck_target ? parseInt(segment.reportPuck.puck_target) : undefined,
    });
    
    if(loadingQueryResults) {
        return <div style={{width: "290px"}}>Loading...</div>;
    }

    // <ReportSegmentTable segment={segment} queryResults={queryResults} />

    return <div key={`segment${index}`}>
        {!loadingQueryResults && queryResults &&  <ReportPuckPreview reportSegment={segment} reportResults={queryResults.results[0]}  /> }
    </div>
}
