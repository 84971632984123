import { DialogContent } from "./DialogContent";
import { Theme } from "@mui/material/styles";
import { Button } from "./Button";
import { Card } from "./Card";
import { Drawer } from "./Drawer";
import { Grid } from "./Grid";
import { Paper } from "./Paper";
import { Table } from "./Table";
import { Autocomplete } from "./Autocomplete";

export const overrideComponents = (theme: Theme) => {
  return Object.assign(
    Autocomplete(theme),
    Button(theme),
    Card(theme),
    DialogContent(theme),
    Drawer(theme),
    Grid(theme),
    Paper(theme),
    Table(theme)
  );
};
