import { Box, Button, Dialog, IconButton, Typography } from "components/mui";
import { useContext, useEffect, useState } from "react";
import moment from "moment";
import apiService from "services/apiService";

import { getSourceDisplayNameByName, getTableDisplayNameByName } from "../../../utils";

import { ReportContext } from "pages/ReportPage/ReportContextProvider";

export function LatestUpdateDialog() {
  const reportContext = useContext(ReportContext);
  const [open, setOpen] = useState<boolean>(false);
  const dbTables = reportContext.dbtTables;
  const [dataDates, setDataDates] = useState<{ source: string; table: string; lastUpdated: string }[]>([]);

  useEffect(() => {
    if (open)
      apiService
        .getApi()
        .reference.referenceControllerGetLastUpdated()
        .then((response) => {
          setDataDates(
            response.data.map((ref) => ({
              source: getSourceDisplayNameByName([], ref.dbName),
              table: getTableDisplayNameByName(dbTables, ref.tableName),
              lastUpdated: ref.lastUpdated,
            }))
          );
        })
        .catch((error) => {
          console.log(error);
        });
  }, [dbTables, open]);

  return (
    <Box>
      {open && (
        <Dialog
          open={true}
          content={
            <Box>
              <table width="100%">
                <thead>
                  <tr>
                    <th align="left">Source</th>
                    <th align="left">Table</th>
                    <th style={{ textAlign: "right" }}>Updated</th>
                  </tr>
                </thead>
                <tbody>
                  {dataDates.map((dataSource) => {
                    return (
                      <tr>
                        <td align="left">{dataSource.source}</td>
                        <td align="left">{dataSource.table}</td>
                        <td style={{ textAlign: "right" }}>{moment(dataSource.lastUpdated).format("YYYY-MM-DD")}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Box>
          }
          footer={
            <Button
              onClick={() => {
                setOpen(false);
              }}
              color="primary"
            >
              Close
            </Button>
          }
        ></Dialog>
      )}
      <IconButton
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          setOpen(true);
        }}
      >
        <Typography variant="caption">DATA FRESHNESS</Typography>
      </IconButton>
    </Box>
  );
}
