import { IconButton, Stack } from "components";
import MenuIcon from "@mui/icons-material/MoreHoriz";
import { DbtDataField } from "api/Api";
import { AggregateFunction, CustomFunction } from "api/Api-extension";
import { useContext, useState } from "react";
import { FieldMenu } from "./FieldMenu/FieldMenu";
import { ReportContext } from "pages/ReportPage/ReportContextProvider";

export const Field = (props: {
  dbtTable: string;
  dataField: DbtDataField;
  isSelected: boolean;
  toggleSelect: () => void;
  selectedAggregateFunctions: AggregateFunction[];
  toggleAggregateFunction: (aggregatedFunction: AggregateFunction) => void;
  selectedCustomFunctions: CustomFunction[];
  toggleCustomFunction: (customdFunction: CustomFunction) => void;
  searchString?: string;
}) => {
  const reportContext = useContext(ReportContext);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <>
      {/* Field */}
      <Stack
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={props.toggleSelect}
        direction="row"
        spacing="xs"
        alignItems={"center"}
        justifyContent="space-between"
        width="100%"
        minHeight={"32px"}
        bgcolor={props.isSelected ? "grey.200" : isHovered ? "grey.50" : undefined}
        px={1}
        pl={5}
        sx={{ cursor: "pointer" }}
        fontSize="0.85rem"
        //fontWeight={(theme) => theme.typography.fontWeightRegular}
      >
        {reportContext.getFieldDisplay(
          { dbName: props.dataField.dbName, tableName: props.dataField.tableName, fieldName: props.dataField.fieldName },
          "node",
          {
            searchString: props.searchString,
          }
        )}

        {/* <DataTypeIcon type={props.dataField.dataType} />

        <Stack
          direction="row"
          spacing="sm"
          alignItems="center"
          justifyContent="flex-start"
          {...ellipsisStyle}
          flexGrow={1}
          //fontWeight={joinable ? "bold" : undefined}
        >
          <Typography fontSize="0.9rem" fontWeight={(theme) => theme.typography.fontWeightLight}>
            <Highlighter searchWords={[props.searchString || ""]} textToHighlight={dataFieldDisplayName} />
          </Typography>
        </Stack> */}

        {(isHovered || Boolean(menuAnchorEl)) && (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setMenuAnchorEl(e.currentTarget);
            }}
            size="small"
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
        )}
      </Stack>

      <FieldMenu
        menuAnchorEl={menuAnchorEl}
        setMenuAnchorEl={setMenuAnchorEl}
        dataField={props.dataField}
        selectedAggregateFunctions={props.selectedAggregateFunctions}
        toggleAggregateFunction={props.toggleAggregateFunction}
        selectedCustomFunctions={props.selectedCustomFunctions}
        toggleCustomFunction={props.toggleCustomFunction}
      />
    </>
  );
};