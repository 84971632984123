import { Theme } from "@mui/material/styles";

// ----------------------------------------------------------------------

export const Drawer= (theme: Theme) => {
  return {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          borderRadius: "0px !important"
        }
      },
    },
  };
}
