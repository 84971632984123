import { useNavigate, NavigateFunction } from "react-router-dom";
import { CLOSE_WARNING } from "../../consts";
import { Button, ButtonProps } from "../mui";

type CloseButtonProps = Omit<ButtonProps, "children"> & {
  confirm?: boolean;
  onClick?: () => void;
};

export const CloseButton = ({ confirm, onClick, ...rest }: CloseButtonProps) => {
  const navigate = useNavigate();

  const handleClose = (confirm?: boolean) => {
    if (confirm) {
      if (window.confirm(CLOSE_WARNING) === true) {
        if (onClick) onClick();
        else navigate(`../`);
      }
    } else {
      if (onClick) onClick();
      else navigate(`../`);
    }
  };

  return (
    <Button onClick={() => handleClose(confirm)} {...rest}>
      Close
    </Button>
  );
};

export const handleClose = (nav: NavigateFunction, confirm?: boolean) => {
  if (confirm) {
    if (window.confirm(CLOSE_WARNING) === true) {
      nav(`../`);
    }
  } else {
    nav(`../`);
  }
};
