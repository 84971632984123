import { EmailAssetInformation, ReportSegment } from "api/Api";
import { useAssets } from "hooks/email/segments/useAssets";
import { useIcons } from "hooks/email/segments/useIcons";
import { useEffect, useState } from "react";

const styles = {
    puckContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
        margin: 0,
        position: 'relative' as 'relative',
        width: '290px',
        height: '256px',
    },
    theOneRing: {
        border: '1px solid #38A194',
        borderRadius: '50%',
        
        width: '186px',
        height: '186px',
        textAlign: 'center' as 'center',
        padding: '1.15rem',

    },
    theIcon: {
        width: '54px',
        height: '54px',
    },
    theValues: {
        display: 'block'
    },
    theValue: {
        display: 'inline-block',
        color: '#38A194',
        fontSize: '1.75rem',
        fontWeight: 'bold'
    },
    theTarget: {
        display: 'inline-block',
        color: '#7E90CF',
        fontSize: '1.15rem',
        fontWeight: 'bold',
        marginLeft: '5px',
    },
    theLabel: {
        fontSize: '1.1rem',
        fontWeight: 600,
        color: '#5E6C9B',
    },
    theDescription: {
        fontSize: '0.6rem',
        color: '#5E6C9B',
    },
    theDirection: {
        position: 'absolute' as 'absolute',
        bottom: '10px',
    },
    theArrow: {
        fontSize: '2rem',
    },
    theDirectionValue: {
        fontSize: '1.5rem',
        fontWeight: 'bold',
    },
    theBadDirectionValue: {
        color: '#ED4924'
    },
    theQualifier: {
        fontSize: '1.15rem',
        color: '#5E6C9B',
    },
    theCorrection: {
        fontSize: '0.9rem',
        color: '#5E6C9B',
        border: '1px solid #E5E9F5',
        borderRadius: '5px',
    }
};

export function ReportPuckPreview({reportSegment, reportResults}: {reportSegment: ReportSegment; reportResults: any;}) {
    
    const [icons, loadingIcons] = useIcons();
    const [segmentIcon, setSegmentIcon] = useState<EmailAssetInformation | undefined>(undefined);

    useEffect(() => {
        if(!loadingIcons && icons.length > 0 && reportSegment.reportPuck.icon) {
            const icon = icons.find((icon) => icon.icon === reportSegment.reportPuck.icon);
            if(icon) {
                setSegmentIcon(icon);
            }
        }
    }, [icons, loadingIcons, reportSegment.reportPuck.icon]);

    if(loadingIcons) {
        return <div>Loading... icons: {loadingIcons ? 'true': 'false'} report results: {reportResults ? "true" : "false"}</div>;
    }
    

    return <div style={styles.puckContainer}>
        <div style={{...styles.theOneRing, borderColor : (reportSegment.reportPuck.trend_is_good_field && reportResults?.[reportSegment?.reportPuck?.trend_is_good_field] === 1 ? '#38A194' : '#ED4924' )}}>
            {segmentIcon && <img style={styles.theIcon} src={`${segmentIcon.iconPath}`} alt={reportSegment.name} />}
            <div style={styles.theValues}>
                <span style={{...styles.theValue, color: (reportSegment?.reportPuck?.trend_is_good_field && reportResults?.[reportSegment?.reportPuck?.trend_is_good_field] === 1 ? '#38A194' : '#ED4924' )}}>{reportSegment?.reportPuck?.puck_value && reportResults?.[reportSegment?.reportPuck?.puck_value]}{" "}</span>
                {reportSegment?.reportPuck?.puck_target && <span style={styles.theTarget}>{" "}/ {reportResults?.[reportSegment?.reportPuck?.puck_target]}</span>}
            </div>
            <div style={styles.theLabel}>
                {reportSegment?.reportPuck?.puck_label}
            </div>
            <div style={styles.theDescription}>
                {reportSegment?.reportPuck?.puck_description}
            </div>
        </div>
        <div style={styles.theDirection}>
            <span style={styles.theArrow}>
                {reportSegment?.reportPuck?.trend_direction_field && reportSegment?.reportPuck?.trend_is_good_field && 
                    <TrendArrow 
                        direction={reportResults?.[reportSegment?.reportPuck?.trend_direction_field]} 
                        goodness={reportResults?.[reportSegment?.reportPuck?.trend_is_good_field]} />}
            </span>{ " " }
            <span style={{...styles.theDirectionValue, color: (reportSegment?.reportPuck?.trend_is_good_field && reportResults?.[reportSegment?.reportPuck?.trend_is_good_field] === 1 ? '#38A194' : '#ED4924' )}}>{reportSegment?.reportPuck?.trend_change_value_field && reportResults?.[reportSegment?.reportPuck?.trend_change_value_field]}</span>
            { " " }
            <span style={styles.theQualifier}>{reportSegment?.reportPuck?.trend_output}</span>
        </div>
        {
            /* if the trend is bad and there's a trend_is_bad message defined for the report segment, output it */
            reportSegment?.reportPuck?.trend_is_bad_message && reportSegment?.reportPuck?.trend_is_good_field && reportResults?.[reportSegment?.reportPuck?.trend_is_good_field] === -1 && 
            <div style={styles.theCorrection}>
                <div>We Can Do better.</div>
                <span>Tip: {reportSegment?.reportPuck?.trend_is_bad_message}</span>
            </div>
        }
    </div>
}

function TrendArrow({direction, goodness}: {direction: number; goodness: number;}) {
    // console.log(`TrendArrow: direction: ${direction} goodness: ${goodness}`);
    const [assets, loadingAssets] = useAssets();
    const [upArrowGood, setUpArrowGood] = useState<string | undefined>(undefined);
    const [upArrowBad, setUpArrowBad] = useState<string | undefined>(undefined);
    const [downArrowGood, setDownArrowGood] = useState<string | undefined>(undefined);
    const [downArrowBad, setDownArrowBad] = useState<string | undefined>(undefined);

    useEffect(() => {
        if(!loadingAssets && assets.length > 0) {
            setUpArrowGood(assets.find((asset) => asset.icon.startsWith('arrow_up_good'))?.iconPath);
            setUpArrowBad(assets.find((asset) => asset.icon.startsWith('arrow_up_bad'))?.iconPath);
            setDownArrowGood(assets.find((asset) => asset.icon.startsWith('arrow_down_good'))?.iconPath);
            setDownArrowBad(assets.find((asset) => asset.icon.startsWith('arrow_down_bad'))?.iconPath);
        }
    }, [assets, loadingAssets]);
    
    if(loadingAssets) {
        return <div>...</div>;
    }

    if(direction === -1) {
        return <img src={`${goodness === 1 ? downArrowGood : downArrowBad }`} alt="down arrow" />;
    } else if(direction === 1) {
        return <img src={`${goodness === 1 ? upArrowGood : upArrowBad }`} alt="up arrow" />;
    } else {
        // let's just say that the arrow is not there if everything is even
        return <></>
    }
}