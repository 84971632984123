import CloseIcon from "@mui/icons-material/Close";
import { Field, GroupByConfig } from "api/Api";
import { Box, Button, Chip, Collapse, Grid, IconButton, Input, Select, Stack, Tooltip, Typography } from "components";
import produce from "immer";
import { ReportContext } from "pages/ReportPage/ReportContextProvider";
import { useContext, useState } from "react";
import { deepCopy } from "services";
import { getSpacing } from "theme";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { GroupByStatus } from "./GroupByStatus";
import { SxProps, Theme } from "@mui/material";

export const GroupByField = (props: { field: Field; setField: (value: Field) => void }) => {
  const reportContext = useContext(ReportContext);
  let unconfiguredField = deepCopy(props.field);
  delete unconfiguredField.groupByConfig;
  const unconfiguredDataType = reportContext.getFieldOutput(unconfiguredField)?.dataType;
  const fieldDisplayName = reportContext.getFieldDisplay(props.field, "string");
  const [showFixedIntervalSampleOutput, setShowFixedIntervalSampleOutput] = useState(false);
  const isConfigValid =
    !props.field.groupByConfig || reportContext.validateGroupByConfig(props.field.groupByConfig, "boolean");

  let groupByMethodOptions = unconfiguredDataType
    ? reportContext.groupByMethodOptions?.filter((x) => x.applicableFieldTypes.includes(unconfiguredDataType)) || []
    : [];

  groupByMethodOptions.unshift({
    // @ts-ignore
    method: undefined,
    name: "Standard",
    applicableFieldTypes: [],
  });

  const groupByMethod = () => {
    return (
      <Select
        label={
          <Stack direction="row" alignItems="center" spacing="xs">
            <Typography variant="inherit">Group Method</Typography>
            <Tooltip
              title={
                <Stack spacing="sm">
                  <Stack>Standard: default grouping method</Stack>
                  <Stack>Fixed Interval: group data into equal and fixed sized intervals/buckets.</Stack>
                  <Stack>Variable Intervals: group data into intervals/buckets that you customize.</Stack>
                </Stack>
              }
              placement="top-start"
            >
              <HelpOutlineIcon fontSize="small" />
            </Tooltip>
          </Stack>
        }
        size="small"
        inputVariant="standard"
        value={groupByMethodOptions?.find((x) => x.method === props.field.groupByConfig?.groupByMethod)}
        options={groupByMethodOptions}
        getOptionLabel={(option) => option.name}
        onChange={(e, value) => {
          props.setField(
            produce(props.field, (draft) => {
              if (!value.method) {
                draft.groupByConfig = undefined;
              } else {
                draft.groupByConfig = {
                  active: true,
                  groupByMethod: value.method,
                  fixedInterval:
                    value.method === "Fixed Interval"
                      ? {
                          intervalSize: 100,
                          offset: 0,
                        }
                      : undefined,
                  variableIntervals: value.method === "Variable Interval" ? [] : undefined,
                };
              }
            })
          );
        }}
        disableClearable
      />
    );
  };

  const fixedInterval = () => {
    const intervalSize = props.field.groupByConfig?.fixedInterval?.intervalSize;
    const intervalOffset = props.field.groupByConfig?.fixedInterval?.offset;

    return (
      <Stack spacing="sm">
        <Stack direction="row" spacing="sm">
          <Input
            variant="standard"
            value={intervalSize}
            onChange={(e) =>
              props.setField(
                produce(props.field, (draft) => {
                  draft.groupByConfig!.fixedInterval!.intervalSize = Number(e.target.value);
                })
              )
            }
            label={
              <Stack direction="row" alignItems="center" spacing="xs">
                <Typography variant="inherit">Interval Size</Typography>
                <Tooltip
                  title={`The size of each interval/bucket for grouping ${fieldDisplayName}`}
                  placement="top-start"
                >
                  <HelpOutlineIcon fontSize="small" />
                </Tooltip>
              </Stack>
            }
            size="small"
            type="number"
            onFocus={() => setShowFixedIntervalSampleOutput(true)}
            onBlur={() => setShowFixedIntervalSampleOutput(false)}
          />

          <Input
            variant="standard"
            value={intervalOffset}
            onChange={(e) => {
              props.setField(
                produce(props.field, (draft) => {
                  draft.groupByConfig!.fixedInterval!.offset = Number(e.target.value);
                })
              );
            }}
            label={
              <Stack direction="row" alignItems="center" spacing="xs">
                <Typography variant="inherit">Offset</Typography>
                <Tooltip title={`A base value used to adjust the starting point of the range`} placement="top-start">
                  <HelpOutlineIcon fontSize="small" />
                </Tooltip>
              </Stack>
            }
            size="small"
            type="number"
            onFocus={() => setShowFixedIntervalSampleOutput(true)}
            onBlur={() => setShowFixedIntervalSampleOutput(false)}
          />
        </Stack>

        {isConfigValid && intervalSize !== undefined && intervalOffset !== undefined && (
          <Collapse in={showFixedIntervalSampleOutput}>
            <Grid container spacing="xs">
              <Grid item>
                <Chip label={"..."} rectangular size="xsmall" />
              </Grid>

              {Array.from({ length: 5 }, (_, i) => {
                const intervalIndex = i - 2;
                const from = intervalIndex * intervalSize + intervalOffset;
                const to = from + intervalSize;
                return (
                  <Grid item>
                    <Chip
                      label={
                        <Box>
                          {from} to {to}
                        </Box>
                      }
                      rectangular
                      size="xsmall"
                    />
                  </Grid>
                );
              })}

              <Grid item>
                <Chip label={"..."} rectangular size="xsmall" />
              </Grid>
            </Grid>
          </Collapse>
        )}
      </Stack>
    );
  };

  const variableIntervals = () => {
    return (
      <Stack spacing="sm">
        {props.field.groupByConfig?.variableIntervals?.map((interval, index) => {
          return (
            <Stack direction="row" spacing="xs" alignItems="center">
              <Input
                value={interval[0]}
                onChange={(e) => {
                  props.setField(
                    produce(props.field, (draft) => {
                      draft.groupByConfig!.variableIntervals![index] = [Number(e.target.value), interval[1]];
                    })
                  );
                }}
                variant="standard"
                size="small"
                placeholder="From"
              />

              <Box> - </Box>

              <Input
                value={interval[1]}
                onChange={(e) => {
                  props.setField(
                    produce(props.field, (draft) => {
                      draft.groupByConfig!.variableIntervals![index] = [interval[0], Number(e.target.value)];
                    })
                  );
                }}
                variant="standard"
                size="small"
                placeholder="To"
              />

              <IconButton
                onClick={() =>
                  props.setField(
                    produce(props.field, (draft) => {
                      draft.groupByConfig!.variableIntervals!.splice(
                        props.field.groupByConfig!.variableIntervals!.indexOf(interval),
                        1
                      );
                    })
                  )
                }
                size="small"
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Stack>
          );
        })}

        <Button
          //variant="outlined"
          size="small"
          onClick={() =>
            props.setField(
              produce(props.field, (draft) => {
                draft.groupByConfig!.variableIntervals!.push([undefined, undefined]);
              })
            )
          }
        >
          + Interval
        </Button>
      </Stack>
    );
  };

  const inactiveSx: SxProps<Theme> =
    props.field.groupByConfig && props.field.groupByConfig.active === false
      ? {
          opacity: 0.3,
          userSelect: "none",
          pointerEvents: "none",
        }
      : {};

  return (
    <Stack
      direction="row"
      // border={(theme) => `1px solid ${theme.palette.border.main}`}
    >
      <Stack px={getSpacing("sm")} py={getSpacing("md")} flexGrow={1} spacing="md" sx={{ ...inactiveSx }}>
        <Stack>
          <Typography variant="caption" lineHeight={"100%"}>
            {/* {field.tableName} */}
          </Typography>
          <Typography variant="inherit">{reportContext.getFieldDisplay(props.field, "node")}</Typography>
        </Stack>

        <Stack spacing="sm">
          {groupByMethod()}

          {props.field.groupByConfig?.groupByMethod === "Fixed Interval" && fixedInterval()}

          {props.field.groupByConfig?.groupByMethod === "Variable Interval" && variableIntervals()}
        </Stack>
      </Stack>

      <Stack direction="column">
        {props.field.groupByConfig && (
          <Box
            sx={{
              borderLeft: (theme) => `1px solid ${theme.palette.border.main}`,
              borderBottom: (theme) => `1px solid ${theme.palette.border.main}`,
            }}
          >
            <GroupByStatus
              groupByConfig={props.field.groupByConfig || ({} as GroupByConfig)}
              setActive={(value) =>
                props.setField(
                  produce(props.field, (draft) => {
                    draft.groupByConfig!.active = value;
                  })
                )
              }
            />
          </Box>
        )}
      </Stack>
    </Stack>
  );
};
