import { EmailableReport } from "api/Api";
import { Select } from "components/mui/Select";
import { useEmailableReports } from "hooks/email/useEmailableReports";
import { useEffect, useState } from "react";

export function ReportPickerDropdown({reportId, setReportId}: {reportId: number, setReportId: Function}) {
    const [reports, loadingReports] = useEmailableReports();
    const [selectedReportId, setSelectedReportId] = useState<number|undefined>(undefined);
    const [selectedReport, setSelectedReport] = useState<EmailableReport|undefined>(undefined);
    useEffect(()=>{
        if(reports.length > 0){
            setSelectedReportId(reportId);
            setSelectedReport(reports.find((report) => report.id === reportId));
        }
    }, [loadingReports, reportId])
    return (<>
        <Select 
            value={selectedReport}
            onChange={(e, value) => {
                setSelectedReportId(value.id); 
                setSelectedReport(value);
                setReportId(value.id);
            }}
            label="Report"
            options={reports}
            getOptionLabel={(report) => `${report.reportName} - ${report.reportDescription}` }
            />
    </>);
}