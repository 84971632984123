import DragIndicatorIcon from "@mui/icons-material/DragIndicator"; // Import drag handle icon
import MenuIcon from "@mui/icons-material/MoreHoriz";
import { PresetListDto } from "api/Api";
import { IconButton, Menu, MenuItem, Stack, Typography } from "components";
import { DELETE_WARNING } from "consts";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setAppToast } from "redux/reducers/appSlice";
import { newToast } from "services";
import apiService from "services/apiService";
import { updatePresetPageRoute } from "../UpdatePresetPage/UpdatePresetPage";
import moment from "moment";
import { RootState } from "redux/store";

export const PresetRow = (props: { preset: PresetListDto; refetch: () => void }) => {
  const userId = useSelector((state: RootState) => state.app.userId);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const remove = (id: number): void => {
    if (window.confirm(DELETE_WARNING)) {
      apiService
        .getApi()
        .presets.presetsControllerRemove(id)
        .then((response) => {
          props.refetch?.();
          dispatch(setAppToast(newToast("success", "Preset Deleted")));
        });
    }
  };

  const lastUpdatedDate = props.preset.modifiedDate || props.preset.createdDate;
  const lastUpdatedBy = props.preset.modifiedByName || props.preset.createdByName;

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        padding: 1,
        //border: (theme) => `1px solid ${theme.palette.border.main}`,
        cursor: "grab",
        bgcolor: (theme) => theme.palette.background.paper,
        "&:hover": {
          bgcolor: "action.hover",
        },
      }}
    >
      <Stack direction="row">
        <DragIndicatorIcon sx={{ mr: 1, color: "text.secondary" }} />
        <Typography>{props.preset.presetName}</Typography>
      </Stack>

      <Stack direction="row" spacing="lg" alignItems="center">
        <Stack alignItems={"flex-end"}>
          <Typography variant="caption" lineHeight={"110%"}>
            Last Updated {moment(lastUpdatedDate).format("MM/DD/YYYY hh:mm A")}
          </Typography>
          <Typography variant="caption" lineHeight={"110%"}>
            By {lastUpdatedBy}
          </Typography>
        </Stack>

        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            setAnchorEl(e.currentTarget);
          }}
          disabled={props.preset.createdByID !== userId}
        >
          <MenuIcon />
        </IconButton>
      </Stack>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() => {
            navigate(updatePresetPageRoute.getPath({ id: props.preset.id }));
            setAnchorEl(null);
          }}
        >
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            remove(props.preset.id);
            setAnchorEl(null);
          }}
        >
          <Typography color="error">Delete</Typography>
        </MenuItem>
      </Menu>
    </Stack>
  );
};
