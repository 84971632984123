import { TerminationReport } from "api/Api";
import { TerminationFilters } from "../../TurnoverReportData";
import { rangeArrayGenerator } from "utils";
import { Box, Stack } from "components";
import { useState } from "react";

export function AnnualTerminationReportByDepartment({terminationData, filters}: {terminationData: TerminationReport; filters: TerminationFilters;}) {
    const [hoverRow, setHoverRow] = useState<number | undefined>(undefined);
    
    return <Stack direction="row">
        <Departments terminationData={terminationData} hoverRow={hoverRow} setHoverRow={setHoverRow} />
        <Terminations terminationData={terminationData} filters={filters} hoverRow={hoverRow} setHoverRow={setHoverRow} />
        <EmployeeCount terminationData={terminationData} filters={filters} hoverRow={hoverRow} setHoverRow={setHoverRow} />
        <TurnoverRate terminationData={terminationData} filters={filters} hoverRow={hoverRow} setHoverRow={setHoverRow} />
        </Stack>
}

function Departments({terminationData, hoverRow, setHoverRow}: {terminationData: TerminationReport; hoverRow: number | undefined; setHoverRow: (index: number | undefined) => void;}) {
    const jobDepartmentData: {[department: string]: any;} = terminationData.yearlyTerminationsByDepartments;

    return <Box className="grouped-by-field">
            <h3>By Department</h3>
            <table>
                <thead>
                    <tr>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                {Object.keys(jobDepartmentData).map((department, index) => {
                    return <tr 
                    className={`${index === hoverRow ? 'hovered':''}`}
                    onMouseOver={(e)=> {
                        setHoverRow(index);
                    }} 
                    onMouseOut={(e)=> {
                        setHoverRow(undefined);
                    }}
                    key={`atrbd-departments-${index}`}>
                            <th style={{width: "100%", textAlign: "left", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}}>{department}</th>
                        </tr>;
                })}
                </tbody>
                <tfoot>
                    <tr>
                        <th style={{ width: "100%", textAlign: "left", overflow: "hidden", whiteSpace: "nowrap" }}>Totals</th>
                    </tr>
                </tfoot>
            </table>
        </Box>
    }
    
function Terminations({terminationData, filters, hoverRow, setHoverRow}: {terminationData: TerminationReport; filters: TerminationFilters;hoverRow: number | undefined; setHoverRow: (index: number | undefined) => void;}) {
    const jobDepartmentData: {[department: string]: any;} = terminationData.yearlyTerminationsByDepartments;
    const yearRange = rangeArrayGenerator(filters.startDate.getFullYear(), filters.endDate.getFullYear(), 1);
    const totals: {[year:number]: number;} = getTerminationTotals(yearRange, jobDepartmentData);

    const grandTotal = Object.values(totals).reduce((acc, total) => acc + total, 0);
    
    const columnWidth = 100 / (yearRange.length + 2);
            return <Box className="data-presentation termination">
                <h3>Terminations</h3>
            
                <table>
                    <thead>
                        <tr>
                            {
                                yearRange.map((year, index) => <th style={{width:`${columnWidth}%`}} key={`atrbd-years-${index}`}>{year}</th>)
                            }
                            <th style={{width:`${columnWidth}%` }}>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                    {Object.keys(jobDepartmentData).map((department, index) => {
                        const departmentGroupData = jobDepartmentData[department];
                        let departmentTotal = 0;
                        
                        return <tr 
                            className={`${index === hoverRow ? 'hovered':''}`}
                            onMouseOver={(e)=> {
                                
                                setHoverRow(index);
                            }} 
                            onMouseOut={(e)=> {
                                
                                setHoverRow(undefined);
                            }}
                            key={`atrbd-terminations-${index}`}>
                                {
                                    yearRange.reduce((yearAcc, year, yidx) => {
                                        departmentTotal += typeof departmentGroupData[year] !== 'number' ? parseInt(departmentGroupData[year]) : departmentGroupData[year];
                                        yearAcc.push(
                                            <td style={{width: `${columnWidth}%`, textAlign: "right", color: (departmentGroupData[year] === 0 ? '#777': '#000')}} key={`atrbd-terminations-year-value-${index}-${year}-${yidx}`}>{departmentGroupData[year]}</td>);
                                        return yearAcc;
                                    },[])
                                }
                                <td style={{textAlign:"right", width: `${columnWidth}%`, color: (departmentTotal === 0 ? '#777': '#000')}}>{departmentTotal}</td>
                            </tr>;
                    })}
                    </tbody>
                    <tfoot>
                        <tr>
                            {
                                Object.values(totals).map((total: number, index) => <th style={{width: `${columnWidth}%`, textAlign: "right"}} key={index}>{total}</th>)
                            }
                            <th style={{width: `${columnWidth}%`, textAlign: "right"}}>{grandTotal}</th>
                        </tr>
                    </tfoot>
                </table>
            </Box>
}

function EmployeeCount({terminationData, filters, hoverRow, setHoverRow}: {terminationData: TerminationReport; filters: TerminationFilters;hoverRow: number | undefined; setHoverRow: (index: number | undefined) => void;}) {
    const yearRange = rangeArrayGenerator(filters.startDate.getFullYear(), filters.endDate.getFullYear(), 1);
    const columnWidth = 100 / (yearRange.length + 2);

    // totals
    const totals: {[year:number]: number;} = getEmployeeCountTotals(yearRange, terminationData);

    return <Box className="data-presentation employee-count">
                <h3>Average Employee Count</h3>
                <table>
                    <thead>
                        <tr>
                            {
                                yearRange.map((year, index) => <th style={{width:`${columnWidth}%`}} key={index}>{year}</th>)
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            Object.keys(terminationData.yearlyAverageEmployeesByDepartment).map((department, index) => {
                                const employeeData = terminationData.yearlyAverageEmployeesByDepartment[department];
                                
                                return <tr 
                                            className={`${index === hoverRow ? 'hovered':''}`}
                                            onMouseOver={(e)=> {
                                                
                                                setHoverRow(index);
                                            }} 
                                            onMouseOut={(e)=> {
                                                
                                                setHoverRow(undefined);
                                            }}
                                            key={`atrbd-employee-counts-${index}`}
                                        >
                                            {
                                                yearRange.map((year, yidx) => {
                                                    const employeeCount = parseFloat(employeeData[year]);
                                                    return <td key={`ytrbd${yidx}${index}`} style={{width: `${columnWidth}%`, textAlign: "right", color: (employeeCount === 0 ? '#777' : '#000')}}>{employeeCount}</td>;
                                                })
                                            }
                                        </tr>
                            })
                        }
                    </tbody>
                    <tfoot>
                        <tr>
                        {
                            yearRange.reduce((yearAcc, year) => {
                                yearAcc.push(
                                    <th style={{width: `${columnWidth}%`, textAlign: "right", color: (totals[year] === 0 ? '#777' : '#000')}} key={year}>{totals[year]}</th>);
                                return yearAcc;
                            }, [])
                        }
                        </tr>
                    </tfoot>
                </table>
            </Box>
}

function TurnoverRate({terminationData, filters, hoverRow, setHoverRow}: {terminationData: TerminationReport; filters: TerminationFilters;hoverRow: number | undefined; setHoverRow: (index: number | undefined) => void;}) {
    const jobDepartmentData: {[department: string]: any;} = terminationData.yearlyTerminationsByDepartments;
    const yearRange = rangeArrayGenerator(filters.startDate.getFullYear(), filters.endDate.getFullYear(), 1);
    const columnWidth = 100 / (yearRange.length + 2);

    const terminationTotals: {[year:number]: number;} = getTerminationTotals(yearRange, jobDepartmentData);
    const employeeCountTotals: {[year:number]: number;} = getEmployeeCountTotals(yearRange, terminationData);
    const turnoverRateTotals: {[year:number]: number;} = getTurnoverRateTotals(terminationTotals, employeeCountTotals);

    return <Box className="data-presentation turnover-rate">
                {
                    // a table with the turnover rate where the termination count is divided by the total headcount
                }
                <h3>Turnover Rate</h3>
                <table>
                    <thead>
                        <tr>
                            {
                                yearRange.map((year, index) => <th style={{width:`${columnWidth}%`}} key={index}>{year}</th>)
                            }
                        </tr>
                    </thead>
                    <tbody>
                        
                            {
                                Object.keys(terminationData.yearlyTurnoverRateByDepartment).map((department, index) => {
                                    const turnoverData = terminationData.yearlyTurnoverRateByDepartment[department];
                                    
                                        return <tr
                                        className={`${index === hoverRow ? 'hovered':''}`}
                                        onMouseOver={(e)=> {
                                            
                                            setHoverRow(index);
                                        }} 
                                        onMouseOut={(e)=> {
                                            
                                            setHoverRow(undefined);
                                        }}
                                        key={`atrbd-ratios-${index}`}
                                    >
                                        {
                                            yearRange.map((year, yidx) => {
                                                const turnoverRate = parseFloat(turnoverData[year]);
                                                
                                                return <td key={`ytrbd${yidx}${index}`} style={{width: `${columnWidth}%`, textAlign: "right", color: (turnoverRate === 0 ? '#777': '#000')}}>{turnoverRate.toFixed(1)}%</td>;
                                            })
                                        }
                                    </tr>
                                })
                            }
                        
                    </tbody>
                    <tfoot>
                        <tr>
                        {
                            yearRange.map((year, index) => <th style={{width: `${columnWidth}%`, textAlign: "right", color: (turnoverRateTotals[year] === 0 ? '#777': '#000')}} key={`atrbd-turnover-totals-${index}`}>{turnoverRateTotals[year].toFixed(1)}%</th>)
                        }
                        </tr>
                    </tfoot>
                </table>
            </Box>
}

function getEmployeeCountTotals(yearRange: number[], terminationData: TerminationReport): {[year:number]: number;} {
    return yearRange.reduce((acc, year) => {
        acc[year] = 0;
        Object.keys(terminationData.yearlyAverageEmployeesByDepartment).forEach((department) => {
            const employeeData = terminationData.yearlyAverageEmployeesByDepartment[department];
            acc[year] += parseFloat(employeeData[year]);
        });
        return acc;
    }, {});
}

function getTerminationTotals(yearRange: number[], jobDepartmentData: {[department: string]: any;}): {[year:number]: number;} {
    return yearRange.reduce((acc, year) => {
        acc[year] = 0;
        Object.keys(jobDepartmentData).forEach((department) => {
            const departmentGroupData = jobDepartmentData[department];
            acc[year] += typeof departmentGroupData[year] !== 'number' ? parseInt(departmentGroupData[year]) : departmentGroupData[year];
        });
        return acc;
    }, {});
}

function getTurnoverRateTotals(
    terminationTotals: {[year:number]: number;}, 
    employeeCountTotals: {[year:number]: number;}
): {[year:number]: number;} {
    return Object.entries(terminationTotals).reduce((acc, [year, terminationTotal]) => {
        acc[year] = (terminationTotal / employeeCountTotals[year]) * 100;
        return acc;
    }, {});
}