import { QueryResult } from "api/Api";
import { useEffect, useState } from "react";
import apiService from "services/apiService";

const cachedData: { [key: string]: any[] } = {};
let lastDbTable = "";

export function useTableData(
  dbName: string,
  tableName: string,
  tableColumns: string[] = [],
  effectiveDates?: { startDate: string; endDate: string | undefined }
): [any[], boolean] {
  const [isLoading, setIsTableDataLoading] = useState(false);
  const [tableRows, setTableRows] = useState<any[]>([]);

  useEffect(() => {
    const effectiveDatesString = effectiveDates
      ? `${effectiveDates.startDate}-${effectiveDates?.endDate ?? "current"}`
      : undefined;
    console.log(effectiveDatesString);
    if (
      !effectiveDates ||
      Object.keys(effectiveDates).length === 0 ||
      tableColumns.length === 0 ||
      isLoading ||
      lastDbTable === `${dbName}.${tableName}-${effectiveDatesString}`
    ) {
      if (!effectiveDates) console.log("no effective dates");
      if (tableColumns.length === 0) console.log("no table columns");
      if (isLoading) console.log("is loading");
      if (lastDbTable === `${dbName}.${tableName}-${effectiveDatesString}`) console.log("lastDbName === dbName");

      return;
    }

    if (cachedData[`${dbName}.${tableName}-${effectiveDatesString}`]) {
      setTableRows(cachedData[`${dbName}.${tableName}-${effectiveDatesString}`]);
      return;
    }

    lastDbTable = `${dbName}.${tableName}-${effectiveDatesString}`;

    setIsTableDataLoading(true);
    // create the rows for the fields property
    const fields: { dbName: string; tableName: string; fieldName: string }[] = tableColumns.map((column) => {
      return {
        dbName,
        tableName,
        fieldName: column,
      };
    });

    apiService
      .getApi()
      .report.reportControllerQuery({
        filters: [],
        fields,
        page: 1,
        pageSize: 100,
        sortFields: [],
        tableTree: {
          dbName: dbName,
          name: tableName,
        },
      })
      .then((response: { data: QueryResult; error: any }) => {
        setIsTableDataLoading(false);
        setTableRows(response.data.dataRows);
        cachedData[`${dbName}.${tableName}-${effectiveDatesString}`] = response.data.dataRows;
      })
      .catch((error) => {
        console.error(`Error fetching ${dbName}.${tableName} table data`, error);
      });
  }, [tableColumns, tableRows, isLoading, dbName, tableName, effectiveDates]);

  return [tableRows, isLoading];
}
