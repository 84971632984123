import { TableRow as MuiTableRow, TableRowProps as MuiTableRowProps } from "@mui/material";
import { useTheme } from "@mui/material";

type TableRowProps = MuiTableRowProps & {
  hoverEffect?: boolean;
};

export const TableRow = (props: TableRowProps) => {
  const { sx, children, onClick, ...rest } = props;
  const theme = useTheme();

  return (
    <MuiTableRow
      sx={{ ...sx, "&:hover": { backgroundColor: props.hoverEffect ? theme.palette.action.hover : undefined } }}
      onClick={(e) => {
        if (onClick) {
          var selection = window.getSelection();
          if (selection?.type !== "Range") {
            // prevents click when selecting text
            onClick(e);
          }
        }
      }}
      {...rest}
    >
      {props.children}
    </MuiTableRow>
  );
};
