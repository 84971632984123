import { Theme } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
import { SxProps } from "@mui/material";
import { DbtTable, Field, Filter as FilterModel } from "api/Api";
import { FilterOperator } from "api/Api-extension";
import { Box, IconButton, Stack } from "components";
import produce from "immer";
import { ReportContext } from "pages/ReportPage/ReportContextProvider";
import { getArrayFromTableTree } from "pages/ReportPage/utils/table-tree-utils";
import { useContext } from "react";
import { FilterFieldInput } from "./FilterFieldInput";
import { FilterOperatorInput } from "./FilterOperatorInput";
import { FilterStatus } from "./FilterStatus";
import { FilterTableInput } from "./FilterTableInput";
import { FilterValueInput } from "./FilterValueInput";

export const Filter = (props: {
  filter: FilterModel;
  setFilter: (filter: FilterModel) => void;
  removeFilter: () => void;
}) => {
  const reportContext = useContext(ReportContext);
  const tableTreeArray = getArrayFromTableTree(reportContext?.queryRequest?.tableTree);
  const dbtTables = tableTreeArray.map((x) => reportContext.getTable(x.dbName, x.name) || ({} as DbtTable)) ?? [];
  const selectedDbtTable = dbtTables.find(
    (x) =>
      x.dbName?.toLowerCase() === props.filter.field?.dbName?.toLowerCase() &&
      x.name?.toLowerCase() === props.filter.field?.tableName?.toLowerCase()
  );
  // const fieldOutputDataType = reportContext.getFieldOutput(props.filter.field)?.dataType;

  const setSelectedTable = (value: DbtTable | undefined) => {
    props.setFilter(
      produce(props.filter, (draft) => {
        draft.field = {
          dbName: value ? value.dbName : "",
          tableName: value ? value.name : "",
          fieldName: "",
        };
        draft.values = [];
      })
    );
  };

  const setSelectedField = (value: Field) =>
    props.setFilter(
      produce(props.filter, (draft) => {
        draft.field = value;
        draft.values = [];
      })
    );

  const setSelectedOperator = (value: FilterOperator) => {
    props.setFilter(
      produce(props.filter, (draft) => {
        draft.operator = value;
        draft.values = [];
      })
    );
  };

  const inactiveSx: SxProps<Theme> = !props.filter.active
    ? {
        opacity: 0.3,
        userSelect: "none",
        pointerEvents: "none",
      }
    : {};

  return (
    <Stack direction="row" spacing="xs">
      <Stack px={2} py={2} flexGrow={1} spacing="xs" sx={{ ...inactiveSx }}>
        <FilterTableInput
          dbtTables={dbtTables}
          selectedDbtTable={selectedDbtTable}
          setSelectedDbtTable={setSelectedTable}
        />

        <FilterFieldInput
          dbtDataFields={selectedDbtTable?.dataFields || []}
          selectedField={props.filter.field}
          setSelectedField={setSelectedField}
        />

        <FilterOperatorInput
          selectedOperator={props.filter.operator}
          setSelectedOperator={setSelectedOperator}
          selectedField={props.filter.field}
        />

        <FilterValueInput filter={props.filter} setFilter={(value) => props.setFilter(value)} />
      </Stack>

      <Stack direction="column">
        <Box
          sx={{
            borderLeft: (theme) => `1px solid ${theme.palette.border.main}`,
            borderBottom: (theme) => `1px solid ${theme.palette.border.main}`,
          }}
        >
          <FilterStatus
            filter={props.filter}
            setActive={(value) => props.setFilter({ ...props.filter, active: value })}
          />
        </Box>

        <Box
          sx={{
            borderLeft: (theme) => `1px solid ${theme.palette.border.main}`,
            borderBottom: (theme) => `1px solid ${theme.palette.border.main}`,
          }}
        >
          <IconButton onClick={() => props.removeFilter()} sx={{ p: 0 }}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      </Stack>
    </Stack>
  );
};
