import { Box, Button, Container, Paper, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ReaderPicker } from "../../ReaderPicker/ReaderPicker";
import { ReportSegment } from "./ReportSegment";
import { getQuerySafeDate } from "utils";
import { DatePicker } from "components";
import { EmailableReport, Employee } from "api/Api";
import { Link } from "react-router-dom";
import { useAssets } from "hooks/email/segments/useAssets";

const styles = {
    locationName: {
        backgroundColor: "#5E6C9B",
        fontSize: "1.25rem",
        fontFamily: `serif`,
        borderRadius: "0 5px 5px 5px",
        padding: "0 10px",
        color: "#fff"
    }
}

export function ReportPreview({report}: {report: EmailableReport}){
    const [reader, setReader] = useState<Employee|undefined>(undefined);
    const [reportDate, setReportDate] = useState<string>(getQuerySafeDate(new Date()));
    const [reportContextValid, setReportContextValid] = useState(false);
    const [assets, loadingAssets] = useAssets();
    const [headerImage, setHeaderImage] = useState<string|undefined>(undefined);

    useEffect(() =>{
        if(reader && reportDate){
            setReportContextValid(true);
        } else {
            setReportContextValid(false);
        }
    }, [reader, reportDate]);

    
    useEffect(() => {
        if(!loadingAssets && assets.length > 0 ) {
            const header = assets.find((asset) => asset.icon.startsWith('TopBackground'));
            if(header) {
                setHeaderImage(header.iconPath);
            }
        }
    },[assets]);
    if(loadingAssets) {
        return <div>Loading assets...</div>;
    }
    
    return <Container>
        <Stack direction="row" spacing={2}>
        <Box width="50%">
             <ReaderPicker
                reader={reader}
                setReader={setReader}
            />
            </Box>
            <Box width="50%">
                <DatePicker 
                    label="Report Date"
                    value={reportDate ?? new Date()}
                    onChange={(e)=>{
                    setReportDate(getQuerySafeDate(e));
                }} />
            </Box>
        </Stack>
        <Stack direction="row" spacing={2} padding={2}>
            <Box width="33%" textAlign="center">badge number: {reader?.badge_number ?? 'none'}</Box>
            <Box width="33%" textAlign="center">facility_id: {reader?.facility_id ?? 'none'}</Box>
            <Box width="33%" textAlign="center">report_date: {reportDate}</Box>
        </Stack>
        <Paper style={{padding: "2rem", backgroundColor: "#F2F3F4"}}>
            {(!reportContextValid || !reader) && <Typography variant="h6">Please select a reader and a report date to preview the report.</Typography>}
            {(reportContextValid && reader) && <>
                <div style={{width:"600px", height: "auto", backgroundColor: "#fff"}}>
                    <div style={{backgroundColor: "rgb(17,25,42)", margin: 0, padding: 0}}>
                        <div style={{backgroundColor: "#fff", minHeight: "74px" }}>
                            <div style={{display: "block", padding: "15px 30px"}}>
                                <img src="/email-assets/aylo-logo.png" alt="Aylo Logo" />
                            </div>
                        </div>
                    </div>
                    <div style={{backgroundColor: "#fff", margin:0, padding: 0, border: `1px solid #E5E9F5`, borderRadius: "10px"}}>
                        <div style={{
                            display:"block", 
                            verticalAlign: "baseline",
                            backgroundImage: `url(${headerImage})`,
                            backgroundPosition: "left bottom",
                            overflow: "hidden", 
                            textOverflow:"ellipses", 
                            padding: "12px", 
                            borderTopLeftRadius: "10px",
                            borderTopRightRadius: "10px",
                            height: '176px',
                            width: '599px',
                            }}>
                            <div style={{ color: "#5E6C9B", fontFamily: `serif`, fontSize: "1.5rem"}}>
                                {report.reportName}
                            </div>
                            {reader && <div>
                                <span style={styles.locationName}>
                                    {reader.maplocation_longname ?? ""}
                                </span>
                            </div>
                            }
                        </div>
                        {report?.preamble && report.preamble.length > 0 && <div style={{padding: '12px'}}>
                            {report?.preamble}
                        </div>}
                        <div className="segmentContainer" style={{padding: 0, margin: 0}}>
                            {(!report.segments || report.segments?.length === 0) &&  <div style={{padding: "12px"}}>No segments selected. Please select some segments to preview the report.</div>}
                            {report.segments?.map((segment, idx) => {
                                return <span style={{display: "inline-block"}}><ReportSegment reader={reader} key={`repseg${idx}`} segment={segment} index={idx} reportDate={reportDate} /></span>
                            })}
                        </div>
                    </div>
                    {/* <div style={{backgroundColor: "#fff", margin: 0, padding: 0, borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px"}}>
                        <ReportFooter />
                    </div> */}
                </div>
                
                {report.id > 0 &&<Box>
                    <Link to={`/custom-reports/report/${report.id}/${reader?.badge_number}/${reportDate}`} target="_blank">
                        <Button>View Report</Button>
                    </Link>
                </Box>}
            </>
        }
    </Paper>
    </Container>;
}

export function ReportFooter() {
    return (<>
        <div>
            <div style={{padding: "12px"}}>
                <p>Powered by <a href="https://ensomata.com" target="_blank" rel="noreferrer">Ensomata</a></p>
            </div>
        </div>
    </>);
}

