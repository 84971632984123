import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import {Employee, ReportSegment as iReportSegment} from "api/Api";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { Button, Input, Select } from "components";
import { SqlQueryEditor } from "../SqlQueryEditor";
import { ReportSegmentPreview } from "../editor-helpers/ReportSegmentPreview";
import { useEffect, useState } from "react";
import { DeleteForever } from "@mui/icons-material";

const chartOptions: string[] = ["Bar Chart", "Stacked Bar Chart", "Table"];

export function DashboardConfiguration({
        tempSegment, 
        setTempSegment, 
        reader, 
        setReader,
        readerDate,
        setReaderDate
    }:{
        tempSegment: iReportSegment; 
        setTempSegment: (segment: iReportSegment) => void; 
        saveReportSegment: () => void; 
        reader: Employee | undefined; 
        setReader: (emp: Employee)=> void;
        readerDate: string;
        setReaderDate:(date:string) => void;
    }) {
        const [disableNewButton, setDisableNewButton] = useState(false);

        useEffect(() => {
            const hasAnEmptyDashboard = tempSegment?.dashboards?.length === 0 || tempSegment?.dashboards?.some((d) => {
                return d.name.trim() === "" || d.sqlQuery.trim() === "";
            })

            setDisableNewButton(hasAnEmptyDashboard);
        }, [tempSegment])

    return <Stack spacing={2} mt={4} mb={4}>
            <Box>
                <Typography variant="h4">Dashboard Configuration</Typography>
            </Box>
            {
                tempSegment?.dashboards?.map((dashboard, index) => {
                    return <DashboardSegment
                                key={`dashboard-segment-${index}`}
                                dashboard={dashboard} 
                                index={index} 
                                tempSegment={tempSegment} 
                                setTempSegment={setTempSegment}
                                reader={reader}
                                setReader={setReader}
                                readerDate={readerDate}
                                setReaderDate={setReaderDate}
                                />
                })
            }
            {
                <Box>
                    <Button variant="outlined" 
                        disabled={
                            disableNewButton
                        }
                        onClick={()=>{
                        setTempSegment({
                            ...tempSegment,
                            dashboards: [...(tempSegment?.dashboards??[]), {
                                name: "",
                                sqlQuery: "",
                                chartType: ""
                            }]
                        });
                    }}>
                        Add New Dashboard Query
                    </Button>
                </Box>
            }
        </Stack>;
}

function DashboardSegment({
        dashboard, 
        index, 
        tempSegment, 
        setTempSegment,
        reader, 
        setReader,
        readerDate,
        setReaderDate
    }: {
        dashboard: any; 
        index: number; 
        tempSegment: any; 
        setTempSegment: any;
        reader: Employee | undefined; 
        setReader: (emp: Employee)=> void;
        readerDate: string;
        setReaderDate:(date:string) => void;
    }) {
    return (
        <>
            <Stack width="100%" direction="row" spacing={2} alignContent={"right"}>
                <Box width="100%" display="flex" justifyContent="flex-end" alignContent="center">
                    <Button
                    disabled={index === 0}
                    onClick={
                        () => {
                            const t = {...tempSegment};
                            const temp = t.dashboards[index];
                            t.dashboards[index] = t.dashboards[index - 1];
                            t.dashboards[index - 1] = temp;
                            setTempSegment(t);
                        }
                    }
                    >
                        <ArrowCircleUpIcon />
                        Move Up
                    </Button>
                    <Button
                    disabled={index === tempSegment.dashboards.length - 1}
                    onClick={
                        () => {
                            const t = {...tempSegment};
                            const temp = t.dashboards[index];
                            t.dashboards[index] = t.dashboards[index + 1];
                            t.dashboards[index + 1] = temp;
                            setTempSegment(t);
                        }
                    }
                    >
                        <ArrowCircleDownIcon />
                        Move Down
                    </Button>
                </Box>
            </Stack>
            <Stack key={`dashboard-segment-query-editor-${index}`} direction="row" spacing={2} mt={4} mb={4}>
                <Stack style={{width: "40%"}} spacing={2}>
                    <Box>
                        <Input
                            type="text"
                            label="Name"
                            value={dashboard.name}
                            onChange={(e) => {
                                const t = {...tempSegment}
                                t.dashboards[index].name = e.target.value;
                                setTempSegment(t);
                            }}  
                        />
                    </Box>
                    <Box>
                        <SqlQueryEditor
                            key={index} 
                            sqlQuery={dashboard.sqlQuery} 
                            onQueryChange={(sqlQuery:string) => {
                                const t = {...tempSegment};
                                t.dashboards[index].sqlQuery = sqlQuery;
                                setTempSegment(t);
                            }} 
                        />
                    </Box>
                    <Stack direction="row" spacing={2}>
                    <Box width="80%">
                        <Select
                            value={dashboard.chartType}
                            label="Chart Type"
                            options={chartOptions}
                            onChange={(e, value) => {
                                const t = {...tempSegment};
                                t.dashboards[index].chartType = value;
                                setTempSegment(t);
                            }}
                            />
                    </Box>
                    <Box width="20%">
                        <Button fullWidth style={{height: "100%"}} variant="text" onClick={() => {
                            if(window.confirm("Are you sure you want to delete this dashboard?")){
                                const t = {...tempSegment};
                                t.dashboards.splice(index, 1);
                                setTempSegment(t);
                            }
                        }}><DeleteForever /></Button>
                    </Box>
                    </Stack>
                    <Box>
                        <Typography variant="caption">*Chart Type is required</Typography>
                    </Box>
                </Stack>
                <Box style={{width: "60%"}}>
                    <ReportSegmentPreview 
                        reportSegment={tempSegment}
                        index={index}
                        name={dashboard.name}
                        sqlQuery={dashboard.sqlQuery}
                        reader={reader}
                        setReader={setReader} 
                        readerDate={readerDate}
                        setReaderDate={setReaderDate}
                        dashboardView={true}
                        />
                </Box>
            </Stack>
            <Divider />
        </>
    );
}