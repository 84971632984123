import { PresetDto, PresetListDto, QueryRequest } from "api/Api";
import {
  Chip,
  CloseButton,
  Dialog,
  handleClose,
  Input,
  Stack,
  SubmitButton,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "components";
import { useUnsavedChanges } from "hooks";
import produce from "immer";
import { PageRoute } from "models/PageRoute";
import { getArrayFromTableTree } from "pages/ReportPage/utils/table-tree-utils";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setAppToast } from "redux/reducers/appSlice";
import { RootState } from "redux/store";
import { newToast } from "services";
import apiService from "services/apiService";
import { buildPath, buildTemplatePath } from "services/routeService";

const routeName = "new-preset";

export const createPresetPageRoute: PageRoute = {
  handle: "Save Preset",
  templatePath: buildTemplatePath(routeName),
  getPath: () => buildPath(routeName),
};

export type CreatePresetPageState = {
  queryRequest: QueryRequest;
};

export const CreatePresetPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();

  const userId = useSelector((state: RootState) => state.app.userId);
  const { queryRequest } = state as CreatePresetPageState;
  const [preset, setPreset] = useState<PresetDto>({ public: false, queryRequest: queryRequest } as PresetDto);
  const { unsavedChanges } = useUnsavedChanges({ trackedItems: [preset] });
  const [publicPresets, setPublicPresets] = useState<PresetListDto[]>();
  const [privatePresets, setPrivatePresets] = useState<PresetListDto[]>();

  useEffect(() => {
    getPublicPresets();
    getPrivatePresets();
  }, []);

  const getPublicPresets = () => {
    apiService
      .getApi()
      .presets.presetsControllerFindAll({ isPublic: true })
      .then((response) => {
        setPublicPresets(response.data);
      });
  };

  const getPrivatePresets = () => {
    apiService
      .getApi()
      .presets.presetsControllerFindAll({ isPublic: false })
      .then((response) => {
        setPrivatePresets(response.data);
      });
  };

  const savePreset = (preset: PresetDto) => {
    apiService
      .getApi()
      .presets.presetsControllerCreate(preset)
      .then((response) => {
        navigate("../");
        dispatch(setAppToast(newToast("success", "Preset Saved")));
      });
  };

  const existingPreset = preset.public
    ? publicPresets?.find((x) => x?.presetName?.toLowerCase() === preset?.presetName?.toLowerCase())
    : privatePresets?.find((x) => x?.presetName?.toLowerCase() === preset?.presetName?.toLowerCase());

  const tables = getArrayFromTableTree(queryRequest.tableTree).length;
  const fields = queryRequest.fields?.length ?? 0;
  const filters = queryRequest.filters?.length ?? 0;
  const sortFields = queryRequest.sortFields?.length ?? 0;

  return (
    <Dialog
      isForm
      open={true}
      onClose={() => handleClose(navigate, unsavedChanges)}
      maxWidth="xs"
      header="Save Preset"
      content={
        <Stack spacing="md">
          <Input
            autoFocus
            label={"Preset Name"}
            value={preset?.presetName}
            onChange={(e) => {
              setPreset(
                produce((draft) => {
                  draft.presetName = e.target.value;
                })
              );
            }}
            size="small"
          />

          <ToggleButtonGroup
            fullWidth
            value={preset.public}
            exclusive
            orientation="vertical"
            onChange={(e, value: boolean | undefined) =>
              setPreset(
                produce((draft) => {
                  if (value !== undefined) draft.public = value;
                })
              )
            }
          >
            <ToggleButton value={false} sx={{ textTransform: "none" }}>
              <Stack direction="row" spacing="sm" alignItems="center">
                <Typography variant="inherit">My Preset:</Typography>
                <Typography variant="caption">Only I can view and update this preset</Typography>
              </Stack>
            </ToggleButton>

            <ToggleButton value={true} sx={{ textTransform: "none" }}>
              <Stack direction="row" spacing="sm" alignItems="center">
                <Typography variant="inherit">Shared Preset:</Typography>
                <Typography variant="caption">All users can view this preset. Only I can update it.</Typography>
              </Stack>
            </ToggleButton>
          </ToggleButtonGroup>

          <Stack direction="row" spacing="sm">
            <Chip label={`Tables: ${tables}`} size="small" />
            <Chip label={`Fields: ${fields}`} size="small" />
            <Chip label={`Filters: ${filters}`} size="small" />
            <Chip label={`Sort: ${sortFields}`} size="small" />
          </Stack>

          {existingPreset && (
            <>
              {existingPreset.createdByID === userId ? (
                <Typography variant="caption" color={(theme) => theme.palette.primary.main}>
                  Preset Name already exists. Saving will override the existing Preset.
                </Typography>
              ) : (
                <Typography variant="caption" color={(theme) => theme.palette.error.main}>
                  Preset Name already exists and was created by a different user.
                </Typography>
              )}
            </>
          )}

          {/* <Stack>
            <Typography>Saving criterias: Tables and fields</Typography>
            <Typography>Not yet saving: Filters, Defined Filters, Sort, etc</Typography>
          </Stack> */}
        </Stack>
      }
      footer={
        <Stack direction="row" spacing="sm" justifyContent={"flex-end"}>
          <CloseButton confirm={unsavedChanges} />
          <SubmitButton
            onClick={() => savePreset(preset)}
            disabled={!unsavedChanges || !preset.presetName || (existingPreset && existingPreset.createdByID !== userId)}
          />
        </Stack>
      }
    />
  );
};
