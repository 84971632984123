import { AlertColor } from "@mui/material";
import { cloneDeep } from "lodash";
import { Toast } from "models";
import moment from "moment";

export const newToast = (alertColor: AlertColor, message?: string): Toast => {
  let toast: Toast = {} as Toast;

  toast.alertColor = alertColor;

  if (message) toast.message = message;
  else {
    if (toast.alertColor === "success") toast.message = "Action Successful";
    if (toast.alertColor === "error") toast.message = "An error has occured";
  }

  toast.timeSent = new Date().getTime();

  return toast;
};

export function propertyName<TObject>(key: keyof TObject, firstLetterToUpper: boolean = false): string {
  if (firstLetterToUpper) {
    const keyString: string = key.toString();
    return keyString[0].toUpperCase() + keyString.substring(1, keyString.length);
  }

  return key.toString();
}

export function typecheck<TObject>(value: TObject): any {
  return value;
}

export const isNullOrEmpty = (value: any): boolean => {
  if (value === undefined || value === null) return true;

  if (Array.isArray(value) && (value as []).length === 0) return true;

  return false;
};

export const combineIntoSentence = (
  stringArray: string[] | undefined,
  delimiter: string,
  lastDelimiter?: string
): string | undefined => {
  if (stringArray && stringArray.length > 0) {
    let sentence: string = "";
    for (let i = 0; i < stringArray.length; i++) {
      if (i > 0) {
        if (lastDelimiter && i === stringArray.length - 1) {
          sentence = sentence + " " + lastDelimiter + " ";
        } else {
          sentence = sentence + delimiter + " ";
        }
      }

      sentence = sentence + stringArray[i];
    }
    return sentence;
  }

  return undefined;
};

export const formatSchoolYear = (schoolYear: number | undefined): string | undefined => {
  if (schoolYear) {
    return `${schoolYear}/${schoolYear + 1}`;
  }

  return undefined;
};

export const formatBoolean = (bool: boolean | undefined): string | undefined => {
  if (bool !== undefined) {
    if (bool === true) return "Yes";
    if (bool === false) return "No";
  }

  return undefined;
};

export const formatCurrency = (value: number | undefined): string | undefined => {
  if (value !== undefined && value !== null) {
    return `$${value.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
  }

  return undefined;
};

export const formatPercent = (value: number | undefined): string | undefined => {
  if (value !== undefined && value !== null) {
    return `${value.toFixed(2)}%`;
  }

  return undefined;
};

export const formatSeconds = (seconds: number | undefined): string | undefined => {
  if (seconds !== undefined) {
    if (seconds >= 3600) {
      return `${new Date(seconds * 1000).toISOString().substring(11, 16)} hours`;
    }
    if (seconds >= 60) {
      return `${new Date(seconds * 1000).toISOString().substring(14, 19)} minutes`;
    } else {
      return `${seconds} seconds`;
    }
  }

  return undefined;
};

export const formatDuration = (
  valueString: string | undefined,
  toDecimalFormat: boolean = false
): string | undefined => {
  if (valueString === undefined) return undefined;

  let hours: number = 0;
  let minutes: number = 0;

  const value: number | undefined = Number(valueString);
  if (isNaN(value)) {
    // if in hh:mm:ss TimeSpan format
    var timeSpanParts = valueString.split(":");
    if (timeSpanParts[0].indexOf(".") >= 0) {
      // if the hour part is in format 0.00, calculate the total hours
      hours = parseInt(timeSpanParts[0]) * 24 + parseInt((parseInt(timeSpanParts[0]) % 1).toFixed(2)) * 100;
    } else {
      // else just take the hours as is
      hours = parseInt(timeSpanParts[0]);
    }

    minutes = parseInt(timeSpanParts[1]);
  } //else if in number format: that means its in minutes
  else {
    hours = value / 60;
    minutes = value % 60;
  }

  if (toDecimalFormat === true) {
    return (Math.round((hours + minutes / 60) * 100) / 100).toFixed(2);
  } else {
    if (hours === 0 && minutes === 0) {
      return "0m";
    }

    var returnString = "";

    if (hours > 0) {
      returnString = hours + "h";
    }

    if (minutes > 0) {
      returnString = returnString + " " + minutes + "m";
    }

    return returnString;
  }
};

export const formatPhoneNumber = (phoneNumber: string | undefined): string | undefined => {
  if (phoneNumber !== undefined) {
    if (phoneNumber && phoneNumber.length === 10) {
      var areaCode = phoneNumber.slice(0, 3);
      var numberPart1 = phoneNumber.slice(3, 6);
      var numberPart2 = phoneNumber.slice(6);
      return `(${areaCode}) ${numberPart1}-${numberPart2}`;
    }
  }

  return undefined;
};

export const formatFileSize = (value: number | undefined) => {
  if (value) {
    if (value >= 0 && value < 1000) {
      return `${value.toFixed(2)} B`;
    } else if (value >= 1000 && value < 1000000) {
      return `${(value / 1000).toFixed(2)} KB`;
    } else if (value >= 1000000 && value < 1000000000) {
      return `${(value / 1000000).toFixed(2)}  MB`;
    }
  }

  return value;
};

// export const formatSeconds = (value: number): string => {
//   return new Date(value * 1000).toISOString().substr(14, 5);
// };

const imgExtensions = ["jpg", "jpeg", "gif", "bmp", "tiff", "png"];
export const isFilenameImage = (filename: string | undefined): boolean => {
  if (filename) {
    for (var i in imgExtensions) {
      if (filename.toLowerCase().endsWith("." + imgExtensions[i])) {
        return true;
      }
    }
  }

  return false;
};

export function deepCopy<T>(obj: T): T {
  return cloneDeep(obj) as T;
}

export function formatMs(ms: number): string {
  const duration = moment.duration(ms);

  // Extract hours, minutes, seconds, and milliseconds
  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();
  const seconds = duration.seconds();
  const milliseconds = duration.milliseconds();

  // Build the formatted string conditionally
  let formatted = "";
  if (hours > 0) {
    formatted += `${hours}h `;
  }
  if (minutes > 0 || hours > 0) {
    // Include minutes if hours are present, even if minutes are 0
    formatted += `${minutes}m `;
  }
  if (seconds > 0 || minutes > 0 || hours > 0) {
    // Include seconds if minutes or hours are present
    formatted += `${seconds}s `;
  }
  if (milliseconds > 0 || formatted === "") {
    // Only include milliseconds if no other components or if the duration is less than a second
    formatted += `${milliseconds}ms`;
  }

  return formatted.trim(); // Trim any trailing space
}

// export const newToast = (alertColor: AlertColor, message?: string): Toast => {
//   let toast: Toast = {} as Toast;

//   toast.alertColor = alertColor;

//   if (message) toast.message = message;
//   else {
//     if (toast.alertColor === "success") toast.message = "Action Successful";
//     if (toast.alertColor === "error") toast.message = "An error has occured";
//   }

//   toast.timeSent = new Date().getTime();

//   return toast;
// };

export const offsetListItems = (listItems: any[], offset: number): any[] => {
  return listItems.slice(0, offset);
};

export const sortListItems = (listItems: any[], sortProperty: string, sortDesc: boolean): any[] => {
  return !sortDesc
    ? listItems.sort((a, b) => {
        if (a[sortProperty] < b[sortProperty]) {
          return -1;
        }
        if (a[sortProperty] > b[sortProperty]) {
          return 1;
        }
        return 0;
      })
    : listItems.sort((a, b) => {
        if (a[sortProperty] < b[sortProperty]) {
          return 1;
        }
        if (a[sortProperty] > b[sortProperty]) {
          return -1;
        }
        return 0;
      });
};
