import { QueryRequest } from "api/Api";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Colors,
} from "chart.js";
import { Line } from "react-chartjs-2";
import randomColor from "randomcolor";
import { Box, Stack } from "components";
import { CHART_LAYOUTS } from "../StatResults/StatsResults";

import "./chart.css";
import { useContext, useState } from "react";
import { ReportContext } from "pages/ReportPage/ReportContextProvider";
import apiService from "services/apiService";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Colors);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
};

const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const monthToAbbrMap = {
  jan: "Jan",
  feb: "Feb",
  mar: "Mar",
  apr: "Apr",
  may: "May",
  jun: "Jun",
  jul: "Jul",
  aug: "Aug",
  sep: "Sep",
  oct: "Oct",
  nov: "Nov",
  dec: "Dec",
};

const abbrMonths = Object.keys(monthToAbbrMap);

export function StatChart(props: { chartLayout: CHART_LAYOUTS; selectedTable: string | undefined }) {
  // generatedReport, reportLoading, queryRequest, selectedTable
  const reportContext = useContext(ReportContext);
  const { queryRequest } = reportContext;

  const [dataRows, setDataRows] = useState<any[] | undefined>();

  const fetch = (queryRequest: QueryRequest) => {
    apiService
      .getApi()
      .report.reportControllerQuery(queryRequest)
      .then((response) => {
        setDataRows(response.data.dataRows);
      });
  };

  if (!dataRows || dataRows.length === 0) {
    console.log(`No data rows for ${props.selectedTable}`);
    return <>...</>;
  }

  try {
    const alias = queryRequest?.tableTree?.name ?? ":poop:";
    //const alias = queryRequest?.tableTree?.alias ?? ":poop:";

    const labelColumn = getFirstDistinctTableColumn(alias, dataRows?.[0]);
    const countColumn =
      Object.keys(dataRows?.[0]).find(
        (key) => key.toLowerCase().includes(`${alias}.count`) || key.toLowerCase().includes(`${alias}.total`)
      ) ?? undefined;
    // debugger;

    const data = {
      labels,
      datasets: dataRows
        .filter((row: any) => row[labelColumn])
        .map((row: any) => {
          // console.log(row);
          return {
            label: `${row[labelColumn]}`, //  ${countColumn ? `(${row[countColumn]})` : ""}
            data: Object.keys(row).reduce((acc, col) => {
              const colName = col.replace(`${alias}.`, "");
              if (abbrMonths.includes(colName)) {
                acc[monthToAbbrMap[colName]] = row[col];
              }
              return acc;
            }, {}),
            fill: false,
            //backgroundColor: 'rgb(255, 99, 132)',
            borderColor: randomColor(),
          };
        }),
    };

    if (!data || !data.datasets || data.datasets.length === 0) {
      throw new Error("no data set");
    }

    const legendCollection = data.datasets.map((dataset: any) => {
      return {
        label: dataset.label,
        color: dataset.borderColor,
      };
    });

    return (
      <Stack
        className={props.chartLayout === CHART_LAYOUTS.fullscreen ? "fullscreen" : ""}
        direction="row"
        height="100%"
      >
        <Box width={props.chartLayout === CHART_LAYOUTS.fullscreen ? "80%" : "50%"} height="100%">
          <Line options={options} data={data} />
        </Box>
        <Box className="legend" width={props.chartLayout === CHART_LAYOUTS.fullscreen ? "20%" : "50%"} height="100%">
          <ol>
            {legendCollection.map((legend: any) => {
              return (
                <li className="legend-item" key={legend.label}>
                  <div
                    style={{
                      display: "inline-block",
                      backgroundColor: legend.color,
                      width: "1em",
                      height: "1em",
                      border: "1px solid #909090",
                    }}
                  ></div>
                  {` ${legend.label}`}
                </li>
              );
            })}
          </ol>
        </Box>
      </Stack>
    );
  } catch (e) {
    console.log(e);
    return <>...</>;
  }
}

function getFirstDistinctTableColumn(alias: string, row: any): string {
  const rowsThatWeCanIgnore = [
    "id",
    "patientid",
    "jan",
    "feb",
    "mar",
    "apr",
    "may",
    "jun",
    "jul",
    "aug",
    "sep",
    "oct",
    "nov",
    "dec",
    "total",
    "count",
    "Count",
    "year",
  ];
  const rowKeys = Object.keys(row).map((key) => key.replace(`${alias}.`, ""));
  const firstDistinctTableColumn = rowKeys.find((key) => {
    // console.log(`${alias}.${key.toLowerCase()}`);
    return !rowsThatWeCanIgnore.includes(`${key.toLowerCase()}`) ? key : undefined;
  });
  return firstDistinctTableColumn ? `${alias}.${firstDistinctTableColumn}` : "No Column Found";
}
