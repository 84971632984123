import { Field, QueryRequest } from "api/Api";
import {
  Card,
  ListContainer,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "components";
import produce from "immer";
import { LatestUpdateDialog } from "pages/ReportPage/DataGrid/LatestUpdateDialog";
import { useContext, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { ReportContext } from "../ReportContextProvider";
import { getFieldFullIdentifier } from "../utils/utils";
import { DataGridPagination } from "./DataGridPagination";
import { SortSummary } from "./SortSummary";
import { TableBodyCell } from "./TableBodyCell";
import { TableHeaderCell } from "./TableHeaderCell";
import { formatMs } from "services";

export const DataGrid = (props: {
  queryRequest: QueryRequest;
  setQueryRequest: (queryRequest: QueryRequest) => void;
}) => {
  const reportContext = useContext(ReportContext);
  const tableContainerRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

  const [draggingField, setDraggingField] = useState<Field>();
  const fields = props.queryRequest.fields;

  const fetchDataElapsedTime = reportContext.queryResult?.elapsedTime;
  const fetchTotalElapsedTime = reportContext?.queryTotalResult?.elapsedTime;

  const onDragStart = (result) => {
    tableContainerRef!.current!.scrollTop = 0;
    setDraggingField(props.queryRequest.fields![result.source.index]);
  };

  const onDragEnd = (result) => {
    setDraggingField(undefined);

    if (!result.destination) {
      return;
    }

    const items = Array.from(props.queryRequest.fields!);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    props.setQueryRequest(
      produce(props.queryRequest, (draft) => {
        draft!.fields = items;
      })
    );
  };

  const header = () => {
    return (
      <Stack width="100%" direction="row" spacing="sm" justifyContent="space-between">
        <Stack direction="row" spacing="sm" alignItems="center">
          <Typography variant="inherit">Results</Typography>

          {props.queryRequest.sortFields && props.queryRequest.sortFields.length > 0 && (
            <SortSummary
              sortFields={props.queryRequest.sortFields}
              setSortFields={(sortFields) =>
                props.setQueryRequest(
                  produce(props.queryRequest, (draft) => {
                    draft!.sortFields = sortFields;
                  })
                )
              }
            />
          )}
        </Stack>

        <Stack alignItems="flex-end">
          {fetchDataElapsedTime !== undefined && (
            <Typography variant="caption" lineHeight={"100%"}>
              Rows: {formatMs(fetchDataElapsedTime)}
            </Typography>
          )}
          {fetchTotalElapsedTime !== undefined && (
            <Typography variant="caption" lineHeight={"100%"}>
              Total: {formatMs(fetchTotalElapsedTime)}
            </Typography>
          )}
        </Stack>
      </Stack>
    );
  };

  const tableHead = () => {
    return (
      <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided) => (
            <TableHead ref={provided.innerRef} {...provided.droppableProps}>
              <TableRow>
                {fields?.map((field, rowIndex) => {
                  const fieldKey = getFieldFullIdentifier(field);

                  return (
                    <Draggable key={fieldKey} draggableId={fieldKey} index={rowIndex}>
                      {(provided) => (
                        <TableHeaderCell
                          key={fieldKey}
                          field={field}
                          sortFields={props.queryRequest.sortFields || []}
                          setSortFields={(sortFields) =>
                            props.setQueryRequest(
                              produce(props.queryRequest, (draft) => {
                                draft!.sortFields = sortFields;
                              })
                            )
                          }
                          remove={() =>
                            props.setQueryRequest(
                              produce(props.queryRequest, (draft) => {
                                draft!.fields?.splice(rowIndex, 1);
                              })
                            )
                          }
                          draggableProps={provided.draggableProps}
                          dragHandleProps={provided.dragHandleProps}
                          dragInnerRef={provided.innerRef}
                          onDragMouseDown={() => {
                            tableContainerRef!.current!.scrollTop = 0;
                          }}
                        />
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </TableRow>
            </TableHead>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  const tableBody = () => {
    return (
      <TableBody>
        {reportContext.queryResult?.dataRows?.map((row, rowIndex) => {
          return (
            <TableRow
              key={rowIndex}
              hoverEffect
              sx={{
                filter: draggingField ? "blur(5px)" : "none",
                opacity: draggingField ? 0.7 : 1,
              }}
            >
              {fields?.map((field, colIndex) => {
                const identifer = getFieldFullIdentifier(field);
                let cellValue = row[identifer];

                return (
                  <TableBodyCell
                    key={identifer}
                    cellValue={cellValue}
                    field={field}
                    colSpan={draggingField && field === draggingField ? 2 : undefined} // colSpan here helps the columns to align better when dragging
                  />
                );
              })}
            </TableRow>
          );
        })}
      </TableBody>
    );
  };

  return (
    <Card
      header={header()}
      sx={{
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        height: "100%",
      }}
    >
      {fields.length > 0 && (
        <ListContainer
          fullHeight
          tableContainer={
            <TableContainer
              ref={tableContainerRef}
              //sx={{ overflow: draggingField ? "hidden" : "auto" }}
            >
              <Table stickyHeader>
                {tableHead()}

                {tableBody()}
              </Table>
            </TableContainer>
          }
          footer={
            <Stack direction="row" justifyContent={"space-between"}>
              <LatestUpdateDialog />
              <DataGridPagination />
            </Stack>
          }
        />
      )}
    </Card>
  );
};
