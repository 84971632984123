import { Highlighter, IconButton, Stack, Typography } from "components";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MenuIcon from "@mui/icons-material/MoreHoriz";
import { DbtDataField, DbtTable, Field as FieldModel } from "api/Api";
import { ellipsisStyle } from "consts";
import {  useState } from "react";
import { FunctionField } from "./FunctionField";
import { Field } from "./Field/Field";
import { TableMenu } from "./TableMenu/TableMenu";
import { getFieldFullIdentifier } from "pages/ReportPage/utils/utils";

export const Table = (props: {
  dbtTable: DbtTable;
  selected?: boolean;
  toggleSelect?: () => void;
  selectedFields: FieldModel[];
  setSelectedFields: (fields: FieldModel[]) => void;
  searchString?: string;
  disabled?: boolean;
  clickMode?: "select" | undefined;
  removeTable?: () => void;
  //dragHandleProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
}) => {


  const [expanded, setExpanded] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [openFilters, setOpenFilters] = useState<boolean>(false);

  // the Highlighter component is used to highlight the search string in the table name
  // a value is required, it cannot be undefined
  const tableDisplayName = props.dbtTable.displayName || props.dbtTable.name || "";

  let dataFields: DbtDataField[] = [];

  const renderTable = () => {
    return (
      <Stack
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => {
          switch (props.clickMode) {
            case "select":
              return props.toggleSelect?.();
            default:
              return undefined;
          }
        }}
        bgcolor={props.selected ? "grey.200" : isHovered ? "grey.50" : undefined}
        color="primary"
        direction="row"
        spacing="xs"
        alignItems="center"
        justifyContent="space-between"
        minHeight="32px"
        width="100%"
        sx={{
          cursor: props.disabled ? "default" : "pointer",
          opacity: props.disabled ? 0.3 : 1,
          pointerEvents: props.disabled ? "none" : "auto",
        }}
        px={1.5}
      >
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            setExpanded(!expanded);
          }}
          size="small"
        >
          <KeyboardArrowUpIcon
            sx={{
              transform: expanded ? "rotate(180deg)" : "rotate(90deg)",
              transition: "all 0.2s",
              opacity: 0.6,
            }}
          />
        </IconButton>

        <Stack {...ellipsisStyle} flexGrow={1}>
          <Typography fontSize="0.9rem" fontWeight={(theme) => theme.typography.fontWeightLight}>
            <Highlighter searchWords={[props.searchString || ""]} textToHighlight={tableDisplayName} />
          </Typography>
        </Stack>

        <Stack direction="row">
          {(isHovered || props.selected || Boolean(menuAnchorEl)) && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setMenuAnchorEl(e.currentTarget);
              }}
              size="small"
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          )}
        </Stack>
      </Stack>
    );
  };

  // if expanded, show all fields
  if (expanded) {
    dataFields = props.dbtTable.dataFields;
  } else if (props.searchString) {
    // if searching, show only matching fields
    dataFields = props.dbtTable.dataFields.filter(
      (x) =>
        x.fieldName.toLowerCase().includes(props.searchString!.toLowerCase()) ||
        x.displayName?.toLowerCase().includes(props.searchString!.toLowerCase())
    );
  }

  const toggleField = (toggleField: FieldModel) => {
    const newFields = new Map<string, FieldModel>([...props.selectedFields].map((x) => [getFieldFullIdentifier(x), x]));
    const addingFieldFullIdentifer = getFieldFullIdentifier(toggleField);
    if (newFields.has(addingFieldFullIdentifer)) newFields.delete(addingFieldFullIdentifer);
    else newFields.set(addingFieldFullIdentifer, toggleField);
    props.setSelectedFields([...newFields.values()]);
  };

  return (
    <Stack>
      {renderTable()}

      {/* Table Menu */}
      <TableMenu
        menuAnchorEl={menuAnchorEl}
        setMenuAnchorEl={setMenuAnchorEl}
        dbtTable={props.dbtTable}
        selectedFields={props.selectedFields}
        setSelectedFields={props.setSelectedFields}
        openFilters={() => setOpenFilters(true)}
        removeTable={props.removeTable}
      />

      {/* Table Fields */}
      {dataFields.length > 0 && (
        <Stack>
          {dataFields.map((dataField) => {
            const fields = props.selectedFields.filter(
              (x) => x.fieldName.toLowerCase() === dataField.fieldName.toLowerCase()
            );
            const selectedBaseField = fields.find((x) => !x.transform) !== undefined;
            const selectedAggregateFunctionFields = fields?.filter((x) => x.transform === "AggregateFunction");
            const selectedCustomFunctionFields = fields?.filter((x) => x.transform === "CustomFunction");

            return (
              <>
                <Field
                  dbtTable={props.dbtTable.name}
                  dataField={dataField}
                  isSelected={selectedBaseField}
                  toggleSelect={() => toggleField({ dbName: props.dbtTable.dbName, tableName: props.dbtTable.name, fieldName: dataField.fieldName })}
                  searchString={props.searchString}
                  selectedAggregateFunctions={selectedAggregateFunctionFields.map((x) => x.aggregateFunction!)}
                  toggleAggregateFunction={(aggregateFunction) => {
                    const field: FieldModel = {
                      dbName: props.dbtTable.dbName,
                      tableName: props.dbtTable.name,
                      fieldName: dataField.fieldName,
                      transform: "AggregateFunction",
                      aggregateFunction: aggregateFunction,
                    };
                    toggleField(field);
                  }}
                  selectedCustomFunctions={selectedCustomFunctionFields.map((x) => x.customFunction!)}
                  toggleCustomFunction={(customFunction) => {
                    const field: FieldModel = {
                      dbName: props.dbtTable.dbName,
                      tableName: props.dbtTable.name,
                      fieldName: dataField.fieldName,
                      transform: "CustomFunction",
                      customFunction: customFunction,
                    };
                    toggleField(field);
                  }}
                />

                {/* If there are any aggregation functions for this field, show them as additional fields here */}
                {selectedAggregateFunctionFields?.map((field) => {
                  return <FunctionField field={field} remove={() => toggleField(field)} />;
                })}

                {selectedCustomFunctionFields?.map((field) => {
                  return <FunctionField field={field} remove={() => toggleField(field)} />;
                })}
              </>
            );
          })}
        </Stack>
      )}
    </Stack>
  );
};