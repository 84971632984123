import { Tooltip } from "components";
import NumbersIcon from "@mui/icons-material/Numbers";
import AbcIcon from "@mui/icons-material/Abc";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import DateRangeIcon from "@mui/icons-material/DateRange";
import DownloadDoneIcon from "@mui/icons-material/DownloadDone";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { DataType } from "api/Api-extension";

export const DataTypeIcon = (props: {
  type: DataType;
  color?:
    | "disabled"
    | "action"
    | "inherit"
    | "warning"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | undefined;
}) => {
  const fontSize: string = "1.2rem";
  const opacity: string = "0.8";
  let comp: any = null;

  switch (props.type) {
    case "String":
      comp = <AbcIcon sx={{ fontSize: fontSize, opacity: opacity }} color={props.color} />;
      break;
    case "Number":
      comp = <NumbersIcon sx={{ fontSize: fontSize, opacity: opacity }} color={props.color} />;
      break;
    case "Date":
      comp = <CalendarTodayIcon sx={{ fontSize: fontSize, opacity: opacity }} color={props.color} />;
      break;
    case "Datetime":
      comp = <DateRangeIcon sx={{ fontSize: fontSize, opacity: opacity }} color={props.color} />;
      break;
    case "Time":
      comp = <AccessTimeIcon sx={{ fontSize: fontSize, opacity: opacity }} color={props.color} />;
      break;
    case "Boolean":
      comp = <DownloadDoneIcon sx={{ fontSize: fontSize, opacity: opacity }} color={props.color} />;
      break;
    default:
      comp = props.type;
      break;
  }

  return <Tooltip title={props.type}>{comp}</Tooltip>;
};
