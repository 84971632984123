import { ThemeProvider } from "@mui/material/styles";
import {
  AppTopBarBrand,
  AppTopBarUserMenu,
  BoxProps,
  Link,
  Paper,
  //NavigationBar,
  Stack
} from "components";
import { adminPageRoute } from "pages/Admin/Admin";
import { financeDashboardPageRoute } from "pages/Finance/FinanceDashboard";
import { statsPageRoute } from "pages/Stats/StatsPage";
import { terminationDashboardPageRoute } from "pages/Turnover/TurnoverDashboardPage";
// import { testPageRoute } from "pages/TestPage/TestPage";
import { useNavigate } from "react-router";
import { PermsCheck, VALID_ROLES, FAILURE_RESPONSE_OPTION } from "routes/PermsCheck";
import { darkTheme } from "theme";

type AppTopBarProps = Omit<BoxProps, "children"> & {};

export const AppTopBar = ({ className, sx, ...rest }: AppTopBarProps) => {
  const navigate = useNavigate();

  return (
    <ThemeProvider theme={darkTheme}>
      <Paper sx={{ position: "relative", borderRadius: "0px" }}>
        <Stack
          direction="row"
          px={2}
          py={4}
          height="50px"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" spacing="md" alignItems="center">
            <AppTopBarBrand />
            <PermsCheck
              requiredRoles={[VALID_ROLES.EXECUTIVE, VALID_ROLES.DEVS]}
              failureResponse={FAILURE_RESPONSE_OPTION.NONE}
            >
              <Link
              onClick={(e) => {
                e.preventDefault();
                navigate(`${statsPageRoute.templatePath}#first`);
              }}
              href=""
              underline="none"
              >
              { statsPageRoute.label}
              </Link>
            </PermsCheck>
            <PermsCheck
              requiredRoles={[
                VALID_ROLES.EXECUTIVE, 
                VALID_ROLES.DEVS, 
                VALID_ROLES.HR
              ]}
              failureResponse={FAILURE_RESPONSE_OPTION.NONE}
            >
            <Link
            onClick={(e) => {
              e.preventDefault();
              navigate(`${terminationDashboardPageRoute.templatePath}`);
            }}
            href=""
            underline="none"
            >
            { terminationDashboardPageRoute.label }
            </Link>
            </PermsCheck>
            <PermsCheck
              requiredRoles={[
                VALID_ROLES.EXECUTIVE, 
                VALID_ROLES.DEVS, 
                VALID_ROLES.FINANCE
              ]}
              failureResponse={FAILURE_RESPONSE_OPTION.NONE}
            >
              <Link
              onClick={(e) => {
                e.preventDefault();
                navigate(`${financeDashboardPageRoute.templatePath}/dashboard`);
              }}
              href=""
              underline="none">
                {
                  financeDashboardPageRoute.label
                }
              </Link>
            </PermsCheck>
            <PermsCheck
              requiredRoles={[VALID_ROLES.EXECUTIVE, VALID_ROLES.DEVS]}
              failureResponse={FAILURE_RESPONSE_OPTION.NONE}
            >
              <Link
              onClick={(e) => {
                e.preventDefault();
                navigate(`${adminPageRoute.path}`);
              }}
              href=""
              underline="none"
              >
              { adminPageRoute.handle}
              </Link>
            </PermsCheck>
          </Stack>

          <AppTopBarUserMenu />
        </Stack>
      </Paper>
    </ThemeProvider>
  );
};