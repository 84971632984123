import { TerminationReport } from "api/Api";
import { Box, Stack } from "components";
import { monthsOfTheYear } from "../helpers";
import { TerminationFilters } from "../../TurnoverReportData";
import { useState } from "react";


export function TerminationReportByDepartment({year, terminationData, filters}: {year: number; terminationData: TerminationReport; filters: TerminationFilters;}) {
    const [hoverRow, setHoverRow] = useState<number | undefined>(undefined);
    return <Stack direction="row">
        <Departments year={year} terminationData={terminationData} hoverRow={hoverRow} setHoverRow={setHoverRow} />
        <Terminations year={year} terminationData={terminationData} was_terminated={filters.was_terminated} hoverRow={hoverRow} setHoverRow={setHoverRow} />
        <EmployeeCount year={year} terminationData={terminationData} hoverRow={hoverRow} setHoverRow={setHoverRow} />
        <Turnovers year={year} terminationData={terminationData} hoverRow={hoverRow} setHoverRow={setHoverRow} />
        </Stack>;
}

function Departments({year, terminationData, hoverRow, setHoverRow}: {year: number; terminationData: TerminationReport; hoverRow: number | undefined; setHoverRow: Function;}) {
    const jobDepartmentData: {
        [department:string]: any;
    } = terminationData.monthlyTerminationsByDepartments;
    
    return <>
        <Box className="grouped-by-field">
            <h3>By Department</h3>
            <table>
                <thead>
                    <tr>
                        <th style={{width:"100%", textAlign: "left", overflow: "nowrap"}}></th>
                    </tr>
                </thead>
                <tbody>
                {Object.keys(jobDepartmentData).map((department, index) => {
                    return <tr 
                            className={`${index === hoverRow ? 'hovered':''}`}
                            onMouseOver={(e)=> {
                                setHoverRow(index);
                            }} 
                            onMouseOut={(e)=> {
                                setHoverRow(undefined);
                            }}
                            key={`mtrbd-departments-${index}`}>
                            <th style={{width: "100%", textAlign: "left", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}}>{department}</th>
                        </tr>;
                })}
                </tbody>
                <tfoot>
                    <tr>
                        <th style={{ width: "100%", textAlign: "left", overflow: "hidden", whiteSpace: "nowrap" }}>Totals</th>
                    </tr>
                </tfoot>
            </table>
        </Box>
    </>
}

function Terminations({year, terminationData, was_terminated, hoverRow, setHoverRow}: {year: number; terminationData: TerminationReport; was_terminated: boolean; hoverRow: number | undefined; setHoverRow: Function;}) {
    const jobDepartmentData: {
        [department:string]: any;
    } = terminationData.monthlyTerminationsByDepartments;

    let maxMonth = 1;

    const columnTotals: number[] = getTerminationColumnTotals(jobDepartmentData, year);

    let departmentDataSummary: {
        [
            department: string
        ]: {
            total: number;
            months:{[month: number]: number;};
        };
    } = Object.keys(jobDepartmentData).reduce((acc, department, idx) => {
        const jobDepartmentGroupData = jobDepartmentData[department];
        acc[department] = {
            total: 0,
            months: Array.from({length: 12}, (_, i) => i).map((month, index) => 0)
        };

        maxMonth = 1;
        let total = 0;
        Object.keys(jobDepartmentGroupData).filter((yearMonth) => yearMonth.startsWith(`${year}`)).forEach((yearMonth) => {
            const month = parseInt(yearMonth.split('-')[1])-1;
            const count = jobDepartmentGroupData[yearMonth];
            acc[department].months[month] = typeof count === 'string' ? parseInt(count) : count;
            total += acc[department].months[month];
            maxMonth = Math.max(maxMonth, month);
        })

        // if maxMonth is less than 12, add empty cells to the end of the array
        if (maxMonth < 11) {
            for (let i = maxMonth+1; i < 12; i++) {
                acc[department].months[i] = 0;
                columnTotals[i] = (columnTotals[i] || 0) + acc[department].months[i];
            }
        }

        acc[department].total = total;

        return acc;
    }, {});

    const columnWidth = 100/13 + "%";

    return <>
        
            <Box className="data-presentation termination">
                <h3>Terminations</h3>
                <table>
                    <thead>
                        <tr>
                            {
                                Array.from({length: 12}, (_, i) => i).map((month, index) => <th style={{width: columnWidth}} key={index}>{monthsOfTheYear[month]}</th>)
                            }
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                    {Object.keys(departmentDataSummary).map((department, index) => {
                        const jobLocationGroupData = departmentDataSummary[department];
                        
                        return <tr 
                                className={`${index === hoverRow ? 'hovered':''}`}
                                onMouseOver={(e)=> {
                                    setHoverRow(index);
                                }} 
                                onMouseOut={(e)=> {
                                    setHoverRow(undefined);
                                }}
                                key={`mtrbd-terminations-${index}`}>
                                {Object.values(jobLocationGroupData.months).map((count, idx) => {
                                    return <td key={`terminations-${department}-${year}-${idx}`} style={{ color: (count === 0 ? '#777': '#000'), textAlign: "right", width: columnWidth}}>{count}</td>
                                })}
                                <td key={`${department}-${year}-total`} style={{ color: (jobLocationGroupData.total === 0 ? '#777': '#000'), textAlign: "right", width: columnWidth}}>{jobLocationGroupData.total}</td>
                            </tr>;
                    })}
                    </tbody>
                    <tfoot>
                        <tr>
                            {
                                columnTotals.map((total, index) => <td key={`${year}-${index}`} style={{ color: (total === 0 ? '#777': '#000'), textAlign: "right", width: columnWidth}}>{total}</td>)
                            }
                            <td style={{textAlign: "right", width: columnWidth}}>
                                {
                                    columnTotals.reduce((acc, total) => acc + total, 0)
                                }
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </Box>
        
    </>
}

function EmployeeCount({year, terminationData, hoverRow, setHoverRow}: {year: number; terminationData: TerminationReport; hoverRow: number | undefined; setHoverRow: Function;}) {
    const jobDepartmentData: {
        [jobTitle:string]: any;
    } = terminationData.monthlyEmployeesAverageByDepartment;

    let maxMonth = 1;

    const columnTotals: number[] = getEmployeeCountColumnTotals(jobDepartmentData, year);

    let departmentDataSummary: {
        [
            department: string
        ]: {
            total: number;
            months:{[month: number]: number;};
        };
    } = Object.keys(jobDepartmentData).reduce((acc, department, idx) => {
        const jobDepartmentGroupData = jobDepartmentData[department];
        acc[department] = {
            total: 0,
            months: {}
        };

        maxMonth = 1;
        let total = 0;
        Object.keys(jobDepartmentGroupData).filter((yearMonth) => yearMonth.startsWith(`${year}`)).forEach((yearMonth) => {
            const month = parseInt(yearMonth.split('-')[1])-1;
            const count = jobDepartmentGroupData[yearMonth];
            acc[department].months[month] = typeof count === 'string' ? parseFloat(count) : count;
            total += acc[department].months[month];
            maxMonth = Math.max(maxMonth, month);
        })

        // if maxMonth is less than 12, add empty cells to the end of the array
        if (maxMonth < 11) {
            for (let i = maxMonth+1; i < 12; i++) {
                acc[department].months[i] = 0;
            }
        }

        acc[department].total = total;

        return acc;
    }, {});

    const columnWidth = `${100/12}%`;
    return <Box className="data-presentation employee-count">
                <h3>Average Employee Count</h3>
                <table>
                    <thead>
                        <tr>
                            {
                                Array.from({length: 12}, (_, i) => i).map((month, index) => <th style={{width: columnWidth}} key={index}>{monthsOfTheYear[month]}</th>)
                            }
                        </tr>
                    </thead>
                    <tbody>
                    {Object.keys(departmentDataSummary).map((department, index) => {
                        const jobLocationGroupData = departmentDataSummary[department];
                        
                        return <tr 
                                className={`${index === hoverRow ? 'hovered':''}`}
                                onMouseOver={(e)=> {
                                    setHoverRow(index);
                                }} 
                                onMouseOut={(e)=> {
                                    setHoverRow(undefined);
                                }}
                                key={`mtrbd-employee-count-${index}`}>
                                {Object.values(jobLocationGroupData.months).map((count, idx) => {
                                    return <td key={`employee-count-${department}-${year}-${idx}`} style={{ color: (count === 0 ? '#777': '#000'), textAlign: "right", width: columnWidth}}>{count}</td>
                                })}
                            </tr>;
                    })}
                    </tbody>
                    <tfoot>
                        <tr>
                            {
                                columnTotals.map((total, index) => <td key={`${year}-${index}`} style={{ color: (total === 0 ? '#777': '#000'), textAlign: "right", width: columnWidth}}>{total}</td>)
                            }
                        </tr>
                    </tfoot>
                </table>
            </Box>;
}

function Turnovers({year, terminationData, hoverRow, setHoverRow}: {year: number; terminationData: TerminationReport; hoverRow: number | undefined; setHoverRow: Function;}) {
    const jobDepartmentData: {
        [jobTitle:string]: any;
    } = terminationData.monthlyTurnoverRateByDepartment;

    const terminationTotals = getTerminationColumnTotals(terminationData.monthlyTerminationsByLocation, year);
    const employeeCountTotals = getEmployeeCountColumnTotals(terminationData.monthlyEmployeesAverageByLocation, year);
    const turnoverRates = getTurnoverRates(terminationTotals, employeeCountTotals);

    let maxMonth = 1;

    let departmentDataSummary: {
        [
            department: string
        ]: {
            total: number;
            months:{[month: number]: number;};
        };
    } = Object.keys(jobDepartmentData).reduce((acc, department, idx) => {
        const jobDepartmentGroupData = jobDepartmentData[department];
        acc[department] = {
            total: 0,
            months: {}
        };

        maxMonth = 1;
        let total = 0;
        Object.keys(jobDepartmentGroupData).filter((yearMonth) => yearMonth.startsWith(`${year}`)).forEach((yearMonth) => {
            const month = parseInt(yearMonth.split('-')[1])-1;
            const count = jobDepartmentGroupData[yearMonth];
            acc[department].months[month] = typeof count === 'string' ? parseInt(count) : count;
            total += acc[department].months[month];
            maxMonth = Math.max(maxMonth, month);
        })

        // if maxMonth is less than 12, add empty cells to the end of the array
        if (maxMonth < 11) {
            for (let i = maxMonth+1; i < 12; i++) {
                acc[department].months[i] = 0;
            }
        }

        acc[department].total = total;

        return acc;
    }, {});

    const columnWidth = `${100/13}%`

    return <Box  className="data-presentation turnover-rate">
                <h3>Turnover Rate</h3>
                <table>
                    <thead>
                        <tr>
                            {
                                Array.from({length: 12}, (_, i) => i).map((month, index) => <th style={{width: columnWidth}} key={index}>{monthsOfTheYear[month]}</th>)
                            }
                        </tr>
                    </thead>
                    <tbody>
                    {Object.keys(departmentDataSummary).map((department, index) => {
                        const jobLocationGroupData = departmentDataSummary[department];
                        
                        return <tr 
                                className={`${index === hoverRow ? 'hovered':''}`}
                                onMouseOver={(e)=> {
                                    setHoverRow(index);
                                }} 
                                onMouseOut={(e)=> {
                                    setHoverRow(undefined);
                                }}
                                key={`mtrbd-ratios-${index}`}>
                                {Object.values(jobLocationGroupData.months).map((count, idx) => {
                                    return <td key={`turnover-rate-${department}-${year}-${idx}`} style={{ color: (count === 0 ? '#777': '#000'), textAlign: "right", width: columnWidth}}>{count}%</td>
                                })}
                            </tr>;
                    })}
                    </tbody>
                    <tfoot>
                        <tr>
                            {
                                turnoverRates.map((total, index) => <td key={`ratios-total-${index}`} style={{ color: (total === 0 ? '#777': '#000'), textAlign: "right", width: columnWidth}}>{total.toFixed(1)}%</td>)
                            }
                        </tr>
                    </tfoot>
                </table>
            </Box>;
}

function getTerminationColumnTotals(jobDepartmentData, year): number[] {
    const columnTotals: number[] = [];
    Object.keys(jobDepartmentData).forEach((location) => {
        const jobDepartmentGroupData = jobDepartmentData[location];
        Object.keys(jobDepartmentGroupData).filter((yearMonth) => yearMonth.startsWith(`${year}`)).forEach((yearMonth) => {
            const month = parseInt(yearMonth.split('-')[1])-1;
            const count = jobDepartmentGroupData[yearMonth];
            columnTotals[month] = (columnTotals[month] || 0) + (typeof count === 'string' ? parseInt(count) : count);
        })
    });
    // add 0s for the months that don't have any data
    for (let i = columnTotals.length; i < 12; i++) {
        columnTotals.push(0);
    }
    
    return columnTotals;
}

function getEmployeeCountColumnTotals(jobDepartmentData, year: number): number[] {
    const columnTotals: number[] = [];
    Object.keys(jobDepartmentData).forEach((location) => {
        const jobDepartmentGroupData = jobDepartmentData[location];
        Object.keys(jobDepartmentGroupData).filter((yearMonth) => yearMonth.startsWith(`${year}`)).forEach((yearMonth) => {
            const month = parseInt(yearMonth.split('-')[1])-1;
            const count = jobDepartmentGroupData[yearMonth];
            columnTotals[month] = (columnTotals[month] || 0) + (typeof count === 'string' ? parseFloat(count) : count);
        })
    });

    // add 0s for the months that don't have any data
    for (let i = columnTotals.length; i < 12; i++) {
        columnTotals.push(0);
    }

    return columnTotals;
}

function getTurnoverRates(terminationTotals: number[], employeeCountTotals: number[]): number[] {
    const termTots = terminationTotals.map((terminationTotal, index) => {
        return employeeCountTotals[index] > 0 ? ((terminationTotal / employeeCountTotals[index]) * 100) : 0;
    });
    // fill in the empty months with 0
    for (let i = termTots.length; i < 12; i++) {
        termTots.push(0);
    }
    return termTots;
}
