import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Page } from "components";
import { PageRoute } from "models/PageRoute";
import { buildPath, buildTemplatePath } from "services/routeService";

const routeName = "test";

export const testPageRoute: PageRoute = {
  handle: "Test",
  templatePath: buildTemplatePath(routeName),
  getPath: () => buildPath(routeName),
};

export const TestPage: React.FC = () => {
  return (
    <Page header={<>Test Page</>}>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell rowSpan={2}>Year: Invoice Date</TableCell>
              <TableCell colSpan={2}>Sum: Invoice Amount</TableCell>
              <TableCell colSpan={3}>Count: Invoice Amount</TableCell> {/* Adjusted colSpan here */}
            </TableRow>
            <TableRow>
              <TableCell>Jan</TableCell>
              <TableCell>Feb</TableCell>
              <TableCell>Jan</TableCell>
              <TableCell>Feb</TableCell>
              <TableCell>Mar</TableCell> {/* Added third column */}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>2021</TableCell>
              <TableCell>100</TableCell>
              <TableCell>200</TableCell>
              <TableCell>3</TableCell>
              <TableCell>4</TableCell>
              <TableCell>5</TableCell> {/* Example data for new column */}
            </TableRow>
            <TableRow>
              <TableCell>2022</TableCell>
              <TableCell>150</TableCell>
              <TableCell>250</TableCell>
              <TableCell>6</TableCell>
              <TableCell>7</TableCell>
              <TableCell>8</TableCell> {/* Example data for new column */}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Page>
  );
};
