import { Auth } from "aws-amplify";
import { Box } from "@mui/material";
import { Role } from "api/Api";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { setAuthLoading, setAuthRoles } from "redux/reducers/authSlice";
import { RootState } from "redux/store";
import { decodeJWT } from "utils";

export enum FAILURE_RESPONSE_OPTION {
    REDIRECT,
    ERROR, 
    NONE
};

/**
 * provide the role name and the routes to redirect to by order of preference
 * name to redirect map - The name of the role to the route to redirect to.
 * {
 *  "devs": "/everything-is-awesome",
 *  "bi-reports-hr": "/nothing-to-see-here"
 * }
 */
export type RedirectMap = {
    [key: string]: string,
};

/*
  // other s3curi7y groups from ensomata
  'e34c38ec-4c22-4f0e-b7c4-3b6ebfb7f810': 'provider',
  '0d33860c-2b4d-4514-b2da-9082cf69827b': 'devs',
  '17b4f823-e62b-4dec-83e4-0120ef0f8980': 'rad-techs',
  '340f7efe-9b5c-407d-b568-11452a5b5e1b': 'legacy-ma-user',
  '7a476a00-aa17-4338-81db-c3dc6017da5c': 'ma-workflow',
  // bi-report s3curi7y groups
  'f0acda5f-7362-463d-8ea9-033157298d51': 'bi-reports-executive',
  '84fa3b36-340d-45d4-b1d4-32c8658348f3': 'bi-reports-finance',
  '5bfbfb7f-cf12-4164-a059-b4319065e591': 'bi-reports-bi-reports-user',
  '0b569143-44ac-4584-8dff-ce79210fd992': 'bi-reports-hr',
*/

export const VALID_ROLES: {[key:string]: Role} = {
    "EXECUTIVE": {
        id: 'f0acda5f-7362-463d-8ea9-033157298d51',
        name: 'bi-reports-executive',
        description: 'Executive'
    },
    "DEVS": {
        id: '0d33860c-2b4d-4514-b2da-9082cf69827b',
        name: 'devs',
        description: 'Developers'
    },
    "FINANCE": {
        id: '84fa3b36-340d-45d4-b1d4-32c8658348f3',
        name: 'bi-reports-finance',
        description: 'Finance'
    },
    "HR": {
        id: '0b569143-44ac-4584-8dff-ce79210fd992',
        name: 'bi-reports-hr',
        description: 'Human Resources'
    },
    "USER": {
        id: '5bfbfb7f-cf12-4164-a059-b4319065e591',
        name: 'bi-reports-bi-reports-user',
        description: 'BI Reports User'
    },
    "MEDICAL_ASSISTANT": {
        id: '7a476a00-aa17-4338-81db-c3dc6017da5c',
        name: 'ma-workflow',
        description: 'Medical Assistant'
    },
    "PROVIDER": {
        id: 'e34c38ec-4c22-4f0e-b7c4-3b6ebfb7f810',
        name: 'provider',
        description: 'Provider'
    },
};

/**
 * 
 * @param props requiredRoles: Role[] - Any roles that are required to view the content. Any one match will succeed.
 *          failureResponse: FailureResponseOption - What to do if the user does not have the required roles.
 *         redirectTo?: string - If failureResponse is redirect, where to redirect to.
 *        children: any - The content to display if the user has the required roles.
 * @returns - void
 */
export function PermsCheck(props: {
    requiredRoles: Role[], 
    failureResponse: FAILURE_RESPONSE_OPTION, 
    redirectTo?: string | RedirectMap, 
    email?: string, 
    children: any}): JSX.Element {
    const {requiredRoles, failureResponse, redirectTo, children} = props;
    const dispatch = useDispatch();
    const [jwtToken, setJwtToken] = useState<string|undefined>(undefined);

    useEffect(() => {
        if(!jwtToken) {
                dispatch(setAuthLoading( true ));
                Auth.currentAuthenticatedUser()
                .then((authData) => {
                    // set the jwtToken
                    const token = authData?.signInUserSession?.idToken?.jwtToken;
                    setJwtToken(token);
                    localStorage.setItem('jwtToken', token);
                });
        }
    }, [jwtToken]);
    

    useEffect(() => {
      if (jwtToken) {
        const decodedToken = decodeJWT(jwtToken);
        const roles = decodedToken?.["cognito:roles"] ?? [];
        
        dispatch(setAuthRoles( roles ));
        dispatch(setAuthLoading( false ));
      }
    }, [dispatch, jwtToken]);

    const userRolesLoading = useSelector((state: RootState) => state.auth.isLoading);
    const userRolesAsString: string[] = useSelector((state: RootState) => {
        return state.auth.roles;
    });
    const sessionEmail = useSelector((state: RootState) => state.app.userEmail);

    // if things are loading, we're not going to make a determination
    if(userRolesLoading) {
        return <Box>Loading...</Box>;
    }

    
    const requiredRolesAsString: string[] = requiredRoles.map(role => role.name);
    let hasPermission: boolean = requiredRolesAsString.some(requiredRole => userRolesAsString.includes(requiredRole));
    console.log(`checking email`, props.email, sessionEmail);
    // if the user is an executive or a dev, we're going to let them in if they have a required userId
    if(![VALID_ROLES.EXECUTIVE.name, VALID_ROLES.DEVS.name].some(requiredRole => userRolesAsString.includes(requiredRole)) && props.email !== sessionEmail) {
        hasPermission = false;
    }

    if(!hasPermission) {
        if(failureResponse === FAILURE_RESPONSE_OPTION.REDIRECT) {
            if(redirectTo && typeof redirectTo === 'object') {
                // look for the first role that the user has that has a redirect
                let redirectToUrl: string|undefined;
                Object.keys(redirectTo).forEach(role => {
                    if (requiredRolesAsString.includes(role)) {
                        redirectToUrl = redirectTo[role];
                    }
                });

                if(!redirectToUrl) {
                    return <Box>You do not have permission to view this information.</Box>;
                }
                
                return <Navigate 
                to={redirectToUrl} 
                replace
                />;
            } else if(typeof props.redirectTo === 'string') {
                return <Navigate 
                to={props.redirectTo} 
                replace
                />;
            }
            return <Navigate to="login" />;
        } else if(failureResponse === FAILURE_RESPONSE_OPTION.ERROR) {
            return <Box>You do not have permission to view this information.</Box>;
        } else {
            // failureResponse === FailureResponseOption.none || default
            return <></>;
        }
    }
    return <>{children}</>;
}