import { Box, Page, Stack } from "components";
import { useNavigate } from "react-router-dom";

export function Missing404Layout(props: any) {
    const history = useNavigate();

    console.log(props);
    console.log(history);

    return   <>
    <Page>
            <Stack display="flex" height="100vh" alignItems="center" justifyContent="center">
                <Box>
                    <h1>Holy Schnikeys!!</h1>
                </Box>
                <Box>
                    <p>
                        This view is either missing or off limits or something is not right. 
                        We're just as surprised as you are. We've got our <abbr title="eyes">👀</abbr> on it.
                    </p>
                </Box>
            </Stack>        
  </Page>
  </>;
}