import { IconButton } from "components";
import DeleteIcon from "@mui/icons-material/Delete";

export type DeleteIconButtonProps = {
  onClick: () => void;
};

export const DeleteIconButton = ({ onClick }: DeleteIconButtonProps) => {
  return (
    <IconButton onClick={onClick} color="error">
      <DeleteIcon />
    </IconButton>
  );
};
