import { IconButton, Menu, MenuItem } from "components";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createPresetPageRoute, CreatePresetPageState } from "pages/presets/CreatePresetPage/CreatePresetPage";
import { presetListPageRoute } from "pages/presets/PresetListPage/PresetListPage";
import { MyPresetsMenuItem } from "./MyPresetsMenuItem";
import { SharedPresetsMenuItem } from "./SharedPresetsMenuItem";
import { ReportContext } from "pages/ReportPage/ReportContextProvider";

export const NavigationMenu = (props: {
  useQueryBuilder: boolean;
  setUseQueryBuilder: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const reportContext = useContext(ReportContext);
  const navigate = useNavigate();
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          setMenuAnchorEl(e.currentTarget);
        }}
      >
        <MoreHorizIcon />
      </IconButton>

      <Menu anchorEl={menuAnchorEl} open={Boolean(menuAnchorEl)} onClose={() => setMenuAnchorEl(null)}>
        <MyPresetsMenuItem closeParentMenu={() => setMenuAnchorEl(null)} />
        <SharedPresetsMenuItem closeParentMenu={() => setMenuAnchorEl(null)} />

        <MenuItem
          onClick={(e: React.MouseEvent<HTMLLIElement>) => {
            e.stopPropagation();
            navigate(createPresetPageRoute.getPath(), {
              state: { queryRequest: reportContext?.queryRequest } as CreatePresetPageState,
            });
            setMenuAnchorEl(null);
          }}
        >
          Save This Preset
        </MenuItem>

        <MenuItem
          onClick={(e: React.MouseEvent<HTMLLIElement>) => {
            e.stopPropagation();
            navigate(presetListPageRoute.getPath());
            setMenuAnchorEl(null);
          }}
        >
          Manage Presets
        </MenuItem>

        {/* <Divider />

        <MenuItem
          onClick={(e: React.MouseEvent<HTMLLIElement>) => {
            e.stopPropagation();
            props.setUseQueryBuilder(true);
            setMenuAnchorEl(null);
          }}
        >
          Convert to Query Builder
        </MenuItem> */}
      </Menu>
    </>
  );
};
