import { EmailList } from "api/Api";
import { useEffect, useState } from "react";
import apiService from "services/apiService";

export function useList(id: number): [EmailList | undefined, boolean] {
  const [list, setList] = useState<EmailList | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (id === 0) {
      setLoading(false);
      return;
    }
    if (!id) {
      setLoading(false);
      return;
    }
    // fetch list by id
    apiService
      .getApi()
      .admin.emailControllerGetList(id)
      .then((response) => {
        setList(response.data);
        setLoading(false);
      });
    setLoading(false);
  }, [id]);

  return [list, loading];
}
