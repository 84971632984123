import {Employee, ReportSegment as iReportSegment} from "api/Api";
import { Box, Stack, Typography } from "@mui/material";
import { ReportSegmentPreview } from "../editor-helpers/ReportSegmentPreview";
import { PuckConfiguration } from "../PuckConfiguration/PuckConfiguration";
import { ReportVariants } from "../ReportVariants";

export function PuckConfigurationPanel({
        tempSegment, 
        setTempSegment, 
        reader, 
        setReader,
        readerDate,
        setReaderDate
        }:
        {
            tempSegment: iReportSegment; 
            setTempSegment: (segment: iReportSegment) => void; 
            saveReportSegment: () => void; 
            reader: Employee | undefined; 
            setReader: (emp:Employee) => void;
            readerDate: string;
            setReaderDate:(date:string) => void;
    }) {
    return <>
            <Stack direction="row" spacing={2} mt={4}>
                <Box style={{width: "40%"}}>
                    <Stack spacing={2}>
                        <Box><Typography variant="h5">Emailable Puck Configuration</Typography></Box>
                        <Box><PuckConfiguration segment={tempSegment} onChange={(segment)=>{
                            setTempSegment(segment);
                        }} /></Box>
                    </Stack>
                </Box>
                <Box style={{width: "60%"}}>
                    <Stack spacing={2}>
                            <Box>
                                <ReportSegmentPreview 
                                    reportSegment={tempSegment}
                                    index={0}
                                    name={tempSegment.name}
                                    description={tempSegment.description}
                                    sqlQuery={tempSegment?.reportPuck?.sqlQuery ?? ""}
                                    reader={reader} 
                                    setReader={setReader} 
                                    readerDate={readerDate}
                                    setReaderDate={setReaderDate}
                                    />
                            </Box>
                            <Box>
                                <ReportVariants reportSegment={tempSegment} />
                            </Box>
                    </Stack>
                </Box>
            </Stack>
        </>;
}