import { Badge, Box, IconButton, Stack, Tooltip } from "components";

import FilterAltIcon from "@mui/icons-material/FilterAlt";
import TableRowsIcon from "@mui/icons-material/TableRows";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import { ReactNode, useContext } from "react";
import { getSpacing } from "theme";
import { ReportContext } from "../../ReportContextProvider";
import { getArrayFromTableTree } from "../../utils/table-tree-utils";
import { NavMenuItemType } from "../NavigationBar";
import { Settings } from "./Settings";

export const MenuPanel = (props: {
  width: number;
  navMenuItemType: NavMenuItemType;
  setNavMenuItemType: (type: NavMenuItemType) => void;
  setNavOpen: (open: boolean) => void;
}) => {
  const reportContext = useContext(ReportContext);
  const explorerCount = getArrayFromTableTree(reportContext.queryRequest.tableTree).length;
  const filtersCount = reportContext.queryRequest?.filters?.length;
  const groupByFields = reportContext.queryRequest.fields?.some((x) => x.transform === "AggregateFunction")
    ? reportContext.queryRequest.fields?.filter((field) => field.transform !== "AggregateFunction")
    : [];
  const groupByCount = groupByFields.length;

  const menuItem = (itemProps: { type: NavMenuItemType; icon: ReactNode; count?: number; tooltip?: ReactNode }) => {
    return (
      <Box>
        <Tooltip title={itemProps.tooltip} placement="right-end">
          <IconButton
            onClick={() => {
              props.setNavMenuItemType(itemProps.type);
              props.setNavOpen(true);
            }}
            color={props.navMenuItemType === itemProps.type ? "success" : "default"}
          >
            <Badge
              badgeContent={itemProps.count}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              color="success"
              max={9}
              sx={{
                "& .MuiBadge-badge": {
                  right: 2,
                  top: 10,
                  border: (theme) => `2px solid ${theme.palette.background.paper}`,
                  padding: "0 4px",
                },
              }}
            >
              {itemProps.icon}
            </Badge>
          </IconButton>
        </Tooltip>
      </Box>
    );
  };

  return (
    <Stack
      borderRight={(theme) => `1px solid ${theme.palette.border.main}`}
      width={props.width}
      alignItems="center"
      justifyContent={"space-between"}
      pt={getSpacing("sm")}
      pb={getSpacing("md")}
    >
      <Stack spacing="xs" alignItems={"center"}>
        {menuItem({
          type: "Explorer",
          icon: <TableRowsIcon fontSize="large" />,
          count: explorerCount,
          tooltip: "Tables Explorer",
        })}

        {menuItem({
          type: "Filters",
          icon: <FilterAltIcon fontSize="large" />,
          count: filtersCount,
          tooltip: "Filters",
        })}

        {menuItem({
          type: "Group-bys",
          icon: <WorkspacesIcon fontSize="large" />,
          count: groupByCount,
          tooltip: "Group-by Fields",
        })}
      </Stack>

      <Stack>
        <Settings />
      </Stack>
    </Stack>
  );
};
