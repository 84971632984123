import { Box } from "@mui/material";

export const Avatar = (props: { string: string | undefined; size?: "small" | "medium" | "large" }) => {
  function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  const getSize = () => {
    if (props.size === "small") {
      return "30px";
    }

    if (props.size === "medium" || props.size === undefined) {
      return "40px";
    }

    if (props.size === "large") {
      return "55px";
    }

    return "100%";
  };

  const _size = getSize();

  const split = props.string ? props.string.split(" ") : "";
  const char1 = split?.[0]?.[0]?.toUpperCase() || "";
  const char2 = split?.[1]?.[0]?.toUpperCase() || "";

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        bgcolor: stringToColor(props.string || ""),
        color: "white",
        height: _size,
        width: _size,
        fontSize: `${parseInt(_size) / 2}px`,
        borderRadius: "50%",
      }}
      children={`${char1}${char2}`}
    />
  );
};
