import { Button, ButtonProps } from "@mui/material";
import { ReactNode } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";

type SubmitButtonProps = Omit<ButtonProps, "children"> & {
  label?: ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
};

export const SubmitButton = ({ label = "Save", onClick, ...rest }: SubmitButtonProps) => {
  const appLoading = useSelector((state: RootState) => state.app.loading);
  const disabledOnLoading = appLoading > 0; // button should be disabled when loading

  const handleClick = (e: any) => {
    if (onClick) {
      e.preventDefault();
      onClick(e);
    }
  };

  return (
    <Button
      variant="contained"
      type="submit"
      onClick={handleClick}
      disabled={disabledOnLoading || rest.disabled}
      {...rest}
    >
      {label}
    </Button>
  );
};
