import { Box,  Link, List,  ListItemButton,  Typography } from "@mui/material";
import Stack from "@mui/material/Stack";

import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { v4 } from "uuid";
import { UploaderControl } from "./UploaderControl/UploaderControl";
import { getUIDateFormat, queryDateFormatter } from "utils";
import { useHistoricalData } from "./hooks/useHistoricalData";
import { useTableData } from "./hooks/useTableData";
import { useDbtTableColumns } from "./hooks/useDbtTables";

import { eq } from "lodash";

export function UploaderTemplate({humanReadableName, dbName, tableName, columns} : {humanReadableName: string;dbName: string; tableName: string; columns: string[];}) {
    const [tableColumns, isTableColumnsLoading] = useDbtTableColumns(dbName, tableName);
    const [effectiveDates, setEffectiveDates] = useState<{startDate:string,endDate:string|undefined}|undefined>();
    const [tableRows, isTableDataLoading] = useTableData(dbName, tableName, columns, effectiveDates);
    const [historicDataDates, _isLoadingHistoricalData, reloadHistoricalDates] = useHistoricalData(dbName, tableName);

    // load up the table rows
    useEffect(() => {
        // use the 'current' date range
        if(historicDataDates.length === 0){
            return;
        }
        const current = historicDataDates[0];
        if(!current || effectiveDates !== undefined){
            return;
        }
        setEffectiveDates({startDate:queryDateFormatter(current.startDate), endDate:(current.endDate ? queryDateFormatter(current.endDate): undefined)});

    }, [historicDataDates, effectiveDates, dbName, tableName, columns]);

    function onUploadComplete() {
        // reload the historical data
        setEffectiveDates(undefined);
        reloadHistoricalDates(false);
    }

    return <Stack direction="column" spacing={2}>
        <Box mt={4}>
            <Typography variant="h4">{humanReadableName} Information</Typography>
        </Box>
        <Box>
        </Box>
            <UploaderControl humanReadableName={humanReadableName} dbName={dbName} tableName={tableName} onUploadComplete={onUploadComplete} />
        <Box>
            <Typography variant="h6">{humanReadableName} Upload {
                effectiveDates ? 
                    `(${getUIDateFormat(new Date(effectiveDates.startDate))}-${effectiveDates.endDate ? getUIDateFormat(new Date(effectiveDates.endDate)) : "Present"})`:
                    ""
            }</Typography>
        </Box>
        <Box>
            <Stack direction="row" spacing={2}>
                <Box width="66%">
                    <DataGrid 
                        columns={tableColumns}
                        rows={tableRows}
                        getRowId={(row) => {return v4()}}
                        loading={isTableColumnsLoading || isTableDataLoading}
                        density="compact"
                    />
                </Box>
                <Box width="33%">
                    <Typography variant="h6">{humanReadableName} Data History</Typography>
                    <p>
                        The following date ranges are available for viewing.  
                        Click on the date range to view the data.
                    </p>
                    <List>
                        {historicDataDates.map((dates, index) => {
                            return <ListItemButton selected={eq(dates, effectiveDates)} key={`historical-list-item-${index}`}>
                                        <Link href="#" onClick={(e)=>{
                                            e.preventDefault();
                                            setEffectiveDates({startDate:queryDateFormatter(dates.startDate), endDate:(dates.endDate ? queryDateFormatter(dates.endDate): undefined)});
                                        }}>{
                                            dates.endDate ?
                                                <>{getUIDateFormat(dates.startDate)}-{getUIDateFormat(dates.endDate)}</> : 
                                                <>{getUIDateFormat(dates.startDate)}-Present</>
                                            }
                                        </Link>
                                    </ListItemButton>;
                        })
                        }
                    </List>
                </Box>
             </Stack>
        </Box>
    </Stack>
}