import { TerminationReport } from "api/Api";
import {MenuItem, Stack} from "@mui/material"
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from "react";
import apiService from "services/apiService";
import { TurnoverReportData } from "./TurnoverReport/TurnoverReportData";

import "./TurnoverDashboardPage.css";
import moment from "moment";

export const terminationDashboardPageRoute = 
    {
        path: 'turnover',
        label: "Turnover Dashboard",
        handle: 'turnover',
        templatePath: '/turnover',
        element: <TurnoverDashboardPage />,
    }
;

const defaultStartDate = new Date();
// five years
defaultStartDate.setMonth(defaultStartDate.getMonth() - 60);

const startDate = moment('2019-01-01').toDate();
const endDate = new Date();

export function TurnoverDashboardPage(props: {byLocation?: boolean, byDepartment?: boolean, byTitle?: boolean}) {
    const [was_terminated, setWasTerminated] = useState<string>("All");

    const [terminationData, setTerminationData] = useState<TerminationReport | undefined>(undefined);

    useEffect(() => {
        // request data from the API
        apiService.getApi()
      .report.reportControllerTerminationReport({
        wasTerminated: was_terminated,
      })
      .then(response => {
            setTerminationData(response.data);
      })
      .catch(error => {
            console.error(error);
        });
    }, [was_terminated]);

    return (
        <div className="termination-dashboard">
            <Stack direction="column">
                <Box>
                    <h1>Turnover Report Dashboard</h1>
                </Box>
                <Box>
                    <Stack direction="row" spacing={2}>
                        <Box sx={{
                            '& .MuiTextField-root': { m: 1, width: '20ch' },
                         }}>
                            <TextField 
                                select
                                label="Terminations"
                                value={was_terminated}
                                onChange={(e) => setWasTerminated(e.target.value)}
                                >
                                <MenuItem value="Yes">Involuntary</MenuItem>
                                <MenuItem value="No">Voluntary</MenuItem>
                                <MenuItem value="All">All</MenuItem>
                            </TextField>
                            
                        </Box>
                        
                    </Stack>
                </Box>
                <Box>
                    <TurnoverReportData terminationData={terminationData} filters={{
                        startDate: startDate,
                        endDate: endDate,
                        was_terminated: was_terminated === "Yes",
                    }} />
                </Box>
            </Stack>
        </div>
    );
}