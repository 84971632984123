import { Filter as FilterModel } from "api/Api";
import { Box, Button, Stack } from "components";
import produce from "immer";
import { ReportContext } from "pages/ReportPage/ReportContextProvider";
import { getArrayFromTableTree } from "pages/ReportPage/utils/table-tree-utils";
import { useContext } from "react";
import { getSpacing } from "theme";
import { Filter } from "./Filter";

export const Filters = () => {
  const reportContext = useContext(ReportContext);
  const queryRequest = reportContext.queryRequest;
  const tableTreeArray = getArrayFromTableTree(reportContext?.queryRequest?.tableTree);

  return (
    <Stack>
      <Stack>
        {queryRequest.filters?.map((filter, index) => {
          return (
            <Box borderBottom={(theme) => `1px solid ${theme.palette.border.main}`}>
              <Filter
                key={index}
                filter={filter}
                setFilter={(filter) =>
                  reportContext.setQueryRequest(
                    produce(queryRequest, (draft) => {
                      draft.filters![index] = filter;
                    })
                  )
                }
                removeFilter={() =>
                  reportContext.setQueryRequest(
                    produce(queryRequest, (draft) => {
                      draft.filters!.splice(index, 1);
                    })
                  )
                }
              />
            </Box>
          );
        })}
      </Stack>

      <Stack direction="row" p={getSpacing("sm")}>
        <Button
          //variant="outlined"
          fullWidth
          onClick={() => {
            const filters = produce(queryRequest, (draft) => {
              draft.filters!.push({
                active: true,
                field: {
                  dbName: tableTreeArray.length === 1 ? tableTreeArray[0].dbName : undefined,
                  // prepopulate table if there is only one table in the table tree
                  tableName: tableTreeArray.length === 1 ? tableTreeArray[0].name : undefined,
                },
                operator: "is",
              } as FilterModel);
            });
            reportContext.setQueryRequest(filters);
          }}
          size="small"
        >
          + Custom Filter
        </Button>
      </Stack>
    </Stack>
  );
};
