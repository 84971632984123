import styled from "@emotion/styled";
import { Box, Collapse, Stack } from "components";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { DetailsPanel } from "./DetailsPanel";
import { MenuPanel } from "./MenuPanel/MenuPanel";
import apiService, { SHOW_LOADING_HEADER } from "services/apiService";

export type NavMenuItemType = "Explorer" | "Filters" | "Group-bys";

export const NavigationBar = (props: { open: boolean; setOpen: (open: boolean) => void }) => {
  const [navMenuItemType, setNavMenuItemType] = useState<NavMenuItemType>("Explorer");
  const navPinned = useSelector((state: RootState) => state.userSetting.navPinned);
  const open = navPinned ? true : props.open;

  const userNavWidth = useSelector((state: RootState) => state.userSetting.navWidth);
  const [currentWidth, setCurrentWidth] = useState<number>(300); // Default width
  const currentWidthRef = useRef<number>(currentWidth); // Ref to store the latest currentWidth
  const MENU_WIDTH = 50;
  const MIN_WIDTH = 250;
  const MAX_WIDTH = 500;
  const resizerRef = useRef<HTMLDivElement>(null);

  // Initialize with navWidth if available
  useEffect(() => {
    if (userNavWidth && userNavWidth > 0) {
      setCurrentWidth(userNavWidth);
      currentWidthRef.current = userNavWidth;
    }
  }, [userNavWidth]);

  const Resizer = styled("div")({
    cursor: "ew-resize",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    width: "5px",
    zIndex: 1, // To ensure it sits above the content
    "&:hover": {
      background: "rgba(0, 0, 0, 0.1)",
    },
  });

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    document.body.style.userSelect = "none"; // Disable text selection
  };

  const handleMouseMove = (e: MouseEvent) => {
    const parentLeft = resizerRef.current?.parentElement?.getBoundingClientRect().left || 0;
    const newWidth = e.clientX - parentLeft;
    if (newWidth >= MIN_WIDTH && newWidth <= MAX_WIDTH) {
      setCurrentWidth(newWidth);
      currentWidthRef.current = newWidth;
    } else if (newWidth < MIN_WIDTH) {
      setCurrentWidth(MIN_WIDTH);
      currentWidthRef.current = MIN_WIDTH;
    } else if (newWidth > MAX_WIDTH) {
      setCurrentWidth(MAX_WIDTH);
      currentWidthRef.current = MAX_WIDTH;
    }
  };

  const handleMouseUp = () => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
    document.body.style.userSelect = ""; // Enable text selection

    // Save the new width to the backend
    apiService.getApi().users.usersControllerSaveNavWidth(
      {
        value: currentWidthRef.current,
      },
      {
        headers: {
          [SHOW_LOADING_HEADER]: "false",
        },
      }
    );
  };

  return (
    <Stack direction="row" height="100%">
      <Resizer ref={resizerRef} onMouseDown={handleMouseDown} />

      <MenuPanel
        width={MENU_WIDTH}
        navMenuItemType={navMenuItemType}
        setNavMenuItemType={setNavMenuItemType}
        setNavOpen={props.setOpen}
      />

      <Collapse in={open} orientation="horizontal" sx={{ height: "100%" }}>
        <Box width={currentWidth} sx={{ height: "100%" }}>
          <DetailsPanel navMenuItemType={navMenuItemType} setNavMenuItemType={setNavMenuItemType} />
        </Box>
      </Collapse>
    </Stack>
  );
};
