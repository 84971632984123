import { Box, Button, PageHeader, Stack } from "components";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { DataGrid } from "pages/ReportPage/DataGrid/DataGrid";
import { StatChart } from "../StatChart/StatChart";
import { useContext, useEffect, useState } from "react";
import { ReportContext } from "pages/ReportPage/ReportContextProvider";

export enum CHART_LAYOUTS {
  "show",
  "hide",
  "fullscreen",
}

const fontSize: string = "1.3rem";

export function StatResults(props: {
  selectedTable: string | undefined;
  selectedTableDisplayName: string | undefined;
  selectedTableFields;
  chartLayout;
  setChartLayout;
}) {
  const reportContext = useContext(ReportContext);
  const { chartLayout, selectedTableDisplayName, selectedTable } = props;
  const [showLastUpdated, setShowLastUpdated] = useState<boolean>(false);
  const [chartHeight, setChartHeight] = useState<string>("33vh");

  useEffect(() => {
    switch (chartLayout) {
      case CHART_LAYOUTS.hide:
        setChartHeight("0vh");
        break;
      case CHART_LAYOUTS.show:
        setChartHeight("33vh");
        break;
      case CHART_LAYOUTS.fullscreen:
        setChartHeight("100vh");
        break;
    }
  }, [chartLayout]);

  return (
    <>
      <PageHeader
        title={selectedTableDisplayName ?? "Statistics"}
        rightHeader={
          <Box>
            {props.chartLayout === CHART_LAYOUTS.show && (
              <Button
                onClick={() => {
                  props.setChartLayout(CHART_LAYOUTS.hide);
                }}
              >
                Hide Chart
              </Button>
            )}
            {props.chartLayout === CHART_LAYOUTS.hide && (
              <Button
                onClick={() => {
                  props.setChartLayout(CHART_LAYOUTS.show);
                }}
              >
                Show Chart
              </Button>
            )}
            {props.chartLayout === CHART_LAYOUTS.show && (
              <Button
                onClick={() => {
                  props.setChartLayout(CHART_LAYOUTS.fullscreen);
                }}
              >
                Full Screen Chart
              </Button>
            )}
            {props.chartLayout === CHART_LAYOUTS.fullscreen && (
              <Button
                onClick={() => {
                  props.setChartLayout(CHART_LAYOUTS.show);
                }}
              >
                Back
              </Button>
            )}
          </Box>
        }
      />
      <Stack direction="column">
        <Box height={chartHeight} width="100%" margin="0 0 2em 0">
          <StatChart chartLayout={props.chartLayout} selectedTable={selectedTable} />
        </Box>
      </Stack>

      <Box height={props.chartLayout === CHART_LAYOUTS.fullscreen ? "0vh" : "66vh"} flexGrow={1} overflow="auto">
        {/* 
        
        dataFields={selectedTableFields}
        page={queryRequest.page}
        setPage={(page) => {
          const _queryRequest = produce(queryRequest, (draft) => {
            draft.page = page;
          });

          setQueryRequest(_queryRequest);
          getGridData(_queryRequest);
        }}
        pageSize={queryRequest.pageSize}
        sortingField={queryRequest.sortField}
        sortingDesc={queryRequest.sortDesc}
        setSorting={(sortingField, sortingDesc) => {
          const _queryRequest = produce(queryRequest, (draft) => {
            draft.sortField = sortingField;
            draft.sortDesc = sortingDesc;
          });

          setQueryRequest(_queryRequest);
          getGridData(_queryRequest);
        }}
        generatedReport={generatedReport}
        totalRecordsCount={totalRecordsCount}
        countLoading={countLoading}
        
        */}
        <DataGrid
          queryRequest={reportContext.queryRequest}
          setQueryRequest={(queryRequest) => reportContext.setQueryRequest(queryRequest)}
        />
      </Box>
      <Box position="relative" flexGrow={0} flexShrink={0} textAlign="right" style={{ margin: "0.25em" }}>
        <div
          onClick={() => {
            setShowLastUpdated(!showLastUpdated);
          }}
        >
          <CalendarTodayIcon sx={{ fontSize: fontSize }} /> Last Updated
        </div>
      </Box>
    </>
  );
}
