import InvalidIcon from "@mui/icons-material/Block";
import { Tooltip } from "@mui/material";
import { Filter as FilterModel } from "api/Api";
import { IconButton, Stack, Typography } from "components";
import { ReportContext } from "pages/ReportPage/ReportContextProvider";
import { ReactNode, useContext } from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

export const FilterStatus = (props: { filter: FilterModel; setActive: (value: boolean) => void }) => {
  const reportContext = useContext(ReportContext);
  const isValid = reportContext.validateFilter(props.filter, "boolean");

  if (isValid) {
    return (
      <Tooltip title={"Click to make this filter active/inactive."} placement="right-end">
        <IconButton onClick={() => props.setActive(!props.filter.active)} sx={{ p: 0 }}>
          {props.filter.active ? (
            <CheckBoxIcon
              fontSize="small"
              // sx={{
              //   p: 0,
              //   borderLeft: (theme) => `1px solid ${theme.palette.border.main}`,
              //   borderBottom: (theme) => `1px solid ${theme.palette.border.main}`,
              // }}
              color="primary"
            />
          ) : (
            <CheckBoxOutlineBlankIcon fontSize="small" />
          )}
        </IconButton>

        {/* <Checkbox
          checked={props.filter.active}
          onChange={(e) => props.setActive(e.target.checked)}
          size="small"
          sx={{
            p: 1,
            // borderLeft: (theme) => `1px solid ${theme.palette.border.main}`,
            // borderBottom: (theme) => `1px solid ${theme.palette.border.main}`,
          }}
        /> */}
      </Tooltip>
    );
  } else {
    const errorMessages = reportContext.validateFilter(props.filter, "errorMessages");
    const errorNode: ReactNode = (
      <Stack spacing="sm">
        <Typography variant="inherit">Invalid Filter: this filter will not be applied</Typography>

        {errorMessages?.length > 0 && (
          <Stack>
            {errorMessages.map((x) => (
              <Typography variant="inherit">- {x}</Typography>
            ))}
          </Stack>
        )}
      </Stack>
    );

    return (
      <Tooltip title={errorNode}>
        <IconButton sx={{ p: 0 }}>
          <InvalidIcon color="error" fontSize="small" />
        </IconButton>
      </Tooltip>
    );
  }
};
