import { Box, Dialog, DialogContent, DialogContentText, DialogTitle, Stack, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Input } from "components";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

export function SqlQueryEditor({sqlQuery, onQueryChange}:{sqlQuery: string, onQueryChange:Function}) {
    const [isChangefulQuery, setIsChangefulQuery] = useState(false);
    const [openHelp, setOpenHelp] = useState(false);
    const descriptionElementRef = useRef<HTMLElement>(null);

    useEffect(() => {
        if(!sqlQuery) {return;}
        
        setIsChangefulQuery(checkIsChangefulQuery(sqlQuery));
    }, [sqlQuery]);


    function checkIsChangefulQuery(query: string = ''): boolean {
        // Trim whitespace from the query and convert to uppercase for case-insensitive comparison
        const trimmedQuery = query?.trim()?.toUpperCase() ?? '';
        
        return /INSERT\s|DELETE\s|UPDATE\s/.test(trimmedQuery);
    }

    return <Stack spacing={2}>
        <Box>
            <Typography variant="h6">
                SQL Query
                {" "}
                <span title="Click to read about how to use this editor"
                style={{
                    cursor: "pointer"
                }}
                onClick={()=>{
                    setOpenHelp(true);
                }}>
                    <HelpOutlineIcon  />
                </span>
            </Typography>
        </Box>
        <Box>
            <Input 
                style={{fontFamily: 'monospace'}}
                label="Report Sql Query"
                value={sqlQuery}
                onChange={(e) => {
                    onQueryChange(e.target.value);
                }}
                multiline
                fullWidth
                rows={10}
                />
                {
                    isChangefulQuery && 
                    <Box>
                        <p style={{color: 'red'}}>This query contains a modification statement (INSERT, DELETE, UPDATE) and will not be executed</p>
                    </Box>
                }
        </Box>
        <Dialog
        open={openHelp}
        onClose={() => {
            setOpenHelp(false);
        }}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
            Sql Query Help
            <span style={{float:"right", cursor: "pointer"}} onClick={()=>{
                setOpenHelp(false);
            }}>x</span>
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Box>
                <Typography variant="h6">Variables</Typography>
                <p>
                    You can use the following variables in your query:
                </p>
                <ul>
                    <li>:facility-id: - The facility ID of the user/reader</li>
                    <li>:user-id: - the user ID of the user/reader</li>
                    <li>:report-date: - the report date as provided in the URL or the current date</li>
                </ul>
                <Typography variant="h6">Example</Typography>
                <Typography variant="body1" style={{fontFamily: "monospace"}}>
                    SELECT * FROM table WHERE facility_id = :facility-id: AND user_id = :user-id: AND date {">"}= :start-date:
                </Typography>
            </Box>
            <Box>
                <Typography variant="h6">Bar Chart Required Results</Typography>
                <p>
                    Bar Charts must return a dataset with the following columns:
                </p>
<pre>
    {"{"}<br />
        "label": "the label for the bar, typically a date",<br />
        "value": "a numerical value", <br/>
        "trend": "a numerical value for the trend",<br/>
        "trend_is_good": "1 for a good trend, -1 for a bad trend"<br/>
    {"}"}
</pre>
                
            </Box>
            <Box>
                <Typography variant="h6">Data Chart String Replacement</Typography>
                <p>
                    Because of the requirements, but the limitations of SQL, we're using a string replacement strategy.
                    For dates within the six week range the following column names will be replaced in the output:
                </p>
                <ol>
                    <li>currentweek ={'>'} 'This Week'</li>
                    <li>lastweek ={'>'} 'Last Week'</li>
                    <li>twoweeksago ={'>'} 'MM/DD/YY' formatted date for two weeks prior to the report date</li>
                    <li>threeweeksago ={'>'} 'MM/DD/YY' formatted date for three weeks prior to the report date</li>
                    <li>fourweeksago ={'>'} 'MM/DD/YY' formatted date for four weeks prior to the report date</li>
                    <li>fiveweeksago ={'>'} 'MM/DD/YY' formatted date for five weeks prior to the report date</li>
                    <li>sixweeksago ={'>'} 'MM/DD/YY' formatted date for six weeks prior to the report date</li>
                </ol>
            </Box>
          </DialogContentText>
        </DialogContent>
    </Dialog>
</Stack>
}