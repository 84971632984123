import { Menu, MenuItem, Stack, Typography } from "components";

import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { DbtDataField } from "api/Api";
import { AggregateFunction } from "api/Api-extension";
import { ReportContext } from "pages/ReportPage/ReportContextProvider";
import { useContext, useState } from "react";
import CheckIcon from "@mui/icons-material/Check";

export const AggregateMenuItem = (props: {
  closeParentMenu: () => void;
  dataField: DbtDataField;
  selectedAggregateFunctions: AggregateFunction[];
  toggleAggregateFunction: (aggregatedFunction: AggregateFunction) => void;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const reportContext = useContext(ReportContext);
  const aggregateFunctionOptions =
    reportContext.aggregateFunctionOptions?.filter((x) => x.dataTypes.includes(props.dataField.dataType)) || [];

  return (
    <>
      <MenuItem
        onClick={(e: React.MouseEvent<HTMLLIElement>) => {
          e.stopPropagation();
          setAnchorEl(e.currentTarget);
        }}
        disabled={aggregateFunctionOptions.length === 0}
      >
        <Stack width="100%" direction="row" spacing="lg" justifyContent={"space-between"} alignItems={"center"}>
          <Typography>Aggregate</Typography>

          <ArrowRightIcon />
        </Stack>
      </MenuItem>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        {aggregateFunctionOptions?.map((aggregateFunctionOption) => {
          const selected = props.selectedAggregateFunctions?.includes(aggregateFunctionOption.function);

          return (
            <MenuItem
              onClick={() => {
                props.toggleAggregateFunction(aggregateFunctionOption.function);
                props.closeParentMenu();
              }}
            >
              <Stack direction="row" spacing="sm" alignItems="center">
                <Typography>{aggregateFunctionOption.displayName}</Typography>
                {selected && <CheckIcon fontSize="small" color="success" />}
              </Stack>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
