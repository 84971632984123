import { Field } from "api/Api";
import { FilterOperator } from "api/Api-extension";
import { Select } from "components";
import { ReportContext } from "pages/ReportPage/ReportContextProvider";
import { useContext, useEffect } from "react";

export const FilterOperatorInput = (props: {
  selectedOperator: FilterOperator;
  setSelectedOperator: (value: FilterOperator) => void;
  selectedField: Field;
}) => {
  const reportContext = useContext(ReportContext);
  const fieldOutputDataType = reportContext.getFieldOutput(props.selectedField)?.dataType;
  const operatorOptions = reportContext?.filterOperatorOptions;

  // if selectedField type changes, and the selected operator is not applicable to the new field type, reset the operator
  useEffect(() => {
    if (
      fieldOutputDataType &&
      operatorOptions &&
      !operatorOptions
        .find((x) => x.operator === props.selectedOperator)
        ?.applicableFieldTypes.includes(fieldOutputDataType)
    ) {
      props.setSelectedOperator(
        operatorOptions.find((x) => x.applicableFieldTypes.includes(fieldOutputDataType))?.operator!
      );
    }
  }, [props.selectedField]);

  return (
    <Select
      placeholder="Operator"
      value={operatorOptions?.find((x) => x.operator === props.selectedOperator)}
      options={operatorOptions?.filter(
        (x) => fieldOutputDataType && x.applicableFieldTypes.includes(fieldOutputDataType)
      )}
      getOptionLabel={(option) => option.name!}
      onChange={(e, value) => props.setSelectedOperator(value.operator)}
      size="small"
      noOptionsText={!props.selectedField && "No selected field"}
      autoComplete="off"
      disableClearable
      inputVariant="standard"
    />
  );
};
