import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";

export function PublicLayout() {
  return (
    <Box>
      PublicLayout
      
      <Outlet />
    </Box>
  );
}
