export const ellipsisStyle: any = {
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
};

export const clickableStyle: any = {
  "&:hover": {
    cursor: "pointer",
  },
};