import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Box, Button, Stack, Typography } from "@mui/material";
import { ReportSegment } from "api/Api";
import { DeleteIconButton } from "components";
import { Select } from "components/mui/Select";
import { usePotentialReportSegments } from "hooks/email/usePotentialReportSegments";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

export function ReportSegmentPicker({segments, onSegmentPickerChange}: {
    segments: ReportSegment[];
    onSegmentPickerChange: Function;
}) {
    const [potentialReportSegments, potentialReportSegmentsLoading] = usePotentialReportSegments();
    const [tempSegments, setTempSegments] = useState<ReportSegment[]>([]);


    useEffect(() => {
        if(segments.length !== tempSegments.length){
            console.log(`setting segments: ${segments.length}`);
            setTempSegments(segments);
        }
    }, [segments]);

    const addNewReportSegment = () => {
        setTempSegments([...tempSegments, {
            id: 0,
            name: "",
        } as ReportSegment]);
    };

    useEffect(() => {
        const temp = tempSegments.filter(segment => typeof segment !== 'undefined' && JSON.stringify(segment) !== '{}');
        onSegmentPickerChange(temp);
    }, [tempSegments]);

    function onDragEnd(result: any) {
        if (!result.destination) {
            return;
        }
      
        const reorderedSegments = Array.from(segments);
        const [movedItem] = reorderedSegments.splice(result.source.index, 1);
        reorderedSegments.splice(result.destination.index, 0, movedItem);
        
        onSegmentPickerChange(reorderedSegments);
        setTempSegments(reorderedSegments);
    }

    // console.log(tempSegments);
    if(potentialReportSegmentsLoading){
        return <Box>Loading...</Box>;
    }

    return (<Stack>
        <Box>
            <h3>Report Segments</h3>
        </Box>
        <Box>
            <DragDropContext onDragEnd={onDragEnd} onDragStart={(e)=>{
                console.log('look and see that the drag did doth start')
            }}>
              <Droppable droppableId="segmentsDroppable">
                {(provided) => (
                    <Stack spacing={2} {...provided.droppableProps} ref={provided.innerRef}>
                        {tempSegments.map((segment, index) => {
                            return (
                                
                                <Draggable key={segment.id} draggableId={`${segment.id}`} index={index} {...provided.droppableProps}>
                                    {(provided) => (
                                    <Box key={`${segment.id}`} direction="row" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                        <ReportSegmentForm
                                            selectedReport={segment}
                                            selectedReports={segments} 
                                            potentialReportSegments={potentialReportSegments} 
                                            onChange={(e) => {
                                                if(e === undefined){
                                                    tempSegments.splice(index, 1);
                                                    setTempSegments([...tempSegments]);
                                                } else {
                                                    tempSegments[index] = e;
                                                    setTempSegments([...tempSegments]);
                                                }
                                            }} 
                                            />
                                    </Box>)
                                    }
                                </Draggable>
                            );
                        })}
                        {provided.placeholder}
                    </Stack>
                )}
            </Droppable>
            </DragDropContext>
        </Box>
        <Box>
            <Button onClick={()=> {
                addNewReportSegment();
            }} fullWidth>
                Add Segment
            </Button>
        </Box>
    </Stack>);
}

function ReportSegmentForm({selectedReport, selectedReports, potentialReportSegments, onChange}: {
    selectedReport?: ReportSegment,
    selectedReports: ReportSegment[],
    potentialReportSegments: ReportSegment[] | undefined,
    onChange: Function,
}){
    const [tempSelectedReport, setTempSelectedReport] = useState(selectedReport);
    useEffect(() => {
        onChange(tempSelectedReport);
    }, [tempSelectedReport])
    if(potentialReportSegments === undefined){
        return <>loading report segments</>
    }
    return <Box>
                <Stack direction="row">
                    <Box style={{width:"calc(100% - 68px)"}}>
                        <Select 
                            width="100%"
                            value={tempSelectedReport} 
                            onChange={(e, value) => {
                                if(value === null ){
                                    if(window.confirm(`Delete this report panel?`)){
                                        setTempSelectedReport(undefined);
                                    }
                                } else if (value !== null) {
                                    setTempSelectedReport(value);
                                }
                            }}
                            options={potentialReportSegments}
                            getOptionLabel={(segment: ReportSegment) => {
                                return segment.name;
                            }} />
                    </Box>
                    <Box alignContent={"center"} width="34px">
                        <DeleteIconButton onClick={()=>{
                            setTempSelectedReport(undefined);
                        }} />
                    </Box>
                    <Box alignContent={"center"} width="34px">
                        <DragIndicatorIcon />
                    </Box>
                </Stack>
                {/* {tempSelectedReport && <Box>
                    <SelectedReportHeaders selectedReport={tempSelectedReport} />
                </Box>} */}
        </Box>
}

export function SelectedReportHeaders({selectedReport}: {selectedReport: any}){
    return  <>{selectedReport?.description && selectedReport?.description?.length > 0 && 
                    <Box><Typography variant="caption">{selectedReport.description}</Typography></Box>}</>
}