import { Box, FormControl } from "@mui/material";
import { useFacilities } from "hooks/useFacilities";
import { Select } from "components/mui/Select";
import { useEffect, useState } from "react";
import { Facility } from "api/Api";

export function FacilityPicker({facilityId, onChange}:{facilityId?: number, onChange: (facility_id: number | undefined) => void}) {
    const [facilities, facilitiesLoading] = useFacilities();
    const [selectedFacility, setSelectedFacility] = useState<Facility|undefined>(undefined);
    useEffect(() => {
        if(facilitiesLoading){ 
            console.log('facilities still loading');
            return; 
        }
        
        setSelectedFacility(facilities.find((facility) => parseInt(`${facility.id}`) === parseInt(`${facilityId}`)));
    }, [facilityId, facilities, facilitiesLoading]);
    if(facilitiesLoading) return <Box>Loading...</Box>
    return <Box>
        <FormControl fullWidth>
            <Select 
                label="Facility"
                value={selectedFacility} 
                options={facilities}
                onChange={(e, facility) => onChange(facility.id)}
                getOptionLabel={(facility) => facility.name}
            />
        </FormControl>
    </Box>
}