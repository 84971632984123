import { Box, Divider, Menu, MenuItem, Stack, Typography } from "components";

import { DbtTable, Field } from "api/Api";
import { getFieldFullIdentifier } from "pages/ReportPage/utils/utils";
import { getSpacing } from "theme";

export const TableMenu = (props: {
  menuAnchorEl: HTMLElement | null;
  setMenuAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  dbtTable: DbtTable;
  selectedFields: Field[];
  setSelectedFields: (fields: Field[]) => void;
  openFilters: () => void;
  removeTable?: () => void;
}) => {
  const renderSelectAllMenuItem = (
    <MenuItem
      onClick={(e: React.MouseEvent<HTMLLIElement>) => {
        e.stopPropagation();
        props.setMenuAnchorEl(null);

        const fields = new Map<string, Field>(
          props.selectedFields.map((field) => [getFieldFullIdentifier(field), field])
        );

        props.dbtTable.dataFields
          ?.map((dataField) => {
            return { dbName: dataField.dbName, tableName: dataField.tableName, fieldName: dataField.fieldName };
          })
          .forEach((field) => {
            fields.set(getFieldFullIdentifier(field), field);
          });

        props.setSelectedFields([...fields.values()]);
      }}
      disabled={props.selectedFields.length === props.dbtTable.dataFields?.length}
    >
      <Stack direction="row" spacing="xs">
        <Box>+ Select all</Box>
        {props.dbtTable.dataFields && (
          <Typography variant="inherit" fontWeight="medium" component="span">
            {props.dbtTable.dataFields?.length}
          </Typography>
        )}
        <Box>fields</Box>
      </Stack>
    </MenuItem>
  );

  const renderClearAllMenuItem = (
    <MenuItem
      onClick={(e: React.MouseEvent<HTMLLIElement>) => {
        e.stopPropagation();
        props.setMenuAnchorEl(null);

        props.setSelectedFields([]);
      }}
      disabled={props.selectedFields.length === 0}
    >
      - Clear all fields
    </MenuItem>
  );

  const renderRemoveTableMenuItem = (
    <MenuItem
      onClick={(e: React.MouseEvent<HTMLLIElement>) => {
        e.stopPropagation();
        props.setMenuAnchorEl(null);

        props.removeTable?.();
      }}
    >
      - Remove selection
    </MenuItem>
  );

  return (
    <Menu anchorEl={props.menuAnchorEl} open={Boolean(props.menuAnchorEl)} onClose={() => props.setMenuAnchorEl(null)}>
      {renderSelectAllMenuItem}
      {renderClearAllMenuItem}
      {props.removeTable && renderRemoveTableMenuItem}

      <Divider />

      <Box px={getSpacing("sm")}>
        <Stack direction="row" spacing="xs">
          <Typography variant="caption">DB Name:</Typography>
          <Typography variant="caption" fontWeight={"medium"}>
            {props.dbtTable.dbName}
          </Typography>
        </Stack>

        <Stack direction="row" spacing="xs">
          <Typography variant="caption">Table Name:</Typography>
          <Typography variant="caption" fontWeight={"medium"}>
            {props.dbtTable.name}
          </Typography>
        </Stack>

        <Stack direction="row" spacing="xs">
          <Typography variant="caption">Fields:</Typography>
          <Typography variant="caption" fontWeight={"medium"}>
            {props.dbtTable.dataFields?.length}
          </Typography>
        </Stack>
      </Box>
    </Menu>
  );
};
