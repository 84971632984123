import { TestReportQuery, TestReportResult } from "api/Api";
import { useEffect, useState } from "react";
import apiService from "services/apiService";

export function useSegmentQuery(reportContext: TestReportQuery): [TestReportResult | undefined, boolean] {
  const [reportResults, setReportResults] = useState<TestReportResult | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (loading || reportResults || !reportContext.query || reportContext.query === "") {
      return;
    }
    setLoading(true);
    // strip the newlines from the query
    const reportContextCopy = { ...reportContext, query: reportContext.query.replace(/\n/g, ``) };
    apiService
      .getApi()
      .admin.reportSegmentControllerTestReportQuery(reportContextCopy)
      .then((response) => {
        setReportResults(response.data as TestReportResult);
        setLoading(false);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [reportContext]);
  return [reportResults, loading];
}
