import { ElementType, lazy, Suspense } from "react";

const Loadable = (Component: ElementType) => (props: any) => {
  return (
    <Suspense fallback={null}>
      <Component {...props} />
    </Suspense>
  );
};

export const lazyLoadingComponents = () => {
  const TestPage = Loadable(
    lazy(() =>
      import("pages/TestPage/TestPage").then((module) => ({
        default: module.TestPage,
      }))
    )
  );

  const LoginPage = Loadable(
    lazy(() =>
      import("pages/LoginPage/LoginPage").then((module) => ({
        default: module.LoginPage,
      }))
    )
  );

  const ReportPage = Loadable(
    lazy(() =>
      import("pages/ReportPage/ReportPage").then((module) => ({
        default: module.ReportPage,
      }))
    )
  );

  const PresetListPage = Loadable(
    lazy(() =>
      import("pages/presets/PresetListPage/PresetListPage").then((module) => ({
        default: module.PresetListPage,
      }))
    )
  );

  const CreatePresetPage = Loadable(
    lazy(() =>
      import("pages/presets/CreatePresetPage/CreatePresetPage").then((module) => ({
        default: module.CreatePresetPage,
      }))
    )
  );

  const UpdatePresetPage = Loadable(
    lazy(() =>
      import("pages/presets/UpdatePresetPage/UpdatePresetPage").then((module) => ({
        default: module.UpdatePresetPage,
      }))
    )
  );

  return {
    LoginPage,
    TestPage,
    ReportPage,
    PresetListPage,
    CreatePresetPage,
    UpdatePresetPage,
  };
};
