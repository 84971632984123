import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { Box, IconButton, Stack, Tab, TabContext, TabList, Tooltip } from "components";
import { useTheme } from "hooks";
import { useContext, useState } from "react";
import { getSpacing } from "theme";

import PushPinIcon from "@mui/icons-material/PushPin";
import { useDispatch, useSelector } from "react-redux";
import { setUserSettingNavPinned } from "redux/reducers/userSettingSlice";
import { RootState } from "redux/store";
import apiService from "services/apiService";
import { ReportContext } from "../../ReportContextProvider";
import { getArrayFromTableTree } from "../../utils/table-tree-utils";
import { NavigationMenu } from "./ExplorerMenu/NavigationMenu";
import { SelectTab } from "./SelectTab";
import { SelectedTab } from "./SelectedTab";

export const TableExplorer = (props: { open: boolean; setOpen: (open: boolean) => void }) => {
  const NAV_TABS = {
    TABLES: "Tables" as const,
    SELECTED: "Selected" as const,
  };

  type NavTab = (typeof NAV_TABS)[keyof typeof NAV_TABS];

  const dispatch = useDispatch();
  const reportContext = useContext(ReportContext);
  const navPinned = useSelector((state: RootState) => state.userSetting.navPinned);
  const theme = useTheme();
  const [currentTab, setCurrentTab] = useState<NavTab>(NAV_TABS.TABLES);
  const [useQueryBuilder, setUseQueryBuilder] = useState<boolean>(false); // TODO: move this later
  const open = navPinned ? true : props.open;

  return (
    <Stack
      //width={widthRef.current}
      height="100%"
      className="stack"
      borderRight={`1px solid ${theme.palette.border.main}`}
    >
      {/* <Resizer onMouseDown={handleMouseDown} /> */}

      {!open && (
        <Stack
          onClick={() => props.setOpen(true)}
          sx={{
            height: "100%",
            "&:hover": {
              cursor: "pointer",
              bgcolor: theme.palette.background.default,
            },
          }}
        >
          <Stack direction="row" justifyContent="flex-end" py={getSpacing("sm")}>
            <IconButton>
              <MenuIcon />
            </IconButton>
          </Stack>
        </Stack>
      )}

      {open && (
        <>
          {!useQueryBuilder ? (
            <TabContext value={currentTab}>
              <Stack direction="row" justifyContent="space-between" px={getSpacing("sm")} py={getSpacing("sm")}>
                <NavigationMenu useQueryBuilder={useQueryBuilder} setUseQueryBuilder={setUseQueryBuilder} />

                <Stack direction="row">
                  <Tooltip title="If pinned, this menu will stay in fixed in place when running report.">
                    <IconButton
                      color={navPinned ? "success" : "default"}
                      onClick={() => {
                        const setValue: boolean = !navPinned;
                        dispatch(setUserSettingNavPinned(setValue));
                        apiService.getApi().users.usersControllerSaveNavPinned({
                          value: setValue,
                        });
                      }}
                    >
                      <PushPinIcon />
                    </IconButton>
                  </Tooltip>

                  {!navPinned && (
                    <IconButton onClick={() => props.setOpen(false)}>
                      <MenuOpenIcon />
                    </IconButton>
                  )}
                </Stack>
              </Stack>

              <TabList
                sx={{ width: "100%", borderBottom: "1px solid #e8e8e8" }}
                onChange={(e, value: NavTab) => setCurrentTab(value)}
                variant="fullWidth"
              >
                <Tab
                  key={NAV_TABS.TABLES}
                  sx={{ width: "33%" }}
                  label={
                    <Stack direction="row" spacing="sm" alignItems="center">
                      {NAV_TABS.TABLES}
                    </Stack>
                  }
                  value={NAV_TABS.TABLES}
                />

                <Tab
                  key={NAV_TABS.SELECTED}
                  sx={{ width: "33%" }}
                  label={
                    <Stack direction="row" spacing="sm" alignItems="center">
                      {NAV_TABS.SELECTED}

                      {getArrayFromTableTree(reportContext.queryRequest.tableTree).length > 0 &&
                        `(${getArrayFromTableTree(reportContext.queryRequest.tableTree).length})`}
                    </Stack>
                  }
                  value={NAV_TABS.SELECTED}
                />
              </TabList>

              <Box hidden={currentTab !== "Tables"} position="relative" height="100%" overflow="auto">
                <SelectTab />
              </Box>

              <Box hidden={currentTab !== "Selected"} position="relative" height="100%" overflow="auto">
                <SelectedTab />
              </Box>
            </TabContext>
          ) : (
            <Box>Placeholder</Box>
            // <QueryBuilder queryRequest={reportContext.queryRequest} setQueryRequest={reportContext.setQueryRequest} />
          )}
        </>
      )}
    </Stack>
  );
};
