import { createSlice } from "@reduxjs/toolkit";
import { Toast } from "models";

interface AppState {
  // general
  toast?: Toast;
  loading: number;
  userId?: number;
  userEmail?: string;
}

const initialState: AppState = {
  loading: 0,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setAppToast: (state, action: { type: string; payload: typeof initialState.toast }) => {
      state.toast = action.payload;
    },
    setAppLoading: (state, action: { type: string; payload: typeof initialState.loading }) => {
      state.loading = action.payload;
    },
    setAppUserId: (state, action: { type: string; payload: typeof initialState.userId }) => {
      state.userId = action.payload;
    },
    setAppUserEmail: (state, action: { type: string; payload: typeof initialState.userEmail }) => {
      state.userEmail = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAppToast, setAppLoading, setAppUserId, setAppUserEmail } = appSlice.actions;

export default appSlice.reducer;
