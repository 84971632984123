import { Page, Box, Button } from "components";
import { useAuth } from "hooks";
import { PageRoute } from "models/PageRoute";
import { buildPath, buildTemplatePath } from "services/routeService";

const routeName = "login";

export const loginPageRoute: PageRoute = {
  handle: "Login",
  templatePath: buildTemplatePath(routeName),
  getPath: () => buildPath(routeName),
};

export const LoginPage = () => {
  const { handleLogin } = useAuth();

  return (
    <Page>
      <Box display="flex" height="100vh" alignItems="center" justifyContent="center">
        <Button variant="contained" onClick={handleLogin}>
          Login with SSO
        </Button>
      </Box>
    </Page>
  );
};
