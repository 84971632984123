import { createSlice } from "@reduxjs/toolkit";

interface AuthState {
  isAuthenticated: boolean;
  isLoading: boolean;
  roles: string[];
  lastAuthed: number;
}

const initialState: AuthState = {
  isAuthenticated: false,
  isLoading: true,
  roles: [],
  lastAuthed: 0,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthInitialState: (state, action: { type: string; payload: AuthState }) => {
      state.isAuthenticated = action.payload.isAuthenticated;
      state.isLoading = action.payload.isLoading;

      state.roles = action.payload.roles;
    },
    setAuthLoading: (state, action: { type: string; payload: typeof initialState.isLoading }) => {
      state.isLoading = action.payload;
    },
    setAuthIsAuthenticated: (state, action: { type: string; payload: typeof initialState.isAuthenticated }) => {
      state.isAuthenticated = action.payload;
      state.lastAuthed = Date.now();
    },
    setAuthRoles: (state, action: { type: string; payload: string[] }) => {
      state.roles = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAuthInitialState, setAuthLoading, setAuthIsAuthenticated, setAuthRoles } = authSlice.actions;

export default authSlice.reducer;
