import { Box, Input, Stack } from "components";

import { getSpacing } from "theme";
import { StatTable } from "./StatTable/StatTable";
import { DbtTable } from "api/Api";
import { useState } from "react";

export function StatTableList(props: {
  filteredDbtTables: DbtTable[];
  selectedTable: string | undefined;
  setSelectedTable: (table: string) => void;
}) {
  const { filteredDbtTables } = props;
  const [searchString, setSearchString] = useState<string>("");

  return (
    <Stack py={getSpacing("md")} direction="column" spacing="md" height="100%">
      <Box px={getSpacing("sm")}>
        <Input
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
          placeholder="Search"
          size="small"
          autoComplete="off"
        />
      </Box>

      <Stack
        px={getSpacing("sm")}
        spacing="sm"
        sx={{
          overflow: "hidden",
          "&:hover": {
            overflow: "auto",
          },
        }}
      >
        {filteredDbtTables
          ?.filter(
            (x) =>
              x.name.toLowerCase().includes(searchString.toLowerCase()) ||
              x.displayName?.toLowerCase().includes(searchString.toLowerCase()) ||
              x.dataFields?.some(
                (y) =>
                  y.fieldName.toLowerCase().includes(searchString.toLowerCase()) ||
                  y.displayName?.toLowerCase().includes(searchString.toLowerCase())
              )
          )
          .map((dbtTable) => {
            const selectedTable = props.selectedTable === dbtTable.name;
            return (
              <StatTable
                name={dbtTable.name}
                key={dbtTable.name}
                dbtTable={dbtTable}
                searchString={searchString}
                selectedTable={selectedTable}
                setSelectedTable={(selectedTable: string) => {
                  props.setSelectedTable(selectedTable);
                }}
              />
            );
          })}
      </Stack>
    </Stack>
  );
}
