import {
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  //NavigationBar,
  Stack,
} from "components";
import { useAuth } from "hooks";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
export const AppTopBarUserMenu = () => {
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState<null | HTMLElement>(null);
  const userEmail = useSelector((state: RootState) => state.app.userEmail);
  const { handleLogout } = useAuth();

  return (
    <Stack direction="row" px={2} height="50px" display="flex" alignItems="center">
      <Box ml="auto">
        <IconButton onClick={(e) => setUserMenuAnchorEl(e.currentTarget)}>
          <Avatar string={userEmail} size="small"></Avatar>
        </IconButton>

        <Menu anchorEl={userMenuAnchorEl} open={Boolean(userMenuAnchorEl)} onClose={() => setUserMenuAnchorEl(null)}>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </Box>
    </Stack>
  );
};
