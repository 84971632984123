import { DbtDataField, DbtTable } from "api/Api";
import { AggregateFunction, CustomFunction } from "api/Api-extension";
import { Divider, Menu, MenuItem } from "components";
import { ReportContext } from "pages/ReportPage/ReportContextProvider";
import { getArrayFromTableTree } from "pages/ReportPage/utils/table-tree-utils";
import { useContext, useState } from "react";
import { Pivot } from "../Pivot";
import { AggregateMenuItem } from "./AggregateMenuItem";
import { CustomFunctionsMenuItem } from "./CustomFunctionsMenuItem";
import { FieldInfoMenuItem } from "./FieldInfoMenuItem";

export const FieldMenu = (props: {
  menuAnchorEl: HTMLElement | null;
  setMenuAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  dataField: DbtDataField;
  selectedAggregateFunctions: AggregateFunction[];
  toggleAggregateFunction: (aggregatedFunction: AggregateFunction) => void;
  selectedCustomFunctions: CustomFunction[];
  toggleCustomFunction: (aggregatedFunction: CustomFunction) => void;
}) => {
  const reportContext = useContext(ReportContext);

  const [pivotOpen, setPivotOpen] = useState(false);
  const tableTreeArray = getArrayFromTableTree(reportContext?.queryRequest?.tableTree);
  const selectableTables = tableTreeArray.map((x) => reportContext.getTable(x.dbName,x.name) || ({} as DbtTable)) ?? [];
  const lookups = reportContext?.lookups || [];
  const pivotFieldOptions = selectableTables
    .flatMap((table) => table.dataFields)
    .filter(
      (field) =>
        field.dataType === "Date" ||
        lookups.some(
          (lookup) =>
            lookup.dbName.toLowerCase() === field.dbName.toLowerCase() &&
            lookup.table.toLowerCase() === field.tableName.toLowerCase() &&
            lookup.column.toLowerCase() === field.fieldName.toLowerCase()
        )
    );

  return (
    <>
      <Menu
        anchorEl={props.menuAnchorEl}
        open={Boolean(props.menuAnchorEl)}
        onClose={(e) => props.setMenuAnchorEl(null)}
      >
        <AggregateMenuItem
          closeParentMenu={() => props.setMenuAnchorEl(null)}
          dataField={props.dataField}
          selectedAggregateFunctions={props.selectedAggregateFunctions}
          toggleAggregateFunction={props.toggleAggregateFunction}
        />

        <CustomFunctionsMenuItem
          closeParentMenu={() => props.setMenuAnchorEl(null)}
          dataField={props.dataField}
          selectedCustomFunctions={props.selectedCustomFunctions}
          toggleCustomFunction={props.toggleCustomFunction}
        />

        {/* <PivotMenuItem closeParentMenu={() => props.setMenuAnchorEl(null)} dataField={props.dataField} /> */}
        <MenuItem
          onClick={() => {
            setPivotOpen(true);
            props.setMenuAnchorEl(null);
          }}
          disabled={pivotFieldOptions.length === 0}
        >
          Pivot
        </MenuItem> 

        <Divider sx={{ my: "0px !important" }} />

        <FieldInfoMenuItem closeParentMenu={() => props.setMenuAnchorEl(null)} dataField={props.dataField} />
      </Menu>

      {pivotOpen && (
        <Pivot dataField={props.dataField} pivotFieldOptions={pivotFieldOptions} onClose={() => setPivotOpen(false)} />
      )}
    </>
  );
};