import { Box } from "@mui/material";
import Stack from "@mui/material/Stack";
import { TerminationReport } from "api/Api";
import { TerminationReportByLocation } from "./TurnoverReportByLocation/TurnoverReportByLocation";
import { TerminationReportByDepartment } from "./TurnoverReportByDepartment/TurnoverReportByDepartment";
import { TerminationReportByTitle } from "./TurnoverReportByTitle/TurnoverReportByTitle";
import { TerminationFilters } from "../TurnoverReportData";

export function MonthlyTerminationReport({year, terminationData, filters} : {year: number, terminationData: TerminationReport, filters: TerminationFilters}) {
    return (<>
        <Stack direction="column" spacing={2}>
            <Box>
                <h1>{year}</h1>
                <TerminationReportByLocation terminationData={terminationData} year={year} filters={filters} />
                <TerminationReportByDepartment terminationData={terminationData} year={year} filters={filters} />
                <TerminationReportByTitle terminationData={terminationData} year={year} filters={filters} />
            </Box>
        </Stack>
    </>);
    }