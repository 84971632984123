import { ChipProps, Chip as MuiChip } from "@mui/material";

export const Chip = (
  props: Omit<ChipProps, "size"> & {
    size?: "xsmall" | "small" | "medium";
    rectangular?: boolean;
  }
) => {
  const { rectangular, size, ...rest } = props;

  return (
    <MuiChip
      {...rest}
      size={size !== "xsmall" ? size : undefined}
      sx={{
        height: size === "xsmall" ? "18px" : undefined,
        minWidth: size === "xsmall" ? "18px" : undefined,
        "& .MuiChip-label": {
          paddingLeft: size === "xsmall" ? "2px" : undefined,
          paddingRight: size === "xsmall" ? "2px" : undefined,
        },

        borderRadius: rectangular ? 0 : undefined,
      }}
    />
  );
};
