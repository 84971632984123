export const FE_SEARCH_DELAY_MS = 200;

var IS_LOCAL_MOCK_ON = false; // Toggle this to show local mock in app (no auth + fixture api responses)

// IS_LOCAL_MOCK_ON = true;
export const IS_LOCAL = process.env.NODE_ENV === "development";
export const USE_LOCAL_MOCK = IS_LOCAL_MOCK_ON && IS_LOCAL;

export const isLocalhostWeb = Boolean(
  window?.location?.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window?.location?.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window?.location?.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

// can be any of the following valid values, or a custom string such as rpeterman.
type APP_MODE = "local_web" | "local_pwa" | "prod" | "dev" | string;

export function getAppMode(): APP_MODE {
  if (window?.location?.hostname?.includes("192.168")) {
    return "local_pwa";
  }

  console.log("REACT_APP_MODE", process.env.REACT_APP_MODE);

  return process.env.REACT_APP_MODE as APP_MODE;
}

export function getAppVersion() {
  return process.env.REACT_APP_VERSION;
}

const biReportDomainRegex = /(http(s*):\/\/bi-reports.*\.ensomata.com)/;

export function getRedirectLink() {
  switch (getAppMode()) {
    case "local_web":
      return "http://localhost:3001";
    case "local_pwa":
      return process.env.REACT_APP_LOCAL_IP;
    case "dev":
    case "prod":
    default:
      const customRedirectUrl = biReportDomainRegex.exec(window.location.href)?.[1];

      console.log(`customRedirectUrl: ${customRedirectUrl}`);

      if (customRedirectUrl && customRedirectUrl.length > 1) {
        return customRedirectUrl;
      }
      return "https://bi-reports.ensomata.com";
  }
}

export const IS_PROD = getAppMode() === "prod";

export const LOCALSTORAGE_DBT_TABLES = "DBT_TABLES";
export const CLOSE_WARNING = "There are unsaved changes. Click OK to close.";
export const DELETE_WARNING =
  "Are you sure you want to delete the follow item? You will not be able to undo this action. Press OK to continue";

export const CHART_COLORS = [
  "#ED4924", // red
  "#38A194", // green
  "#7E90CF", // purple
];
