import { PresetListDto, PresetReorderDto } from "api/Api";
import { CloseButton, Page, Stack, Tab, TabContext, TabList, Typography } from "components";
import { ListContext } from "contexts";
import { PageRoute } from "models/PageRoute";
import { useCallback, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import apiService from "services/apiService";
import { buildPath, buildTemplatePath } from "services/routeService";
import { PresetRow } from "./PresetRow";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { newToast, typecheck } from "services";
import { setAppToast } from "redux/reducers/appSlice";
import { useDispatch } from "react-redux";

const routeName = "presets";

export const presetListPageRoute: PageRoute = {
  handle: "Manage Presets",
  templatePath: buildTemplatePath(routeName),
  getPath: () => buildPath(routeName),
};

export const PresetListPage = () => {
  type PresetsTab = "Shared Presets" | "My Presets";

  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState<PresetsTab>("My Presets");
  const [presets, setPresets] = useState<PresetListDto[]>();

  const fetchPresets = useCallback((currentTab: PresetsTab) => {
    apiService
      .getApi()
      .presets.presetsControllerFindAll({ isPublic: currentTab === "Shared Presets" })
      .then((response) => {
        setPresets(response.data);
      });
  }, []);

  useEffect(() => {
    fetchPresets(currentTab);
  }, [currentTab, fetchPresets]);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedPresets = Array.from(presets!);
    const [movedItem] = reorderedPresets.splice(result.source.index, 1);
    reorderedPresets.splice(result.destination.index, 0, movedItem);
    setPresets(reorderedPresets);

    const savingPresets = reorderedPresets.map(
      (x, index) => ({ id: x.id, displayOrder: index + 1 } as PresetReorderDto)
    );

    apiService
      .getApi()
      .presets.presetsControllerReorder(savingPresets)
      .then((response) => {
        dispatch(setAppToast(newToast("success", "Preset Reordered")));
      });
  };

  return (
    <>
      <Page
        maxWidth="800px"
        header={
          <Stack direction="row" justifyContent={"space-between"} alignItems="center">
            <Typography variant="inherit">{presetListPageRoute.handle}</Typography>

            <CloseButton />
          </Stack>
        }
      >
        <Stack spacing="md">
          <TabContext value={currentTab}>
            <TabList
              sx={{
                width: "100%",
                borderBottom: "1px solid #e8e8e8",
                bgcolor: (theme) => theme.palette.background.paper,
              }}
              onChange={(e, value: PresetsTab) => setCurrentTab(value)}
              variant="fullWidth"
            >
              <Tab
                label={
                  <Stack direction="row" spacing="sm" alignItems="center">
                    {typecheck<PresetsTab>("My Presets")}
                  </Stack>
                }
                value={"My Presets"}
              />
              <Tab
                label={
                  <Stack direction="row" spacing="sm" alignItems="center">
                    {typecheck<PresetsTab>("Shared Presets")}
                  </Stack>
                }
                value={"Shared Presets"}
              />
            </TabList>
          </TabContext>

          <Stack spacing={"sm"}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="presetsDroppable">
                {(provided) => (
                  <Stack spacing={"sm"} ref={provided.innerRef} {...provided.droppableProps}>
                    {presets?.map((preset, index) => {
                      return (
                        <Draggable key={preset.presetName} draggableId={preset.presetName} index={index}>
                          {(provided) => (
                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                              <PresetRow
                                key={preset.presetName}
                                preset={preset}
                                refetch={() => fetchPresets(currentTab)}
                              />
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </Stack>
                )}
              </Droppable>
            </DragDropContext>
          </Stack>
        </Stack>
      </Page>

      <ListContext.Provider
        value={{
          getList: () => fetchPresets(currentTab),
        }}
      >
        <Outlet />
      </ListContext.Provider>
    </>
  );
};
