import { Box, Page, Stack } from "components";

import { PageRoute } from "models/PageRoute";
import { useContext, useState } from "react";
import { Outlet } from "react-router-dom";
import { buildPath, buildTemplatePath } from "services/routeService";
import { DataGrid } from "./DataGrid/DataGrid";
import { ReportContext, ReportContextProvider } from "./ReportContextProvider";
import { NavigationBar } from "./NavigationBar/NavigationBar";
import { ReportPageHeader } from "./ReportPageHeader/ReportPageHeader";

const routeName = "report";

export const reportPageRoute: PageRoute = {
  handle: "Report",
  templatePath: buildTemplatePath(routeName),
  getPath: () => buildPath(routeName),
};

export const ReportPage = () => (
  <>
    <ReportContextProvider>
      <ReportPageContent />
    </ReportContextProvider>

    <Outlet />
  </>
);

export const ReportPageContent = () => {
  const reportContext = useContext(ReportContext);
  const [navOpen, setNavOpen] = useState<boolean>(true);

  return (
    <Page
      nav={<NavigationBar open={navOpen} setOpen={setNavOpen} />}
      header={<ReportPageHeader setNavOpen={setNavOpen} />}
    >
      <Stack spacing="md" height="100%">
        <Box flexGrow={1} overflow="auto">
          <DataGrid queryRequest={reportContext.queryRequest} setQueryRequest={reportContext.setQueryRequest} />
        </Box>
      </Stack>
    </Page>
  );
};
