import { ReportSegment } from "api/Api";
import { ReportPuckPreview } from "./ReportPuckPreview";
import { Box, Paper, Typography } from "@mui/material";


export function ReportVariants({reportSegment}:{reportSegment: ReportSegment}) {
    if(reportSegment &&
        reportSegment.reportPuck &&
        reportSegment.reportPuck.trend_is_good_field &&
        reportSegment.reportPuck.puck_value &&
        reportSegment.reportPuck.trend_direction_field &&
        reportSegment.reportPuck.trend_change_value_field &&
        reportSegment.reportPuck.puck_label &&
        reportSegment.reportPuck.puck_description &&
        reportSegment.reportPuck.trend_output) {
            // generate previews of the report calling ReportPuckPreview for one good case and one bad case
            const goodReportResults: any = {};
            // the good
            if (reportSegment.reportPuck.trend_is_good_field) {
                goodReportResults[reportSegment.reportPuck.trend_is_good_field] = 1;
            }
            if (reportSegment.reportPuck.puck_value) {
                goodReportResults[reportSegment.reportPuck.puck_value] = 100;
            }
            if(reportSegment.reportPuck.puck_target) {
                goodReportResults[reportSegment.reportPuck.puck_target] = 100;
            }
            if (reportSegment.reportPuck.trend_direction_field) {
                goodReportResults[reportSegment.reportPuck.trend_direction_field] = 1;
            }
            if (reportSegment.reportPuck.trend_change_value_field) {
                goodReportResults[reportSegment.reportPuck.trend_change_value_field] = 50;
            }
            // the bad
            const badReportResults = {};
            if (reportSegment.reportPuck.trend_is_good_field) {
                badReportResults[reportSegment.reportPuck.trend_is_good_field] = 0;
            }
            if (reportSegment.reportPuck.puck_value) {
                badReportResults[reportSegment.reportPuck.puck_value] = 50;
            }
            if(reportSegment.reportPuck.puck_target) {
                badReportResults[reportSegment.reportPuck.puck_target] = 100;
            }
            if (reportSegment.reportPuck.trend_direction_field) {
                badReportResults[reportSegment.reportPuck.trend_direction_field] = -1;
            }
            if (reportSegment.reportPuck.trend_change_value_field) {
                badReportResults[reportSegment.reportPuck.trend_change_value_field] = -25;
            }
            return <Paper style={{padding: '1rem'}}>
                <Box>
                    <Typography variant="h6">We're doing Great!</Typography>
                </Box>
                <Box>
                    <ReportPuckPreview reportSegment={reportSegment} reportResults={goodReportResults} />
                </Box>
                <Box>
                    <Typography variant="h6">We Can Do Better!</Typography>
                </Box>
                <Box>
                    <ReportPuckPreview reportSegment={reportSegment} reportResults={badReportResults} />
                </Box>
            </Paper>
        }
    else {
        return <div>Finish creating the segment to preview the puck</div>
    }
}