import { EmployeeEmail } from "api/Api";
import { useEffect, useState } from "react";
import apiService from "services/apiService";

export function useIncompleteSubscribers(): [EmployeeEmail[], boolean] {
  const [employees, setEmployees] = useState<EmployeeEmail[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (employees.length > 0) {
      return;
    }
    // fetch employees from the database
    apiService
      .getApi()
      .admin.emailControllerGetIncompleteSubscribers()
      .then((results) => {
        setEmployees(results.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, [employees, loading]);

  return [employees, loading];
}
