import { Table, TableHead, TableRow, TableCell, TableBody, Stack, Box } from "@mui/material";
import { ReportSegmentDashboard, TestDashboardReportResult, TestReportQuery } from "api/Api";
import { ChartData, ChartOptions } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useMemo } from "react";
import { getChartColor, hyphenate } from "utils";
import moment from 'moment';

export function ReportResults({reportResults, dashboard, reportContext}: {
  reportResults:TestDashboardReportResult|undefined; 
  dashboard: ReportSegmentDashboard; 
  reportContext: TestReportQuery;}){
        const chartType: 'bar' | 'stackedBar' | 'table' = useMemo(() => {
            switch(dashboard.chartType)
            {
                case 'Bar Chart':
                    return 'bar';
                case "Stacked Bar Chart":
                    return 'stackedBar';
                default:
                    return 'table';
            }
        }, [
            dashboard
        ]);

        if(!reportResults){
            console.log('no report results');
            return <></>
        }

    return (
        <Stack>
            <Box>
                {(chartType === 'bar' || chartType === 'stackedBar') && <BarChart chartType={chartType} dataSet={reportResults.dataSet} />}
                {chartType === 'table' && <DataGrid reportContext={reportContext} dashboard={dashboard} dataSet={reportResults.dataSet} />}
            </Box>
            
        </Stack>);
}

function DataGrid({dashboard, dataSet, reportContext}:{dataSet: any[]; dashboard: ReportSegmentDashboard; reportContext: TestReportQuery;}) {
    const dashboardName = dashboard.name;
    const escapedDashboardName = hyphenate(dashboardName);
    const propertyNames = dataSet ? Object.keys(dataSet[0]) : [];
    // get the monday of the current week
    
    const weekRange = getWeekRange(reportContext.reportDate);
    return (
        <Box sx={{ overflow: 'auto' }}>
          <Table sx={{ width: '100%' }}>
            <TableHead>
              <TableRow>
                {propertyNames?.map((propertyName, index) => (
                  <TableCell key={`${escapedDashboardName}-header-${index}`} align={index === 0 ? 'left' : 'right'}>
                    {index === 0 ? propertyName : attemptColumnNameReplacement(propertyName, weekRange, index)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {dataSet?.map((row, index) => (
                <TableRow key={`${escapedDashboardName}-data-row-${index}`}>
                  {Object.values(row).map((value: any, cellIndex) => (
                    <TableCell key={cellIndex} align={cellIndex === 0 ? 'left' : 'right'}>
                      {value}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      );
}

function BarChart({chartType, dataSet}:{chartType: 'bar'|'stackedBar', dataSet: any[]}) {
    const data: ChartData<'bar', any[], any> = {
        labels: dataSet.map((row) => row.label),
        datasets: [{
            label: 'Dataset',
            data: dataSet.map((row) => row.value),
            backgroundColor: dataSet.map((row, index) => row.trend_is_good === 1 ? getChartColor(1):getChartColor(0)),
            minBarLength: 2,
        }],
      };

      const beginAtZero = dataSet.every((row) => row.value >= 0);
  
      const options: ChartOptions<'bar'> = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'top',
            display: false, // Show legend only if there's more than one dataset
          },
          title: {
            display: true,
            //text: 'Chart Title',
          },
          tooltip: {
            enabled: true,
          },
        },
        scales: {
          x: {
            stacked: chartType === 'stackedBar',
          },
          y: {
            stacked: chartType === 'stackedBar',
            beginAtZero,
          },
        },
      };
  
      return (
        <Box style={{ height: '60vh', minHeight: '300px', maxHeight: '477px' }}>
          <Bar data={data} options={options} />
        </Box>
      );
}

/**
 * 
 * @param str 
 * @returns a corrected column name depending on the date of the report
 */
function attemptColumnNameReplacement(str: string, weekRange: string[], index: number) {
  switch (str.toLowerCase()) {
    case 'currentweek':
    case 'lastweek':
    case 'twoweeksago':
    case 'threeweeksago':
    case 'fourweeksago':
    case 'fiveweeksago':
    case 'sixweeksago':
      return weekRange[index-1];
    default:
      return str;
  }
}

/**
 * the reportDate is a string in the format 'yyyy-mm-dd'
 * @param reportDate - as a string
 * 
 * @returns an array of dates for the week of the report date
 */
function getWeekRange(reportDate: string | undefined): string[] {
  const reportDateAsMoment = moment(reportDate, 'YYYY-MM-DD');
  console.log('reportDateAsMoment', reportDateAsMoment);

  const currentWeeksMonday = new Date();
  currentWeeksMonday.setDate(currentWeeksMonday.getDate() - (currentWeeksMonday.getDay() + 6) % 7);
  const results: string[] = [];
  // if(reportDateAsMoment.isSame(currentWeeksMonday, 'week')){
    
  //   for(let i = 0; i < 6; i++){
  //     if(i === 0){
  //       results.push('This Week');
  //     }
  //     else if (i === 1){
  //       results.push('Last Week');
  //     } else {
  //       // format the date in 'mm/dd/yy' format
  //       const d = moment(currentWeeksMonday).subtract(i, 'weeks').format('MM/DD/YY');
  //       results.push(d);
  //     }
  //   }
  // } else {

    for(let i = 1; i < 7; i++){
      // format the date in 'mm/dd/yy' format
      const d = moment(reportDateAsMoment).subtract(i, 'weeks').format('MM/DD/YY');
      results.push(d);
    }
  // }
  
  return results.reverse();
}