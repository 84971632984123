import { Box, LinearProgress, Stack } from "components";
import { useAuth } from "hooks";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { setAppUserEmail, setAppUserId } from "redux/reducers/appSlice";
import { setUserSettingInitialState } from "redux/reducers/userSettingSlice";
import { RootState } from "redux/store";
import apiService, { SHOW_LOADING_HEADER } from "services/apiService";

const FIVE_MINUTES = 1000 * 60 * 5;

export function AuthCheck({ children }: any) {
  const lastAuthed = useSelector((state: RootState) => state.auth.lastAuthed);
  const { isAuthenticated, isLoading } = useAuth();
  const [fetching, setFetching] = useState(false);
  const [progress, setProgress] = useState(0);

  const dispatch = useDispatch();

  // each time a user is authenticated, save that user's info into our database and fetch settings
  useEffect(() => {
    // use lastAuthed to try to prevent a long loading time over and over. 
    // As a Dev this is a good way to prevent slowdown.
    if (
        isAuthenticated
        && 
        (!lastAuthed
        || lastAuthed + FIVE_MINUTES < Date.now())
      ) {
      setFetching(true);

      apiService
        .getApi()
        .users.usersControllerSaveUser({
          headers: {
            [SHOW_LOADING_HEADER]: "false",
          },
        })
        .then((response) => {
          const user = response.data;
          dispatch(setAppUserId(user.id));
          dispatch(setAppUserEmail(user.email));

          setProgress(100);

          apiService
            .getApi()
            .users.usersControllerSetting({
              headers: {
                [SHOW_LOADING_HEADER]: "false",
              },
            })
            .then((response) => {
              dispatch(setUserSettingInitialState(response.data));
              setFetching(false);
            });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  if (isLoading || fetching)
    return (
      <Stack
        bgcolor={(theme) => theme.palette.primary.dark}
        height="100vh"
        alignItems="center"
        justifyContent={"center"}
      >
        <Stack spacing="md">
          <Box>
            <img width="200px" src="/ensomata-word-logo-white.svg" alt="logo" />
          </Box>
          <LinearProgress variant="determinate" value={progress} />
        </Stack>
      </Stack>
    );

  if (isAuthenticated) return <>{children}</>;
  else return <Navigate to="login" />;
}
