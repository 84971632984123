import { TerminationReport } from "api/Api";
import { Box, Stack } from "components";
import { TerminationFilters } from "../../TurnoverReportData";
import { rangeArrayGenerator } from "utils";
import React, { ReactElement, useState } from "react";

export function AnnualTurnoverReportByLocation({terminationData, filters}: { terminationData: TerminationReport; filters: TerminationFilters;}) {
    const [hoverRow, setHoverRow] = useState<number | undefined>(undefined);
    const jobLocationData: {
        [location:string]: any;
    } = terminationData.yearlyTerminationsByLocation;

    // convert the filter dates to to a year range
    const yearRange = rangeArrayGenerator(filters.startDate.getFullYear(), filters.endDate.getFullYear(), 1);

    const columnWidth = 100 / (yearRange.length + 2);
    const locationColumnWidth = 100 / (yearRange.length + 3);

    return <>
        <Stack direction="row">
            <Locations jobLocationData={jobLocationData} hoverRow={hoverRow} setHoverRow={setHoverRow} />
            <Terminations yearRange={yearRange} columnWidth={locationColumnWidth} jobLocationData={jobLocationData} terminationData={terminationData} filters={filters} hoverRow={hoverRow} setHoverRow={setHoverRow} />
            <EmployeeCount yearRange={yearRange} columnWidth={columnWidth} terminationData={terminationData} hoverRow={hoverRow} setHoverRow={setHoverRow} />
            <TurnoverRatio yearRange={yearRange} columnWidth={columnWidth} terminationData={terminationData} hoverRow={hoverRow} setHoverRow={setHoverRow} />
        </Stack>
    </>
}

function Locations({jobLocationData, hoverRow, setHoverRow}: {jobLocationData:{ [location: string]: any; }; hoverRow: number | undefined; setHoverRow: (index: number | undefined) => void;}) {
    const locations = Object.keys(jobLocationData);
    
    return <Box className="grouped-by-field">
        <h3>By Location</h3>
        <table>
            <thead>
                <tr>
                    <th>{" "}</th>
                </tr>
            </thead>
            <tbody>
                {locations.map((location, index) => {
                    return <tr
                    className={`${index === hoverRow ? 'hovered':''}`}
                    onMouseOver={(e)=> {
                        setHoverRow(index);
                    }} 
                    onMouseOut={(e)=> {
                        setHoverRow(undefined);
                    }}
                    key={`atrbl-locations-${index}`}>
                        <th style={{ width: "100%", textAlign: "left", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{location}</th>
                    </tr>;
                })}
            </tbody>
            <tfoot>
                <tr>
                    <th style={{ width: "100%", textAlign: "left", overflow: "hidden", whiteSpace: "nowrap" }}>Totals</th>
                </tr>
            </tfoot>
        </table>
    </Box>;
}

function Terminations({yearRange, columnWidth, jobLocationData, terminationData, filters, hoverRow, setHoverRow}: {yearRange: number[]; columnWidth: number; jobLocationData: { [location: string]: any; }; terminationData: TerminationReport; filters: TerminationFilters; hoverRow: number | undefined; setHoverRow: (index: number | undefined) => void;}) {

    // const forecastedTerminations = getAnnualLocationForecasts(terminationData);

    // const annualizedData = calculatePeriodizedTurnoverRate(terminationData.monthlyTerminationsByLocation, terminationData.monthlyEmployeesAverageByLocation);

    // totals and grand total of terminations
    const totals: {[year:number]: number;} = getTerminationTotals(yearRange, jobLocationData);

    const grandTotal = Object.values(totals).reduce((acc, total) => acc + total, 0);

    return <Box className="data-presentation termination">
        <h3>Terminations</h3>
        <table>
            <thead>
                <tr>
                    {yearRange.map((year, index) => <th style={{ width: `${columnWidth}%` }} key={`dpt-terminations-${index}`}>{year}</th>)}
                    {/* <th style={{color: 'grey'}}>2025</th> */}
                    <th>Total</th>
                </tr>
            </thead>
            <tbody>
                {Object.keys(jobLocationData).reduce((locAcc: React.ReactElement[], location, index) => {
                    const jobLocationGroupData = jobLocationData[location];
                    let locationTotal = 0;
                    locAcc.push(<tr
                        className={`${index === hoverRow ? 'hovered':''}`}
                        onMouseOver={(e)=> {
                            setHoverRow(index);
                        }} 
                        onMouseOut={(e)=> {
                            setHoverRow(undefined);
                        }}
                        key={`atrbl-terminations-${index}`}>
                        {yearRange.reduce((yearAcc: ReactElement[], year, yidx) => {
                            locationTotal += typeof jobLocationGroupData[year] !== 'number' ? parseInt(jobLocationGroupData[year]) : jobLocationGroupData[year];
                            yearAcc.push(
                                <td style={{ width: `${columnWidth}%`, textAlign: "right", color: (jobLocationGroupData[year] === 0 ? '#777' : '#000') }} key={`atrble-terminations-year-value-${index}-${year}-${yidx}`}>{jobLocationGroupData[year]}</td>
                                );
                            return yearAcc;
                        }, [])}
                        {/* <td style={{textAlign: "right", width: `${columnWidth}%`}}>
                            { round(forecastedTerminations[location], 0) }
                        </td> */}
                        <td style={{ textAlign: "right", width: `${columnWidth}%`, color: (locationTotal === 0 ? '#777' : '#000') }}>{locationTotal}</td>
                    </tr>);

                    // iterate by the years in the range
                    return locAcc;
                }, [])}
            </tbody>
            <tfoot>
                <tr>
                    {yearRange.reduce((yearAcc: ReactElement[], year) => {
                        yearAcc.push(
                            <td style={{ width: `${columnWidth}%`, textAlign: "right", color: (totals[year] === 0 ? '#777' : '#000') }} key={`atrble-terminations-footer-year-${year}`}>{totals[year]}</td>);
                        return yearAcc;
                    }, [])}
                    {/* <td>
                        { round(Object.values(forecastedTerminations).reduce((acc, forecast) => acc + forecast, 0), 0)}
                    </td> */}
                    <td style={{ textAlign: "right", width: `${columnWidth}%`, color: (grandTotal === 0 ? '#777' : '#000') }}>{grandTotal}</td>
                </tr>
            </tfoot>
        </table>
    </Box>;
}

function EmployeeCount({yearRange, columnWidth, terminationData, hoverRow, setHoverRow}: {yearRange: number[]; columnWidth: number; terminationData: TerminationReport; hoverRow: number | undefined; setHoverRow: (index: number | undefined) => void;}) {
    const totals: {[year:number]: number;} = getEmployeeCountTotals(yearRange, terminationData);
    return <Box className="data-presentation employee-count">
        <h3>Average Employee Count</h3>
        <table>
            <thead>
                <tr>
                    {yearRange.map((year, index) => <th style={{ width: `${columnWidth}px` }} key={`atrblec-employee-counts-year-${index}`}>{year}</th>)}
                </tr>
            </thead>
            <tbody>
                {Object.keys(terminationData.yearlyAverageEmployeesByLocation).map((location, index) => {
                    const employeeData = terminationData.yearlyAverageEmployeesByLocation[location];

                    return <tr className={`${index === hoverRow ? 'hovered':''}`}
                    onMouseOver={(e)=> {
                        setHoverRow(index);
                    }} 
                    onMouseOut={(e)=> {
                        setHoverRow(undefined);
                    }}
                    key={`atrbl-employee-count-${index}`}>
                        {yearRange.map((year, yidx) => {
                            const employeeCount = parseFloat(employeeData[year]);
                            return <td key={`ytrbd${yidx}${index}`} style={{ width: `${columnWidth}%`, textAlign: "right", color: (employeeCount === 0 ? '#777' : '#000') }}>{employeeCount}</td>;
                        })}
                    </tr>;
                })}
            </tbody>
            <tfoot>
                <tr>
                    {yearRange.reduce((yearAcc: ReactElement[], year) => {
                        yearAcc.push(
                            <td style={{ width: `${columnWidth}%`, textAlign: "right", color: (totals[year] === 0 ? '#777' : '#000') }} key={`atrble-employee-counts-year-${year}`}>{totals[year]}</td>);
                        return yearAcc;
                    }, [])}
                </tr>
            </tfoot>
        </table>
    </Box>;
}

function TurnoverRatio({yearRange, columnWidth, terminationData, hoverRow, setHoverRow}: {yearRange: number[]; columnWidth: number; terminationData: TerminationReport; hoverRow: number | undefined; setHoverRow: (index: number | undefined) => void;}){
    const jobLocationData: {
        [location:string]: any;
    } = terminationData.yearlyTerminationsByLocation;
    const terminationTotals: {[year:number]: number;} = getTerminationTotals(yearRange, jobLocationData);
    const employeeCountTotals: {[year:number]: number;} = getEmployeeCountTotals(yearRange, terminationData);
    const turnoverRateTotals: {[year:number]: number;} = getTurnoverRateTotals(terminationTotals, employeeCountTotals);

    return <Box  className="data-presentation turnover-rate">
        {
            // a table with the turnover rate where the termination count is divided by the total headcount
        }
        <h3>Turnover Rate</h3>
        <table>
            <thead>
                <tr>
                    {yearRange.map((year, index) => <th style={{ width: `${columnWidth}%` }} key={index}>{year}</th>)}
                </tr>
            </thead>
            <tbody>
                {Object.keys(terminationData.yearlyTurnoverRateByLocation).map((location, index) => {
                    const turnoverData = terminationData.yearlyTurnoverRateByLocation[location];

                    return <tr className={`${index === hoverRow ? 'hovered':''}`}
                    onMouseOver={(e)=> {
                        setHoverRow(index);
                    }} 
                    onMouseOut={(e)=> {
                        setHoverRow(undefined);
                    }}
                    key={`atrbl-ratios-${index}`}
                    >
                        {yearRange.map((year, yidx) => {
                            const turnoverRate = parseFloat(turnoverData[year]);

                            return <td key={`ytrbd${yidx}${index}`} style={{ width: `${columnWidth}%`, textAlign: "right", color: (turnoverRate === 0 ? '#777' : '#000') }}>{turnoverRate.toFixed(1)}%</td>;
                        })}
                    </tr>;
                })}
            </tbody>
            <tfoot>
                <tr>
                    {yearRange.reduce((yearAcc: ReactElement[], year) => {
                        yearAcc.push(
                            <td style={{ width: `${columnWidth}%`, textAlign: "right", color: (turnoverRateTotals[year] === 0 ? '#777' : '#000') }} key={`atrblr-ratios-year-${year}`}>{turnoverRateTotals[year].toFixed(1)}%</td>);
                        return yearAcc;
                    }, [])}
                </tr>
            </tfoot>
        </table>
    </Box>;
}

function getTerminationTotals(yearRange: number[], jobLocationData: { [location: string]: any; }) {
    return yearRange.reduce((acc, year) => {
        acc[year] = 0;
        Object.keys(jobLocationData).forEach((location) => {
            const locationGroupData = jobLocationData[location];
            acc[year] += typeof locationGroupData[year] !== 'number' ? parseInt(locationGroupData[year]) : locationGroupData[year];
        });
        return acc;
    }, {})
}

function getEmployeeCountTotals(yearRange: number[], terminationData: TerminationReport): { [year: number]: number; } {
    return yearRange.reduce((acc, year) => {
        acc[year] = 0;
        Object.keys(terminationData.yearlyAverageEmployeesByLocation).forEach((location) => {
            const employeeData = terminationData.yearlyAverageEmployeesByLocation[location];
            acc[year] += parseFloat(employeeData[year]);
        });
        return acc;
    }, {});
}

function getTurnoverRateTotals(
    terminationTotals: {[year:number]: number;}, 
    employeeCountTotals: {[year:number]: number;}
): {[year:number]: number;} {
    return Object.entries(terminationTotals).reduce((acc, [year, terminationTotal]) => {
        acc[year] = (terminationTotal / employeeCountTotals[year]) * 100;
        return acc;
    }, {});
}