import { Box, Button, ButtonGroup, Checkbox, Container, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack, Typography } from "@mui/material";
import { Input, Select, TimePicker } from "components";
import { EmailList } from "api/Api";
import { useDispatch } from "react-redux";
import { useList } from "hooks/email/useList";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SubscriptionPicker } from "./SubscriptionPicker/SubscriptionPicker";
import apiService from "services/apiService";
import { setAppToast } from "redux/reducers/appSlice";
import { newToast } from "services";
import { ReportPicker } from "./ReportPicker/ReportPicker";
import { FacilityPicker } from "./FacilityPicker/FacilityPicker";


const frequencyOptions = [
    "Daily",
    "Weekly",
    "Monthly"
];

const dayOfWeekOptions = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
];

export function List() {
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id: idParam } = params;
    const [id, setId] = useState(typeof idParam === "string" ? parseInt(idParam) : idParam ?? 0);
    const [listFacilityId, setListFacilityId] = useState<number | undefined>(undefined);
    const [tempList, setTempList] = useState<EmailList>({
        id: 0,
        name: "",
        description: "",
        active: true,
        frequency: "Daily",
        dayOfWeek: 1,
        dayOfMonth: 1,
        timeOfDay: "07:30:00"
    } as EmailList);

    const [list, loading] = useList(id);

    // assign the temp list to the list when the list is loaded, that way changes to the list are not lost
    useEffect(() => {
        if(list)
        setTempList(list);
    }, [list]);

    useEffect(() => {
        // update the subscription list when the list facility id changes
        const subs = tempList?.subscribers ?? [];
        subs.forEach((sub) => {
            sub.facilityId = listFacilityId ?? 0;
        });
        setTempList({
            ...tempList,
            subscribers: subs
        });
    }, [listFacilityId]);

    useEffect(() => {
        // get the listFacilityId from the subscribers
        const subs = tempList?.subscribers ?? [];
        if(subs.length > 0){
            const facilityId = subs[0].facilityId 
            setListFacilityId(facilityId);
        }
    }, [tempList]);

    if(loading){
        return (<>Loading...</>);
    }
    
    function saveEmailList() {
        apiService
        .getApi()
        .admin
        .emailControllerPostList(id, tempList)
        .then((response) => {
            if(tempList.id === 0){
                setId(response.data.id);
                navigate(`/admin/email-management/list/${response.data.id}`);
            }
            dispatch(setAppToast(newToast("success", "List Saved")));
        });
    }
    
    return (<Container>
        <Stack direction="column" spacing="md" padding={4}>
            <Box>
                <h1>{tempList?.name ?? "New List"}</h1>
            </Box>
            <Stack direction="column" spacing={2}>
                <Box>
                    <Typography variant="h6">Edit List</Typography>
                </Box>
                <Stack direction="row" spacing={2} width="100%">
                    <Box width="85%">
                        <Input 
                            autoFocus={true}
                            label="Email List Name" 
                            value={tempList?.name} 
                            onChange={(e) => {
                                setTempList({
                                    ...tempList,
                                    name: e.target.value
                                });
                            
                            }}
                            fullWidth={true}
                            />
                    </Box>
                    <Box>
                        <label>
                        <Checkbox name="Active" checked={tempList?.active} value={tempList?.active ? "Yes" : "No"} onChange={(ex) => {
                            setTempList({
                                ...tempList,
                                active: !tempList.active
                            });
                        }} />
                        Active</label>
                    </Box>
                </Stack>
                <Box>
                    <Input 
                        label="Description" 
                        value={tempList?.description} 
                        multiline={true}
                        minRows={3}
                        maxRows={3}
                        onChange={(e) => {
                        setTempList({
                            ...tempList,
                            description: e.target.value
                        });
                    }} />
                </Box>
                
                <Box style={{display: "none"}}>
                    <FormControl>
                        <Input
                            label="Subject Format"
                            value={tempList?.subjectPrefix}
                            onChange={(e) => {
                                setTempList({
                                    ...tempList,
                                    subjectPrefix: e.target.value
                                });
                            }} 
                            />
                            <Typography variant="caption" display="block" gutterBottom style={{marginLeft: "2rem"}}>
                                Used to format the subject of the email.  
                                For example, if the subject is "Weekly Report", and the prefix is "[HR]", the subject of the email will be "[HR] Weekly Report".
                                This can be handy for email filtering and organization.
                            </Typography>
                    </FormControl>
                </Box>
                <Box>
                    <FormControl>
                        <FormLabel>Frequency</FormLabel>
                        <RadioGroup row>
                            {frequencyOptions.map((option, index) => {
                                return (
                                    <FormControlLabel
                                        key={`frequency-${index}`}
                                        value={option}
                                        control={<Radio />}
                                        label={option}
                                        checked={tempList?.frequency === option}
                                        onChange={(e) => {
                                            setTempList({
                                                ...tempList,
                                                frequency: option
                                            });
                                        }}
                                    />
                                );
                            })}
                        </RadioGroup>
                    </FormControl>
                    
                </Box>
                <Stack direction="row" spacing={2}>
                    
                    <Box width="25%">
                        <TimePicker
                            label="Send At" 
                            value={tempList?.timeOfDay ?? "07:30"}
                            onChange={(value) => {
                                
                                if(value === "Invalid date"){
                                    console.log('invalid date');
                                    return;
                                }
                                setTempList({
                                    ...tempList,
                                    timeOfDay: value
                                });
                            }}
                            
                            views={["hours", "minutes"]}
                            inputFormat="hh:mm A"
                            />
                    </Box>
                    <Box width="25%">
                        {tempList?.frequency === "Weekly" && 
                            <Select 
                                label="Day of Week" 
                                value={dayOfWeekOptions[tempList?.dayOfWeek ?? 1]} 
                                options={
                                    dayOfWeekOptions
                                }
                                onChange={(e, value) => {
                                    const index = dayOfWeekOptions.indexOf(value);
                                    setTempList({
                                        ...tempList,
                                        dayOfWeek: index
                                    });
                                }}
                            />
                        }
                        {tempList?.frequency === "Monthly" && 
                            <Select 
                                label="Day of Month" 
                                value={list?.dayOfMonth ?? 1} 
                                options={
                                    Array.from({length: 31}, (_, i) => i + 1)
                                }
                                onChange={(e, value) => {
                                    setTempList({
                                        ...tempList,
                                        dayOfMonth: value
                                    });
                                }}
                            />
                        }
                    </Box>
                </Stack>
                <Box>
                    <FacilityPicker
                        facilityId={listFacilityId}
                        onChange={(e) => {
                            setListFacilityId(e);
                        }}
                    />
                </Box>
                <Box>
                        <ReportPicker
                            reportId={tempList?.reportId ?? 0}
                            onChange={(e: number | undefined) => {
                                setTempList({
                                    ...tempList,
                                    reportId: e
                                });
                            }}
                            />

                </Box>
                <Box>
                    
                         <SubscriptionPicker
                            listId={id}
                            facilityId={listFacilityId ?? 0}
                            subscribers={tempList?.subscribers ?? []}
                            onChange={(e) => {
                                setTempList({
                                    ...tempList,
                                    subscribers: e
                                });
                            }}
                         />
                    
                </Box>                        
                <Box>
                    <ButtonGroup variant="contained">
                        <Button onClick={
                            () => {
                                saveEmailList();
                            }
                        }>Save</Button>
                    </ButtonGroup>
                </Box>
            </Stack>
        </Stack>
    </Container>);
}