import { Box, Card as MuiCard, CardProps as MuiCardProps, Stack } from "@mui/material";
import { ReactNode } from "react";
import { useTheme } from "../../hooks";
import { getSpacing } from "theme";

type CardProps = MuiCardProps & {
  header?: ReactNode;
  canCollapse?: boolean;
};

export const Card = ({ header, canCollapse, children, ...rest }: CardProps) => {
  const theme = useTheme();

  return (
    <MuiCard {...rest}>
      <Stack height="100%">
        {(header || canCollapse) && (
          <Stack
            direction="row"
            width="100%"
            borderBottom={1}
            borderColor={theme.palette.divider}
            px={3}
            py={0.5}
            justifyContent="space-between"
            alignItems="center"
            //minHeight={30}
          >
            <Box width="100%" py={getSpacing("sm")} fontWeight="regular" fontSize="1.1rem">
              {header}
            </Box>
          </Stack>
        )}

        {/* Collapse breaks table's scroll: fix? */}
        {/* <Collapse in={!collapsed}> */}
        <Box px={3} py={3} flexGrow={1} overflow="auto">
          {children}
        </Box>
        {/* </Collapse> */}
      </Stack>
    </MuiCard>
  );
};
