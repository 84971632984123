import { EmailList } from "api/Api";
import { useEffect, useState } from "react";
import apiService from "services/apiService";

export function useEmailLists(): [EmailList[], boolean] {
  const [lists, setLists] = useState<EmailList[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    // api call to get email lists
    apiService
      .getApi()
      .admin.emailControllerPostLists({
        /* todo... */
      })
      .then((response) => {
        setLists(response.data);
      });
    setLoading(false);
  }, []);

  return [lists, loading];
}
