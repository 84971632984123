import { useEffect, useState } from "react";
import { useFacilities } from "./useFacilities";
import { Facility } from "api/Api";

export function useFacility(facility_id: number | string): [Facility | undefined, boolean] {
  const [locations, loadingLocations] = useFacilities();

  const [location, setLocation] = useState<Facility | undefined>(undefined);

  useEffect(() => {
    if (loadingLocations) {
      console.log("loading locations");
      return;
    }
    const fac_id = parseInt(facility_id as string);
    const loc = locations.find((loc) => parseInt(loc.id as unknown as string) === fac_id);
    setLocation(loc);
  }, [locations, loadingLocations, facility_id]);

  return [location, loadingLocations];
}
