
import Box from "@mui/material/Box";
import { EmailableReport, Employee, SendEmailReport } from "api/Api";
import { useCustomReport } from "hooks/email/useCustomReport";
import { usePotentialReaders } from "hooks/email/usePotentialReaders";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import apiService from "services/apiService";


export function CustomReport()  {
    const params = useParams();
    
    console.log(`params: ${JSON.stringify(params)}`);
    const {reportid, userbadge, date} = params;
    const [report, reportLoading] = useCustomReport(reportid, userbadge, date);
    if(!reportid || !userbadge || !date) {
        return <Box>
            <p>Uh, Oh, Spaghetti-oh!</p>
            <p>Missing required parameters: reportId: {reportid}, userBadge: {userbadge}, date: {date}. If there's a good explanation let us know. If there isn't then bad things have happened and the developer behind this is openly crying at his/her desk.</p>
        </Box>;
    }

    // TODO: check all of this out to make sure you actually have access to these things

    // {/*!reportLoading && report && <RenderCustomReport report={report} reportIdNum={reportIdNum} userId={userid} date={date} />*/}
    return (
        <div>
            {reportLoading && <div>Loading...</div>}
            {/* {!reportLoading && report && <RenderCustomReport report={report} reportIdNum={reportIdNum} userId={userid} date={date} />} */}
            
            {!reportLoading && report && <RenderReportHTML report={report} userBadge={userbadge} date={date} />}
        </div>
    );
}

export function RenderReportHTML({ userBadge, report, date}: {userBadge: string, report: EmailableReport, date: string}) {
    const iframeRef = useRef(null);
    const [reportHTML, setReportHTML] = useState<string|undefined>(undefined);
    const [potentialReaders, loadingPotentialReaders] =  usePotentialReaders(undefined);
    const [reader, setReader] = useState<Employee|undefined>(undefined);

    useEffect(()=>{
        if(loadingPotentialReaders) 
        {
            console.log('loading potential readers')
            return;
        }
        if(potentialReaders && potentialReaders.length > 0) {
            console.log(`userBadge: ${userBadge} ${parseInt(userBadge, 10)}`);
            const r = potentialReaders.sort((r1, r2) => {
                return parseInt(r1.badge_number, 10) - parseInt(r2.badge_number, 10);
            }).find((reader) => {
                console.log(`${parseInt(reader.badge_number, 10)} ${parseInt(userBadge, 10)}`);
                return parseInt(reader.badge_number, 10) === parseInt(userBadge, 10);
            });
            if (r !== undefined) {
                setReader(r);
            }
            
        } else {
            console.log('no potential readers');
        }
    }, [potentialReaders, userBadge]);

    useEffect(()=>{
        if(!reader){
            console.log('no reader', reader)
            return;
        }

        console.log(`getting html for report ${report.id}`);

        apiService.getApi()
        .admin
        .emailReportControllerGetEmailReportHtml(`${report.id}`,{
            'report_id': report.id,
            'badge_number': reader.badge_number,
            'facility_id': reader.facility_id,
            report_date: date,
        } as SendEmailReport)
        .then(async (response) => {
            const html = await response.blob();
            const txt = await html.text();
            if (iframeRef.current) {
                const doc = (iframeRef.current as HTMLIFrameElement).contentDocument;
                if(doc){
                    doc.open();
                    doc.write(txt);
                }
            }
            setReportHTML(txt);
        }).catch((error) => {
            console.error(error);
        });
    }, [potentialReaders, date, reader, report.id]);

    console.log('doing the insert');
    console.log(reportHTML);
    return <iframe title="email" ref={iframeRef} style={{ width: '100%', height: 'calc(100vh - 55px)', border: '1px solid #ccc' }}></iframe>;
}

// function RenderCustomReport({report, reportIdNum, userId, date}: {report: EmailableReport, reportIdNum: number, userId: string, date: string}) {
//     const [potentialReaders, loadingPotentialReaders] =  usePotentialReaders(undefined);
//     const [reader, setReader] = useState<Employee|undefined>(undefined);

//     useEffect(()=>{
//         if(potentialReaders && potentialReaders.length > 0) {
//             const r = potentialReaders.find((reader) => parseInt(reader.badge_number) === parseInt(userId));
//             setReader(r);
//         }
//     });

//     if(loadingPotentialReaders || reader === undefined) {
//         return <div>Loading...</div>;
//     }
//     return (<>
//     <Container>
//         <Paper>
//             <Stack spacing={2} padding={4}>
//                 <Box>
//                 <Typography variant="h3">{report.reportName}</Typography>
//                 </Box>
//                 <Box>
//                     <Typography variant="body1">{report.preamble}</Typography>
//                 </Box>
//                 <Box>
//                     <Typography variant="h4">Your Report for {date}</Typography>
//                 </Box>
//                 {
//                     report?.segments?.map((segment, idx) => {
//                         return (
//                             <Box key={segment.id}>
//                                 <Typography variant="h5">{segment.name}</Typography>
//                                 <Typography variant="body1">{segment.description}</Typography>
//                                 <ReportSegment reader={reader} key={`repseg${idx}`} segment={segment} index={idx} reportDate={date} />
//                             </Box>
//                         );
//                     })
//                 }
//             </Stack>
//         </Paper>
//     </Container>
//     </>);
// }