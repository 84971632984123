import { EmailableReport } from "api/Api";
import { useEffect, useState } from "react";
import apiService from "services/apiService";
import { useEmailableReports } from "./useEmailableReports";

export function useCustomReport(
  reportId: string | number | undefined,
  userId: string | number | undefined,
  date: string | undefined
): [EmailableReport | undefined, boolean] {
  const [emailableReports, loadingEmailableReports] = useEmailableReports();
  const [report, setCustomReport] = useState<EmailableReport | undefined>(undefined);
  const [loading, setLoading] = useState(loadingEmailableReports);

  useEffect(() => {
    if (reportId === undefined || userId === undefined || date === undefined || loadingEmailableReports) {
      console.log(reportId, userId, date, loading, report);
      return;
    }
    setLoading(true);
    const emailableReport = emailableReports.find((report) => report.id === reportId);
    if (emailableReport) {
      setCustomReport(emailableReport);
      setLoading(false);
    }
  }, [emailableReports, report, loading, reportId, userId, date]);

  return [report, loading];
}
