import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, Checkbox, Container, FormControl, FormLabel, Input, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { EmailableReport } from "api/Api";
import { useEmailableReports } from "hooks/email/useEmailableReports";
import { Link } from 'react-router-dom';
import { useEffect, useState } from "react";
import apiService from 'services/apiService';

export function Reports() {
    const [emailableReports, emailableReportsLoading, reloadEmailableReports] = useEmailableReports();
    const [filteredEmailableReports, setFilteredEmailableReports] = useState<EmailableReport[]>([]);
    const [searchValue, setSearchValue] = useState<string>("");
    const [selectedReports, setSelectedReports] = useState<number[]>([]);
    useEffect(() => {
        
        if(emailableReportsLoading){ return; }
        // emergency room, or emailable reports
        const er = emailableReports.filter(report => (
            searchValue === "" ||
            report?.reportName?.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
        ));


        setFilteredEmailableReports(er);
    },[emailableReports, emailableReportsLoading, filteredEmailableReports, searchValue])

    function deleteReport(reportId: number) {
        apiService.getApi()
            .admin.emailReportControllerDeleteEmailableReport(reportId)
            .then(() => {
                reloadEmailableReports();
            })
            .catch((error) => {
                console.error(error);
            });
    }

    if(emailableReportsLoading) return <div>Loading Reports...</div>
    return (
        <Container>
            <Stack direction="column" spacing="2" width="100%">
                <Stack direction="row" spacing={2} width="100%">
                    <Box width="75%">
                        <Typography variant="h6">Report Configuration</Typography>
                    </Box>
                    <Box width="12.5%">
                        <Link to="/admin/email-management/email-report/0"><Button style={{display: "inline-block"}}>New Report</Button></Link>
                    </Box>
                </Stack>
                
                <Box>
                    <Typography variant='caption'>
                        The following reports are available to be sent via email or as a landing page/dashboard.
                        Choose wisely.
                    </Typography>
                    <p>
                        Deleting a report will remove it from the list of available reports. It will not, however, delete its related list(s). Of course, if there's no report to email, it won't be emailed.
                    </p>
                </Box>
                <Box>
                    <FormControl fullWidth>
                        <FormLabel>
                            <SearchIcon fontSize='small' style={{width: '2rem'}} />
                            <Input name="search" value={searchValue}
                            style={{width: "calc(100% - 2rem)"}}
                            onChange={(e) => {
                                setSearchValue(e.target.value);
                            }} 
                            placeholder="Search by report name"
                            />
                        </FormLabel>
                    </FormControl>
                </Box>
                <Box>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox checked={selectedReports.length === filteredEmailableReports.length} onChange={(e) => {
                                        if(e.target.checked){
                                            setSelectedReports(filteredEmailableReports.map(report => report.id));
                                        } else {
                                            setSelectedReports([]);
                                        }
                                    }} />
                                </TableCell>
                                <TableCell>Report Name</TableCell>
                                <TableCell>Report Description</TableCell>
                                <TableCell>Report Type</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {emailableReports.map(report => (
                                <TableRow key={report.id}>
                                    <TableCell padding="checkbox">
                                        <Checkbox checked={selectedReports.includes(report.id)} value={report.id} onChange={(e) => {
                                            if(e.target.checked){
                                                setSelectedReports([...selectedReports, report.id]);
                                            } else {
                                                setSelectedReports(selectedReports.filter(rid => rid !== report.id));
                                            }
                                        }} />
                                    </TableCell>
                                    <TableCell>
                                        <Link to={`/admin/email-management/email-report/${report.id}`} title="View or Edit Report">{report.reportName}</Link>
                                    </TableCell>
                                    <TableCell>{report.reportDescription}</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>
                                        <a href="/admin/email-management/reports/delete-report" onClick={(e) => {
                                            e.preventDefault();
                                            deleteReport(report.id);
                                        }}>Delete</a>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
            </Stack>
        </Container>);
}