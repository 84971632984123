import { DbtTable, JoinMap, PresetDtoTable, TableNode } from "api/Api";
import { deepCopy } from "services";

export const treeHasTable = (tableTree: TableNode | undefined, { dbName, name }: DbtTable): boolean => {
  if (!tableTree) return false;

  return treeHasTableTraverse(tableTree, dbName.toLowerCase(), name.toLowerCase());
};

const treeHasTableTraverse = (treeNode: TableNode, tableDb: string, tableName: string): boolean => {
  if (treeNode.dbName === tableDb && treeNode.name.toLowerCase() === tableName) return true;

  if (treeNode.children)
    for (const childNode of treeNode.children) {
      const found = treeHasTableTraverse(childNode, tableDb, tableName);
      if (found) return true;
    }

  return false;
};

export const addTableToTree = (
  tableTree: TableNode | undefined,
  { dbName, name }: DbtTable | PresetDtoTable,
  joinMaps: JoinMap[] | undefined
): TableNode | undefined => {
  let _tableTree = deepCopy(tableTree);

  if (!_tableTree) {
    _tableTree = { dbName: dbName.toLowerCase(), name: name.toLowerCase() };
  } else {
    addTableToTreeTraverse(_tableTree, dbName.toLowerCase(), name.toLowerCase(), joinMaps);
  }

  return _tableTree;
};

const addTableToTreeTraverse = (
  treeNode: TableNode,
  addingDbName: string,
  addingTable: string,
  joinMaps: JoinMap[] | undefined
): void => {
  // find a matching relationship for current node and the adding table
  const matchingJoinMap = joinMaps?.find(
    (x) =>
      x.nativeDbName.toLowerCase() === treeNode.dbName.toLowerCase() &&
      x.nativeTable.toLowerCase() === treeNode.name.toLowerCase() &&
      x.foreignDbName.toLowerCase() === addingDbName.toLowerCase() &&
      x.foreignTable.toLowerCase() === addingTable.toLowerCase()
  );

  if (matchingJoinMap) {
    treeNode.children = treeNode.children ?? [];
    treeNode.children.push({
      dbName: addingDbName,
      name: addingTable,
      joinFromField: matchingJoinMap.nativeColumn,
      joinToField: matchingJoinMap.foreignColumn,
    });
  } // if not matched, we recurse down further into its childrens using a left search
  else {
    if (treeNode.children) {
      for (let j = 0; j < treeNode.children.length; j++) {
        addTableToTreeTraverse(treeNode.children[j], addingDbName, addingTable, joinMaps);
      }
    }
  }
};

export const removeTableFromTree = (
  tableTree: TableNode | undefined,
  { dbName, name }: DbtTable
): TableNode | undefined => {
  let _tableTree = deepCopy(tableTree);
  if (!_tableTree) {
    return;
  }

  const remove = removeTableFromTreeTraverse(_tableTree, dbName.toLowerCase(), name.toLowerCase());
  if (remove) {
    return undefined;
  }

  return _tableTree;
};

const removeTableFromTreeTraverse = (treeNode: TableNode, dbName: string, tableName: string): boolean => {
  if (treeNode.dbName.toLowerCase() === dbName && treeNode.name.toLowerCase() === tableName) {
    return true;
  }

  if (treeNode.children)
    for (const childNode of treeNode.children) {
      const remove = removeTableFromTreeTraverse(childNode, dbName, tableName);

      if (remove) {
        treeNode.children = treeNode.children?.filter(
          (x) => x.dbName.toLowerCase() === dbName && x.name.toLowerCase() !== tableName
        );
        treeNode.children = treeNode.children?.length === 0 ? undefined : treeNode.children;
      }
    }

  return false;
};

export const getArrayFromTableTree = (tableTree: TableNode | undefined): PresetDtoTable[] => {
  if (!tableTree) return [];
  return getArrayFromTableTreeTraverse(tableTree);
};

const getArrayFromTableTreeTraverse = (treeNode: TableNode): PresetDtoTable[] => {
  let result: PresetDtoTable[] = [{ dbName: treeNode.dbName, name: treeNode.name }];

  if (treeNode.children)
    for (const childNode of treeNode.children) {
      result = result.concat(getArrayFromTableTreeTraverse(childNode));
    }

  return result;
};

export const isTableJoinable = (
  tableTree: TableNode | undefined,
  { dbName, name }: DbtTable,
  joinMaps: JoinMap[] | undefined
): boolean => {
  const tableName = `${dbName}.${name}`;
  const selectedTables = getArrayFromTableTree(tableTree);
  if (selectedTables.length === 0) return true; // If no tables selected, all are joinable

  for (const selectedTable of selectedTables) {
    const joinableTables = getJoinableTables(selectedTable.dbName, selectedTable.name, joinMaps);
    if (joinableTables.includes(tableName)) return true;
  }

  return selectedTables.map((st) => `${st.dbName}.${st.name}`).includes(tableName); // If table is already selected
};

// get joinable tables from both sides of the relationships
const getJoinableTables = (dbname: string, tableName: string, joinMaps: JoinMap[] | undefined) => {
  let tableNames: string[] = [];

  tableNames = tableNames.concat(
    joinMaps
      ?.filter(
        (joinMap) =>
          joinMap.nativeDbName.toLowerCase() === dbname.toLowerCase() &&
          joinMap.nativeTable.toLowerCase() === tableName.toLowerCase()
      )
      .map((joinMap) => `${joinMap.foreignDbName}.${joinMap.foreignTable}`) || []
  );

  tableNames = tableNames.concat(
    joinMaps
      ?.filter(
        (joinMap) =>
          joinMap.foreignDbName.toLowerCase() === dbname.toLowerCase() &&
          joinMap.foreignTable.toLowerCase() === tableName.toLowerCase()
      )
      .map((joinMap) => `${joinMap.nativeDbName}.${joinMap.nativeTable}`) || []
  );

  return tableNames;
};
