import { Theme } from "@mui/material/styles";

// ----------------------------------------------------------------------

export const Paper = (theme: Theme) => {
  return {
    MuiPaper: {
      styleOverrides: {
        root: {
          //borderRadius: "10px",
          boxShadow: "rgb(159 162 191 / 18%) 0px 9px 16px, rgb(159 162 191 / 32%) 0px 2px 2px",
        },
      },
    },
  };
};
