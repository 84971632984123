import { createSearchParams, URLSearchParamsInit } from "react-router-dom";

// build a template path like this: "developer/:developerId/:developerCrudMode"
export const buildTemplatePath = <TParams>(routeName: string, cls?: new () => TParams): string => {
  let templatePath = routeName;

  if (cls) {
    const obj = new cls();
    let array: string[] = Object.getOwnPropertyNames(obj);
    for (const propertyIndex in array) {
      // append routeName infront of each parameter so that it can be globally unique within each route
      // we need every parameter to be uniquely named so that nested routes with same parameter name (ex. id)
      // can be correctly captured within each nested routed component
      templatePath = `${templatePath}/:${routeName}${array[propertyIndex]}`;
    }
  }

  return templatePath;
};

// build a path with parameters like this `developer/${id}/${crudMode}`
export const buildPath = (routeName: string, params?: any) => {
  let path = routeName;

  if (params) {
    for (let property in params) {
      //if (params[property]) {
        path = `${path}/${params[property]}`;
      //}
    }
  }

  return path;
};

export const buildOptionalParams = (params: any) => {
  let copy: any = {};

  for (let property in params) {
    if (params[property]) {
      copy[property] = params[property];
    }
  }

  return createSearchParams(copy as URLSearchParamsInit).toString();
};
