import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker as MuiTimePicker, TimePickerProps as MuiTimePickerProps } from "@mui/x-date-pickers/TimePicker";
import moment from "moment";
import { TextField } from "./TextField";

export type TimePickerProps = Omit<MuiTimePickerProps<Date, Date>, "value" | "onChange" | "renderInput"> & {
  label?: string;
  value?: any;
  onChange?: (date: any | undefined, keyboardInputValue?: string) => void;
  errors?: string[];
  required?: boolean;
  size?: "small" | "medium" | undefined;
  disabled?: boolean;
  autoFocus?: boolean;
  inputVariant?: "standard" | "outlined" | "filled";
  placeholder?: string;
};

// export class UTCUtils extends MomentUtils {
//   format = (value, formatString) => {
//     return moment(value).utc().format(formatString);
//   };
// }

export const TimePicker = ({
  label,
  value,
  onChange,
  size = "medium",
  errors,
  required,
  disabled,
  autoFocus,
  inputVariant,
  placeholder,
  ...rest
}: TimePickerProps) => {
  const errorMsg = errors && errors?.length > 0 ? errors[0] : null;
  const hasError = errorMsg ? true : false;

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MuiTimePicker
        label={label}
        value={value ? moment(`0001-01-01T${value}`).toDate() : null}
        onChange={(timeValue) => onChange?.(timeValue ? moment(timeValue).format("HH:mm:ss") : undefined)}
        disabled={disabled}
        ampm={false}
        views={["hours", "minutes", "seconds"]} // Add 'seconds' to views
        inputFormat="HH:mm:ss" // Change input format to include seconds
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            required={required}
            variant={inputVariant ? inputVariant : "outlined"}
            error={params.error ? params.error : hasError}
            helperText={errorMsg}
            size={size}
            autoComplete="off"
            autoFocus={autoFocus}
            placeholder={"placeholder"}
          />
        )}
      />
    </LocalizationProvider>
  );
};
