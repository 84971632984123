import { Pagination } from "components";
import produce from "immer";
import { ReportContext } from "pages/ReportPage/ReportContextProvider";
import { useContext } from "react";

export const DataGridPagination = () => {
  const reportContext = useContext(ReportContext);

  const total = reportContext.queryTotalResult?.total;
  const loading = reportContext.queryTotalResultLoading;

  return (
    <Pagination
      loading={loading}
      total={total || 0}
      rowsPerPage={reportContext.queryRequest.pageSize}
      page={reportContext.queryRequest.page}
      onPageChange={(event, page) => {
        const updatedQueryRequest = produce(reportContext.queryRequest, (draft) => {
          draft.page = page;
        });
        reportContext.fetchQueryResult(updatedQueryRequest);
        reportContext.fetchQueryTotalResult(updatedQueryRequest);
        reportContext.setQueryRequest(updatedQueryRequest);
      }}
    />
  );
};
