import { Box, Button, Dialog, Stack } from "components";

import { QueryRequest } from "api/Api";
import { useDispatch } from "react-redux";
import { setAppToast } from "redux/reducers/appSlice";
import { newToast } from "services";

export const InspectQueryRequest = (props: { queryRequest: QueryRequest; close: () => void }) => {
  const dispatch = useDispatch();

  const copyToClipboard = () => {
    const textToCopy = JSON.stringify(props.queryRequest, null, 4);
    if (navigator.clipboard) {
      navigator.clipboard.writeText(textToCopy).then(
        () => dispatch(setAppToast(newToast("success", "Copied to Clipboard"))),
        (err) => dispatch(setAppToast(newToast("error", "Unable to copy to Clipboard")))
      );
    } else {
      // Fallback for browsers that don't support the Clipboard API
      const textarea = document.createElement("textarea");
      textarea.value = textToCopy;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
    }
  };

  return (
    <Dialog
      open={true}
      onClose={() => props.close()}
      maxWidth="md"
      content={
        <Box>
          <pre>{JSON.stringify(props.queryRequest, null, 4)}</pre>
        </Box>
      }
      footer={
        <Stack direction="row" spacing="sm">
          <Button variant="outlined" onClick={() => copyToClipboard()}>
            Copy to Clipboard
          </Button>

          <Button variant="outlined" onClick={() => props.close()}>
            Close
          </Button>
        </Stack>
      }
    ></Dialog>
  );
};
