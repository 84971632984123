import { ReportSegment } from "api/Api";
import { useEffect, useState } from "react";
import apiService from "services/apiService";

export function useReportSegment(segmentId: number = 0): [ReportSegment | undefined, boolean] {
  const [reportSegment, setReportSegment] = useState<ReportSegment | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!segmentId) {
      setLoading(false);
      return;
    }

    apiService
      .getApi()
      .admin.reportSegmentControllerGetReportSegment(segmentId)
      .then((response) => {
        setReportSegment(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, [segmentId]);
  return [reportSegment, loading];
}
