import { Stack } from "components";
import { reportPageRoute } from "pages/ReportPage/ReportPage";
import { useNavigate } from "react-router-dom";

export const AppTopBarBrand = () => {
  const navigate = useNavigate();

  return (
    <Stack
      direction="row"
      spacing="sm"
      alignItems="center"
      onClick={(e) => navigate(reportPageRoute.getPath())}
      sx={{
        cursor: "pointer",
      }}
    >
      <img width="60px" src="/ensomata-word-logo-white.svg" alt="logo" />
    </Stack>
  );
};
