import { Theme } from "@mui/material/styles";

// ----------------------------------------------------------------------

export const Autocomplete = (theme: Theme) => {
  return {
    MuiAutocomplete: {
      styleOverrides: {
        tag: {
          marginTop: "1px",
          marginBottom: "0px",
        },
      },
    },
  };
};
