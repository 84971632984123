import {  IconButton, Stack } from "components";

import ClearIcon from "@mui/icons-material/Clear";
import { ReportContext } from "pages/ReportPage/ReportContextProvider";
import { useContext, useState } from "react";
import { Field } from "api/Api";

export const FunctionField = (props: { field: Field; remove: () => void }) => {
  const reportContext = useContext(ReportContext);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  return (
    <Stack
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={props.remove}
      direction="row"
      spacing="xs"
      alignItems={"center"}
      justifyContent="space-between"
      width="100%"
      minHeight={"32px"}
      bgcolor={"grey.200"}
      px={1}
      pl={5}
      sx={{ cursor: "pointer" }}
      fontSize="0.85rem"
    >
      {reportContext.getFieldDisplay(props.field, "node")}

      {/* <DataTypeIcon type={props.dataField.dataType} />

      <Stack
        direction="row"
        spacing="sm"
        alignItems="center"
        justifyContent="flex-start"
        {...ellipsisStyle}
        flexGrow={1}
        //fontWeight={joinable ? "bold" : undefined}
      >
        <Typography fontSize="12px" fontWeight={(theme) => theme.typography.fontWeightRegular}>
          {props.field.calc_displayString as ReactNode}
        </Typography>
      </Stack> */}

      {isHovered && (
        <IconButton size="small" disabled>
          <ClearIcon sx={{ fontSize: "1.1rem" }} />
        </IconButton>
      )}
    </Stack>
  );
};
