import InvalidIcon from "@mui/icons-material/Block";
import { Tooltip } from "@mui/material";
import { GroupByConfig } from "api/Api";
import { IconButton, Stack, Typography } from "components";
import { ReportContext } from "pages/ReportPage/ReportContextProvider";
import { ReactNode, useContext } from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

export const GroupByStatus = (props: { groupByConfig: GroupByConfig; setActive: (value: boolean) => void }) => {
  const reportContext = useContext(ReportContext);
  const isValid = reportContext.validateGroupByConfig(props.groupByConfig, "boolean");

  if (isValid) {
    return (
      <Tooltip title={"Click to make this config active/inactive."} placement="right-end">
        <IconButton onClick={() => props.setActive(!props.groupByConfig.active)} sx={{ p: 0 }}>
          {props.groupByConfig.active ? (
            <CheckBoxIcon fontSize="small" color="primary" />
          ) : (
            <CheckBoxOutlineBlankIcon fontSize="small" />
          )}
        </IconButton>
      </Tooltip>
    );
  } else {
    const errorMessages = reportContext.validateGroupByConfig(props.groupByConfig, "errorMessages");
    const errorNode: ReactNode = (
      <Stack spacing="sm">
        <Typography variant="inherit">Invalid Config: this config will not be applied</Typography>

        {errorMessages?.length > 0 && (
          <Stack>
            {errorMessages.map((x) => (
              <Typography variant="inherit">- {x}</Typography>
            ))}
          </Stack>
        )}
      </Stack>
    );

    return (
      <Tooltip title={errorNode}>
        <IconButton sx={{ p: 0 }}>
          <InvalidIcon color="error" fontSize="small" />
        </IconButton>
      </Tooltip>
    );
  }
};
