import { Box, Paper, Stack, Table, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import apiService from "services/apiService";

import "./SharedQueries.css";

export function SharedPresetQueries() {
    const [sharedPresetQueryListData, setSharedPresetQueryListData] = useState<any[]>([]);


    useEffect(() => {

        apiService
        .getApi()
        .reference
        .referenceControllerSharedPresetQueryList()
        .then((response: any) => {
            setSharedPresetQueryListData(response.data);
        });
        
    }, []);

    return <Box width={"98%"}>
                <Paper sx={{ padding: "0.2rem" }}>
                <Stack direction="column" spacing={2}>
                    <Box><Typography fontWeight="bold" fontSize="1.1rem" margin="0.5em 0">Shared Presets</Typography></Box>
                    <Box>
                        <Table className="shared-queries">
                            <thead>
                                <tr>
                                    <th>Preset Name</th>
                                    <th>Created By</th>
                                    <th>Created At</th>
                                    <th>Modified At</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    sharedPresetQueryListData.map((row, index) => {
                                        return <tr key={index}>
                                            <td>{row.Preset_Name}</td>
                                            <td>{row.createdBy}</td>
                                            <td>{row.CreatedDate}</td>
                                            <td>{row.ModifiedDate}</td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </Table>
                    </Box>
                </Stack>
                </Paper>
            </Box>
}