import { GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import apiService from "services/apiService";

export function useDbtTableColumns(dbName: string, tableName: string): [GridColDef[], boolean] {
  const [isLoading, setIsDbtTablesLoading] = useState(false);
  const [dbtTables, setDbtTables] = useState<any[]>([]);
  const [dbtTableColumns, setDbtTableColumns] = useState<GridColDef[]>([]);

  // load up the dbtTables

  useEffect(() => {
    if (dbtTables.length === 0 && !isLoading) {
      setIsDbtTablesLoading(true);
      apiService
        .getApi()
        .report.reportControllerGetResource()
        .then((response) => {
          setIsDbtTablesLoading(false);
          setDbtTables(response.data.tables);
        })
        .catch((error) => {
          console.error("Error fetching dbt tables", error);
        });
    }
  }, [dbtTables, dbtTableColumns, isLoading]);

  useEffect(() => {
    // set the columns
    if (isLoading || dbtTables.length === 0) {
      return;
    }

    // find the table "payers" with the dbName "financial_report"
    const table = dbtTables.find((table) => table.dbName === dbName && table.name === tableName);

    if (!table) {
      console.error(`Table ${dbName}.${tableName} not found`);
      return;
    }

    const columns = table.dataFields.map((column) => {
      return {
        field: `${dbName}.${tableName}.${column.fieldName}`,
        headerName: column.displayName,
        width: 200,
      };
    });

    setDbtTableColumns(columns);
  }, [dbName, tableName, dbtTables, isLoading]);

  return [dbtTableColumns, isLoading];
}
