import {ReportSegment as iReportSegment} from "api/Api";
import { Box, Stack, Typography } from "@mui/material";
import { Input } from "components";
import { IconPicker } from "../editor-helpers/IconPicker";
import { SqlQueryEditor } from "../SqlQueryEditor";

export function PuckConfiguration({segment, onChange} : {segment: iReportSegment, onChange: (segment: iReportSegment) => void}) {
    return (<Stack spacing={2}>
        <Box>
            <SqlQueryEditor 
                sqlQuery={segment?.reportPuck?.sqlQuery} 
                onQueryChange={(sqlQuery:string) => {
                    const seg = {...segment};
                    seg.reportPuck.sqlQuery = sqlQuery;
                    onChange(seg);
                }} />
        </Box>
        <Box>
            <Typography variant="h6">Puck Details</Typography>
        </Box>
        <Box>
            <IconPicker 
                segmentIconName={segment?.reportPuck?.icon} 
                setSelectedIcon={(iconName)=> {
                    const seg = {...segment};
                    seg.reportPuck.icon = iconName;
                    onChange(seg);
                }} 
                />
        </Box>
        <Box>
            <Input 
                type="text" 
                label="Primary Metric Field" 
                value={segment?.reportPuck?.puck_value}
                onChange={(e)=>{
                    const seg = {...segment};
                    seg.reportPuck.puck_value =  e.target.value;
                    onChange(seg);
                }} 
                />
        </Box>
        <Box>
            <Input 
                type="text" 
                label="Target Metric Field" 
                value={segment?.reportPuck?.puck_target}
                onChange={(e)=>{
                    const seg = {...segment};
                    seg.reportPuck.puck_target =  e.target.value;
                    onChange(seg);
                }} 
                />
        </Box>
        <Box>
            <Input 
                type="text" 
                label="Metric Label" 
                value={segment?.reportPuck?.puck_label}
                onChange={(e)=>{
                    const seg = {...segment};
                    seg.reportPuck.puck_label =  e.target.value;
                    onChange(seg);
                }} 
                />
        </Box>
        <Box>
            <Input 
                type="text" 
                label="Metric Description" 
                value={segment?.reportPuck?.puck_description}
                onChange={(e)=>{
                    const seg = {...segment};
                    seg.reportPuck.puck_description =  e.target.value;
                    onChange(seg);
                }} 
                />
        </Box>
        <Box>
            <Input 
                type="text" 
                label="Trend Direction Field" 
                value={segment?.reportPuck?.trend_direction_field}
                onChange={(e)=>{
                    const seg = {...segment};
                    seg.reportPuck.trend_direction_field =  e.target.value;
                    onChange(seg);
                }} 
                />
        </Box>
        <Box>
            <Input 
                type="text" 
                label="Trend Is Good Field" 
                value={segment?.reportPuck?.trend_is_good_field}
                onChange={(e)=>{
                    const seg = {...segment};
                    seg.reportPuck.trend_is_good_field =  e.target.value;
                    onChange(seg);
                }} 
                />
        </Box>
        <Box>
            <Input 
                type="text" 
                label="Trend Change Value Field" 
                value={segment?.reportPuck?.trend_change_value_field}
                onChange={(e)=>{
                    const seg = {...segment};
                    seg.reportPuck.trend_change_value_field =  e.target.value;
                onChange(seg);
            }} />
        </Box>
        <Box>
            <Input 
                type="text" 
                label="Trend text" 
                value={segment?.reportPuck?.trend_output}
                onChange={(e)=>{
                    const seg = {...segment};
                    seg.reportPuck.trend_output =  e.target.value;
                    onChange(seg);
                }} 
                />
        </Box>
        <Box>
            <Input type="text" label="Bad Trend Message" 
                value={segment?.reportPuck?.trend_is_bad_message}
                onChange={(e)=>{
                    const seg = {...segment};
                    seg.reportPuck.trend_is_bad_message =  e.target.value;
                    onChange(seg);
                }}
                />
        </Box>
    </Stack>);
}