import { Menu, MenuItem, Stack, Typography } from "components";

import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import CheckIcon from "@mui/icons-material/Check";
import { DbtDataField } from "api/Api";
import { CustomFunction } from "api/Api-extension";
import { ReportContext } from "pages/ReportPage/ReportContextProvider";
import { useContext, useState } from "react";

export const CustomFunctionsMenuItem = (props: {
  closeParentMenu: () => void;
  dataField: DbtDataField;
  selectedCustomFunctions: CustomFunction[];
  toggleCustomFunction: (aggregatedFunction: CustomFunction) => void;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const reportContext = useContext(ReportContext);
  const customFunctionOptions =
    reportContext.customFunctionOptions?.filter((x) => x.dataTypes.includes(props.dataField.dataType)) || [];

  return (
    <>
      <MenuItem
        onClick={(e: React.MouseEvent<HTMLLIElement>) => {
          e.stopPropagation();
          setAnchorEl(e.currentTarget);
        }}
        disabled={customFunctionOptions.length === 0}
      >
        <Stack width="100%" direction="row" spacing="lg" justifyContent={"space-between"} alignItems={"center"}>
          <Typography>Custom Function</Typography>

          <ArrowRightIcon />
        </Stack>
      </MenuItem>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        {customFunctionOptions?.map((customFunctionOption) => {
          const selected = props.selectedCustomFunctions?.includes(customFunctionOption.function);

          return (
            <MenuItem
              onClick={() => {
                props.toggleCustomFunction(customFunctionOption.function);
                props.closeParentMenu();
              }}
            >
              <Stack direction="row" spacing="sm" alignItems="center">
                <Typography>{customFunctionOption.name}</Typography>
                {selected && <CheckIcon fontSize="small" color="success" />}
              </Stack>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
