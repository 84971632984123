import { TextField, TextFieldProps } from "@mui/material";

type InputProps = Omit<TextFieldProps, "helperText"> & {
  // no helperText props because we are using that for validation text
  errors?: any[];
};

export const Input = ({
  label,
  value,
  type,
  placeholder,
  size = "medium",
  errors,
  multiline,
  minRows,
  ...rest
}: InputProps) => {
  const errorMsg = errors && errors?.length > 0 ? errors[0] : null;
  const hasError = errorMsg ? true : false;

  if (type === "duration") {
    placeholder = "hh:mm";
  }

  return (
    <TextField
      fullWidth
      label={label}
      value={value !== null && value !== undefined ? value : ""}
      error={hasError}
      helperText={errorMsg}
      variant={"outlined"}
      type={type}
      //InputLabelProps={{ shrink: false }}
      InputProps={{ readOnly: false }} // could use this someday
      multiline={multiline}
      placeholder={placeholder}
      minRows={minRows ? minRows : 2} // only applies if multiline === true
      maxRows={Infinity} // only applies if multiline === true
      size={size}
      {...rest}
    />
  );
};
