import { Auth } from "aws-amplify";
import axios, { AxiosInstance } from "axios";
// import {
//   AUTH_JWT_TOKEN,
//   AUTH_JWT_TOKEN_EXPIRATION,
//   AUTH_REFRESH_TOKEN,
//   AUTH_REFRESH_TOKEN_EXPIRATION,
// } from "@compass/core";
import { store } from "redux/store";
// import { setAppAuthStatus } from "redux/reducers/appSlice";
// import { AuthResult } from "generated/openApi";
import { setAppLoading } from "redux/reducers/appSlice";

declare module "axios" {
  export interface AxiosRequestConfig {
    noLoading?: boolean;
  }
}

const baseUrl = "";
// const baseUrl = process.env.REACT_APP_API_ENDPOINT;

const httpRequestService: AxiosInstance = axios.create({
  timeout: 360000,
  baseURL: baseUrl,
  withCredentials: false, // what's this?
});

httpRequestService.interceptors.request.use(
  async (requestConfig) => {
    // headers
    if (!requestConfig.headers) requestConfig.headers = {};
    if (!requestConfig.headers["Content-Type"]) requestConfig.headers["Content-Type"] = "application/json";
    //if (!requestConfig.headers.Accept) requestConfig.headers.Accept = "*/*";
    // if (!requestConfig.headers["X-Requested-With"]) requestConfig.headers["X-Requested-With"] = "XMLHttpRequest";
    // if (!requestConfig.headers["Access-Control-Allow-Origin"])
    //   requestConfig.headers["Access-Control-Allow-Origin"] = "*";
    // if (!requestConfig.headers["Access-Control-Allow-Methods"])
    //   requestConfig.headers["Access-Control-Allow-Methods"] = "GET,PUT,POST,DELETE,PATCH,OPTIONS";
    // if (!requestConfig.headers["Access-Control-Allow-Headers"])
    //   requestConfig.headers["Access-Control-Allow-Headers"] =
    //     "append,delete,entries,foreach,get,has,keys,set,values,Authorization";

    // method
    if (!requestConfig.method) requestConfig.method = "GET";

    // authorization
    const authData = await Auth.currentAuthenticatedUser();
    const token = authData?.signInUserSession?.idToken?.jwtToken;

    if (!requestConfig.headers.Authorization) {
      if (token) {
        requestConfig.headers.Authorization = `Bearer ${token}`;
      }
    }

    if (!requestConfig.noLoading) {
      const appLoading: number = store.getState().app.loading;
      store.dispatch(setAppLoading(appLoading + 1));
    }

    return requestConfig;
  },
  (error) => {
    // if (!error.requestConfig.noLoading) {
    //   const appLoading: number = store.getState().app.loading;
    //   store.dispatch(setAppLoading(appLoading - 1));
    // }

    return Promise.reject(error);
  }
);

httpRequestService.interceptors.response.use(
  (response) => {
    if (!response.config.noLoading) {
      const appLoading: number = store.getState().app.loading;
      store.dispatch(setAppLoading(appLoading - 1));
    }

    return response;
  },
  async (error) => {
    if (!error.config.noLoading) {
      const appLoading: number = store.getState().app.loading;
      store.dispatch(setAppLoading(appLoading - 1));
    }

    // 400: bad request, return the error response
    // if (error.response && error.response.status === 400) {
    //   return error.response;
    // }

    // 401: unauthorized: use refreshToken to get a new jwtToken and try to authorize again
    // if (error.response && error.response.status === 401) {
    //   // this call should set the JwtToken in the cookie if successful
    //   const refreshToken: string | null = localStorage.getItem(AUTH_REFRESH_TOKEN);

    //   // If we have not logged in before, it makes no sense to try to get a new token
    //   if (!refreshToken) {
    //     store.dispatch(setAppAuthStatus("NOT_AUTHENTICATED")); // set isAuthenticate to false
    //     return Promise.reject(error);
    //   }

    //   // isRefreshingToken prevents multiple concurrent requests from trying to refresh the tokens (and get back different tokens)
    //   if (!isRefreshingToken) {
    //     isRefreshingToken = true;

    //     axios
    //       .post(`${baseUrl}/auth/refreshToken`, refreshToken, { headers: { "Content-Type": "application/json" } })
    //       .then(({ data }) => {
    //         const authResult: AuthResult = data;
    //         localStorage.setItem(AUTH_JWT_TOKEN, authResult.jwtToken!);
    //         localStorage.setItem(AUTH_JWT_TOKEN_EXPIRATION, authResult.jwtTokenExpiration?.toString()!);
    //         localStorage.setItem(AUTH_REFRESH_TOKEN, authResult.refreshToken!);
    //         localStorage.setItem(AUTH_REFRESH_TOKEN_EXPIRATION, authResult.refreshTokenExpiration?.toString()!);

    //         // execute original requests to be called later once token is fetched
    //         requestsToRefresh.forEach((cb) => cb(authResult.jwtToken!));
    //       })
    //       .catch(() => {
    //         store.dispatch(setAppAuthStatus("NOT_AUTHENTICATED")); // set isAuthenticate to false
    //       })
    //       .finally(() => {
    //         requestsToRefresh = [];
    //         isRefreshingToken = false;
    //       });
    //   }

    //   return new Promise((resolve, reject) => {
    //     // requestsToRefresh (requests that expect a new token from the first request), we add a callback which the first request to execute
    //     // these functions won't be executed until the first request finishes refreshing token
    //     requestsToRefresh.push((jwtToken: string) => {
    //       if (jwtToken) {
    //         error.config.headers.Authorization = `BEARER ${jwtToken}`;
    //         resolve(httpRequestService(error.config));
    //       }

    //       // If the first request could not refresh the token, we must return the basic request processing logic
    //       reject(error);
    //     });
    //   });
    // }

    if (error.response && error.response.data) {
      alert(error.response.data.message);
    } else {
      alert("An unexpected error occured.");
    }

    return Promise.reject(error.response || error.message);
  }
);

export default httpRequestService;
