import { Box, Page, Stack } from "components";
import { emailManagementPageRoute } from "./EmailManagement/EmailManagement";
// import {taskSchedulerPageRoute} from "./TaskScheduler/TaskScheduler";
import { Link, Outlet, RouteObject } from "react-router-dom";
import { buildTemplatePath } from "services/routeService";
import { AdminDashboard } from "./AdminDashboard/AdminDashboard";

import "./Admin.css"
import { useSelector } from "react-redux";
import { RootState } from "redux/store";


const routeName = "admin";

export const adminPageRoute: RouteObject = {
  handle: "Admin",
  path: buildTemplatePath(routeName),
  element: <AdminPage />,
  children: [
    {
      index: true,
      path: '',
      handle: 'Admin',
      element: <AdminDashboard />,
    },
    emailManagementPageRoute,
    // taskSchedulerPageRoute,
  ]
};

// create the Tree view for the Admin Nav
const NestedRoutes = ({ routes, parentPath = '' }) => {
  return (
    <ul>
      {routes.filter((route) => {
        // remove routes that aren't indexes
        return !route.index;
      }).map((route, index) => (
        <li key={`nav-routes-${index}`}>
          <Link to={`${parentPath}/${route.path}`}>{route.handle}</Link>
          {
            route.children && <NestedRoutes routes={route.children} parentPath={`${parentPath}/${route.path}`} />
          }
        </li>
      ))}
    </ul>
  );
};

export function AdminNav({route}: {route:RouteObject;}) {
  const userNavWidth = useSelector((state: RootState) => state.userSetting.navWidth);
  
  return (
    <Stack direction="row" spacing="md" width={userNavWidth}>
      <Box className="nested-routes">
        <NestedRoutes routes={[route]} />
      </Box>
    </Stack>
  );
}

export function AdminPage() {
  return <>
    <Page
      nav={<AdminNav route={adminPageRoute} />}
      header={<>Admin</>}
    >
      <Stack spacing="md" height="100%">
        <Box>{/* probably a breadcrumb here? */}</Box>

        <Box flexGrow={1} overflow="auto">
          <Outlet />
        </Box>
      </Stack>    
    </Page>
  </>
};