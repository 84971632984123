import { ReactNode } from "react";
import { Stack, Typography } from "components";

type PageHeaderProps = {
  title?: ReactNode;
  // menuItems?: MenuItemType[];
  rightHeader?: ReactNode;
};

export const PageHeader = ({ title, rightHeader }: PageHeaderProps) => {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
      <Stack direction="row" spacing="sm" alignItems="center">
        <Typography variant="h5">{title}</Typography>

        {/* {menuItems && menuItems.length > 0 && (
          <>
            <DropDownMenu menuItems={menuItems} />
          </>
        )} */}
      </Stack>

      <Stack direction="row" spacing="sm" alignItems="center">
        {rightHeader}
      </Stack>
    </Stack>
  );
};
