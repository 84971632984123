import { Employee } from "api/Api";
import { useEffect, useState } from "react";
import apiService from "services/apiService";

export function usePotentialReaders(roll: string | undefined, limitToFacility?: number): [Employee[], boolean] {
  const [readers, setReaders] = useState<Employee[]>([]);
  const [filteredReaders, setFilteredReaders] = useState<Employee[]>([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (readers.length > 0) {
      return;
    }
    apiService
      .getApi()
      .admin.emailReportControllerGetPotentialEmployees()
      .then((response) => {
        setReaders(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, [readers]);

  useEffect(() => {
    if (readers.length === 0) {
      return;
    }

    let fedReaders = [...readers];

    if (limitToFacility) {
      // unfortunately the facility_id is a string at times.
      fedReaders = readers.filter((reader) => parseInt(reader.facility_id as unknown as string) === limitToFacility);
    }

    if (roll) {
      fedReaders = fedReaders.filter((reader) => roll.toLowerCase() === reader.job_title.toLowerCase());
    }

    setFilteredReaders(fedReaders);
  }, [roll, readers, limitToFacility]);

  return [filteredReaders, loading];
}
