import { Employee } from "api/Api";
import { Select } from "components/mui/Select";
import { usePotentialReaders } from "hooks/email/usePotentialReaders";

export function ReaderPicker({reader, setReader, limitToFacility}: {reader: Employee | undefined, setReader: Function, limitToFacility?: number}) {
    
    const [potentialReaders, loadingPotentialReaders] = usePotentialReaders(undefined, limitToFacility);
    if(loadingPotentialReaders) {
        return <div>Loading...</div>
    }
    return <>
        <Select 
            value={reader}
            onChange={(e, value) => {setReader(value)}}
            label="View the Report As"
            options={potentialReaders}
            getOptionLabel={(reader) => `${reader.last_name}, ${reader.first_name} -- ${reader.maplocation_longname} -- ${reader.job_title} (${reader.department_name})`}
        />
    </>
}