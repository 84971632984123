import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useDispatch } from "react-redux";
import { setAppToast } from "redux/reducers/appSlice";
import { newToast } from "services";

export function CopyToClipboard(props: { valueToCopy: string }) {
    const dispatch = useDispatch();
    const copyTextToClipbard = () => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(props.valueToCopy).then(
              () => dispatch(setAppToast(newToast("success", "Copied to Clipboard"))),
              (err) => dispatch(setAppToast(newToast("error", "Unable to copy to Clipboard")))
            );
          } else {
            // Fallback for browsers that don't support the Clipboard API
            const textarea = document.createElement("textarea");
            textarea.value = props.valueToCopy;
            document.body.appendChild(textarea);
            textarea.select();
            document.execCommand("copy");
            document.body.removeChild(textarea);
          }
    }

    return <span title="Copy to clipboard" style={{cursor: "pointer"}} className="copy-to-clipboard" onClick={() => {
        copyTextToClipbard();
    }}>
        <ContentCopyIcon />
    </span>
}