import { Box, Stack } from "components";
import produce from "immer";
import { ReportContext } from "pages/ReportPage/ReportContextProvider";
import { getFieldFullIdentifier } from "pages/ReportPage/utils/utils";
import { useContext } from "react";
import { GroupByField } from "./GroupByField";

export const GroupBy = () => {
  const reportContext = useContext(ReportContext);
  const queryRequest = reportContext.queryRequest;
  const groupByFields = queryRequest.fields?.some((x) => x.transform === "AggregateFunction")
    ? queryRequest.fields?.filter((field) => field.transform !== "AggregateFunction")
    : [];

  return (
    <Stack>
      {groupByFields.map((field, index) => (
        <Box key={index} borderBottom={(theme) => `1px solid ${theme.palette.border.main}`}>
          <GroupByField
            field={field}
            setField={(value) => {
              reportContext.setQueryRequest(
                produce(reportContext.queryRequest, (draft) => {
                  const fieldIdentifier = getFieldFullIdentifier(field);
                  const fieldIndex = draft.fields.findIndex((x) => getFieldFullIdentifier(x) === fieldIdentifier);
                  if (fieldIndex !== -1) draft.fields[fieldIndex] = value;
                })
              );
            }}
          />
        </Box>
      ))}
    </Stack>
  );
};
