import { CloseButton, Dialog, MenuItem, Stack, Typography } from "components";

import { DbtDataField } from "api/Api";
import { useState } from "react";
import { getSpacing } from "theme";

export const FieldInfoMenuItem = (props: { closeParentMenu: () => void; dataField: DbtDataField }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <MenuItem onClick={() => setOpen(true)}>Properties</MenuItem>

      <Dialog
        isForm
        open={open}
        onClose={() => {
          setOpen(false);
          props.closeParentMenu();
        }}
        maxWidth="xs"
        header="Properties"
        content={
          <Stack p={getSpacing("sm")}>
            <Stack direction="row" spacing="xs">
              <Typography variant="caption">Field Name:</Typography>
              <Typography variant="caption" fontWeight={"medium"}>
                {props.dataField.fieldName}
              </Typography>
            </Stack>

            <Stack direction="row" spacing="xs">
              <Typography variant="caption">Field Type:</Typography>
              <Typography variant="caption" fontWeight={"medium"}>
                {props.dataField.dataType}
              </Typography>
            </Stack>

            <Stack direction="row" spacing="xs">
              <Typography variant="caption">DB Field Type:</Typography>
              <Typography variant="caption" fontWeight={"medium"}>
                {props.dataField.dbDataType}
              </Typography>
            </Stack>

            <Stack direction="row" spacing="xs">
              <Typography variant="caption">Context:</Typography>
              <Typography variant="caption" fontWeight={"medium"}>
                {props.dataField.context}
              </Typography>
            </Stack>
          </Stack>
        }
        footer={
          <CloseButton
            onClick={() => {
              setOpen(false);
              props.closeParentMenu();
            }}
          />
        }
      />
    </>
  );
};
