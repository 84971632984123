import Box from '@mui/material/Box';
import { Outlet } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Button, Divider, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Typography, useTheme } from '@mui/material';
import { UploaderTemplate } from './spreadsheets/UploaderTemplate';
import Paper from '@mui/material/Paper';
import { useEffect, useState } from 'react';
import { DbtTable, ReportIntervalDates } from 'api/Api';
import moment from 'moment';
import apiService from 'services/apiService';
import { getUIDateFormat } from 'utils';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import CircleIcon from '@mui/icons-material/Circle';
import { DeferredLoader } from 'components/common/DeferredLoader';
import {orderBy} from 'lodash';

import "./FinanceDashboard.css";

function getUIDateTimeFormat(dt: string) {
    // parse the date
    const date = new Date(dt);

    return `${date.toLocaleDateString()} ${date.toLocaleTimeString(undefined, {hour: '2-digit', minute: '2-digit'})}`.replace("AM","").replace("PM","");
}

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => void;
}

const spreadsheetRoutes = [
    // {
    //     path: 'cpt-averages-uploads',
    //     label: "CPT Average Uploads",
    //     handle: 'cpt-averages-uploads',
    //     templatePath: '/cpt-averates-uploads',
    //     element: <DeferredLoader
    //     requiredUrl="/finance/cpt-averages-uploads"
    // ><UploaderTemplate 
    //         humanReadableName="CPT Averages"
    //         dbName="financial_reports"
    //         tableName="cpt_averages"
    //         columns={[
    //             `cpt_code`,
    //             `average_dollar`,
    //         ]}
    //         /></DeferredLoader>,
    // },
    {
        path: 'cpt-uploads',
        label: "CPT Uploads",
        handle: 'cpt-uploads',
        templatePath: '/cpt-uploads',
        element: <DeferredLoader
        requiredUrl="/finance/cpt-uploads"
    ><UploaderTemplate 
            humanReadableName="CPT Codes"
            dbName="financial_reports"
            tableName="cpt_map"
            columns={[
                `cpt_code`,
                `cpt_code_description`,
                `cpt_group`,
                `clinical_band`,
                `ov` ,
                `central_lab`,
                `intacct`,
                `comp_Cat`,
                `cdss`,
            ]}
            /></DeferredLoader>,
    },
    {
        path: 'customer-cpt-historical-uploads',
        label: "Customer CPT Historical Uploads",
        handle: 'customer-cpt-historical-uploads',
        templatePath: '/customer-cpt-historical-uploads',
        element: <DeferredLoader
        requiredUrl="/finance/customer-cpt-historical-uploads"
    ><UploaderTemplate 
            humanReadableName="Customer CPT Historical"
            dbName="financial_reports"
            tableName="customer_cpt_historical"
            columns={[
                `yearmonth`,
                `customer_cpt`,
                `average_of_dollar`,
            ]}
            /></DeferredLoader>,
    },
    {
        path: 'fee-schedule-uploads',
        label: "Fee Schedule",
        handle: 'fee-schedule-uploads',
        templatePath: '/fee-schedule-uploads',
        element: <DeferredLoader
        requiredUrl="/finance/fee-schedule-uploads">
            <UploaderTemplate 
                humanReadableName="Fee Schedules"
                dbName="financial_reports"
                tableName="fee_schedule"
                columns={[
                    `payer`,
                    `cpt_code`,
                    `modifiers`,
                    'amount',
                    'radiology',
                    'start_date',
                    'end_date'
                ]}
            />
        </DeferredLoader>
    },
    // {
    //     path: 'historical-CPT-payment-uploads',
    //     label: "Historical CPT Payment Uploads",
    //     handle: 'historical-cpt-payment-uploads',
    //     templatePath: '/historical-cpt-payment-uploads',
    //     element: <DeferredLoader
    //     requiredUrl="finance/historical-CPT-payment-uploads"
    // ><UploaderTemplate 
    //         humanReadableName="Historical CPT Payment"
    //         dbName="financial_reports"
    //         tableName="historical_customer_cpt_payments"
    //         columns={[
    //             `year_month`,
    //             `customer`,
    //             `cpt_group`,
    //             `ins_cpt`,
    //             `cpt`,
    //             `paid`
    //         ]}
    //         /></DeferredLoader>,
    // },
    {
        path: 'intacct-customer-map-uploads',
        label: "Intacct Customer Map",
        handle: 'intacct-customer-map-uploads',
        templatePath: '/intacct-customer-map-uploads',
        element: <DeferredLoader
        requiredUrl="/finance/intacct-customer-map-uploads">
            <UploaderTemplate 
                humanReadableName="Intacct Customer Map"
                dbName="financial_reports"
                tableName="intacct_customer_map"
                columns={[
                    `intacct_customer`,
                    `name`,
                ]}
            />
        </DeferredLoader>
    },
    {
        path: 'location-uploads',
        label: "Location Uploads",
        handle: 'location-uploads',
        templatePath: '/location-uploads',
        element: <DeferredLoader
            requiredUrl="/finance/location-uploads"
        ><UploaderTemplate 
            humanReadableName="Location Uploads"
            dbName="financial_reports"
            tableName="locations"
            columns={[
                "facility_id",
                "intacct_location_id",
                "name",
                "short_name",
            ]}
        /></DeferredLoader>,
    },
    {
        path: 'payer-uploads',
        label: "Payer Uploads",
        handle: 'payer-uploads',
        templatePath: '/payer-uploads',
        element: <DeferredLoader
            requiredUrl="/finance/payer-uploads"
        ><UploaderTemplate 
            humanReadableName="Payer Uploads"
            dbName="financial_reports"
            tableName="payers"
            columns={[
                "insurance_name",
                "ins_id",
                "intacct_account",
                "intacct_customer",
                "lab",
            ]}
        /></DeferredLoader>,
    },
    {
        path: 'provider-uploads',
        label: "Provider Uploads",
        handle: 'provider-uploads',
        templatePath: '/provider-uploads',
        element: <DeferredLoader
            requiredUrl="/finance/provider-uploads"
        ><UploaderTemplate 
            humanReadableName="Provider Uploads"
            dbName="financial_reports"
            tableName="providers"
            columns={[
                "provider_id",
                "provider_npi",
                "appointment_provider_name",
                "intacct_dept",
                "prov_type_status",
                "provider_id2",
                "provider_location",
                "employee_id",
            ]}
        /></DeferredLoader>,
    }
];

export const financeDashboardPageRoute = 
    {
        path: 'finance',
        label: "Finance Dashboard",
        handle: 'finance',
        templatePath: '/finance',
        element: <FinanceDashboardPage />,
        children: [
            {
                path: 'dashboard',
                label: "Finance Dashboard",
                handle: 'finance',
                templatePath: '/finance-dashboard-landing-page',
                element: <FinanceDashboardPageLandingPage />,
            },
            ...spreadsheetRoutes
        ]
    };

export function FinanceDashboardPage() {

    return <>
        <Stack direction="row" spacing={2}  width="100vw">
            <Paper elevation={3} style={{width: "20%", maxWidth: "300px", height: "calc(100vh - 50px)"}}>
            <Stack direction="column" spacing={2} m={2}>
                <Box>
                    <Typography variant="h6">Reports</Typography>
                    <Stack direction="column" spacing={2}>
                        <List className="nav-list">
                            <ListItem>
                                <Link className="nav-list-link" to="dashboard">Home (The Report)</Link>
                            </ListItem>
                        </List>
                    </Stack>
                </Box>
                <Box>
                    <Typography variant="h6">Spreadsheets</Typography>
                    <Stack direction="column" spacing={2}>
                    <List>
                        {
                            spreadsheetRoutes.map((route, index) => {
                                return <ListItem key={`spreadsheet-routes-${index}`}>
                                        <Link className="nav-list-link" to={route.path}>{route.label}</Link>
                                    </ListItem>;
                            })
                        }
                        </List>
                    </Stack>
                </Box>
            </Stack>
            </Paper>
            <Stack direction="column">
                <Outlet />
            </Stack>
        </Stack>
    </>
}

const reportGenerations = [
    {
        id: 'historical-cpt-payment',
        label: "Historical CPT Payment",
    },
    {
        id: 'claims-detail-all',
        label: "Claims Detail All",
    },
    {
        id: 'claims-detail-all-non-med',
        label: "Claims Detail All Non-Med Items",
    },
    {
        id: 'payments-at-cpt-level-with-payers',
        label: "Payments at CPT Level with Payers",
    },
    {
        id: 'customer-bad-debt-averages',
        label: "Customer Bad Debt Averages",
    },
    {
        id: 'revenue-detail',
        label: 'Revenue Report',
    }

];

const defaultStartDate = new Date();

defaultStartDate.setMonth(defaultStartDate.getMonth() - 1);
defaultStartDate.setHours(0,0,0,0);
defaultStartDate.setDate(1);

// default end time is the end of the month of the defaultStartDate
const defaultEndDate = new Date(defaultStartDate);
defaultEndDate.setMonth(defaultEndDate.getMonth()+1);
defaultEndDate.setDate(0);

const dbName = 'mobiledoc';
const tableName = 'edi_invoice';

function FinanceDashboardPageLandingPage() {
    const [selectedReports, setSelectedReports] = useState<string[]>(['the-whole-nine-yards',...reportGenerations.map(r => r.id)]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [dbtTable, setDbtTable] = useState<DbtTable | undefined>(undefined);
    const [minStartDate, setMinStartDate] = useState<Date|undefined>(undefined);
    const [maxEndDate, setMaxEndDate] = useState<Date|undefined>(undefined);
    const [startDate, setStartDate] = useState<Date>(defaultStartDate);
    const [endDate, setEndDate] = useState<Date>(defaultEndDate);

    const [historicalReports, setHistoricalReports] = useState<ReportIntervalDates[]>([]);
    const [filteredHistoricalReports, setFilteredHistoricalReports] = useState<ReportIntervalDates[]>([]);

    useEffect(() => {
        if(dbtTable){
            return;
        }
        apiService.getApi()
        .report.reportControllerGetResource()
        .then((response) => {
            const resource = response.data;
            const tables = resource.tables;
            
            const dbtTable = tables.find((table: DbtTable) => {
                return table.dbName === dbName && table.name === tableName;
            });
            if(dbtTable){
                setDbtTable(dbtTable);
            }
        })
        .catch(error => {
            console.error(error);
        });
    }, [dbtTable]);

    useEffect(() => {

        if(historicalReports.length){
            const start = page * rowsPerPage;
            const end = start + rowsPerPage;
            setFilteredHistoricalReports(orderBy(historicalReports, ['runDate'], ['desc']).slice(start, end));
        }

    }, [historicalReports, page, rowsPerPage])

    useEffect(() => {
        if(historicalReports.length){
            return;
        }

        apiService.getApi()
        .report.financeControllerGetReportDates()
        .then((response) => {

            const historicalReports = response.data;
            setHistoricalReports(historicalReports);

        })
        .catch(error => {
            console.error(error);
        });

    }, [historicalReports]);

    useEffect(() => {
        //financeControllerGetTableFirstAndLastDate
        if(minStartDate && maxEndDate){
            return;
        }

        apiService
        .getApi()
        .report.financeControllerGetTableFirstAndLastDate(dbName, tableName)
        .then((response) => {
            const {startDate, endDate} = response.data;
            setMinStartDate(moment(startDate).toDate());
            setMaxEndDate(moment(endDate).toDate());
        })

    }, [minStartDate, maxEndDate]);

    function downloadTheReportById(reportRunId: number, startDate, endDate) {
        apiService.getApi()
        .report                                       
        .financeControllerDownloadExcelFileFromS3(reportRunId)
        .then(async (response: any) => {
            const fileName = `finance-report-${startDate}-${endDate}.xlsx`;
            const blob = await response.blob();
            const href = URL.createObjectURL(blob!);
            const link = document.createElement("a");
            link.href = href;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        })
        .catch(error => {
            console.error(`Error downloading report`, error);
        });
    }

    async function generateReports() {
        // using the start and end date, let us generate the report
        const reports = selectedReports.filter(report => report !== 'the-whole-nine-yards');
        
        const fmtdStartDate = moment(startDate).format("YYYY-MM-DD");
        const fmtdEndDate = moment(endDate).format("YYYY-MM-DD");

        // debugger;

        await apiService.getApi()
        .report.financeControllerGenerateReport(
            fmtdStartDate, 
            fmtdEndDate,
            {
                reportNames: reports,
                startDate:fmtdStartDate, 
                endDate: fmtdEndDate,
            }
        )
        .then(async (response) => {
            const fileName = `finance-report-${fmtdStartDate}-${fmtdEndDate}.xlsx`;
            const blob = await response.blob();
            const href = URL.createObjectURL(blob!);
            const link = document.createElement("a");
            link.href = href;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            // reload the historical reports
            setHistoricalReports([]);
        })
        .catch(error => {
            console.error(`Error generating report`, error);
        });
        
    }

    function TablePaginationActions(props: TablePaginationActionsProps) {
        const theme = useTheme();
        const { count, page, rowsPerPage, onPageChange } = props;
      
        const handleFirstPageButtonClick = (
          event: React.MouseEvent<HTMLButtonElement>,
        ) => {
          onPageChange(event, 0);
        };
      
        const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
          onPageChange(event, page - 1);
        };
      
        const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
          onPageChange(event, page + 1);
        };
      
        const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
          onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };
      
        return (
          <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
              onClick={handleFirstPageButtonClick}
              disabled={page === 0}
              aria-label="first page"
            >
              {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
              onClick={handleBackButtonClick}
              disabled={page === 0}
              aria-label="previous page"
            >
              {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
              onClick={handleNextButtonClick}
              disabled={page >= Math.ceil(count / rowsPerPage) - 1}
              aria-label="next page"
            >
              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
              onClick={handleLastPageButtonClick}
              disabled={page >= Math.ceil(count / rowsPerPage) - 1}
              aria-label="last page"
            >
              {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
          </Box>
        );
      }

      const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
      ) => {
        setPage(newPage);
      };
      const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };
    

    return <Box>
        <Stack direction="column" spacing={2} width="100%">
            {/* listen, this is just terrible. We need a component to get this styling consistent :D */}
            <Box
                display="flex"
                flexDirection="column"
                width="100%"
                px={3}
                py={1.5}
                minHeight="60px"
                justifyContent="center"
                fontSize="20px"
                >
                    <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                        <Stack direction="row" spacing="sm" alignItems="center">
                            <Typography variant="h5">Finance Dashboard</Typography>
                        </Stack>
                    </Stack>
            </Box>
            <Box>
                <Stack direction="row" spacing={2}>
                    <Box sx={{ width: '60%' }}>
                        <Box sx={{ bgcolor: 'background.paper', p: 4 }}>
                        <List>
                            <Typography variant="h6">Monthly Finance Report</Typography>
                            <Box>
                                <p>
                                    The monthly finance report is a summary of the financial data for the month.
                                    It includes the following sub-reports.
                                </p>
                            </Box>
                            <ListItem
                                key={'everything-everywhere-all-at-once'}
                                disablePadding
                            >
                                <ListItemButton role={undefined} dense>
                                    <ListItemIcon>
                                        <AnalyticsIcon />
                                    </ListItemIcon>
                                    <ListItemText id="everything-everywhere-all-at-once" primary="Included in the Finance Report" />
                                </ListItemButton>
                            </ListItem>
                            
                            {reportGenerations.map((report, index) => {

                                const labelId = `checkbox-list-label-${report.id}`;

                                        return (
                                        <ListItem
                                            key={report.id}
                                        >
                                            <ListItemButton role={undefined} dense>
                                                <ListItemIcon>
                                                    <CircleIcon />
                                                </ListItemIcon>
                                                <ListItemText id={labelId} primary={report.label} />
                                            </ListItemButton>
                                        </ListItem>);

                            })}
                            
                        </List>
                        <Divider variant="fullWidth" />
                        <Stack direction="row" spacing={2} sx={{m:4}}>
                        <TextField
                            id="start-date"
                            label="Start Date"
                            type="date"
                            inputProps={{ min: minStartDate, max: maxEndDate }}
                            onChange={
                                (e) => {
                                    setStartDate(moment(e.target.value).toDate());
                                }
                            }
                            defaultValue={defaultStartDate.toISOString().substring(0, 10)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <TextField
                            id="end-date"
                            label="End Date"
                            type="date"
                            onChange={
                                (e) => {
                                    setEndDate(moment(e.target.value).toDate());
                                }
                            }
                            defaultValue={defaultEndDate.toISOString().substring(0, 10)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <Button
                            variant="contained"
                            onClick={
                                () => {
                                    generateReports();
                                }
                            }
                            disabled={!startDate || !endDate || !minStartDate || !maxEndDate || startDate > maxEndDate || endDate > maxEndDate || startDate < minStartDate || endDate < minStartDate}
                        >
                            Generate Report
                        </Button>
                        <Box sx={{verticalAlign: "center"}}>
                            {!minStartDate || !maxEndDate ? "" : startDate > maxEndDate ? "Start date is after the last invoice date" : endDate > maxEndDate ? "End date is after the last invoice date" : startDate < minStartDate ? "Start date is before the first invoice date" : endDate < minStartDate ? "End date is before the first invoice date" : ""}
                        </Box>
                        </Stack>
                        <Box>
                            {minStartDate && maxEndDate ? <p>Invoice Date Range Available: {minStartDate?.toISOString().substring(0, 10)} - {maxEndDate?.toISOString().substring(0, 10)}</p> : <p>Loading valid date ranges.</p>}
                        </Box>
                        
                        </Box>
                    </Box>
                    
                    <Box width="40%">
                        <TableContainer sx={{ p:2 }}>
                        <Typography variant="h6">Past Reports</Typography>
                        <Box>
                            <p>
                                Past runs of the reports. Click on the report to download the spreadsheets.
                            </p>
                        </Box>
                            <Table component={Paper}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell width="25%">Run Date</TableCell>
                                        <TableCell width="50%">Start Date - End Date</TableCell>
                                        <TableCell width="25%">Download</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {
                                    filteredHistoricalReports.map((report, index) => {
                                        return  <TableRow  key={`report-run-row-${index}`}>
                                                    <TableCell width="33%">
                                                        {getUIDateTimeFormat(report.runDate)}
                                                    </TableCell>
                                                    <TableCell width="42%">
                                                        {getUIDateFormat(report.startDate)} - {getUIDateFormat(report.endDate)}
                                                    </TableCell>
                                                    <TableCell width="25%">
                                                        <a href={`/report/${report.reportRunId}`} onClick={(e) => {
                                                            e.preventDefault();
                                                            downloadTheReportById(report.reportRunId, report.startDate, report.endDate);
                                                        }} download={`finance-report-${report.runDate}.xlsx`}>Download</a>
                                                    </TableCell>
                                                </TableRow>;
                                    })
                                }
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                            colSpan={3}
                                            count={historicalReports.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActions}
                                            />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </Box>
                </Stack>
            </Box>
        </Stack>
    </Box>
}