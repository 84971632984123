import { EmailableReport } from "api/Api";
import { useEffect, useState } from "react";
import apiService from "services/apiService";

export function useEmailableReports(): [EmailableReport[], boolean, Function] {
  const [emailableReports, setEmailableReports] = useState<EmailableReport[]>([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (emailableReports.length > 0) {
      return;
    }
    setLoading(true);
    // fetch reports from the database
    apiService
      .getApi()
      .admin.emailReportControllerGetEmailableReports()
      .then((results) => {
        setEmailableReports(results.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, [emailableReports, loading]);
  function reloadEmailableReports() {
    setEmailableReports([]);
  }

  return [emailableReports, loading, reloadEmailableReports];
}
