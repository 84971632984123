import { Box, Button, Dialog, Stack, Tab, TabContext, TabList } from "components";

import { useContext, useEffect, useState } from "react";
import { deepCopy, newToast, typecheck } from "services";
import { ReportContext } from "../ReportContextProvider";
import { QueryRequest, QuerySqlResult } from "api/Api";
import apiService from "services/apiService";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { prism } from "react-syntax-highlighter/dist/esm/styles/prism";
import { format as sqlFormatter } from "sql-formatter";
import { useDispatch } from "react-redux";
import { setAppToast } from "redux/reducers/appSlice";

type NavTab = "Data" | "Total";
export const InspectSql = (props: { queryRequest: QueryRequest; close: () => void }) => {
  const reportContext = useContext(ReportContext);
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState<NavTab>("Data");
  const [sql, setSql] = useState<QuerySqlResult>();

  const transparentPrism = {
    ...prism,
    'pre[class*="language-"]': {
      ...prism['pre[class*="language-"]'],
      background: "transparent",
    },
    'code[class*="language-"]': {
      ...prism['code[class*="language-"]'],
      background: "transparent",
    },
  };

  useEffect(() => {
    fetch(props.queryRequest);
  }, []);

  function fetch(queryRequest: QueryRequest) {
    let postingQueryRequest = deepCopy(queryRequest);

    postingQueryRequest.filters = postingQueryRequest.filters?.filter((filter) =>
      reportContext.validateFilter(filter, "boolean")
    );

    apiService
      .getApi()
      .report.reportControllerQuerySql(postingQueryRequest)
      .then((response) => {
        setSql(response.data);
      });
  }

  const copyToClipboard = () => {
    // Directly assign the SQL query string based on the current tab, without using JSON.stringify
    const textToCopy = currentTab === "Data" ? sql?.dataSql : sql?.totalSql;
    if (textToCopy && navigator.clipboard) {
      navigator.clipboard.writeText(textToCopy).then(
        () => dispatch(setAppToast(newToast("success", "Copied to Clipboard"))),
        (err) => dispatch(setAppToast(newToast("error", "Unable to copy to Clipboard")))
      );
    } else {
      // Fallback for browsers that don't support the Clipboard API
      const textarea = document.createElement("textarea");
      textarea.value = textToCopy || ""; // Ensure there's a string to avoid null/undefined
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
    }
  };

  return (
    <Dialog
      open={true}
      onClose={() => props.close()}
      maxWidth="md"
      content={
        <Box>
          <TabContext value={currentTab}>
            <TabList
              sx={{ width: "100%", borderBottom: "1px solid #e8e8e8" }}
              onChange={(e, value) => setCurrentTab(value)}
              variant="fullWidth"
            >
              <Tab label={typecheck<NavTab>("Data")} value={typecheck<NavTab>("Data")} />
              <Tab label={typecheck<NavTab>("Total")} value={typecheck<NavTab>("Total")} />
            </TabList>

            {sql && (
              <>
                {currentTab === "Data" && (
                  <SyntaxHighlighter language="sql" style={transparentPrism}>
                    {sqlFormatter(sql?.dataSql, { language: "mysql" })}
                  </SyntaxHighlighter>
                )}

                {currentTab === "Total" && (
                  <SyntaxHighlighter language="sql" style={transparentPrism}>
                    {sqlFormatter(sql?.totalSql, { language: "mysql" })}
                  </SyntaxHighlighter>
                )}
              </>
            )}
          </TabContext>
        </Box>
      }
      footer={
        <Stack direction="row" spacing="sm">
          <Button variant="outlined" onClick={() => copyToClipboard()}>
            Copy to Clipboard
          </Button>

          <Button variant="outlined" onClick={() => props.close()}>
            Close
          </Button>
        </Stack>
      }
    ></Dialog>
  );
};
