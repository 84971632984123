import { TerminationReport } from "api/Api";
import { TerminationFilters } from "../../TurnoverReportData";
import { rangeArrayGenerator } from "utils";
import { Box, Stack } from "components";
import { ReactElement, useState } from "react";

export function AnnualTerminationReportByTitle({terminationData, filters}: {terminationData: TerminationReport; filters: TerminationFilters;}) {
    const [hoverRow, setHoverRow] = useState<number | undefined>(undefined);
    const jobTitleData: {
        [jobTitle:string]: any;
    } = terminationData.yearlyTerminationsByJobTitle;

    // convert the filter dates to to a year range
    const yearRange = rangeArrayGenerator(filters.startDate.getFullYear(), filters.endDate.getFullYear(), 1);
    const columnWidth = 75 / (yearRange.length + 2);

    return <>
        <Stack direction="row">
            <Titles jobTitleData={jobTitleData} hoverRow={hoverRow} setHoverRow={setHoverRow} />
            <Terminations yearRange={yearRange} columnWidth={columnWidth} jobTitleData={jobTitleData} filters={filters} hoverRow={hoverRow} setHoverRow={setHoverRow} />
            <EmployeeCount yearRange={yearRange} columnWidth={columnWidth} terminationData={terminationData} hoverRow={hoverRow} setHoverRow={setHoverRow} />
            <TurnoverRatio yearRange={yearRange} columnWidth={columnWidth} terminationData={terminationData} hoverRow={hoverRow} setHoverRow={setHoverRow} />
        </Stack>
    </>
}

function Titles({jobTitleData, hoverRow, setHoverRow}: {jobTitleData:{ [jobTitle: string]: any; }; hoverRow: number | undefined; setHoverRow: React.Dispatch<React.SetStateAction<number | undefined>>;}) {
    return <Box className="grouped-by-field">
        <h3>By Title</h3>
        <table>
            <thead>
                <tr>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {Object.keys(jobTitleData).map((title, index) => {
                    return <tr 
                    className={`${index === hoverRow ? 'hovered':''}`}
                    onMouseOver={(e)=> {
                        setHoverRow(index);
                    }} 
                    onMouseOut={(e)=> {
                        setHoverRow(undefined);
                    }}
                    key={`atrbt-titles-${index}`}>
                        <th style={{ width: "100%", textAlign: "left", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{title}</th>
                    </tr>;
                })}
            </tbody>
            <tfoot>
                <tr>
                    <th style={{ width: "100%", textAlign: "left", overflow: "hidden", whiteSpace: "nowrap" }}>Totals</th>
                </tr>
            </tfoot>
        </table>
    </Box>;
}

function Terminations({yearRange, columnWidth, jobTitleData, filters, hoverRow, setHoverRow}: {yearRange: number[]; columnWidth: number; jobTitleData: { [jobTitle: string]: any; }, filters: TerminationFilters; hoverRow: number | undefined; setHoverRow: React.Dispatch<React.SetStateAction<number | undefined>>; }) {
    // totals and grand total of terminations
    const totals: { [year: number]: number; } = getTerminationTotals(yearRange, jobTitleData);

    const grandTotal = Object.values(totals).reduce((acc, total) => acc + total, 0);
    
    return <Box className="data-presentation termination">
        <h3>Terminations</h3>
        <table>
            <thead>
                <tr>
                    {yearRange.map((year, index) => <th style={{ width: `${columnWidth}px` }} key={`atrbt-terminations-year-${index}`}>{year}</th>)}
                    <th>Total</th>
                </tr>
            </thead>
            <tbody>
                {Object.keys(jobTitleData).reduce((locAcc: React.ReactElement[], jobTitle, index) => {
                    const jobTitleGroupData = jobTitleData[jobTitle];
                    let jobTitleTotal = 0;
                    locAcc.push(<tr 
                                    className={`${index === hoverRow ? 'hovered':''}`}
                                    onMouseOver={(e)=> {
                                        setHoverRow(index);
                                    }} 
                                    onMouseOut={(e)=> {
                                        setHoverRow(undefined);
                                    }}
                                    key={`atrbt-terminations-${index}`}>
                        {yearRange.reduce((yearAcc: ReactElement[], year) => {
                            jobTitleTotal += typeof jobTitleGroupData[year] !== 'number' ? parseInt(jobTitleGroupData[year]) : jobTitleGroupData[year];
                            yearAcc.push(
                                <td style={{ width: `${columnWidth}px`, textAlign: "right", color: (jobTitleGroupData[year] === 0 ? '#777' : '#000') }}  key={`atrbt-terminations-count-${year}`}>{jobTitleGroupData[year]}</td>);
                            return yearAcc;
                        }, [])}
                        <td style={{ textAlign: "right", width: `${columnWidth}px`, color: (jobTitleTotal === 0 ? '#777' : '#000') }}>{jobTitleTotal}</td>
                    </tr>);
                    return locAcc;
                }, [])}
            </tbody>
            <tfoot>
                <tr>
                    {yearRange.reduce((yearAcc: ReactElement[], year) => {
                        yearAcc.push(
                            <th style={{ width: `${columnWidth}px`, textAlign: "right", color: (totals[year] === 0 ? '#777' : '#000') }}  key={`atrbt-employee-count-year-${year}`}>{totals[year]}</th>);
                        return yearAcc;
                    }, [])}
                    <th style={{ width: `${columnWidth}px`, textAlign: "right", color: (grandTotal === 0 ? '#777' : '#000') }}>{grandTotal}</th>
                </tr>
            </tfoot>
        </table>
    </Box>;
}

function EmployeeCount({yearRange, columnWidth, terminationData, hoverRow, setHoverRow}: {yearRange: number[]; columnWidth: number; terminationData: TerminationReport; hoverRow: number | undefined; setHoverRow: React.Dispatch<React.SetStateAction<number | undefined>>;}) {
    // totals
    const totals: { [year: number]: number; } = getEmployeeCountTotals(yearRange, terminationData);

    return <Box className="data-presentation employee-count">
        <h3>Average Employee Count</h3>
        <table>
            <thead>
                <tr>
                    {yearRange.map((year, index) => <th style={{ width: `${columnWidth}px` }}  key={`atrbt-employee-count-year-values-${index}`}>{year}</th>)}
                </tr>
            </thead>
            <tbody>
                {Object.keys(terminationData.yearlyAverageEmployeesByTitle).map((jobTitle, index) => {
                    const employeeData = terminationData.yearlyAverageEmployeesByTitle[jobTitle];

                    return <tr
                    className={`${index === hoverRow ? 'hovered':''}`}
                    onMouseOver={(e)=> {
                        setHoverRow(index);
                    }} 
                    onMouseOut={(e)=> {
                        setHoverRow(undefined);
                    }}
                    key={`atrbt-employee-count-${index}`}
                    >
                        {yearRange.map((year, yidx) => {
                            const employeeCount = parseFloat(employeeData[year]);
                            return <td key={`ytrbd${yidx}${index}`} style={{ width: `${columnWidth}px`, textAlign: "right", color: (employeeCount === 0 ? '#777' : '#000') }}>{employeeCount}</td>;
                        })}
                    </tr>;
                })}
            </tbody>
            <tfoot>
                <tr>
                {
                    Object.values(totals).map((total, yidx) => {
                        return <th key={`ytrbd-totals-${yidx}`} style={{width: `${columnWidth}px`, textAlign: "right", color: (total === 0 ? '#777' : '#000')}}>{total}</th>;
                    })
                }
                </tr>
            </tfoot>
        </table>
    </Box>;
}

function TurnoverRatio({yearRange, columnWidth, terminationData, hoverRow, setHoverRow}: {
    yearRange: number[];
    columnWidth: number;
    terminationData: TerminationReport;
    hoverRow: number | undefined;
    setHoverRow: Function;
}) {
    // get the totals
    const terminationTotals = getTerminationTotals(yearRange, terminationData.yearlyTerminationsByJobTitle);
    const employeeCountTotals = getEmployeeCountTotals(yearRange, terminationData);
    const turnoverRateTotals = getTurnoverRateTotals(terminationTotals, employeeCountTotals);
    return <Box className="data-presentation turnover-rate">
        <h3>Turnover Rate</h3>
        <table>
            <thead>
                <tr>
                    {yearRange.map((year, index) => <th style={{ width: `${columnWidth}px` }}  key={`atrbt-ratios-year-${index}`}>{year}</th>)}
                </tr>
            </thead>
            <tbody>
                {Object.keys(terminationData.yearlyTurnoverRateByJobTitle).map((jobTitle, index) => {
                    const turnoverData = terminationData.yearlyTurnoverRateByJobTitle[jobTitle];

                    return <tr
                    className={`${index === hoverRow ? 'hovered':''}`}
                    onMouseOver={(e)=> {
                        setHoverRow(index);
                    }} 
                    onMouseOut={(e)=> {
                        setHoverRow(undefined);
                    }}
                    key={`atrbt-ratios-${index}`}
                    >
                        {yearRange.map((year, yidx) => {
                            const turnoverRate = parseFloat(turnoverData[year]);

                            return <td key={`ytrbd${yidx}${index}`} style={{ width: `${columnWidth}px`, textAlign: "right", color: (turnoverRate === 0 ? '#777' : '#000') }}>{turnoverRate.toFixed(1)}%</td>;
                        })}
                    </tr>;
                })}
            </tbody>
            <tfoot>
                <tr>
                {
                    Object.values(turnoverRateTotals).map((total, yidx) => {
                        return <th key={`ytrbd-totals-${yidx}`} style={{width: `${columnWidth}px`, textAlign: "right", color: (total === 0 ? '#777' : '#000')}}>{total.toFixed(1)}%</th>;
                    })
                }
                </tr>
            </tfoot>
        </table>
    </Box>;
}

function getTerminationTotals(yearRange: number[], jobLocationData: { [location: string]: any; }) {
    return yearRange.reduce((acc, year) => {
        acc[year] = 0;
        Object.keys(jobLocationData).forEach((location) => {
            const locationGroupData = jobLocationData[location];
            acc[year] += typeof locationGroupData[year] !== 'number' ? parseInt(locationGroupData[year]) : locationGroupData[year];
        });
        return acc;
    }, {})
}

function getEmployeeCountTotals(yearRange: number[], terminationData: TerminationReport): { [year: number]: number; } {
    return yearRange.reduce((acc, year) => {
        acc[year] = 0;
        Object.keys(terminationData.yearlyAverageEmployeesByLocation).forEach((location) => {
            const employeeData = terminationData.yearlyAverageEmployeesByLocation[location];
            acc[year] += parseFloat(employeeData[year]);
        });
        return acc;
    }, {});
}

function getTurnoverRateTotals(
    terminationTotals: {[year:number]: number;}, 
    employeeCountTotals: {[year:number]: number;}
): {[year:number]: number;} {
    return Object.entries(terminationTotals).reduce((acc, [year, terminationTotal]) => {
        acc[year] = (terminationTotal / employeeCountTotals[year]) * 100;
        return acc;
    }, {});
}