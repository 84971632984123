import { DateIntervalResults } from "api/Api";
import { useEffect, useState } from "react";
import apiService from "services/apiService";

let lastDbTable = "";

export function useHistoricalData(
  dbName: string,
  tableName: string
): [{ startDate: Date; endDate: Date | undefined }[], boolean, Function] {
  const [isLoading, setIsLoading] = useState(false);
  const [historicDataDates, setHistoricDataDates] = useState<{ startDate: Date; endDate: Date | undefined }[]>([]);
  const loadHistoricalData = () => {
    if (isLoading || lastDbTable === `${dbName}.${tableName}`) {
      return;
    }
    lastDbTable = `${dbName}.${tableName}`;
    apiService
      .getApi()
      .report.financeControllerGetTableDateIntervals(dbName, tableName)
      .then((response: { data: DateIntervalResults; error: any }) => {
        setIsLoading(false);
        const intervalData: { startDate: Date; endDate: Date | undefined }[] = response.data.dateIntervals.map(
          (dates: { startDate: string; endDate: string | undefined }) => {
            return {
              startDate: new Date(dates.startDate),
              endDate: dates.endDate ? new Date(dates.endDate) : undefined,
            };
          }
        );

        setHistoricDataDates(intervalData);
      })
      .catch((error) => {
        console.error("Error fetching historic data dates", error);
      });
    return false;
  };

  useEffect(() => {
    if (historicDataDates.length > 0 || isLoading) {
      return;
    }
    loadHistoricalData();
  });

  return [historicDataDates, isLoading, loadHistoricalData];
}
