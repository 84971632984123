import { Box, Button, Checkbox, Container, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { EmailList } from "api/Api";
import { useEmailLists } from "hooks/email/useEmailLists";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import apiService from "services/apiService";

export function ListManagement() {
    // const [page, setPage] = useState(1);
    // const [pageSize, setPageSize] = useState(100);
    // const [active, setActive] = useState<boolean | undefined>(undefined);
    // const [searchString, setSearchString] = useState<string | undefined>(undefined);
    const [selectedLists, setSelectedLists] = useState<number[]>([]);
    const [lists, loading] = useEmailLists();
    const [filteredEmailList, setFilteredEmailList] = useState<EmailList[]>([]);

    useEffect(() => {

    }, []);

    useEffect(() => {
        if (loading) {
            return;
        }
        setFilteredEmailList(lists);
    }, [lists]);

    function toggleListActiveState(listId: number) {
        const list = lists?.find((list: EmailList) => list.id === listId);
        if(!list){
            console.log('doh! Something has gone horribly wrong. Could not toggle the active state on the list.');
            return;
        }
        apiService
            .getApi()
            .admin
            .emailControllerPostList(
                listId,
                {
                ...list,
                active:!list?.active
                }
            )
            .then(() => {
                if (list) {
                    list.active = !list.active;
                }
            })
        
    }

    function deleteList(listId: number) {
        const list = lists?.find((list: EmailList) => list.id === listId);
        // delete list from the database
        apiService.getApi()
            .admin
            .emailControllerDeleteList(listId).then(
                (resp) => {
                    
                }
            );
    }

    return (<Container>
        <Stack direction="column" spacing="2">
            <Stack direction="row" spacing={2} width="100%">
                <Box width="75%">
                    <Typography variant="h6">List Configuration</Typography>
                </Box>
                <Box style={{textAlign: "right", width: "25%"}}>
                    <Link to="/admin/email-management/list/0" title="Create a new list"><Button>Create New List</Button></Link>
                </Box>
            </Stack>
        
            <Box>
                <Table>
                    <TableHead>
                        <TableCell padding="checkbox">
                            <Checkbox checked={selectedLists.length === filteredEmailList.length} onChange={(e) => {
                                        if(e.target.checked){
                                            setSelectedLists(filteredEmailList.map(report => report.id));
                                        } else {
                                            setSelectedLists([]);
                                        }
                                    }} />
                        </TableCell>
                        <TableCell>
                            List Name
                        </TableCell>
                        <TableCell>
                            Description
                        </TableCell>
                        <TableCell>
                            Active
                        </TableCell>
                        <TableCell>
                            Actions
                        </TableCell>
                    </TableHead>
                    <TableBody>
                    {
                        loading &&
                        <TableRow>
                            <TableCell colSpan={4}>
                                Loading...
                            </TableCell>
                        </TableRow>
                    }
                    {
                        filteredEmailList?.map((list: EmailList, index) => {
                            return (
                                <TableRow key={`list-table-row-${index}`}>
                                    <TableCell padding="checkbox">
                                        <Checkbox />
                                    </TableCell>
                                    <TableCell>
                                        <Link to={`/admin/email-management/list/${list.id}`} title="View or Edit List">{list.name}</Link>
                                    </TableCell>
                                    <TableCell>
                                        {list.description}
                                    </TableCell>
                                    <TableCell>
                                        <a href={`/admin/email-management/lists/set-active-state/${list.id}`} onClick={(e) => {
                                            e.preventDefault();
                                            toggleListActiveState(list.id);
                                        }}>
                                            {list.active ? "Yes" : "No"}
                                        </a>
                                    </TableCell>
                                    <TableCell>
                                        <Stack direction="row" spacing="sm">
                                            <a href={`/admin/email-management/lists/delete/${list.id}`} onClick={(e) => {
                                                e.preventDefault();
                                                deleteList(list.id);
                                            }}>Delete</a>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            );
                        })      
                    }
                    </TableBody>
                </Table>
            </Box>
        </Stack>
    </Container>);
}