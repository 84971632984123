import { Employee } from "api/Api";
import { useEffect, useState } from "react";
import apiService from "services/apiService";

export function useEmployee(email: string | undefined): [Employee | undefined, boolean] {
  const [employee, setEmployee] = useState<Employee | undefined>(undefined);
  const [loadingFacilityId, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (email !== undefined) {
      apiService
        .getApi()
        .employees.employeesControllerGetEmployeeByEmail(email)
        .then((response) => {
          setEmployee(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    }
  }, []);

  return [employee, loadingFacilityId];
}
