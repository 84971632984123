import { Box, Paper, Stack } from "components";
import { ReactNode } from "react";

export const Page = (props: {
  header?: ReactNode;
  nav?: ReactNode;
  isForm?: boolean;
  children?: ReactNode;
  bgColor?: "default" | "paper";
  noPadding?: boolean;
  dialogContent?: boolean;
  maxWidth?: string;
}) => {
  const renderNav = () => {
    return (
      <Paper
        sx={{
          position: "relative",
          //height: "100vh",
          borderRadius: "0px",
          overflow: "auto",
          flexShrink: 0,
          zIndex: (theme) => theme.zIndex.appBar,
        }}
      >
        {props.nav}
      </Paper>
    );
  };
  return (
    <Stack
      direction="row"
      sx={{
        position: !props.dialogContent ? "absolute" : "relative",
        width: "100%",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 100,
        display: "flex",
        //flexDirection: "column",
        background: (theme) =>
          props.bgColor === "paper"
            ? theme.palette.background.paper
            : !props.dialogContent
            ? theme.palette.background.default
            : theme.palette.background.paper,
      }}
      className="page"
    >
      {props.nav && renderNav()}

      <Box display="flex" flexDirection="column" flexGrow={1} height="100%" overflow="auto" alignItems="center">
        <Box
          display="flex"
          flexDirection="column"
          maxWidth={props.maxWidth ? "800px" : "none"}
          width="100%"
          height="100%"
          overflow="auto"
        >
          {props.header && (
            <Box
              display="flex"
              flexDirection="column"
              width="100%"
              px={3}
              py={1.5}
              minHeight="60px"
              justifyContent="center"
              fontSize="20px"
            >
              {props.header}
            </Box>
          )}

          {props.children && (
            <Box
              sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                flexShrink: 1,
                overflow: "auto",

                px: props.noPadding ? 0 : 3,
                pt: props.noPadding ? 0 : 1,
                pb: props.noPadding ? 0 : 3,
              }}
            >
              {props.children}
            </Box>
          )}
        </Box>
      </Box>
    </Stack>
  );
};
