import {Employee, ReportSegment as iReportSegment} from "api/Api";
import { RootState } from "redux/store";
import { AppBar, Box, Container, Stack, Toolbar } from "@mui/material";
import { Button, Input } from "components";
import { useReportSegment } from "hooks/email/segments/useReportSegment";
import { useEffect, useState, useCallback, useRef } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import apiService from "services/apiService";
import { useDispatch } from "react-redux";
import { setAppToast } from "redux/reducers/appSlice";
import { newToast } from "services";
import { DashboardConfiguration } from "./DashboardConfiguration/DashboardConfiguration";
import { PuckConfigurationPanel } from "./PuckConfigurationPanel/PuckConfigurationPanel";
import { getQuerySafeDate } from "utils";

const mostRecentMonday = new Date();
mostRecentMonday.setDate(mostRecentMonday.getDate() - (mostRecentMonday.getDay() + 6) % 7);

export function ReportSegment() {
    console.log("ReportSegment component function called");

    const params = useParams();
    const navigate = useNavigate();
    const segmentId = parseInt(params?.id as string ?? '0');
    const [segment, segmentLoading] = useReportSegment(segmentId);
    const [reader, setReader] = useState<Employee|undefined>(undefined);
    const [readerDate, setReaderDate] = useState<string>(getQuerySafeDate(mostRecentMonday));
    
    const userNavWidth = useSelector((state: RootState) => state.userSetting.navWidth);
    
    const dispatch = useDispatch();
    const [tempSegment, setTempSegment] = useState({
        id: 0,
        name: "",
        description: "",
        reportPuck: {
            sqlQuery: "",
            icon: "",
            puck_target: "",
            puck_target_type: "greater_than"
        },
        dashboards: [{
            name: "",
            sqlQuery: "",
            chartType: "Bar Chart"
        }]
    } as iReportSegment);

    useEffect(() => {
        if(segmentId === 0){
            return;
        }
        if(segment) {
            if(segment?.dashboards?.length === 0){
                console.log('no dashboards')
                setTempSegment({...segment, dashboards: [
                    {
                        name: "",
                        sqlQuery: "",
                        chartType: "Bar Chart"
                    }
                ]});
            }else {
                setTempSegment(segment);
            }
        }
    }, [segmentId, segment, segmentLoading]);

    const saveReportSegment = () => {
        if(tempSegment.id === 0){
            apiService.getApi()
            .admin
            .reportSegmentControllerCreateReportSegment(tempSegment)
            .then((response) => {
                setTempSegment(response.data);
                dispatch(setAppToast(newToast("success", "Report Segment Saved")));
                const newUrl = `/admin/email-management/report-segments/${response.data.id}`;
                // Update URL without navigation
                window.history.replaceState(null, '', newUrl);
            }).catch((error) => {
                console.error(error);
                dispatch(setAppToast(newToast("error", "Error Saving Report Segment")));
            })
        } else {
            apiService.getApi()
            .admin
            .reportSegmentControllerPostReportSegment(tempSegment.id, tempSegment)
            .then((response) => {
                setTempSegment(response.data);
                dispatch(setAppToast(newToast("success", "Report Segment Saved")));
            }).catch((error) => {
                console.error(error);
                dispatch(setAppToast(newToast("error", "Error Saving Report Segment")));
            });
        }
    }

    if(segmentLoading){
        return <div>Loading...</div>
    }

    if(segmentId !== 0 && typeof segment === 'undefined'){
        return <div>Segment not found</div>
    }
    
    return (
        <Container style={{paddingBottom: "80px"}}>
            <Stack spacing={2}>
                <Box>
                    <h1>{tempSegment?.name}</h1>
                </Box>
                <Box>
                    <Stack spacing={2}>
                        <Box>
                            <Input
                                label="Name"
                                value={tempSegment?.name}
                                onChange={(e) => {
                                    setTempSegment({...tempSegment, name: e.target.value});
                                }}
                                />
                        </Box>
                        <Box>
                            <Input 
                                label="Description"
                                multiline={true}
                                value={tempSegment?.description}
                                onChange={(e) => {
                                    setTempSegment({...tempSegment, description: e.target.value});
                                }}
                                />
                        </Box>
                    </Stack>
                </Box>
                <Box>
                    <PuckConfigurationPanel 
                        tempSegment={tempSegment} 
                        setTempSegment={setTempSegment} 
                        reader={reader} 
                        setReader={setReader} 
                        readerDate={readerDate}
                        setReaderDate={setReaderDate}
                        saveReportSegment={saveReportSegment} 
                        />
                </Box>
                <Box>
                    <DashboardConfiguration 
                        tempSegment={tempSegment} 
                        setTempSegment={setTempSegment} 
                        reader={reader} 
                        setReader={setReader}
                        readerDate={readerDate}
                        setReaderDate={setReaderDate}
                        saveReportSegment={saveReportSegment} 
                        />
                </Box>
            </Stack>
        <AppBar position="fixed" sx={{ top: 'auto', bottom: 0, backgroundColor: 'white', boxShadow: 3, boxShadowLeft: 0, left: `${(userNavWidth ? userNavWidth+0 : '300')}px` }}>
        <Toolbar>
            <Button color="primary" variant="outlined" onClick={()=>{
                saveReportSegment();
            }}>
                Save
            </Button>
        </Toolbar>
        </AppBar>
    </Container>)
}
