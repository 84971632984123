import { Box, Container, Stack, Typography } from "@mui/material";
import { useFacilities } from "hooks/useFacilities";
import { Link } from "react-router-dom";
import { FacilityReportPicker } from "../FacilityReports/FacilityReportPicker";


export function EmailManagementDashboard() {
    
    return (<Container>
        <Stack direction="column" spacing={2}>
            <Box>
                <Typography variant="h3">Email Management</Typography>
            </Box>
            <Box>
                To build email reports you will need to configure lists of recipients, reports, and the segments (or sections) of those reports that you want to include in the email.
            </Box>
            <Stack direction="row" spacing={2}>
                <Box>
                    <Link to={`/admin/email-management/lists`}>Manage the Lists</Link>
                </Box>
                <Box>
                    <Link to={`/admin/email-management/reports`}>Manage the Reports</Link>
                </Box>
                <Box>
                    <Link to={`/admin/email-management/segments`}>Manage the Segments of a Report</Link>
                </Box>
            </Stack>
            <Stack>
                <Box>
                    <Typography variant="h5">Reports By Location</Typography>
                </Box>
                <FacilityReportPicker />
            </Stack>
        </Stack>
    </Container>);
}