import { Theme } from "@mui/material/styles";

// ----------------------------------------------------------------------

export const Table = (theme: Theme) => {
  return {
    MuiTableContainer: {
      styleOverrides: {
        root: {
          border: `1px solid ${theme.palette.border.main}`,
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          //borderCollapse: "separate",
          //border: `1px solid ${theme.palette.border.main}`,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: "none",

          color: "black !important",
          //borderColor: theme.palette.border.light
          borderRight: `1px solid ${theme.palette.border.main}`,
        },
        head: {
          height: "48px !important",
          color: theme.palette.text.secondary,
          //backgroundColor: "transparent",
          backgroundColor: theme.palette.background.paper,
          //borderTop: `1px solid ${theme.palette.border.main}`,
          borderBottom: `1px solid ${theme.palette.border.main}`,

          paddingTop: theme.spacing(0.5),
          paddingBottom: theme.spacing(0.5),

          "&:first-of-type": {
            //paddingLeft: theme.spacing(3),
            // borderTopLeftRadius: theme.shape.borderRadius,
            // borderBottomLeftRadius: theme.shape.borderRadius,
            //boxShadow: `inset 8px 0 0 ${theme.palette.background.paper}`,
          },
          "&:last-of-type": {
            //paddingRight: theme.spacing(3),
            // borderTopRightRadius: theme.shape.borderRadius,
            // borderBottomRightRadius: theme.shape.borderRadius,
            //boxShadow: `inset -8px 0 0 ${theme.palette.background.paper}`,
          },
        },
        body: {
          height: 48,
          paddingTop: theme.spacing(0.5),
          paddingBottom: theme.spacing(0.5),
          borderBottom: `1px solid ${theme.palette.border.main}`,

          "&:first-of-type": {
            paddingLeft: theme.spacing(3),
          },
          "&:last-of-type": {
            paddingRight: theme.spacing(3),
          },
        },
      },
    },
  };
};
