import { Box, Chip, Stack, Tooltip, Typography } from "components";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import CancelIcon from "@mui/icons-material/Cancel";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IconButton, Popover, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { SortField } from "api/Api";
import produce from "immer";
import { useContext, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { getSpacing } from "theme";
import { ReportContext } from "../ReportContextProvider";
import { getFieldFullIdentifier } from "../utils/utils";

export const SortSummary = (props: { sortFields: SortField[]; setSortFields: (sortFields: SortField[]) => void }) => {
  const reportContext = useContext(ReportContext);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items = Array.from(props.sortFields);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    props.setSortFields(items);
  };

  return (
    <>
      <Chip
        label={
          <Stack direction="row" spacing="xs" alignItems="center">
            <Typography variant="inherit">Sorting by {props.sortFields.length} fields</Typography>
            <ExpandMoreIcon fontSize={"small"} />
          </Stack>
        }
        onClick={(e) => setMenuAnchorEl(e.currentTarget)}
        size="small"
        color="success"
      />

      {/* <IconButton onClick={(e) => setMenuAnchorEl(e.currentTarget)}>click</IconButton> */}

      <Popover
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={() => setMenuAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box px={getSpacing("md")} py={getSpacing("md")}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="sortSummaryDroppable">
              {(provided) => (
                <Stack ref={provided.innerRef} {...provided.droppableProps} spacing="sm">
                  {props.sortFields.map((sortField, index) => {
                    const fieldKey = getFieldFullIdentifier(sortField.field);

                    return (
                      <Draggable key={fieldKey} draggableId={fieldKey} index={index}>
                        {(provided) => (
                          <div ref={provided.innerRef} {...provided.draggableProps}>
                            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing="md">
                              <Stack direction="row" alignItems="center" spacing="sm">
                                <Tooltip title="Drag field to reorder">
                                  <Box ref={provided.innerRef} {...provided.dragHandleProps} lineHeight="100%">
                                    <DragIndicatorIcon />
                                  </Box>
                                </Tooltip>

                                <Typography variant="inherit" flexGrow={1}>
                                  {reportContext.getFieldDisplay(sortField.field, "string")}
                                </Typography>
                              </Stack>

                              {/* <Typography variant="inherit">{sortField.desc ? "Desc" : "Asc"}</Typography> */}

                              <Stack direction="row" alignItems="center" spacing="sm">
                                <ToggleButtonGroup
                                  value={sortField.desc}
                                  exclusive
                                  onChange={(e, value: boolean | undefined) =>
                                    props.setSortFields(
                                      produce(props.sortFields, (draft) => {
                                        if (value !== undefined) draft[index].desc = value;
                                      })
                                    )
                                  }
                                  size="small"
                                >
                                  <ToggleButton value={false} sx={{ textTransform: "none" }}>
                                    <Stack direction="row" alignItems="center">
                                      <ArrowUpwardIcon fontSize="small" />
                                      <Typography variant="inherit">ASC</Typography>
                                    </Stack>
                                  </ToggleButton>
                                  <ToggleButton value={true} sx={{ textTransform: "none" }}>
                                    <Stack direction="row" alignItems="center">
                                      <ArrowDownwardIcon fontSize="small" />
                                      <Typography variant="inherit">DESC</Typography>
                                    </Stack>
                                  </ToggleButton>
                                </ToggleButtonGroup>

                                <IconButton
                                  size="small"
                                  onClick={() =>
                                    props.setSortFields(
                                      produce(props.sortFields, (draft) => {
                                        draft.splice(index, 1);
                                      })
                                    )
                                  }
                                >
                                  <CancelIcon />
                                </IconButton>
                              </Stack>
                            </Stack>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </Stack>
              )}
            </Droppable>
          </DragDropContext>
        </Box>
      </Popover>
    </>
  );
};
