import { DbtTable } from "api/Api";
import { Chip, Highlighter, Stack } from "components";
import { ellipsisStyle } from "consts";

export function StatTable(props: {
  name: string;
  dbtTable: DbtTable;
  searchString: string | undefined;
  selectedTable: boolean | undefined;
  setSelectedTable: Function;
}) {
  return (
    <Chip
      sx={{ width: "100%" }}
      onClick={() => {
        props.setSelectedTable(props.dbtTable.name);
      }}
      variant={props.selectedTable ? "filled" : "outlined"}
      color="primary"
      disabled={false}
      label={
        <Stack
          direction="row"
          spacing="xs"
          alignItems="center"
          justifyContent="space-between"
          minHeight="20px"
          width="100%"
        >
          <Stack {...ellipsisStyle} flexGrow={1}>
            <Highlighter searchWords={[props.searchString || ""]} textToHighlight={props.dbtTable.displayName} />
          </Stack>
        </Stack>
      }
    />
  );
}
