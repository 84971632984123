import { EmailAssetInformation } from "api/Api";
import { useEffect, useState } from "react";
import apiService from "services/apiService";

const cachedIcons: EmailAssetInformation[] = [];

export function useIcons(): [EmailAssetInformation[], boolean] {
  const [icons, setIcons] = useState([] as EmailAssetInformation[]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (cachedIcons.length > 0) {
      setIcons(cachedIcons);
      return;
    }
    setLoading(true);
    apiService
      .getApi()
      .admin.reportSegmentControllerGetIcons()
      .then((response) => {
        cachedIcons.push(...response.data);
        setIcons(response.data);
        setLoading(false);
      });
  }, []);

  return [icons, loading];
}
