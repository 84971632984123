import { Field } from "api/Api";

// all selected fields need to have a truly unique identifier to be used and find in SQL
// for example: "edi_invoice.patientid" is not unique, but "Sum(edi_invoice.patientid) is unique"
// this is also used in aliasing in SQL for now
export const getFieldFullIdentifier = (field: Field): string => {
  let column = `${field.dbName}.${field.tableName}.${field.fieldName}`;

  switch (field.transform) {
    default:
      return column;
    case "CustomFunction":
      return `${field.customFunction}(${column})`;
    case "AggregateFunction": {
      if (!field.aggregatePivot) {
        return `${field.aggregateFunction}(${column})`;
      } else {
        const pivotField = field.aggregatePivot.field;
        const pivotColumn = getFieldFullIdentifier(pivotField);
        //const pivotValueLabel = field.aggregatePivot.values.join(',');
        const pivotValueLabel = field.aggregatePivot.values.length === 1 ? field.aggregatePivot.values[0] : "TOTAL";

        return `${field.aggregateFunction}(${column})Pivot(${pivotColumn}=${pivotValueLabel})`;
      }
    }
  }
};
