import {
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogProps as MuiDialogProps,
  DialogTitle,
  Paper,
  PaperProps,
} from "@mui/material";
import { Form } from "components";
import { ReactNode } from "react";
import Draggable from "react-draggable";
import { useTheme } from "../../hooks";

type DialogProps = MuiDialogProps & {
  header?: ReactNode;
  content?: ReactNode;
  footer?: ReactNode;
  width?: string;
  isForm?: boolean;
};

function PaperComponent(props: PaperProps) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export const Dialog = ({
  onClose,
  transitionDuration,
  header,
  content,
  footer,
  width,
  sx,
  isForm,
  ...rest
}: DialogProps) => {
  const theme = useTheme();

  const handleClose = (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
    // disabled backdrop click
    if (reason === "backdropClick") {
    } else {
      onClose?.(event, reason);
    }
  };

  const handleBackdropClick = (event) => {
    event.stopPropagation();
    return false;
  };

  const dialogBody = (
    <>
      <DialogTitle
        style={{ cursor: "move" }}
        id="draggable-dialog-title"
        sx={{ backgroundColor: theme.palette.background.default, py: 1, minHeight: "30px" }}
      >
        {header}
      </DialogTitle>

      <DialogContent>{content}</DialogContent>
      <DialogActions>{footer}</DialogActions>
    </>
  );

  return (
    <MuiDialog
      {...rest}
      PaperComponent={PaperComponent}
      transitionDuration={{
        appear: 0,
        enter: 0,
        exit: 0,
      }}
      sx={{
        "& .MuiDialog-paper": {
          width: width ? width : "80%",
        },
        ...sx,
      }}
      onClose={handleClose}
      onBackdropClick={handleBackdropClick}
    >
      {isForm ? <Form>{dialogBody}</Form> : dialogBody}
    </MuiDialog>
  );
};
