import { ReportSegment } from "api/Api";
import { useEffect, useState } from "react";
import apiService from "services/apiService";

export function useReportSegments(): [ReportSegment[], boolean, Function] {
  const [reportSegments, setReportSegments] = useState<ReportSegment[]>([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    apiService
      .getApi()
      .admin.reportSegmentControllerGetReportSegments()
      .then((response) => {
        setReportSegments(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  function reloadReportSegments() {
    setReportSegments([]);
  }

  return [reportSegments, loading, reloadReportSegments];
}
