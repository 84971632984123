import { Box, Paper, Stack, Typography } from "@mui/material";
import { Employee } from "api/Api";
import { DatePicker } from "components";
import { useEffect, useState } from "react";
import { ReaderPicker } from "../ReaderPicker/ReaderPicker";
import { useFacility } from "hooks/useFacility";
import { getQuerySafeDate } from "utils";
import { useCustomReport } from "hooks/email/useCustomReport";
import { RenderReportHTML } from "pages/Custom-Reports/CustomReport/CustomReport";
import { ReportPickerDropdown } from "./ReportPickerDropdown";

const mostRecentMonday = new Date();
mostRecentMonday.setDate(mostRecentMonday.getDate() - (mostRecentMonday.getDay() + 6) % 7);

export function FacilityReports({facilityId}: { facilityId: number}) {
    
    const [facility, loadingFacility] = useFacility(facilityId);
    const [employee, setEmployee] = useState<Employee|undefined>(undefined);
    const [reportDate, setReportDate] = useState<string>(getQuerySafeDate(mostRecentMonday));
    const [reportId, setReportId] = useState<number>(2);

    useEffect(()=>{
        if(!loadingFacility && facility) {
            setEmployee(undefined);
        }
    }, [facilityId])

    return <Paper style={{paddingTop:'1rem'}}>
            <Stack m={2} spacing={2}>
                <Box>
                    <Typography variant="h4">Location: {facility?.name}</Typography>
                </Box>
                <Box style={{width: "100%"}}>
                    <Stack direction="row" spacing={2}>
                        <Box sx={{width: '33%'}}>
                            <ReportPickerDropdown 
                                reportId={reportId}
                                setReportId={(newReportId: number) => {
                                    setReportId(newReportId)
                                }}
                            />
                        </Box>
                        <Box sx={{width: '33%'}}>
                            <ReaderPicker
                                reader={employee}
                                setReader={setEmployee}
                                limitToFacility={facilityId}
                            />
                        </Box>
                        <Box sx={{width: '33%'}}>
                        <DatePicker 
                            label="Report Date"
                            value={reportDate}
                            onChange={(e)=>{setReportDate(e);}} />
                        </Box>
                    </Stack>
                </Box>
                <Box>
                    {employee && reportDate && <StartThatReportLoading reportid={reportId} employee={employee} reportDate={reportDate} />}
                </Box>
            </Stack>
        </Paper>
}

function StartThatReportLoading({reportid, employee, reportDate}: {reportid: number, employee: Employee, reportDate: string}) {
    const [report, reportLoading] = useCustomReport(reportid, employee?.badge_number, reportDate);
    if(reportLoading || !report) {
        return <div>Loading...</div>;
    }

    return <RenderReportHTML 
    report={report} 
    userBadge={employee.badge_number} 
    date={reportDate} />;
}