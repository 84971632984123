import { Page } from "components/pages/Page";
import { CHART_LAYOUTS, StatResults } from "./StatResults/StatsResults";
import { StatTableList } from "./StatTableList/StatTableList";
import { useContext, useEffect, useState } from "react";
import { DbtDataField, DbtTable, QueryRequest } from "api/Api";
import { Outlet } from "react-router-dom";
import { Stack } from "components";
import { useNavigate } from "react-router-dom";
import { ReportContext, ReportContextProvider } from "pages/ReportPage/ReportContextProvider";

export const statsPageRoute = {
  label: "Statistics",
  templatePath: "/stats",
  handle: () => <StatsPage />,
};

export const StatsPage = () => (
  <>
    <ReportContextProvider>
      <StatsPageContent />
    </ReportContextProvider>

    <Outlet />
  </>
);

export function StatsPageContent(props) {
  const reportContext = useContext(ReportContext);
  const { dbtTables, setQueryRequest } = reportContext;
  const [filteredDbtTables, setFilteredDbtTables] = useState<DbtTable[]>([]);

  const [selectedTable, setSelectedTable] = useState<string | undefined>(
    window.location.hash?.replace("#", "") ?? undefined
  );
  const [selectedTableDisplayName, setSelectedTableDisplayName] = useState<string | undefined>(undefined);
  const [selectedTableFields, setSelectedTableFields] = useState<DbtDataField[]>([]);

  const [chartLayout, setChartLayout] = useState(CHART_LAYOUTS.show);

  const navigate = useNavigate();

  useEffect(() => {
    if (!dbtTables || dbtTables.length === 0) {
      return;
    }
    // filter out tables that don't have data fields with 'jan' in them
    const filteredTables = dbtTables.filter((table) => {
      return table.dataFields.some((field) => field.fieldName.includes("jan"));
    });
    setFilteredDbtTables(filteredTables);

    if (selectedTable === "first") {
      setSelectedTable(filteredTables[0].name);
    }
  }, [dbtTables]);

  useEffect(() => {
    const table = filteredDbtTables.find((x) => x.name === selectedTable);
    if (!table) {
      console.log("No table :(");
      return;
    }

    const selectedFields: DbtDataField[] = table?.dataFields ?? [];
    setSelectedTableFields(selectedFields);
    setSelectedTableDisplayName(table?.displayName ?? table.name);
    navigate(`/stats#${selectedTable}`, { replace: true });
  }, [filteredDbtTables, selectedTable]);

  useEffect(() => {
    if (!selectedTable || selectedTableFields.length === 0) {
      return;
    }

    const table = filteredDbtTables.find((x) => x.name === selectedTable);
    if (!table) {
      console.log("No table :(");
      return;
    }

    const selectedFields: DbtDataField[] = table?.dataFields ?? [];
    const queryRequest: QueryRequest = {
      tableTree: {
        dbName: table.dbName,
        name: selectedTable,
      },
      fields: selectedFields.map((field) => {
        return { dbName: field.dbName, tableName: field.tableName, fieldName: field.fieldName };
      }),
      filters: [],
      page: 1,
      pageSize: 20,
      // tableTree: {
      //   name: selectedTable,
      //   alias: selectedTable,
      //   fields: selectedTableFieldNames,
      // },
      //tables: [{ name: selectedTable, fields: selectedTableFieldNames }],
      sortFields: [],
    };

    setQueryRequest(queryRequest);
    reportContext.fetchQueryResult(queryRequest);
  }, [selectedTable, selectedTableFields]);

  return (
    <>
      <Page
        nav={
          chartLayout !== CHART_LAYOUTS.fullscreen && (
            <StatTableList
              filteredDbtTables={filteredDbtTables}
              selectedTable={selectedTable}
              setSelectedTable={(selectedTable) => {
                navigate(`/stats#${selectedTable}`, { replace: true });
                setSelectedTable(selectedTable);
              }}
            />
          )
        }
      >
        <Stack spacing="md" height="100%">
          <StatResults
            selectedTable={selectedTable}
            selectedTableDisplayName={selectedTableDisplayName}
            selectedTableFields={selectedTableFields}
            chartLayout={chartLayout}
            setChartLayout={(layout) => {
              setChartLayout(layout);
            }}
          />
        </Stack>
      </Page>
    </>
  );
}
