import { Facility } from "api/Api";
import { useEffect, useState } from "react";
import apiService from "services/apiService";

export function useLocationEncountersForDate(locationId: number, date: string): [Facility | undefined, boolean] {
  const [facility, setFacility] = useState<Facility | undefined>();
  const [loadingFacility, setLoadingFacility] = useState(true);

  useEffect(() => {
    if (!facility) {
      apiService
        .getApi()
        .facilities.facilitiesControllerGetFacilityById(locationId, date)
        .then((response) => {
          setFacility(response.data);
          setLoadingFacility(false);
        })
        .catch((error) => {
          console.error(error);
          setLoadingFacility(false);
        });
    }
  }, [locationId]);

  return [facility, loadingFacility];
}
