import React from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Box, IconButton, Skeleton, Stack } from "components";

export const Pagination = (props: {
  total: number;
  loading: boolean;
  rowsPerPage: number;
  page: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => void;
}) => {
  const numOfPages = Math.ceil(props.total! / props.rowsPerPage);

  const handleNextPage = () => {
    if (props.page < numOfPages) {
      props.onPageChange(null, props.page + 1);
    }
  };

  const handlePrevPage = () => {
    if (props.page > 1) {
      props.onPageChange(null, props.page - 1);
    }
  };

  const start = ((props.page - 1) * props.rowsPerPage + 1).toLocaleString();
  const end = Math.min(props.total, props.page * props.rowsPerPage).toLocaleString();
  const total = props.total.toLocaleString();

  return (
    <Stack direction="row" spacing="sm" alignItems="center">
      <Box mx={2}>
        {props.loading ? (
          <Skeleton variant="text" width="120px" height="20px" />
        ) : (
          <Box>{`${start} - ${end} of ${total}`}</Box>
        )}
      </Box>

      <IconButton disabled={props.page === 1 || props.loading} onClick={handlePrevPage}>
        <ArrowBackIosIcon fontSize="small" />
      </IconButton>

      <IconButton disabled={props.page === numOfPages || props.loading} onClick={handleNextPage}>
        <ArrowForwardIosIcon fontSize="small" />
      </IconButton>
    </Stack>
  );
};
