import { Facility } from "api/Api";
import { useState, useEffect } from "react";
import apiService from "services/apiService";

export function useFacilities(): [Facility[], boolean] {
  const [locations, setLocations] = useState<Facility[]>([]);
  const [loadingLocations, setLoadingLocations] = useState(false);

  useEffect(() => {
    if (loadingLocations || locations.length > 0) {
      return;
    }
    apiService
      .getApi()
      .facilities.facilitiesControllerGetFacilities()
      .then((response) => {
        setLocations(response.data);
        setLoadingLocations(false);
      });
  }, [locations, loadingLocations]);

  return [locations, loadingLocations];
}
