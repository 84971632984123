import { useEffect, useState } from "react";
import { CLOSE_WARNING } from "../consts";
import { deepCopy } from "../services";

// this hook containers the operations needed to manipulate editable list: edit/create/delete/cancel a list item
type useUnsavedChangesProps = {
  trackedItems: any[] | undefined;
  handleConfirmClose?: () => void;
};

export const useUnsavedChanges = ({
  trackedItems,
  handleConfirmClose,
}: useUnsavedChangesProps): {
  unsavedChanges: boolean;
  resetUnsavedChanges: (trackedItems: any[] | undefined) => void;
  handleConfirmClose: () => void;
} => {
  const [originalTrackedItems, setOriginalTrackedItems] = useState<any[] | undefined>(deepCopy(trackedItems));
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);

  useEffect(() => {
    if (!unsavedChanges && JSON.stringify(originalTrackedItems) !== JSON.stringify(trackedItems)) {
      setUnsavedChanges(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, trackedItems);

  const resetUnsavedChanges = (trackedItems: any[] | undefined): void => {
    setTimeout(() => {
      setUnsavedChanges(false);
      setOriginalTrackedItems(deepCopy(trackedItems));
    }, 50);
  };

  const handleClose = () => {
    if (handleConfirmClose) {
      if (unsavedChanges) {
        if (window.confirm(CLOSE_WARNING) === true) {
          handleConfirmClose();
        }
      }
      else
      {
        handleConfirmClose();
      }
    }
  }

  return {
    unsavedChanges: unsavedChanges,
    resetUnsavedChanges: (trackedItems: any[] | undefined) => resetUnsavedChanges(trackedItems),
    handleConfirmClose: () => handleClose()
  };
};
