import { TerminationReport } from "api/Api";
import { Box } from "components";
import Stack from '@mui/material/Stack';
import { monthsOfTheYear } from "../helpers";
import { TerminationFilters } from "../../TurnoverReportData";
import { useState } from "react";


export function TerminationReportByLocation({year, terminationData, filters}: {year: number; terminationData: TerminationReport; filters: TerminationFilters;}) {
    const [hoverRow, setHoverRow] = useState<number | undefined>(undefined);
    
    return <Stack direction="row" spacing={2}>
            <Locations terminationData={terminationData} hoverRow={hoverRow} setHoverRow={setHoverRow} />
            <Terminations year={year} terminationData={terminationData} was_terminated={filters.was_terminated} hoverRow={hoverRow} setHoverRow={setHoverRow} />
            <EmployeeCount year={year} terminationData={terminationData} hoverRow={hoverRow} setHoverRow={setHoverRow} />
            <Turnovers year={year} terminationData={terminationData} hoverRow={hoverRow} setHoverRow={setHoverRow} />
        </Stack>
    ;
}

function Locations({ terminationData, hoverRow, setHoverRow}: { terminationData: TerminationReport; hoverRow: number | undefined; setHoverRow: (row: number | undefined) => void;}) {
    const jobLocations: string[] = Object.keys(terminationData.monthlyTerminationsByLocation);
    
    return <>
        <Box className="grouped-by-field">
            <h3>By Location</h3>
            <table>
                    <thead>
                        <tr>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                    {jobLocations.map((location, index) => {
                        return <tr 
                            className={`${index === hoverRow ? 'hovered':''}`}
                            onMouseOver={(e)=> {
                                setHoverRow(index);
                            }} 
                            onMouseOut={(e)=> {
                                setHoverRow(undefined);
                            }}
                            key={`mtrbl-locations-${index}`}>
                                <th style={{width: "100%", textAlign: "left", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}}>{location}</th>
                            </tr>;
                    })}
                    </tbody>
                    <tfoot>
                        <tr>
                            <th style={{ width: "100%", textAlign: "left", overflow: "hidden", whiteSpace: "nowrap" }}>Totals</th>
                        </tr>
                    </tfoot>
                </table>
        </Box>
    </>
}

function Terminations({year, terminationData, was_terminated, hoverRow, setHoverRow}: {year: number; terminationData: TerminationReport; was_terminated: boolean; hoverRow: number | undefined; setHoverRow: Function;}) {
    const jobLocationData: {
        [jobTitle:string]: any;
    } = terminationData.monthlyTerminationsByLocation;

    let maxMonth = 1;

    const columnTotals: number[] = getTerminationColumnTotals(jobLocationData, year);

    let locationDataSummary: {
        [
            location: string
        ]: {
            total: number;
            months:{[month: number]: number;};
        };
    } = Object.keys(jobLocationData).reduce((acc, location, idx) => {
        const jobLocationGroupData = jobLocationData[location];
        acc[location] = {
            total: 0,
            months: {}
        };
        maxMonth = 1;
        let total = 0;
        Object.keys(jobLocationGroupData).filter((yearMonth) => yearMonth.startsWith(`${year}`)).forEach((yearMonth) => {
            const month = parseInt(yearMonth.split('-')[1])-1;
            const count = typeof jobLocationGroupData[yearMonth] === 'string' ? parseInt(jobLocationGroupData[yearMonth]) : jobLocationGroupData[yearMonth];
            acc[location].months[month] = count;
            total += count;
            maxMonth = Math.max(maxMonth, month);
        })

        // if maxMonth is less than 12, add empty cells to the end of the array
        if (maxMonth < 11) {
            for (let i = (maxMonth+1); i < 12; i++) {
                acc[location].months[i] = 0;
                columnTotals[i] = 0;
            }
        }

        acc[location].total = total;

        return acc;
    }, {});

    const columnWidth = `${100/13}%`;
    
    return <>
        <Box className="data-presentation termination">
            <h3>Terminations</h3>
            <table>
                <thead>
                    <tr>
                        {
                            Array.from({length: 12}, (_, i) => i).map((month, index) => <th key={index} style={{width: columnWidth}}>{monthsOfTheYear[month]}</th>)
                        }
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                {Object.keys(locationDataSummary).map((location, index) => {
                    const jobLocationGroupData = locationDataSummary[location];
                    
                    return <tr 
                            className={`${index === hoverRow ? 'hovered':''}`}
                            onMouseOver={(e)=> {
                                setHoverRow(index);
                            }} 
                            onMouseOut={(e)=> {
                                setHoverRow(undefined);
                            }}
                            key={`mtrbl-terminations-${index}`}>
                            {Object.values(jobLocationGroupData.months).map((count, idx) => {
                                return <td key={`$dep-${year}-${idx}`} style={{ color: (count === 0 ? '#777': '#000'), textAlign: "right", width: columnWidth}}>{count}</td>
                            })}
                            <td style={{textAlign: "right", color: (jobLocationGroupData.total === 0 ? '#777': '#000'), width: columnWidth}}>
                                {jobLocationGroupData.total}
                            </td>
                        </tr>;
                })}
                </tbody>
                <tfoot>
                    <tr>
                        {columnTotals.map((total, index) => <td style={{width: columnWidth, textAlign: "right"}} key={index}>{total}</td>)}
                        <td style={{width: columnWidth, textAlign: "right"}}>{Object.values(locationDataSummary).reduce((acc, location) => acc + location.total, 0)}</td>
                    </tr>
                </tfoot>
            </table>
        </Box>
    </>
}

function EmployeeCount({year, terminationData, hoverRow, setHoverRow}: {year: number; terminationData: TerminationReport; hoverRow: number | undefined; setHoverRow: Function;}) {
    const jobLocationData: {
        [jobTitle:string]: any;
    } = terminationData.monthlyEmployeesAverageByLocation;

    let maxMonth = 1;

    const columnTotals: number[] = getEmployeeCountColumnTotals(jobLocationData, year);

    let locationDataSummary: {
        [
            location: string
        ]: {
            total: number;
            months:{[month: number]: number;};
        };
    } = Object.keys(jobLocationData).reduce((acc, location, idx) => {
        const jobLocationGroupData = jobLocationData[location];
        acc[location] = {
            total: 0,
            months: {}
        };

        maxMonth = 1;
        let total = 0;
        Object.keys(jobLocationGroupData).filter((yearMonth) => yearMonth.startsWith(`${year}`)).forEach((yearMonth) => {
            const month = parseInt(yearMonth.split('-')[1])-1;
            const count = jobLocationData[location][yearMonth];
            acc[location].months[month] = typeof count === 'string' ? parseFloat(count) : count;
            total += acc[location].months[month];
            maxMonth = Math.max(maxMonth, month);
        })

        // if maxMonth is less than 12, add empty cells to the end of the array
        if (maxMonth < 11) {
            for (let i = maxMonth+1; i < 12; i++) {
                acc[location].months[i] = 0;
                columnTotals[i] = 0;
            }
        }

        acc[location].total = total;

        return acc;
    }, {});

    const columnWidth = `${100/13}%`;
    
    return <>

    <Box className="data-presentation employee-count">
        <h3>Average Employee Count</h3>
        <table>
            <thead>
                <tr>
                    {
                        Array.from({length: 12}, (_, i) => i).map((month, index) => <th key={index} style={{width: columnWidth}}>{monthsOfTheYear[month]}</th>)
                    }
                </tr>
            </thead>
            <tbody>
            {Object.keys(locationDataSummary).map((location, index) => {
                const jobLocationGroupData = locationDataSummary[location];
                
                return <tr
                            className={`${index === hoverRow ? 'hovered':''}`}
                            onMouseOver={(e)=> {
                                setHoverRow(index);
                            }} 
                            onMouseOut={(e)=> {
                                setHoverRow(undefined);
                            }}
                            key={`mtrbl-employee-count-${index}`}>
                        {Object.values(jobLocationGroupData.months).map((count, idx) => {
                            return <td key={`$dep-${year}-${idx}`} style={{ color: (count === 0 ? '#777': '#000'), textAlign: "right", width: columnWidth}}>{count}</td>
                        })}
                    </tr>;
            })}
            </tbody>
            <tfoot>
                <tr>
                    {columnTotals.map((total, index) => <td style={{width: columnWidth, textAlign: "right"}} key={index}>{total}</td>)}
                </tr>
            </tfoot>
        </table>
    </Box>

    </>
}

function Turnovers({year, terminationData, hoverRow, setHoverRow}: {year: number; terminationData: TerminationReport; hoverRow: number | undefined; setHoverRow: Function;}) {
    const jobLocationData: {
        [jobTitle:string]: any;
    } = terminationData.monthlyTurnoverRateByLocation;

    // get the ratio totals
    const terminationTotals: number[] = getTerminationColumnTotals(terminationData.monthlyTerminationsByLocation, year);
    const employeeCountTotals: number[] = getEmployeeCountColumnTotals(terminationData.monthlyEmployeesAverageByLocation, year);
    const turnoverRates: number[] = getTurnoverRates(terminationTotals, employeeCountTotals);

    let maxMonth = 1;

    let locationDataSummary: {
        [
            location: string
        ]: {
            total: number;
            months:{[month: number]: number;};
        };
    } = Object.keys(jobLocationData).reduce((acc, location, idx) => {
        const jobLocationGroupData = jobLocationData[location];
        acc[location] = {
            total: 0,
            months: {}
        };

        maxMonth = 1;
        let total = 0;
        Object.keys(jobLocationGroupData).filter((yearMonth) => yearMonth.startsWith(`${year}`)).forEach((yearMonth) => {
            const month = parseInt(yearMonth.split('-')[1])-1;
            const count = jobLocationData[location][yearMonth];
            acc[location].months[month] = typeof count === 'string' ? parseInt(count) : count;
            total += acc[location].months[month];
            maxMonth = Math.max(maxMonth, month);
        })

        // if maxMonth is less than 12, add empty cells to the end of the array
        if (maxMonth < 11) {
            for (let i = maxMonth+1; i < 12; i++) {
                acc[location].months[i] = 0;
            }
        }

        acc[location].total = total;

        return acc;
    }, {});

    const columnWidth = `${100/13}%`;
    
    return <>
        <Box className="data-presentation turnover-rate">
            <h3>Turnover Rate</h3>
            <table>
                <thead>
                    <tr>
                        {
                            Array.from({length: 12}, (_, i) => i).map((month, index) => <th key={index} style={{width:columnWidth}}>{monthsOfTheYear[month]}</th>)
                        }
                    </tr>
                </thead>
                <tbody>
                {Object.keys(locationDataSummary).map((location, index) => {
                    const jobLocationGroupData = locationDataSummary[location];
                    
                    return <tr
                                className={`${index === hoverRow ? 'hovered':''}`}
                                onMouseOver={(e)=> {
                                    setHoverRow(index);
                                }} 
                                onMouseOut={(e)=> {
                                    setHoverRow(undefined);
                                }}
                                key={`mtrbl-ratios-${index}`}>
                            {Object.values(jobLocationGroupData.months).map((count, idx) => {
                                return <td key={`$dep-${year}-${idx}`} style={{ color: (count === 0 ? '#777': '#000'), textAlign: "right", width: columnWidth}}>{count}%</td>
                            })}
                        </tr>;
                })}
                </tbody>
                <tfoot>
                    <tr>
                        {turnoverRates.map((total, index) => <td style={{width: columnWidth, textAlign: "right"}} key={`mtrbt-ratio-totals-${index}`}>{total.toFixed(1)}%</td>)}
                    </tr>
                </tfoot>
            </table>
        </Box>
    </>
}

function getTerminationColumnTotals(jobLocationData, year): number[] {
    const columnTotals: number[] = [];
    Object.keys(jobLocationData).forEach((location) => {
        const jobLocationGroupData = jobLocationData[location];
        console.log(Object.keys(jobLocationData));
        Object.keys(jobLocationGroupData).filter((yearMonth) => yearMonth.startsWith(`${year}`)).forEach((yearMonth) => {
            const month = parseInt(yearMonth.split('-')[1])-1;
            const count = jobLocationGroupData[yearMonth];
            columnTotals[month] = (columnTotals[month] || 0) + (typeof count === 'string' ? parseInt(count) : count);
        })
    });
    // add 0s for the months that don't have any data
    for (let i = columnTotals.length; i < 12; i++) {
        columnTotals.push(0);
    }
    
    return columnTotals;
}

function getEmployeeCountColumnTotals(jobLocationData, year: number): number[] {
    const columnTotals: number[] = [];
    Object.keys(jobLocationData).forEach((location) => {
        const jobLocationGroupData = jobLocationData[location];
        Object.keys(jobLocationGroupData).filter((yearMonth) => yearMonth.startsWith(`${year}`)).forEach((yearMonth) => {
            const month = parseInt(yearMonth.split('-')[1])-1;
            const count = jobLocationGroupData[yearMonth];
            columnTotals[month] = (columnTotals[month] || 0) + (typeof count === 'string' ? parseFloat(count) : count);
        })
    });

    // add 0s for the months that don't have any data
    for (let i = columnTotals.length; i < 12; i++) {
        columnTotals.push(0);
    }

    return columnTotals;
}

function getTurnoverRates(terminationTotals: number[], employeeCountTotals: number[]): number[] {
    const termTots = terminationTotals.map((terminationTotal, index) => {
        return employeeCountTotals[index] > 0 ? ((terminationTotal / employeeCountTotals[index]) * 100) : 0;
    });
    // fill in the empty months with 0
    for (let i = termTots.length; i < 12; i++) {
        termTots.push(0);
    }
    return termTots;
}