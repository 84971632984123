import { Box, Stack, Typography } from "@mui/material";
import { useFacilities } from "hooks/useFacilities";
import { FacilityReports } from "./FacilityReports";
import { useState } from "react";

export function FacilityReportPicker(){
    const [locations, loadingLocations] = useFacilities();
    const [facilityId, setFacilityId] = useState<number|undefined>(undefined);
    return (
        <>
        <Stack direction="row" spacing={2}>
            <Box width={"33%"}>
                <Stack direction="column" spacing={2}>
                    <Box>
                        <Typography variant="h6">
                            Select a Location
                        </Typography>
                    </Box>
                {
                    loadingLocations ? <div>Loading...</div> : locations.map((location) => {
                        return <Box key={location.id} sx={{ cursor: 'pointer', padding: "10px"}} onClick={()=>{
                                    setFacilityId(parseInt(location.id as unknown as string))
                                }}>
                                {location.name}
                        </Box>
                    })
                }
                </Stack>
            </Box>
            <Box width={"66%"}>
                {facilityId && <FacilityReports
                    facilityId={facilityId}
                />}
            </Box>
        </Stack>
    </>
    );
}