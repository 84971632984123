import { Box, IconButton, Menu, MenuItem, Stack, Typography } from "components";

import SettingsIcon from "@mui/icons-material/Settings";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserSettingNavPinned } from "redux/reducers/userSettingSlice";
import { RootState } from "redux/store";
import apiService from "services/apiService";
import CheckIcon from "@mui/icons-material/Check";

export const Settings = (props: {}) => {
  const dispatch = useDispatch();
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const navPinned = useSelector((state: RootState) => state.userSetting.navPinned);

  const pinNavigationMenuItem = () => {
    return (
      <MenuItem
        onClick={(e: React.MouseEvent<HTMLLIElement>) => {
          e.stopPropagation();

          const setValue: boolean = !navPinned;
          dispatch(setUserSettingNavPinned(setValue));
          apiService.getApi().users.usersControllerSaveNavPinned({
            value: setValue,
          });

          setMenuAnchorEl(null);
        }}
      >
        <Stack direction="row" spacing="xs">
          {navPinned && <CheckIcon />}
          <Typography variant="inherit">Pin Navigation Bar</Typography>
        </Stack>
      </MenuItem>
    );
  };

  return (
    <Box>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          setMenuAnchorEl(e.currentTarget);
        }}
      >
        <SettingsIcon fontSize="medium" />
      </IconButton>

      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={() => setMenuAnchorEl(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {pinNavigationMenuItem()}
      </Menu>
    </Box>
  );
};
