import { Button, Dialog, Input, Stack } from "components";

import { QueryRequest } from "api/Api";
import { useState } from "react";

export const EnterQueryRequest = (props: {
  queryRequest: QueryRequest;
  setQueryRequest: (value: QueryRequest) => void;
  close: () => void;
}) => {
  const [input, setInput] = useState(JSON.stringify(props.queryRequest, null, 4));

  const setQueryRequest = () => {
    const queryRequest = JSON.parse(input);
    props.setQueryRequest(queryRequest);
  };

  return (
    <Dialog
      open={true}
      onClose={() => props.close()}
      maxWidth="md"
      content={<Input multiline value={input} onChange={(e) => setInput(e.target.value)} />}
      footer={
        <Stack direction="row" spacing="sm">
          <Button variant="outlined" onClick={() => props.close()}>
            Close
          </Button>

          <Button
            variant="contained"
            onClick={() => {
              setQueryRequest();
              props.close();
            }}
          >
            Enter
          </Button>
        </Stack>
      }
    ></Dialog>
  );
};
