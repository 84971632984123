import { MedicalAssistantReport } from "./MedicalAssistantReport/MedicalAssistantReport";
import { Outlet } from "react-router-dom";
import { CustomReport } from './CustomReport/CustomReport';

export const CustomReportPageRoutes = 
    {
        path: 'custom-reports',
        label: "Custom Reports",
        handle: 'custom-reports',
        templatePath: '/custom-reports',
        element: <CustomReports />,
        children: [
            {
                index: true,
                path: '',
                label: "Custom Reports",
                handle: 'custom-reports',
                templatePath: '/custom-reports',
                element: <>Custom Reports Default. Nothing to See Here.</>,
            },
            {
                path: 'medical-assistant/:email/:date',
                label: "Medical Assistant",
                handle: 'medical-assistant',
                templatePath: '/medical-assistant',
                element: <MedicalAssistantReport />,
            },
            {
                path: 'report/:reportid',
                label: "Custom Report",
                handle: 'custom-report',
                templatePath: '/custom-report',
                element: <CustomReport />,
            },
            {
                path: 'report/:reportid/:userbadge',
                label: "Custom Report",
                handle: 'custom-report',
                templatePath: '/custom-report',
                element: <CustomReport />,
            },
            {
                path: 'report/:reportid/:userbadge/:date',
                label: "Custom Report",
                handle: 'custom-report',
                templatePath: '/custom-report',
                element: <CustomReport />,
            }
        ]
    };

export function CustomReports() {
    return (
        <><Outlet /></>
    );
}