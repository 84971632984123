import { PresetDto } from "api/Api";
import { CloseButton, Dialog, handleClose, Input, Stack, SubmitButton } from "components";
import { ListContext, ListContextValue } from "contexts";
import { useParams, useUnsavedChanges } from "hooks";
import produce from "immer";
import { PageRoute } from "models/PageRoute";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setAppToast } from "redux/reducers/appSlice";
import { newToast } from "services";
import apiService from "services/apiService";
import { buildPath, buildTemplatePath } from "services/routeService";

const routeName = "updatePreset";

class Params {
  id: number = 0;
}

export const updatePresetPageRoute: PageRoute<Params> = {
  handle: "Edit Preset",
  templatePath: buildTemplatePath(routeName, Params),
  getPath: (params) => buildPath(routeName, params),
};

export const UpdatePresetPage = () => {
  const { params } = useParams(routeName, Params);

  const listContext = useContext<ListContextValue>(ListContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [preset, setPreset] = useState<PresetDto>();
  const { unsavedChanges, resetUnsavedChanges } = useUnsavedChanges({ trackedItems: [preset] });

  useEffect(() => {
    apiService
      .getApi()
      .presets.presetsControllerFindOne(params.id)
      .then((response) => {
        setPreset(response.data);
        resetUnsavedChanges([response.data]);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updatePreset = (preset: PresetDto) => {
    apiService
      .getApi()
      .presets.presetsControllerUpdate(preset)
      .then((response) => {
        navigate("../");
        dispatch(setAppToast(newToast("success", "Preset Updated")));
        listContext.getList?.();
      });
  };

  if (!preset) return null;

  return (
    <Dialog
      isForm
      open={true}
      onClose={() => handleClose(navigate, unsavedChanges)}
      maxWidth="sm"
      header={updatePresetPageRoute.handle}
      content={
        <Stack>
          <Input
            label={"Name"}
            value={preset?.presetName}
            onChange={(e) => {
              setPreset(
                produce((draft) => {
                  draft!.presetName = e.target.value;
                })
              );
            }}
            size="small"
          />
        </Stack>
      }
      footer={
        <Stack direction="row" spacing="sm" justifyContent={"flex-end"}>
          <CloseButton confirm={unsavedChanges} />
          <SubmitButton onClick={() => updatePreset(preset!)} disabled={!unsavedChanges} />
        </Stack>
      }
    />
  );
};
