import { TableCell } from "components";

import { Field } from "api/Api";
import moment from "moment";
import { useContext } from "react";
import { ReportContext } from "../ReportContextProvider";

export const TableBodyCell = (props: { cellValue: string; field: Field; colSpan?: number }) => {
  const reportContext = useContext(ReportContext);
  const fieldOutput = reportContext.getFieldOutput(props.field);
  // const dataField = reportContext.getDataField(props.field);
  // // this is the returned dataType which might be different from dataField's dataType (ex. Count of Date is Number)
  // const dataType = reportContext.getFieldDataType(props.field);
  // const numberPrecision = reportContext.getFieldNumberPrecision(props.field);

  const formatCellValue = (cellValue: string) => {
    if (cellValue !== undefined && cellValue !== null) {
      if (props.field.transform === "CustomFunction") {
        switch (props.field.customFunction) {
          case "Week":
            return (Number(cellValue) + 1).toString();
          case "DayOfWeek":
            return moment().day(cellValue).format("ddd");
          case "Year_Month":
            return moment(cellValue).format("MM/YYYY");
        }
      }

      if (fieldOutput?.dataType === "Number") {
        const parsedValue = parseFloat(cellValue);
        if (isNaN(parsedValue)) return cellValue;

        let formattedValue: string = parsedValue.toString();

        // toLocaleString("en-US") adds comma to number: 1000.00 -> 1,000.00
        // applies to decimal
        if (fieldOutput?.numberPrecision !== undefined && fieldOutput.numberPrecision > 0) {
          formattedValue = parsedValue.toLocaleString("en-US", {
            minimumFractionDigits: fieldOutput.numberPrecision,
            maximumFractionDigits: fieldOutput.numberPrecision,
          });
        }

        if (fieldOutput?.context === "Currency") {
          formattedValue = `$${formattedValue}`;
        }

        return formattedValue;
      }

      // format date and datetime
      if (fieldOutput?.dataType === "Date") {
        return moment(cellValue).format("MM/DD/YYYY");
      } else if( fieldOutput?.dataType === "Datetime") {
        return moment(cellValue).format("MM/DD/YYYY HH:mm:ss");
      }

      // format time: use the 24 hour format
      if (fieldOutput?.dataType === "Time") {
        // if time is avg or sum: it will return as a decimal representing seconds
        if (
          props.field.transform === "AggregateFunction" &&
          (props.field.aggregateFunction === "Sum" || props.field.aggregateFunction === "Avg")
        ) {
          let totalSeconds = cellValue;
          let duration = moment.duration(totalSeconds, "seconds");
          let hours = Math.floor(duration.asHours());
          let minutes = duration.minutes();
          let seconds = duration.seconds();
          return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
            .toString()
            .padStart(2, "0")}`;
        } else {
          const parsedDate = moment.utc(cellValue);
          const hours = String(parsedDate.hours()).padStart(2, "0");
          const minutes = String(parsedDate.minutes()).padStart(2, "0");
          const seconds = String(parsedDate.seconds()).padStart(2, "0");
          return `${hours}:${minutes}:${seconds}`;
        }
      }

      return cellValue;
    }

    return undefined;
  };

  const getWidth = () => {
    if (fieldOutput?.dataType === "Number") {
      return "150px";
    }

    return undefined;
  };

  const getAlign = () => {
    if (fieldOutput?.dataType === "Number") {
      return "right";
    }

    return "inherit";
  };

  return (
    <TableCell width={getWidth()} align={getAlign()} colSpan={props.colSpan}>
      {formatCellValue(props.cellValue)}
    </TableCell>
  );
};
