import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteRenderOptionState,
  BoxProps,
  Chip,
  SxProps,
  TextField,
  Theme,
} from "@mui/material";
import React, { ReactNode, useState } from "react";

// here we are not extending the because we are expanding on more than one MUI components
// lets just define properties here as we need
export type SelectProps<T, Multiple extends boolean = false> = Omit<BoxProps, "children" | "onChange"> & {
  multiple?: Multiple;
  value?: Multiple extends true ? T[] : T;
  onChange?: (
    event: React.SyntheticEvent,
    value: Multiple extends true ? T[] : T,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<T>
  ) => void;
  inputSx?: SxProps<Theme>;
  getOptionLabel?: (option: T) => string;
  groupBy?: (option: T) => string | undefined;
  label?: ReactNode;
  required?: boolean;
  errors?: string[];
  options?: T[];
  defaultValue?: any;
  disabled?: boolean;
  //disableClearable?: boolean;
  isOptionEqualToValue?: ((option: T, value: T) => boolean) | undefined;
  fetchFunction?: (searchString: string) => Promise<T[]>;
  placeholder?: string;
  autoComplete?: string;
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: T,
    state: AutocompleteRenderOptionState
  ) => React.ReactNode;
  freeSolo?: boolean;
  size?: "small" | "medium" | undefined;
  open?: boolean;
  onInputChange?: (e: any, value: string) => void;
  popupIcon?: React.ReactNode;
  inputRef?: React.RefObject<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLDivElement>;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  autoFocus?: boolean;
  inputValue?: string;
  noOptionsText?: ReactNode;
  getOptionDisabled?: (option: T) => boolean;
  name?: string;
  disableClearable?: boolean;
  inputVariant?: "standard" | "outlined" | "filled";
  chipSize?: "small" | "medium";
};

export const Select = <T, Multiple extends boolean = false>(props: SelectProps<T, Multiple>) => {
  const [typeDelayTimeoutId, setTypeDelayTimeoutId] = useState<any>();
  const [fetchOptions, setFetchOptions] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  // do some checking here
  if (props.options && props.fetchFunction) {
    throw new Error("options and fetchFunction props cannot be used together.");
  }

  // if (fetchFunction) {
  //   if (!isOptionEqualToValue) {
  //     throw new Error("isOptionEqualToValue prop must be provided if fetchFunction prop is used. ");
  //   }
  // }

  // format value
  //let _value = props.value;

  // if (!_value) {
  //   if (props.multiple) {
  //     _value = [];
  //   } else {
  //     _value = null;
  //   }
  // }

  const errorMsg = props.errors ? props.errors?.[0] : null;
  const hasError = errorMsg ? true : false;
  const isFetch = props.fetchFunction ? true : false; // this indicates whether we are grabbing the data from API

  const handleInputChange = (e, value: string) => {
    if (isFetch) {
      setLoading(true);
      setFetchOptions([]);

      clearTimeout(typeDelayTimeoutId);
      const _typeDelayTimeoutId = setTimeout(function () {
        getFetchOptions(value);
      }, 500); // wait for user to finish typing input: how many ms to wait
      setTypeDelayTimeoutId(_typeDelayTimeoutId);
    }

    if (props.onInputChange) props.onInputChange(e, value);
  };

  const getFetchOptions = (searchString: string) => {
    props.fetchFunction?.(searchString).then((response: any) => {
      setFetchOptions(response);
      setLoading(false);
    });
  };

  const getOptions = () => {
    if (isFetch) {
      return fetchOptions ? fetchOptions : [];
    } else {
      return props.options ? props.options : [];
    }
  };

  // everytime we focus, we search without a searching string, showing all options
  // this also triggers a search everytime we focus, so if we have some filtering going on that filter will be immediately applied
  // for example, look at StudentGrade's class which can be filtered by school id
  const handleFocus = (e) => {
    props.onFocus?.(e);

    if (isFetch) getFetchOptions("");
  };

  let _placeholder = props.placeholder;

  if (isFetch) {
    if (props.placeholder) _placeholder = `${props.placeholder} (search to get more options)`;
    else _placeholder = "Search to get more options";
  }

  return (
    <Autocomplete
      disablePortal={false}
      multiple={props.multiple}
      // @ts-ignore
      value={props.value || (props.multiple ? [] : null)}
      fullWidth
      options={getOptions()}
      // @ts-ignore
      getOptionLabel={props.getOptionLabel}
      groupBy={(t) => props.groupBy?.(t)!}
      renderOption={props.renderOption}
      disabled={props.disabled}
      inputValue={props.freeSolo ? props.inputValue || "" : undefined}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={props.inputSx}
          autoFocus={props.autoFocus}
          label={props.label}
          error={hasError}
          helperText={errorMsg as unknown as string}
          required={props.required}
          disabled={props.disabled}
          autoComplete={props.autoComplete}
          placeholder={_placeholder}
          size={props.size}
          inputRef={props.inputRef}
          name={props.name}
          variant={props.inputVariant}
        />
      )}
      disableClearable={props.disableClearable} // always show clearable: the calling function will need to handle setting to possibly undefined to prevent error
      // @ts-ignore
      onChange={props.onChange}
      onInputChange={(e, value) => handleInputChange(e, value)}
      loading={loading}
      isOptionEqualToValue={props.isOptionEqualToValue}
      disableCloseOnSelect={props.multiple ? true : false}
      freeSolo={props.freeSolo}
      open={props.open}
      onBlur={props.onBlur}
      onFocus={handleFocus}
      autoHighlight
      selectOnFocus={!props.freeSolo}
      noOptionsText={props.noOptionsText ? props.noOptionsText : undefined}
      getOptionDisabled={props.getOptionDisabled}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            {...getTagProps({ index })}
            // @ts-ignore
            label={option}
            size={props.chipSize} // Set the chip size to small
          />
        ))
      }
    />
  );
};
