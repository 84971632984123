import { Button, Checkbox, CloseButton, Dialog, Select, Stack, Typography } from "components";

import { AggregateFunctionOption, CustomFunctionOption, DbtDataField } from "api/Api";
import produce from "immer";
import { ReportContext } from "pages/ReportPage/ReportContextProvider";
import { useContext, useState } from "react";
import { getSpacing } from "theme";

type PivotValueOption = {
  label: string;
  value: string;
};

export const Pivot = (props: { dataField: DbtDataField; pivotFieldOptions: DbtDataField[]; onClose: () => void }) => {
  const reportContext = useContext(ReportContext);
  const lookups = reportContext?.lookups || [];
  const [selectedPivotDataField, setSelectedPivotDataField] = useState<DbtDataField>();
  const [selectedPivotValues, setSelectedPivotValues] = useState(new Map<string, PivotValueOption>());
  const [selectedAggregateFunction, setSelectedAggregateFunction] = useState<AggregateFunctionOption>();
  const [selectedPivotFieldCustomFunction, setSelectedPivotFieldCustomFunction] = useState<CustomFunctionOption>();
  const customFunctionOptions =
    reportContext?.customFunctionOptions?.filter(
      (x) => selectedPivotDataField && x.dataTypes.includes(selectedPivotDataField.dataType) && x.canPivot
    ) || [];

  // a field in selected tables is pivotable if any satisfies below:
  // - if it's a look up
  // - if it is a Date field
  //   const pivotFieldOptions = selectableTables
  //     .flatMap((table) => table.dataFields)
  //     .filter(
  //       (field) =>
  //         field.dataType === "Date" ||
  //         lookups.some(
  //           (lookup) =>
  //             lookup.dbName.toLowerCase() === field.dbName.toLowerCase() &&
  //             lookup.table.toLowerCase() === field.field.table.toLowerCase() &&
  //             lookup.column.toLowerCase() === field.field.field.toLowerCase()
  //         )
  //     );
  const pivotValueOptions = getPivotValueOptions(selectedPivotDataField);
  const [pivotValueTotalSelected, setPivotValueTotalSelected] = useState(false);

  function getPivotValueOptions(field: DbtDataField | undefined) {
    let options: PivotValueOption[] = [];

    if (field) {
      if (field.dataType === "Date") {
        switch (selectedPivotFieldCustomFunction?.function) {
          case "Month":
            for (let month = 1; month <= 12; month++) {
              const date = new Date(0, month - 1);
              const monthName = date.toLocaleDateString("en", { month: "short" });
              options.push({ label: monthName, value: month.toString() });
            }
            break;
          default:
            return [];
        }
      } else {
        options =
          lookups
            .find(
              (lookup) =>
                lookup.dbName.toLowerCase() === selectedPivotDataField?.dbName.toLowerCase() &&
                lookup.table.toLowerCase() === selectedPivotDataField?.tableName.toLowerCase() &&
                lookup.column.toLowerCase() === selectedPivotDataField?.fieldName.toLowerCase()
            )
            ?.values?.map((x) => {
              return {
                label: x,
                value: x,
              };
            }) || [];
      }
    }

    return options;
  }

  const aggregateFunctionOptions =
    reportContext.aggregateFunctionOptions?.filter((x) => x.dataTypes.includes(props.dataField.dataType)) || [];

  const addColumns = () => {
    if (selectedPivotDataField && selectedAggregateFunction) {
      reportContext.setQueryRequest(
        produce(reportContext.queryRequest, (draft) => {
          // add each column
          selectedPivotValues.forEach((value) => {
            draft.fields.push({
              dbName: props.dataField.dbName,
              tableName: props.dataField.tableName,
              fieldName: props.dataField.fieldName,
              transform: "AggregateFunction",
              aggregateFunction: selectedAggregateFunction.function,
              aggregatePivot: {
                field: {
                  dbName: selectedPivotDataField.dbName,
                  tableName: selectedPivotDataField.tableName,
                  fieldName: selectedPivotDataField.fieldName,
                  transform: selectedPivotFieldCustomFunction ? "CustomFunction" : undefined,
                  customFunction: selectedPivotFieldCustomFunction?.function,
                },
                values: [value.value],
              },
            });
          });

          // add total column
          if (pivotValueTotalSelected) {
            draft.fields.push({
              dbName: props.dataField.dbName,
              tableName: props.dataField.tableName,
              fieldName: props.dataField.fieldName,
              transform: "AggregateFunction",
              aggregateFunction: selectedAggregateFunction.function,
              aggregatePivot: {
                field: {
                  dbName: selectedPivotDataField.dbName,
                  tableName: selectedPivotDataField.tableName,
                  fieldName: selectedPivotDataField.fieldName,
                  transform: selectedPivotFieldCustomFunction ? "CustomFunction" : undefined,
                  customFunction: selectedPivotFieldCustomFunction?.function,
                },
                values: [...selectedPivotValues.values()].map((x) => x.value),
              },
            });
          }
        })
      );
    }

    props.onClose();
  };

  return (
    <Dialog
      isForm
      open
      onClose={() => props.onClose()}
      maxWidth="sm"
      header={`Pivot: ${props.dataField.displayName}`}
      content={
        <Stack spacing="sm">
          <Select
            label="Aggregate"
            value={selectedAggregateFunction}
            options={aggregateFunctionOptions}
            getOptionLabel={(option) => `${option.function}: ${props.dataField.displayName}`}
            onChange={(e, value) => setSelectedAggregateFunction(value)}
            size="small"
          />

          <Select
            label="Pivot column"
            name="pivotColumn" // avoid password suggest
            value={selectedPivotDataField}
            options={props.pivotFieldOptions}
            getOptionLabel={(option) => option.displayName}
            onChange={(e, value) => {
              setSelectedPivotDataField(value);
              setSelectedPivotValues(new Map());
              setPivotValueTotalSelected(false);
            }}
            size="small"
          />

          {customFunctionOptions.length > 0 && (
            <Select
              label="Pivot method"
              value={selectedPivotFieldCustomFunction}
              options={customFunctionOptions}
              getOptionLabel={(option) => `by ${option.function}`}
              onChange={(e, value) => {
                setSelectedPivotFieldCustomFunction(value);
                setSelectedPivotValues(new Map());
                setPivotValueTotalSelected(false);
              }}
              size="small"
            />
          )}

          {pivotValueOptions.length > 0 && (
            <Stack border={(theme) => `1px solid ${theme.palette.border.main}`} p={getSpacing("md")}>
              <Stack alignItems={"flex-start"}>
                {selectedPivotValues.size !== pivotValueOptions.length && (
                  <Button
                    onClick={() => {
                      setSelectedPivotValues(
                        new Map(getPivotValueOptions(selectedPivotDataField).map((x) => [x.label, x]))
                      );
                      setPivotValueTotalSelected(true);
                    }}
                    variant="outlined"
                  >
                    <Typography sx={{ textTransform: "none !important" }}>+ All</Typography>
                  </Button>
                )}
              </Stack>

              {pivotValueOptions.map((option) => {
                return (
                  <Checkbox
                    key={option.label}
                    label={option.label}
                    checked={selectedPivotValues.has(option.label)}
                    onChange={() => {
                      const updated = new Map(selectedPivotValues);
                      if (updated.has(option.label)) updated.delete(option.label);
                      else updated.set(option.label, option);
                      setSelectedPivotValues(updated);
                    }}
                  />
                );
              })}

              {selectedPivotValues.size > 0 && (
                <Checkbox
                  label={
                    <Stack>
                      <Typography fontWeight={(theme) => theme.typography.fontWeightMedium}>
                        Total (of {selectedPivotValues.size} selected)
                      </Typography>
                    </Stack>
                  }
                  checked={pivotValueTotalSelected}
                  onChange={(e) => setPivotValueTotalSelected(e.target.checked)}
                />
              )}
            </Stack>
          )}
        </Stack>
      }
      footer={
        <Stack direction="row" spacing="sm">
          <CloseButton onClick={() => props.onClose()} />
          <Button variant="outlined" onClick={() => addColumns()} disabled={selectedPivotValues.size === 0}>
            Add Columns
          </Button>
        </Stack>
      }
    />
  );
};